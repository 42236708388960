import React, { useContext } from 'react';
import { Card, CardActionArea, useTheme } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { Course } from 'models';
import { DataContext } from 'providers/Data';
import { Duration } from 'components/Common/Duration';
import { RobotoMedium } from 'components/Common/Roboto/Medium';
import { Status } from 'components/Common/Status';
import { useStyles } from '.';

export default function CourseCard({
  course,
  currentLocale,
  disabled,
  fallbackLocale,
}: {
  course: Course;
  currentLocale: string;
  disabled?: boolean;
  fallbackLocale: string;
}) {
  const availableCourses = useContext(DataContext).courses.app;
  const availableLessons = useContext(DataContext).lessons.app;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const { userProgress } = useContext(DataContext);
  const passedLessons = userProgress.map(progress => progress.lesson);
  const locked = course.isLocked(availableCourses, availableLessons, passedLessons);

  return (
    <Card classes={{ root: classes.root }} style={{ background: theme.palette.background.paper }}>
      <CardActionArea
        className={classes.root}
        disabled={disabled || locked}
        onClick={() => history.push(`/learning/course/${course.id}`)}
      >
        <div className={classes.top} style={{ backgroundColor: course.courseColor }}>
          <RobotoMedium style={{ color: '#FFF', fontSize: 14, width: '70%' }}>
            {course.getTitle(currentLocale, fallbackLocale)}
          </RobotoMedium>
          {course.courseLogoUrl && (
            <div
              style={{
                backgroundImage: `url("${course.courseLogoUrl}")`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: '100%',
                width: '30%',
              }}
            />
          )}
        </div>
        <div className={classes.bottom}>
          <div className={classes.status}>
            <Status status={course.getStatus(availableLessons, passedLessons, userProgress)} />
          </div>
          <div className={classes.duration}>
            <Duration minutes={course.getDuration(availableLessons)} />
          </div>
        </div>
      </CardActionArea>
      {locked && (
        <div className={classes.overlay}>
          <Lock />
        </div>
      )}
    </Card>
  );
}
