import React, { useRef } from 'react';
import clsx from 'clsx';
import { useStyles } from '.';

import defaultErrorImage from 'static/img/logo-dark.svg';

export default function FallbackImage({
  alt,
  className,
  height,
  src,
  width,
}: {
  alt?: string;
  className?: string;
  height?: string | number;
  src?: string;
  width?: string | number;
}) {
  const classes = useStyles();
  const imageEl = useRef(null);

  const classNames = [classes.fallback];

  if (!!className) {
    classNames.push(className);
  }

  return (
    <img
      alt={alt}
      className={className}
      height={height}
      onError={() => {
        // @ts-ignore
        imageEl.current.className = clsx([...classNames]);
        // @ts-ignore
        imageEl.current.src = defaultErrorImage;
      }}
      ref={imageEl}
      src={src}
      width={width}
    />
  );
}
