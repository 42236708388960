import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(10),
    marginTop: theme.spacing(3),
    width: theme.spacing(10),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textTransform: 'none',
  },
  input: {
    display: 'none',
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#000',
    color: '#FFF',
    cursor: 'pointer',
    borderRadius: '50%',
    height: theme.spacing(10),
    marginTop: theme.spacing(3),
    width: theme.spacing(10),
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    opacity: theme.palette.type === 'dark' ? 0.75 : 0.5,
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export { default as AvatarCard } from './AvatarCard';
