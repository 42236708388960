import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const unitUserValidation = () =>
  yup.object({
    email: yup.string().required(i18n.t('form_value_required')).email(i18n.t('form_value_email')),
    confirmEmail: yup
      .string()
      .required(i18n.t('form_value_required'))
      .email(i18n.t('form_value_email'))
      .oneOf([yup.ref('email')], i18n.t('form_value_confirm_new_email')),
    groups: yup.array().of(yup.string()),
    units: yup.array().of(yup.string()).required(i18n.t('form_value_required')).min(1, i18n.t('form_value_required')),
  });

export { default as AdminUnitsPage } from './AdminUnitsPage';
