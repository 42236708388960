import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.background.default,
  },
  defaultPadding: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  iconButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  left: {
    flexGrow: 1,
    width: '100%',
  },
  logo: {
    height: 28,
    width: 28,
  },
  main: {
    flexGrow: 1, // flexGrow: 1 for remaining height for main
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(3.5),
    },
  },
  mobileToolbar: {
    padding: theme.spacing(0, 0.5),
  },
  title: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: ' 100%',
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  verticalCentered: {
    flexGrow: 0,
    marginBottom: 'auto',
    marginTop: 'auto',
  },
}));

export { default as Layout } from './Layout';
