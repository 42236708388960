import React from 'react';
import { Brand } from 'models';
import { addIdToData } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';
import { IBrandDataState } from 'interfaces';

export default function useBrand(brandPath: string) {
  const [state, setState] = React.useState<IBrandDataState>({
    brandError: { status: false, message: '' },
    brandData: undefined,
    brandLoading: true,
  });

  React.useEffect(() => {
    onFirestore('onSnapshot', brandPath);
    const unsubscribe = firestore.doc(brandPath).onSnapshot(
      snapshot => {
        onListener(0, 1, 'Brand', snapshot.id, -1, 0, 'changed');
        const brand = new Brand({ ...addIdToData(snapshot.data(), snapshot.id) });
        setState(prevState => ({ ...prevState, brandData: brand, brandLoading: false }));
      },
      error => {
        const message = 'Brand data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({ ...prevState, brandError: { status: true, message }, brandLoading: false }));
        onError('Brand', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [brandPath]);

  return { ...state };
}
