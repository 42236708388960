import { makeStyles } from '@material-ui/core';
import * as yup from 'yup';

export const fileValidation = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string();
    else {
      localeObject[key] = yup.string();
    }
  });

  const schema = yup.object().shape({
    groupRestrictions: yup.array().of(yup.string()),
    hasFile: yup
      .boolean()
      .required()
      .test('isTrue', 'hasFile must be true', value => value === true),
    localizedFields: yup
      .object({
        description: yup.object({ ...localeObject }).noUnknown(),
      })
      .noUnknown(),
    metaData: yup.object().shape({
      contentType: yup.string(),
      size: yup.number().required(),
    }),
    name: yup.string().required().max(256),
    roleRestricitons: yup.array().of(yup.string()),
  });

  return schema;
};

export const useStyles = makeStyles(theme => ({
  bottom: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    height: theme.spacing(4.75),
    padding: theme.spacing(0.5, 2, 0.5, 1),
  },
  cell: {
    borderBottom: 0,
    fontSize: theme.typography.fontSize - 2,
  },
  input: {
    display: 'none',
  },
  top: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(11),
    justifyContent: 'center',
  },
}));

export { default as FileFields } from './FileFields';
