import * as yup from 'yup';

export const courseValidationRestrictions = () =>
  yup.object().shape({
    groupRestrictions: yup.array().of(yup.string()),
    preConditions: yup.array().of(yup.string()),
    roleRestrictions: yup.array().of(yup.string()),
  });

export { default as AdminCourseDetailRestrictionsPage } from './AdminCourseDetailRestrictionsPage';
