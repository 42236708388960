import React, { CSSProperties, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '.';

export default function RobotoBold({
  children,
  noWrap,
  style,
}: {
  children?: ReactNode;
  noWrap?: boolean;
  style?: CSSProperties;
}) {
  const classes = useStyles();

  return (
    <Typography className={classes.root} component='div' noWrap={noWrap} style={style}>
      {children || null}
    </Typography>
  );
}
