import React from 'react';
import { IResourcesDataState } from 'interfaces';
import { Resource } from 'models';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useResources(isUserAdmin?: boolean) {
  const [state, setState] = React.useState<IResourcesDataState>({
    resourcesError: { status: false, message: '' },
    resourcesData: [],
    resourcesLoading: true,
  });
  const resourcesPath = firestore.collection(Resource.collection).path;

  React.useEffect(() => {
    if (isUserAdmin) {
      onFirestore('onSnapshot', resourcesPath);
      const unsubscribe = firestore.collection(resourcesPath).onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(
              i,
              snapshot.docs.length,
              'Resource',
              change.doc.id,
              change.oldIndex,
              change.newIndex,
              change.type
            );
          });
          const resources = snapshot.docs
            .map(doc => new Resource(addIdToDocument(doc)))
            .filter(resource => resource.id !== 'rLoginBackend');
          setState(prevState => ({ ...prevState, resourcesData: resources, resourcesLoading: false }));
        },
        error => {
          const message = 'Resource data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({ ...prevState, resourcesError: { status: true, message }, resourcesLoading: false }));
          onError('Resources', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, resourcesLoading: false }));
    }
  }, [isUserAdmin, resourcesPath]);

  return { ...state };
}
