import React from 'react';
import clsx from 'clsx';

import logo from 'static/img/logo.svg';
import word from 'static/img/word-logo.png';
import { useStyles } from '.';

export default function Logo({
  onClick,
  size = 'small',
  wordLogo,
}: {
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large' | 'extraLarge' | 'big';
  wordLogo?: boolean;
}) {
  const classes = useStyles();
  let className = classes[size];

  if (!!onClick) {
    className = clsx(classes[size], classes.pointer);
  }

  if (wordLogo) {
    return <img alt='brainee' className={className} onClick={onClick} src={word} />;
  }

  return <img alt='brainee' className={className} onClick={onClick} src={logo} />;
}
