import React, { CSSProperties, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '.';

export default function RobotoBlack({
  children,
  noWrap,
  style,
}: {
  children?: ReactNode;
  noWrap?: boolean;
  style?: CSSProperties;
}) {
  const classes = useStyles();

  return (
    <Typography component='div' className={classes.root} noWrap={noWrap} style={style}>
      {children || null}
    </Typography>
  );
}
