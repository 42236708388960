import React, { useContext } from 'react';
import { Column } from 'material-table';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { Brand, Unit, User } from 'models';
import { IUnitFormData } from 'interfaces';
import { UnitFields } from 'components/Forms/Fields/Admin/UnitFields';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onError, onFirestore, onSuccess } from 'utils/logger';

export default function AdminBrandUnitsPage() {
  const notify = useNotification();
  const user = useContext(UserContext).user as User;

  const { brand, units } = useContext(DataContext);
  const { t } = useTranslation();

  const [createState, setCreateState] = React.useState<{
    dialogOpen: boolean;
  }>({
    dialogOpen: false,
  });

  const [deleteState, setDeleteState] = React.useState<{
    dialogOpen: boolean;
    unit?: Unit;
    loading: boolean;
    name: string | null;
  }>({
    dialogOpen: false,
    unit: undefined,
    loading: false,
    name: null,
  });

  const [updateState, setUpdateState] = React.useState<{
    dialogOpen: boolean;
    loading: boolean;
    unit?: Unit;
  }>({
    dialogOpen: false,
    loading: false,
    unit: undefined,
  });

  const handleCreate = async ({ internalId, key }: IUnitFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', brandRef.collection(Unit.collection).path);

    return new Promise<void>(async (resolve, reject) => {
      try {
        await brandRef.collection(Unit.collection).doc().set({
          createdAt: serverTimestamp,
          createdFrom: userRef.id,
          createdFromRef: userRef,
          internalId,
          key,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        });
        onSuccess(brandRef.collection(Unit.collection).doc().path, 'set-success');
        setCreateState({ dialogOpen: false });
        return resolve();
      } catch (err) {
        onError('AdminBrandUnitsPage', err);
        setCreateState({ dialogOpen: false });
        return reject(err);
      }
    });
  };

  const handleDelete = async () => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);

    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const { unit } = { ...deleteState };

    if (unit) {
      try {
        const unitRef = brandRef.collection(Unit.collection).doc(unit.id);
        onFirestore('delete', unitRef.path);
        await unitRef.delete();
        notify(t('alert_unit_deletion_success'));
        onSuccess(unitRef.path, 'delete-success');
      } catch (err) {
        onError('AdminBrandUnitsPage', err);
        notify(t('alert_unit_deletion_error'), 'error');
      }
    } else {
      console.error('Unit not found');
      notify(t('alert_unit_deletion_error'), 'error');
    }

    setDeleteState({ unit: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteDialog = (id: string) => {
    const unit = units.find(unit => unit.id === id);

    if (!unit) {
      notify(t('alert_unit_deletion_error'), 'error');
    } else {
      setDeleteState({ unit, dialogOpen: true, loading: false, name: unit.key ? unit.key : null });
    }
  };

  const handleUpdate = async ({ internalId, key }: IUnitFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    const { unit } = { ...updateState };

    return new Promise<void>(async (resolve, reject) => {
      if (unit === undefined) {
        return reject('Unit not found');
      }

      const unitRef = brandRef.collection(Unit.collection).doc(unit.id);

      onFirestore('set', unitRef.path);
      setUpdateState(prevState => ({ ...prevState, loading: true }));

      try {
        unitRef.set(
          {
            internalId,
            key,
            lastUpdateAt: serverTimestamp,
            lastUpdateFrom: userRef.id,
            lastUpdateFromRef: userRef,
          },
          { merge: true }
        );
        setUpdateState({ dialogOpen: false, loading: false, unit: undefined });
        return resolve();
      } catch (err) {
        onError('AdminBrandUnitPage', err);
        setUpdateState({ dialogOpen: false, loading: false, unit: undefined });
        return reject(err);
      }
    });
  };

  const columns: Column<object>[] = [
    { field: 'key', title: t('unit_key_label'), defaultSort: 'asc' },
    { field: 'internalId', title: t('unit_internalId_label') },
    {
      defaultSort: 'desc',
      editable: 'never',
      field: 'lastUpdateAt',
      title: t('last_modified'),
      type: 'date',
    },
    {
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ id }: any) => (
        <Link
          color='error'
          component='button'
          onClick={() => {
            handleOpenDeleteDialog(id);
          }}
          underline='none'
        >
          {t('delete_unit_button')}
        </Link>
      ),
    },
  ];

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_unit_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ unit: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_unit_confirmation_dialog_title')}
        type='delete'
      />
      {createState.dialogOpen && (
        <UnitFields
          handleClick={values => handleCreate(values)}
          handleClose={() => {
            setCreateState({ dialogOpen: false });
          }}
          initialValues={{ internalId: '', key: '' }}
          open={createState.dialogOpen}
          title={t('create_unit_dialog_title')}
          type='create'
        />
      )}
      {updateState.dialogOpen && (
        <UnitFields
          handleClick={values => handleUpdate(values)}
          handleClose={() => {
            setUpdateState(prevState => ({ ...prevState, dialogOpen: false, unit: undefined }));
          }}
          initialValues={{
            key: updateState.unit?.key || '',
            internalId: updateState.unit?.internalId || '',
          }}
          open={updateState.dialogOpen}
          title={t('update_unit_dialog_title')}
          type='update'
        />
      )}
      <Table
        columns={[...columns]}
        data={units.map(unit => ({
          id: unit.id,
          internalId: unit.internalId,
          key: unit.key,
          lastUpdateAt: unit.lastUpdateAt ? unit.lastUpdateAt.toDate() : t('loading_progress'),
        }))}
        title='Units'
        onAddClick={() => {
          setCreateState({ dialogOpen: true });
        }}
        onRowClick={(event, { id }: any) => {
          setUpdateState(prevState => ({
            ...prevState,
            dialogOpen: true,
            unit: units.find(unit => unit.id === id),
          }));
        }}
      />
    </>
  );
}
