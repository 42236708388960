import React from 'react';
import { IUsersDataState } from 'interfaces';
import { User } from 'models';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useUsers(brandId: string, isUserAdmin: boolean) {
  const [state, setState] = React.useState<IUsersDataState>({
    usersError: { status: false, message: '' },
    usersData: { admin: [] },
    usersLoading: true,
  });

  const usersPath = firestore.collection(User.collection).path;

  React.useEffect(() => {
    if (isUserAdmin) {
      onFirestore('onSnapshot', usersPath);
      const unsubscribe = firestore
        .collection(usersPath)
        .where('brand', '==', brandId)
        .onSnapshot(
          snapshot => {
            snapshot.docChanges().forEach((change, i) => {
              onListener(i, snapshot.docs.length, 'User', change.doc.id, change.oldIndex, change.newIndex, change.type);
            });
            const users = snapshot.docs.map(doc => new User(addIdToDocument(doc)));
            setState(prevState => ({ ...prevState, usersData: { admin: users, app: [] }, usersLoading: false }));
          },
          error => {
            const message = 'Users data could not be loaded.';
            console.error('Error: ', message);
            setState(prevState => ({ ...prevState, usersError: { status: true, message }, usersLoading: false }));
            onError('Users', error);
          }
        );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, usersLoading: false }));
    }
  }, [brandId, isUserAdmin, usersPath]);

  return { ...state };
}
