import { useEffect, useState } from 'react';

import { User } from 'models';
import { authentication, firestore, serverTimestamp } from 'configuration/firebase';
import { onSuccess, onFirestore } from 'utils/logger';

export function useAuthentication() {
  const [state, setState] = useState<{
    loading: boolean;
    authUser: firebase.default.User | null;
  }>({ loading: true, authUser: null });

  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged(authUser => {
      if (authUser) {
        const userRef = firestore.collection(User.collection).doc(authUser.uid);
        onFirestore('set', userRef.path, 'lastLoginAt');
        userRef
          .set(
            {
              lastLoginAt: { general: serverTimestamp },
            },
            { merge: true }
          )
          .then(() => {
            onSuccess(userRef.path, 'set-success', 'lastLoginAt');
          })
          .catch(() => {
            console.error('Login data could not be stored.');
          })
          .finally(() => {
            setState(prevState => ({ ...prevState, loading: false, authUser }));
          });
      } else {
        setState(prevState => ({ ...prevState, loading: false, authUser: null }));
      }
    });
    return () => unsubscribe();
  }, []);

  return { ...state };
}
