import React, { Dispatch, useContext } from 'react';
import { Button, Container, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Logo } from 'components/Common/Logo';
import { Layout } from 'components/Layout';
import { LoadingButton } from 'components/Buttons/Loading';
import { NotificationContext } from 'providers/Notifications';
import { NotificationProviderActions } from 'types';
import { functions } from 'configuration/firebase';
import { useGlobalStyles } from 'providers/Theme';
import { validationSchema, initialValues, useStyles } from '.';

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const dispatch: Dispatch<NotificationProviderActions> = useContext(NotificationContext).dispatch;
  const theme = useTheme();

  const { t } = useTranslation();

  const handleSubmit = (
    values: { email: string },
    formikHelpers: FormikHelpers<{
      email: string;
    }>
  ) => {
    formikHelpers.setSubmitting(true);
    const sendResetPasswordMail = functions.httpsCallable('sendResetPasswordMail');
    sendResetPasswordMail({ email: values.email })
      .then(() => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: t('alert_reset_password_success') },
        });
        formikHelpers.setSubmitting(false);
        history.push('/');
      })
      .catch(err => {
        formikHelpers.setSubmitting(false);
        dispatch({ type: 'ADD_NOTIFICATION', payload: { message: t('alert_reset_password_error'), type: 'error' } });
      });
  };

  return (
    <Layout disableHeader verticalCentered>
      <Container maxWidth='sm'>
        <div className={globalClasses.paper}>
          <Logo size='large' />
          <br />
          <Typography variant='caption' style={{ marginTop: theme.spacing(1) }}>
            {t('forgot_password_1')}
          </Typography>
          <Typography variant='caption'>{t('forgot_password_2')}</Typography>
          <br />
          <Formik
            validateOnBlur
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form className={globalClasses.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  autoComplete='email'
                  disabled={isSubmitting}
                  error={!!errors.email && touched.email}
                  fullWidth
                  helperText={!!errors.email && touched.email && errors.email ? errors.email : ' '}
                  FormHelperTextProps={{
                    style: {
                      minHeight: 19,
                    },
                  }}
                  id='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label='Email'
                  margin='none'
                  name='email'
                  required
                  type='email'
                  value={values.email}
                  variant='outlined'
                />
                <div className={classes.container} style={{ margin: theme.spacing(1, 0) }}>
                  <LoadingButton
                    color='primary'
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    {t('get_reset_link_button')}
                  </LoadingButton>
                </div>
                <Grid container>
                  <Grid item xs>
                    <Grid container justify='flex-end'>
                      <Button disabled={isSubmitting} onClick={() => history.push('/')} variant='text'>
                        {t('back_to_login')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
}
