import { createStyles, makeStyles, ButtonProps } from '@material-ui/core';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      color: theme.palette.text.secondary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: -12,
      marginTop: -12,
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
    },
  })
);

export { default as LoadingButton } from './LoadingButton';
