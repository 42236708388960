import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '.';

export default function LoadingBackdrop({
  showText,
  text,
  hideLoading,
  open,
}: {
  showText?: boolean;
  text?: string;
  hideLoading?: boolean;
  open: boolean;
}) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div className={classes.container}>
        {hideLoading ? null : <CircularProgress color='inherit' />}
        {showText && text && (
          <Typography className={classes.text} variant='body2'>
            {text}
          </Typography>
        )}
      </div>
    </Backdrop>
  );
}
