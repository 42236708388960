import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  IThreeColumnAdminLayoutItem,
  ITwoColumnAdminLayoutItem,
} from 'interfaces';

export default function convertEditorItemToHtml(
  item:
    | IAdminContentEditorItem
    | IContentImageItem
    | IContentVideoItem
    | ITwoColumnAdminLayoutItem
    | IThreeColumnAdminLayoutItem
): any {
  switch (item.type) {
    case 'editor': {
      const rawContentState = convertToRaw(item.content.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      return { ...item, content: markup };
    }
    case 'image': {
      const { name, url } = item.content;
      return { ...item, content: { name, url } };
    }
    case 'video': {
      const { name, url } = item.content;
      return { ...item, content: { name, url } };
    }
    case 'two-column': {
      const { columns } = item;
      return {
        ...item,
        columns: {
          left: [...columns.left.map(leftItem => convertEditorItemToHtml(leftItem))],
          right: [...columns.right.map(rightItem => convertEditorItemToHtml(rightItem))],
        },
      };
    }
    case 'three-column': {
      const { columns } = item;
      return {
        ...item,
        columns: {
          left: [...columns.left.map(leftItem => convertEditorItemToHtml(leftItem))],
          middle: [...columns.middle.map(middleItem => convertEditorItemToHtml(middleItem))],
          right: [...columns.right.map(rightItem => convertEditorItemToHtml(rightItem))],
        },
      };
    }
    default:
      return null;
  }
}
