import { createContext } from 'react';
import { IDataContext } from 'interfaces';

export const DataContext = createContext<IDataContext>({
  adminLessonContents: [],
  brand: undefined,
  courses: { app: [], admin: [] },
  files: { admin: [], app: [] },
  groups: [],
  lessons: { admin: [], app: [] },
  news: { admin: [], app: [] },
  resources: [],
  roles: {
    brand: [],
    default: [],
  },
  units: [],
  users: { admin: [] },
  userProgress: [],
});

export { default as DataProvider } from './DataProvider';
