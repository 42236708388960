import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const userValidation = () =>
  yup.object().shape({
    currentLocale: yup.string().required(i18n.t('form_value_required')),
    email: yup.string().email(i18n.t('form_value_email')).required(i18n.t('form_value_required')),
    groups: yup.array().of(yup.string()),
    role: yup.string().required(i18n.t('form_value_required')),
    units: yup.array().of(yup.string()),
  });

export { default as UserFields } from './UserFields';
