import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { DataContext } from 'providers/Data';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { IRoleFormData } from 'interfaces';
import { SelectWithChipsField } from 'components/Forms/SelectWithChips';
import { UserContext } from 'providers/User';
import { roleValidation } from '.';
import { useGlobalStyles } from 'providers/Theme';

export default function RoleFields({
  handleClick,
  handleClose,
  open,
  initialValues,
  title,
  type,
}: {
  handleClick: (values: IRoleFormData) => Promise<void>;
  handleClose: () => void;
  open: boolean;
  initialValues: IRoleFormData;
  title: string;
  type: 'create' | 'update';
}) {
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const { resources } = useContext(DataContext);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleClick(values)
          .then(() => {
            if (type === 'create') {
              notify(t('alert_role_creation_success'));
            }
            if (type === 'update') {
              notify(t('alert_role_update_success'));
            }
          })
          .catch(() => {
            if (type === 'create') {
              notify(t('alert_role_creation_error'), 'error');
            }
            if (type === 'update') {
              notify(t('alert_role_update_error'), 'error');
            }
          });
      }}
      validateOnBlur
      validationSchema={() => roleValidation()}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <FormDialog
            createButtonText={t('create_button')}
            handleClick={handleSubmit}
            handleClose={handleClose}
            loading={isSubmitting}
            open={open}
            title={title}
            type={type}
            updateButtonText={t('update_button')}
          >
            <Form className={globalClasses.form} noValidate>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting}
                    error={!!errors.key && touched.key}
                    fullWidth
                    helperText={!!errors.key && touched.key && errors.key ? errors.key : ' '}
                    FormHelperTextProps={{
                      style: {
                        minHeight: 19,
                      },
                    }}
                    id='key'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={t('role_key_label')}
                    name='key'
                    required
                    size='small'
                    value={values.key}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWithChipsField
                    dense
                    disabled={resources.length === 0 || isSubmitting}
                    handleChange={handleChange}
                    label={t('role_resources_selection_label')}
                    name='resources'
                    selectedValues={values.resources}
                    values={resources.map(resource => ({ label: resource.key || resource.id, value: resource.id }))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                    <Grid item xs={12}>
                      <FormControlLabel
                        classes={{
                          label: globalClasses.checkboxLabels,
                        }}
                        control={
                          <Checkbox
                            checked={values.isSelectableInRestrictions}
                            color='primary'
                            disabled={isSubmitting || !user?.isFileAdmin() || !user?.isLearningContentAdmin()}
                            name='isSelectableInRestrictions'
                            onChange={handleChange}
                            size='small'
                            style={{ marginRight: 4 }}
                          />
                        }
                        label={t('role_selectable_in_restrictions_switcher_description')}
                        labelPlacement='start'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormDialog>
        );
      }}
    </Formik>
  );
}
