import React, { useContext } from 'react';
import clsx from 'clsx';
import { Breadcrumbs, createStyles, Link, Typography, withStyles } from '@material-ui/core';
import { DataContext } from 'providers/Data';
import { useHistory } from 'react-router-dom';
import { useGlobalStyles } from 'providers/Theme';

const CustomLink = withStyles(theme =>
  createStyles({
    root: {
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
  })
)(Link);

export default function AdminLearningItemDetailSubHeader({
  courseId,
  lessonId,
  lessonContentId,
}: {
  courseId?: string;
  lessonId?: string;
  lessonContentId?: string;
}) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const { courses, lessons, adminLessonContents } = useContext(DataContext);

  const handleCourseClick = (id: string) => {
    history.push(`/admin/learning-content/course/${id}`);
  };

  const handleLessonClick = (id: string) => {
    history.push(`/admin/learning-content/lesson/${id}`);
  };

  const course = courses.admin.find(courseItem => courseId && courseItem.id === courseId);
  const lesson = lessons.admin.find(lessonItem => lessonId && lessonItem.id === lessonId);
  const lessonContent = adminLessonContents.find(
    lessonContentItem => lessonContentId && lessonContentItem.id === lessonContentId
  );

  if (!!course && lesson === undefined && lessonContent === undefined) {
    return (
      <div className={globalClasses.layout}>
        <div
          className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}
          style={{ paddingBottom: 0 }}
        >
          <Breadcrumbs>
            <Typography variant='body2'>{course.key}</Typography>
          </Breadcrumbs>
        </div>
      </div>
    );
  }

  if (!!course && !!lesson && lessonContent === undefined) {
    return (
      <div className={globalClasses.layout}>
        <div
          className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}
          style={{ paddingBottom: 0 }}
        >
          <Breadcrumbs>
            <CustomLink
              color='inherit'
              href='#'
              onClick={() => handleCourseClick(course.id)}
              underline='none'
              variant='body2'
            >
              {course.key}
            </CustomLink>
            <Typography variant='body2'>{lesson.key}</Typography>
          </Breadcrumbs>
        </div>
      </div>
    );
  }

  if (!!course && !!lesson && !!lessonContent) {
    return (
      <div className={globalClasses.layout}>
        <div
          className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}
          style={{ paddingBottom: 0 }}
        >
          <Breadcrumbs>
            <CustomLink
              color='inherit'
              href='#'
              onClick={() => handleCourseClick(course.id)}
              underline='none'
              variant='body2'
            >
              {course.key}
            </CustomLink>
            <CustomLink
              color='inherit'
              href='#'
              onClick={() => handleLessonClick(lesson.id)}
              underline='none'
              variant='body2'
            >
              {lesson.key}
            </CustomLink>
            <Typography variant='body2'>{lessonContent.key}</Typography>
          </Breadcrumbs>
        </div>
      </div>
    );
  }

  return null;
}
