import React, { ReactNode } from 'react';
import { Tabs } from '@material-ui/core';

export default function LocaleTabs({
  children,
  onChange,
  value,
}: {
  children: ReactNode;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: boolean | string;
}) {
  return (
    <Tabs
      indicatorColor='primary'
      onChange={onChange}
      scrollButtons='on'
      style={{ width: '100%' }}
      textColor='primary'
      value={value}
      variant='scrollable'
      TabScrollButtonProps={{
        style: { width: 14 },
      }}
    >
      {children}
    </Tabs>
  );
}
