import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Brand, News, User } from 'models';
import { DataContext } from 'providers/Data';
import { INewsFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { NewsFields } from 'components/Forms/Fields/Admin/NewsFields';
import { NotFoundView } from 'views/NotFound';
import { UserContext } from 'providers/User';
import { convertEditorItemToHtml, convertHtmlToEditor, filterEmptyLocaleFields } from 'utils/data';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onError } from 'utils/logger';

export default function AdminUpdateNewsPage() {
  const brand = useContext(DataContext).brand as Brand;
  const user = useContext(UserContext).user as User;

  const { news } = useContext(DataContext);
  const { newsId }: { newsId: string } = useParams();

  const newsItem = news.admin.find(item => item.id === newsId);

  if (newsItem === undefined) return <NotFoundView />;

  const { key, localizedFields, photoUrl, published } = newsItem;

  const contentBuilderLocales = newsItem.locales;

  const localizedFieldCopy: any = {
    caption: {},
    content: {},
    title: {},
  };

  brand.configuration.locales.forEach(locale => {
    localizedFieldCopy['caption'][locale] = localizedFields.caption[locale] ? localizedFields.caption[locale] : '';
    localizedFieldCopy['title'][locale] = localizedFields.title[locale] ? localizedFields.title[locale] : '';

    if (contentBuilderLocales.includes(locale)) {
      localizedFieldCopy['content'][locale] = newsItem.localizedFields.content[locale].map(item => {
        if (item.type === 'editor') {
          return { ...convertHtmlToEditor(item) };
        }

        if (item.type === 'two-column') {
          return {
            ...item,
            columns: {
              left: item.columns.left.map(leftItem => {
                if (leftItem.type === 'editor') {
                  return { ...convertHtmlToEditor(leftItem) };
                }
                return leftItem;
              }),
              right: item.columns.right.map(rightItem => {
                if (rightItem.type === 'editor') {
                  return { ...convertHtmlToEditor(rightItem) };
                }
                return rightItem;
              }),
            },
          };
        }

        if (item.type === 'three-column') {
          return {
            ...item,
            columns: {
              left: item.columns.left.map(leftItem => {
                if (leftItem.type === 'editor') {
                  return { ...convertHtmlToEditor(leftItem) };
                }
                return leftItem;
              }),
              middle: item.columns.middle.map(middleItem => {
                if (middleItem.type === 'editor') {
                  return { ...convertHtmlToEditor(middleItem) };
                }
                return middleItem;
              }),
              right: item.columns.right.map(rightItem => {
                if (rightItem.type === 'editor') {
                  return { ...convertHtmlToEditor(rightItem) };
                }
                return rightItem;
              }),
            },
          };
        }

        return item;
      });
    } else {
      localizedFieldCopy['content'][locale] = [];
    }
  });

  const initialValues: INewsFormData = {
    locales: [...brand.configuration.locales],
    key,
    localizedFields: {
      content: { ...localizedFieldCopy.content },
      caption: { ...localizedFieldCopy.caption },
      title: { ...localizedFieldCopy.title },
    },
    photoUrl,
    published,
  };

  const handleSubmit = async (values: INewsFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand.id);
    const newsRef = brandRef.collection(News.collection).doc(newsItem.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    const { key, localizedFields, photoUrl, published } = values;

    /* Filtes out all locales which does not have any content */
    const filteredLocales = values.locales.filter(locale => {
      const content = localizedFields.content[locale];
      return content && content.length > 0;
    });

    const localizedFieldsCopy = {
      content: {},
      ...filterEmptyLocaleFields({ caption: { ...localizedFields.caption }, title: { ...localizedFields.title } }),
    };

    filteredLocales.forEach(locale => {
      localizedFieldsCopy['content'][locale] = localizedFields.content[locale]
        .map(item => convertEditorItemToHtml(item))
        .filter(item => item !== null);
    });

    // set delete flag for unused fields is not necessary here because news ref update method is used here

    return newsRef
      .update({
        key,
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: user.id,
        lastUpdateFromRef: userRef,
        locales: filteredLocales,
        localizedFields: { ...localizedFieldsCopy },
        photoUrl,
        published,
      })
      .catch(err => {
        onError('AdminUpdateNewsPage', err);
      });
  };

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
      <NewsFields initialValues={initialValues} onSubmit={handleSubmit} type='update' />
    </Layout>
  );
}
