import React from 'react';
import { Course } from 'models';
import { ICoursesDataState } from 'interfaces';
import { addIdToDocument, conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useCourses(
  brandPath: string,
  isLearningContentAdmin: boolean,
  userGroups: string[],
  userRole: string
): ICoursesDataState {
  const [state, setState] = React.useState<ICoursesDataState>({
    coursesError: { status: false, message: '' },
    coursesData: { admin: [], app: [] },
    coursesLoading: true,
  });

  const coursesPath = firestore.doc(brandPath).collection(Course.collection).path;

  React.useEffect(() => {
    onFirestore('onSnapshot', coursesPath);

    const collectionRef = conditionalCollection(coursesPath);
    let query = collectionRef;

    if (!isLearningContentAdmin) {
      query = query.where('published', '==', true);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'Course', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const courses = snapshot.docs.map(doc => new Course(addIdToDocument(doc)));
        setState(prevState => ({
          ...prevState,
          coursesData: {
            ...prevState.coursesData,
            app: courses,
            admin: isLearningContentAdmin ? courses : [],
          },
          coursesLoading: false,
        }));
      },
      error => {
        const message = 'Courses data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({ ...prevState, coursesError: { status: true, message }, coursesLoading: false }));
        onError('Course', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [coursesPath, isLearningContentAdmin]);

  return {
    ...state,
    coursesData: {
      ...state.coursesData,
      app: state.coursesData.app
        /**
         * Filtering of app courses is done here in return statement to prevent user group array to be in use effect deps array.
         * see: https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
         * and see: https://stackoverflow.com/questions/36084515/how-does-shallow-compare-work-in-react
         */
        .filter(course => course.isNotRestricted(userGroups, userRole) && course.published),
    },
  };
}
