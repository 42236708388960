import React, { useContext, useState } from 'react';
import { Check } from '@material-ui/icons';
import { Link, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { File } from 'models';
import { Layout } from 'components/Layout';
import { Table } from 'components/Common/Table';

export default function AdminFilesPage() {
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();

  const { brand, files } = useContext(DataContext);
  const { t } = useTranslation();

  const [deleteState, setDeleteState] = useState<{
    file?: File;
    dialogOpen: boolean;
    loading: boolean;
    name: string | null;
  }>({
    file: undefined,
    dialogOpen: false,
    loading: false,
    name: null,
  });

  const handleOpenDeleteDialog = (id: string) => {
    const file = files.admin.find(file => file.id === id);

    if (!file) {
      notify(t('alert_file_deletion_error'), 'error');
    } else {
      setDeleteState({ file, dialogOpen: true, loading: false, name: file.name });
    }
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const copy = { ...deleteState };

    if (copy.file) {
      try {
        await copy.file.delete(brand?.id as string, copy.file.downloadUrl);
        notify(t('alert_file_deletion_success'));
      } catch (err) {
        console.error(err);
        notify(t('alert_file_deletion_error'), 'error');
      }
    } else {
      notify(t('alert_file_deletion_error'), 'error');
    }

    setDeleteState({ file: undefined, loading: false, name: null, dialogOpen: false });
  };

  const onAddClick = () => history.push('/admin/files/new-file');

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_file_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ file: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_file_confirmation_dialog_title')}
        type='delete'
      />
      <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
        <Table
          onAddClick={onAddClick}
          columns={[
            {
              field: 'name',
              title: 'Name',
              cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 280 },
            },
            {
              field: 'size',
              title: t('file_size'),
            },
            {
              field: 'hasRestrictions',
              title: t('restrictions'),
              render: (rowData: any) =>
                rowData.hasRestrictions ? (
                  <div style={{ fontSize: theme.typography.fontSize - 1 }}>
                    <Check fontSize='inherit' />
                  </div>
                ) : null,
            },
            {
              field: 'published',
              title: 'Status',
              type: 'string',
              render: ({ published }: any) =>
                published ? 'Published' : <span style={{ color: theme.palette.error.light }}>Unpublished</span>,
            },
            {
              defaultSort: 'desc',
              field: 'lastUpdateAt',
              title: t('last_modified'),
              type: 'date',
            },
            {
              field: 'actions',
              sorting: false,
              searchable: false,
              disableClick: true,
              align: 'right',
              render: ({ downloadUrl }: any) => (
                <Link component='button' onClick={() => window.open(downloadUrl, '_blank')} underline='none'>
                  {t('preview_file_button')}
                </Link>
              ),
            },
            {
              field: 'actions',
              sorting: false,
              searchable: false,
              disableClick: true,
              align: 'right',
              render: ({ id }: any) => (
                <Link color='error' component='button' onClick={() => handleOpenDeleteDialog(id)} underline='none'>
                  {t('delete_file_button')}
                </Link>
              ),
            },
          ]}
          data={files.admin.map(file => {
            const {
              metaData: { size },
              downloadUrl,
              hasGroupRestrictions,
              hasRoleRestrictions,
              id,
              lastUpdateAt,
              name,
              published,
            } = file;

            return {
              downloadUrl,
              hasRestrictions: hasGroupRestrictions || hasRoleRestrictions,
              id,
              lastUpdateAt: lastUpdateAt ? lastUpdateAt.toDate() : t('loading_progress'),
              name,
              published,
              size:
                Math.floor((size / 1000 / 1000) * 100) / 100 === 0
                  ? '0,01 MB'
                  : Math.floor((size / 1000 / 1000) * 100) / 100 + ' MB',
            };
          })}
          onRowClick={(event, data: any) => history.push(`/admin/files/update-file/${data.id}`)}
          title={t('/files')}
        />
      </Layout>
    </>
  );
}
