import React from 'react';
import clsx from 'clsx';
import { ContentBuilderItemDialog } from 'components/Dialogs/ContentBuilderItemDialog';
import { Delete, DragIndicator, Edit } from '@material-ui/icons';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  ITwoColumnAdminLayoutItem,
  IThreeColumnAdminLayoutItem,
} from 'interfaces';
import { useGlobalStyles } from 'providers/Theme';
import { useTranslation } from 'react-i18next';

const renderItemTitle = (
  item:
    | IAdminContentEditorItem
    | IContentImageItem
    | IContentVideoItem
    | ITwoColumnAdminLayoutItem
    | IThreeColumnAdminLayoutItem,
  t: any
) => {
  switch (item.type) {
    case 'editor': {
      return 'Text';
    }
    case 'image': {
      return `Image`;
    }
    case 'video': {
      return `Video`;
    }
    case 'two-column': {
      return t('two_column_layout_block');
    }
    case 'three-column': {
      return t('three_column_layout_block');
    }
    default:
      return 'undefined';
  }
};

export default function ContentBuilderItemBar({
  activeLocale,
  handleDeleteItem,
  item,
  isRootItem,
  calledFromColumn,
}: {
  activeLocale: string;
  handleDeleteItem: (id: string) => void;
  item:
    | IAdminContentEditorItem
    | IContentImageItem
    | IContentVideoItem
    | ITwoColumnAdminLayoutItem
    | IThreeColumnAdminLayoutItem;
  isRootItem?: boolean;
  calledFromColumn?: 'left' | 'middle' | 'right';
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const isEditorItem = item.type === 'editor';

  return (
    <>
      <ContentBuilderItemDialog
        activeLocale={activeLocale}
        calledFromColumn={calledFromColumn}
        handleClose={() => setOpen(false)}
        open={open}
        item={item}
        title={renderItemTitle(item, t)}
      />
      <div className={classes.root}>
        {isRootItem ? <DragIndicator color='action' /> : null}
        <Typography className={clsx(classes.title, globalClasses.noSelect)} noWrap variant='caption'>
          {renderItemTitle(item, t)}
        </Typography>
        <div style={{ opacity: 0.075, marginRight: 16 }}>{item.sortIndex}</div>
        {!isRootItem && isEditorItem ? (
          <IconButton onClick={handleOpen} size='small' style={{ padding: 2 }}>
            <Edit />
          </IconButton>
        ) : null}
        <IconButton
          onClick={() => {
            const shouldDelete = window.confirm(t('content_builder_delete_confirmation'));
            if (shouldDelete) {
              handleDeleteItem(item.id);
            }
          }}
          size='small'
          style={{ padding: 2 }}
        >
          <Delete />
        </IconButton>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
}));
