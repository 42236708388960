import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AdminFilesPage } from './Files';
import { AdminNewFilePage } from './NewFile';
import { AdminUpdateFilePage } from './UpdateFile';
import { NotFoundView } from 'views/NotFound';

export default function AdminFileMainPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminFilesPage />
      </Route>
      <Route exact path={path + '/new-file'}>
        <AdminNewFilePage />
      </Route>
      <Route exact path={`${path}/update-file/:fileId`}>
        <AdminUpdateFilePage />
      </Route>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
