import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { MenuItem, TextField, useTheme } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, User } from 'models';
import { DataContext } from 'providers/Data';
import { LoadingButton } from 'components/Buttons/Loading';
import { SettingsFormPaper } from '../SettingsFormPaper';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles } from '.';
import { validationSchema } from '.';

export default function GeneralSettingsFields({ user }: { user?: User }) {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    currentLocale: user?.settings.currentLocale || '',
    firstname: user?.settings.firstname || '',
    lastname: user?.settings.lastname || '',
  };

  if (!user) return null;

  return (
    <SettingsFormPaper title={t('general_settings')}>
      <Formik
        validateOnBlur
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={({ currentLocale, firstname, lastname }, actions) => {
          user
            .updateGeneralSettings(firstname, lastname, currentLocale)
            .then(() => {
              notify(t('alert_settings_changed_success'));
              actions.resetForm({ values: { firstname, lastname, currentLocale } });
            })
            .catch(() => {
              notify(t('alert_settings_changed_error'), 'error');
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ dirty, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
            <Form className={globalClasses.form} noValidate>
              <TextField
                disabled={isSubmitting}
                error={!!errors.firstname && touched.firstname}
                fullWidth
                helperText={!!errors.firstname && touched.firstname && errors.firstname ? errors.firstname : ' '}
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                  },
                }}
                id='firstname'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('firstname')}
                margin='none'
                name='firstname'
                required
                type='text'
                value={values.firstname}
                variant='outlined'
                size='small'
              />
              <TextField
                disabled={isSubmitting}
                error={!!errors.lastname && touched.lastname}
                fullWidth
                helperText={!!errors.lastname && touched.lastname && errors.lastname ? errors.lastname : ' '}
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                    marginBottom: theme.spacing(1),
                  },
                }}
                id='lastname'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('lastname')}
                margin='none'
                name='lastname'
                required
                size='small'
                style={{ marginTop: theme.spacing(1) }}
                type={'text'}
                value={values.lastname}
                variant='outlined'
              />
              <TextField
                disabled={isSubmitting}
                error={!!errors.currentLocale && touched.currentLocale}
                fullWidth
                helperText={
                  !!errors.currentLocale && touched.currentLocale && errors.currentLocale ? errors.currentLocale : ' '
                }
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                  },
                }}
                id='currentLocale'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('language_label')}
                margin='none'
                name='currentLocale'
                required
                select
                size='small'
                value={values.currentLocale}
                variant='outlined'
              >
                {brand.configuration.locales.map(locale => (
                  <MenuItem key={locale} value={locale}>
                    {t(`locale_${locale}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Form>
            <div className={classes.container}>
              <LoadingButton
                color='primary'
                disabled={isSubmitting || dirty === false}
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                size='small'
                variant='contained'
              >
                {t('update_button')}
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </SettingsFormPaper>
  );
}
