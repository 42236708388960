import React, { useContext } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DateFNS from 'utils/date/date-fns';
import useBreakpoints from 'hooks/layout/useBreakpoints';
import { Course } from 'models';
import { DataContext } from 'providers/Data';
import { RobotoBlack } from 'components/Common/Roboto/Black';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles } from '.';

export default function FullScreenCourseCard({ course }: { course: Course }) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const { brand } = useContext(DataContext);
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { sm, xs } = useBreakpoints();

  const lastUpdateDate = course.lastUpdateAt.toDate();
  const lastUpdateDateString = format(lastUpdateDate, 'dd LLLL, yyyy', { locale: DateFNS.getLocale(language) });

  return (
    <div className={classes.container} style={{ backgroundColor: course.courseColor, display: 'flex', width: '100%' }}>
      <div className={globalClasses.layout} style={{ display: 'flex', width: '100%' }}>
        <div className={globalClasses.horizontalPadding} style={{ display: 'flex', width: '100%' }}>
          <div className={classes.card}>
            <div style={{ width: xs ? '70%' : '80%' }}>
              <RobotoBlack style={{ color: '#FFF', fontSize: xs ? 16 : sm ? 22 : 28 }}>
                {course.getTitle(language, brand?.configuration.fallbackLocale as string)}
              </RobotoBlack>
              <Typography
                style={{
                  color: '#FFF',
                  fontSize: xs ? theme.typography.fontSize - 3 : theme.typography.fontSize - 1,
                  marginTop: theme.spacing(0.5),
                }}
              >
                {t('last_update_at')} {lastUpdateDateString}
              </Typography>
            </div>
            {course.courseLogoUrl && (
              <div
                style={{
                  backgroundImage: `url("${course.courseLogoUrl}")`,
                  backgroundPosition: 'right',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  height: '100%',
                  width: xs ? '30%' : '20%',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
