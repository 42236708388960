import React, { MouseEvent, useContext } from 'react';
import { Notifications } from '@material-ui/icons';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DateFNS from 'utils/date/date-fns';
import { DataContext } from 'providers/Data';
import { IMessageItem } from 'interfaces';
import { Lesson, User } from 'models';
import { UserContext } from 'providers/User';
import { useStyles } from '.';

export default function NotificationsButton() {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { brand, lessons, news, userProgress } = useContext(DataContext);
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const FIRST_ITEM_HEIGHT = 40;
  const ITEM_HEIGHT = 68;
  const MENU_PADDING = 10;

  const baseDate = new Date();

  const lessonsWithUpdate = lessons.app.filter(
    lesson => lesson.getStatus(user.lessonsProgress, userProgress) === 'update'
  );
  const lessonsWithUpdateLength = lessonsWithUpdate.length;

  const undreadNews = news.app.filter(item => !item.isRead(user.newsProgress || [])).filter(item => item.createdAt && user.createdAt && item.createdAt.toMillis() > user.createdAt.toMillis());
  const undreadNewsLength = undreadNews.length;

  const messages: IMessageItem[] = [...undreadNews, ...lessonsWithUpdate]
    .map(item => ({
      avatarText: item instanceof Lesson ? 'L' : 'N',
      date:
        item instanceof Lesson
          ? item.lastUpdateAt
            ? item.lastUpdateAt.toDate()
            : new Date()
          : item.createdAt
          ? item.createdAt.toDate()
          : new Date(),
      key: item.id,
      primaryText: item.getTitle(language, brand?.configuration.fallbackLocale as string),
      redirectPath: item instanceof Lesson ? `/learning/course/${item.course}` : `/news/${item.id}`,
      secondaryText:
        item instanceof Lesson
          ? item.lastUpdateAt
            ? item.lastUpdateAt.toDate()
            : new Date()
          : item.createdAt
          ? item.createdAt.toDate()
          : new Date(),
    }))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  return (
    <>
      <IconButton onClick={handleOpen} size='small'>
        <Badge
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          badgeContent={undreadNewsLength + lessonsWithUpdateLength}
          color='primary'
          max={9}
        >
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='menu-notifications'
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            maxHeight: MENU_PADDING + FIRST_ITEM_HEIGHT + ITEM_HEIGHT * 5,
            width: '50ch',
          },
        }}
      >
        <MenuItem dense disabled>
          <ListItemText secondary={`${t('notifications', { number: messages.length.toString() })}`} />
        </MenuItem>
        <Divider />
        {messages.length === 0 && (
          <Typography align='center' className={classes.noMessages} variant='body2'>
            {t('no_notifications')}
          </Typography>
        )}
        {messages.map(message => (
          <MenuItem
            key={message.key}
            onClick={() => {
              history.push(message.redirectPath);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Avatar className={classes.small}>{message.avatarText}</Avatar>
            </ListItemIcon>
            <ListItemText
              primary={message.primaryText}
              secondary={formatDistance(message.date, baseDate, {
                addSuffix: true,
                locale: DateFNS.getLocale(language),
              })}
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
