import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    opacity: theme.palette.type === 'dark' ? 0.5 : 1,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(3),
  },
  middle: {
    flexGrow: 1,
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
  },
  newsItem: {
    cursor: 'pointer',
    // padding: theme.spacing(2, 0, 1),
    margin: theme.spacing(2, 0, 2),
  },
  paper: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export { default as HomePage } from './HomePage';
