import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export { default as AddContentBuilderItemDialog } from './AddContentBuilderItemDialog';
