import 'firebase/app';
import Model from '../Model';

class Role extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'roles';

  // Fields for brand roles and default roles
  private _createdAt!: firebase.default.firestore.Timestamp;
  private _isDefaultRole!: boolean;
  private _resources!: string[];
  private _resourceRefs!: firebase.default.firestore.DocumentReference[];

  // Fields for brand roles
  private _createdFrom?: string;
  private _createdFromRef?: firebase.default.firestore.DocumentReference;
  private _key?: string;
  private _isSelectableInRestrictions?: boolean;
  private _lastUpdateAt?: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom?: string;
  private _lastUpdateFromRef?: firebase.default.firestore.DocumentReference;

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp | undefined {
    return this._createdAt;
  }

  get createdFrom(): string | undefined {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference | undefined {
    return this._createdFromRef;
  }

  get isDefaultRole(): boolean {
    return this._isDefaultRole;
  }

  get isSelectableInRestrictions(): boolean | undefined {
    return this._isSelectableInRestrictions;
  }

  get key(): string | undefined {
    return this._key;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp | undefined {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string | undefined {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference | undefined {
    return this._lastUpdateFromRef;
  }

  get resources(): string[] {
    return this._resources;
  }

  get resourceRefs(): firebase.default.firestore.DocumentReference[] {
    return this._resourceRefs;
  }
}

export default Role;
