import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Check, Remove } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { User } from 'models';
import { useStyles } from '.';

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  })
)(Avatar);

export default function AvatarCard({ user }: { user?: User }) {
  const classes = useStyles();
  const notify = useNotification();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  if (!user) return null;

  const downloadUrl = user.settings.photoUrl;

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const renderAvatarCard = () => {
    if (!!downloadUrl) {
      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Badge
            overlap='circle'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <IconButton color='default' onClick={handleClick} size='small' style={{ padding: 0 }}>
                <SmallAvatar>
                  <Remove />
                </SmallAvatar>
              </IconButton>
            }
          >
            {
              <>
                <Avatar className={classes.avatar} src={user.settings.photoUrl} />
                {open && (
                  <div
                    className={classes.overlay}
                    onClick={async () => {
                      try {
                        await user.deleteAvatar();
                        notify(t('alert_delete_avatar_success'));
                      } catch (err) {
                        console.error(err);
                        notify(t('default_alert_deletion_error'), 'error');
                      } finally {
                        setOpen(false);
                      }
                    }}
                  >
                    <Check />
                  </div>
                )}
              </>
            }
          </Badge>
        </ClickAwayListener>
      );
    }

    return <Avatar className={classes.avatar} src={user.settings.photoUrl} />;
  };

  return (
    <Grid container direction='column' justify='center' alignContent='center' alignItems='center'>
      <Grid item>{renderAvatarCard()}</Grid>
      <Grid>
        <input
          accept='image/*'
          className={classes.input}
          id='file-upload'
          type='file'
          onChange={event => {
            user.updateAvatar(event);
            event.target.value = '';
          }}
        />
        <label htmlFor='file-upload'>
          <Button variant='text' color='primary' component='span' className={classes.button} size='small'>
            {t('edit_button')}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}
