import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { IUnitFormData } from 'interfaces';
import { unitValidation } from '.';
import { useGlobalStyles } from 'providers/Theme';

export default function UnitFields({
  handleClick,
  handleClose,
  open,
  initialValues,
  title,
  type,
}: {
  handleClick: (values: IUnitFormData) => Promise<void>;
  handleClose: () => void;
  open: boolean;
  initialValues: IUnitFormData;
  title: string;
  type: 'create' | 'update';
}) {
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleClick(values)
          .then(() => {
            if (type === 'create') {
              notify(t('alert_unit_creation_success'));
            }
            if (type === 'update') {
              notify(t('alert_unit_update_success'));
            }
          })
          .catch(() => {
            if (type === 'create') {
              notify(t('alert_unit_creation_error'), 'error');
            }
            if (type === 'update') {
              notify(t('alert_unit_update_error'), 'error');
            }
          });
      }}
      validateOnBlur
      validationSchema={() => unitValidation()}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <>
            <FormDialog
              createButtonText={t('create_button')}
              handleClick={handleSubmit}
              handleClose={handleClose}
              loading={isSubmitting}
              open={open}
              title={title}
              type={type}
              updateButtonText={t('update_button')}
            >
              <Form className={globalClasses.form} noValidate>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.key && touched.key}
                      fullWidth
                      helperText={!!errors.key && touched.key && errors.key ? errors.key : ' '}
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='key'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('unit_key_label')}
                      name='key'
                      required
                      size='small'
                      value={values.key}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.internalId && touched.internalId}
                      fullWidth
                      helperText={
                        !!errors.internalId && touched.internalId && errors.internalId ? errors.internalId : ' '
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='internalId'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('unit_internalId_label')}
                      name='internalId'
                      required
                      size='small'
                      value={values.internalId}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Form>
            </FormDialog>
          </>
        );
      }}
    </Formik>
  );
}
