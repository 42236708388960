import React, { useContext } from 'react';
import { Rating } from '@material-ui/lab';
import { Button, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Brand, Course, Lesson, User } from 'models';
import { CircularProgress } from 'components/Loading/CircularProgress';
import { DataContext } from 'providers/Data';
import { ErrorView } from 'views/Error';
import { LessonDetailContext } from 'pages/App/Learning/LessonDetail';
import { functions } from 'configuration/firebase';
import { onFunctions, onSuccess, onError } from 'utils/logger';
import { UserContext } from 'providers/User';

export default function LessonFinishedView({
  addingState: { error, loading },
  course,
  finishedSuccessful,
  lesson,
}: {
  addingState: { error: boolean; loading: boolean };
  course: Course;
  finishedSuccessful: boolean;
  lesson: Lesson;
}) {
  const brand = useContext(DataContext).brand as Brand;
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { dispatch } = React.useContext(LessonDetailContext);
  const { t } = useTranslation();

  const [rated, setRated] = React.useState({ loading: false, value: 0 });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorView disableLayout msg='Learning content could not be added.' />;
  }

  const handleRating = (value: number | null) => {
    if (value) {
      setRated(prevState => ({ ...prevState, value }));
      onFunctions('rateLesson');
      const rateLesson = functions.httpsCallable('rateLesson');
      rateLesson({ lessonId: lesson.id, rate: value })
        .then(() => {
          onSuccess('rateLesson', 'callable-function-success');
        })
        .catch(err => {
          console.error(err);
          onError('LessonFinishedView', err);
        });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        {!!course.courseLogoUrl && (
          <img
            alt={course.getTitle(user.settings.currentLocale, brand.configuration.fallbackLocale) + ' Logo'}
            height={theme.spacing(6)}
            src={course.courseLogoUrl}
          />
        )}
      </div>
      <div style={{ margin: theme.spacing(5, 0, 1, 0) }}>
        {finishedSuccessful ? t('learning_congrats') : t('learning_not_passed')}
      </div>
      {finishedSuccessful && <div>{rated.value === 0 ? t('learning_rating_question') : t('learning_feedback')}</div>}
      <div style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}>
        {finishedSuccessful && (
          <Rating
            disabled={rated.value !== 0}
            name={'rating'}
            onChange={(event, newValue) => {
              handleRating(newValue);
            }}
            value={rated.value}
          />
        )}
        {finishedSuccessful === false && (
          <Button
            color='primary'
            onClick={() => dispatch({ type: 'handleReset' })}
            size='small'
            type='submit'
            variant='contained'
          >
            {t('try_again_button')}
          </Button>
        )}
      </div>
    </div>
  );
}
