import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { ContentItem, LayoutItem } from 'types';
import { ContentEditorItem } from './EditorItem';
import { ContentImageItem } from './ImageItem';
import { ContentVideoItem } from './VideoItem';
import { LayoutTwoColumnItem } from '../Layout/TwoColumnItem';
import { LayoutThreeColumnItem } from '../Layout/ThreeColumnItem';

const ErrorItem = ({ msg }: { msg: string }) => (
  <Typography color='error' gutterBottom variant='caption'>
    {msg}
  </Typography>
);

export default function ContentItemComponent({
  item,
  isPreviewMode,
  previewSize,
}: {
  item: ContentItem | LayoutItem;
  isPreviewMode?: boolean;
  previewSize?: number;
}) {
  const classes = useStyles();

  if (item.type === 'editor')
    return (
      <div className={classes.item}>
        <ContentEditorItem item={item} />
      </div>
    );
  if (item.type === 'image')
    return (
      <div className={classes.item}>
        <ContentImageItem item={item} />
      </div>
    );
  if (item.type === 'video')
    return (
      <div className={classes.item}>
        <ContentVideoItem item={item} />
      </div>
    );
  if (item.type === 'two-column')
    return <LayoutTwoColumnItem item={item} isPreviewMode={isPreviewMode} previewSize={previewSize} />;
  if (item.type === 'three-column')
    return <LayoutThreeColumnItem item={item} isPreviewMode={isPreviewMode} previewSize={previewSize} />;

  return (
    <div style={{ padding: '8px 0' }}>
      <ErrorItem msg={(item as any).id + ' not supported'} />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(1),
  },
}));
