import React from 'react';
import { IUserProgressDataState } from 'interfaces';
import { User, UserProgress } from 'models';
import { addIdToDocument, conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onError, onFirestore, onListener } from 'utils/logger';

export default function useUserProgress(userId: string): IUserProgressDataState {
  const [state, setState] = React.useState<IUserProgressDataState>({
    userProgressError: { status: false, message: '' },
    userProgressData: [],
    userProgressLoading: true,
  });
  const userProgressPath = firestore.collection(User.collection).doc(userId).collection(UserProgress.collection).path;

  React.useEffect(() => {
    onFirestore('onSnapshot', userProgressPath);
    const collectionRef = conditionalCollection(userProgressPath);
    let query = collectionRef;

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(
            i,
            snapshot.docs.length,
            'UserProgress',
            change.doc.id,
            change.oldIndex,
            change.newIndex,
            change.type
          );
        });
        const userProgress = snapshot.docs.map(doc => new UserProgress(addIdToDocument(doc)));
        setState(prevState => ({
          ...prevState,
          userProgressData: userProgress,
          userProgressLoading: false,
        }));
      },
      error => {
        const message = 'User progress data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({
          ...prevState,
          userProgressError: { status: true, message },
          userProgressLoading: false,
        }));
        onError('User progress', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [userProgressPath]);

  return { ...state };
}
