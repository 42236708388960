import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  container: {
    height: theme.spacing(12),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(22),
      padding: theme.spacing(4, 0),
    },
  },
  duration: {
    color: '#FFF',
    marginTop: theme.spacing(0.5),
  },
}));

export { default as FullScreenCourseCard } from './FullScreenCourseCard';
