import React from 'react';
import { AccessTime } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';

import { RobotoMedium } from 'components/Common/Roboto/Medium';
import { useStyles } from '.';

const Duration = ({ color, fontSize, minutes }: { color?: string; fontSize?: number; minutes: number }) => {
  const classes = useStyles();
  const theme = useTheme();

  const convertHours = () => {
    const hours = Math.floor(minutes / 60);
    if (hours < 10) return '0' + hours;
    return hours;
  };

  const convertMinutes = () => {
    const mins = minutes % 60;
    if (mins < 10) return '0' + mins;
    return mins;
  };

  const colour = color || theme.palette.text.secondary;
  const label = convertHours() + ':' + convertMinutes();

  return (
    <div className={classes.container}>
      <AccessTime className={classes.icon} style={{ color: colour, fontSize: fontSize ? fontSize + 2 : 11 }} />
      <RobotoMedium style={{ color: colour, fontSize: fontSize || 10 }}>{label}</RobotoMedium>
    </div>
  );
};

export default Duration;
