import { makeStyles } from '@material-ui/core';

export { default as LessonCard } from './LessonCard';

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2),
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#000',
    color: '#FFF',
    borderRadius: theme.shape.borderRadius,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    opacity: theme.palette.type === 'dark' ? 0.75 : 0.5,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  right: {},
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    width: '100%',
  },
}));
