import 'firebase/app';
import Model from '../Model';

class Resource extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'resources';
  private _createdAt?: firebase.default.firestore.Timestamp;
  private _description!: string;
  private _key!: string;

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp | undefined {
    return this._createdAt;
  }

  get description(): string {
    return this._description;
  }

  get key(): string {
    return this._key;
  }
}

export default Resource;
