import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import { Course } from 'models';
import { CourseCard } from 'components/Cards/CourseCard';
import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { useTranslation } from 'react-i18next';

const sortCourses = (a: Course, b: Course) => a.sortIndex - b.sortIndex;

export default function CoursesPage() {
  const { brand, courses } = useContext(DataContext);
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'app' }}>
      <Grid container spacing={2}>
        {courses.app.sort(sortCourses).map(course => (
          <Grid key={course.id} item xs={12} sm={6} md={4} lg={3}>
            <CourseCard
              course={course}
              currentLocale={language}
              fallbackLocale={brand?.configuration.fallbackLocale as string}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
