import React, { ReactNode } from 'react';
import useBreakpoints from 'hooks/layout/useBreakpoints';
import { TransparentTabs, useStyles } from '.';

export default function VerticalTabs({
  children,
  onChange,
  value,
}: {
  children: ReactNode;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: boolean | string;
}) {
  const { xs } = useBreakpoints();
  const { root } = useStyles();

  return (
    <TransparentTabs
      className={root}
      orientation={xs ? 'horizontal' : 'vertical'}
      indicatorColor='primary'
      textColor='primary'
      variant='scrollable'
      scrollButtons='off'
      onChange={onChange}
      value={value}
    >
      {children}
    </TransparentTabs>
  );
}
