import React from 'react';
import { Form, Formik } from 'formik';
import { IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { User } from 'models';
import { LoadingButton } from 'components/Buttons/Loading';
import { SettingsFormPaper } from '../SettingsFormPaper';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles, validationSchema } from '.';

export default function PasswordFields({ user }: { user?: User }) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();

  const [visible, setVisible] = React.useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });

  const initialValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };

  if (!user) return null;

  return (
    <SettingsFormPaper title={t('update_password_settings')}>
      <Formik
        validateOnBlur
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async ({ currentPassword, password }, actions) => {
          try {
            await user.reauthenticate(currentPassword);
          } catch (err) {
            let msg = t('default_error_message');
            if (err.code === 'auth/wrong-password') {
              msg = t('reauthenticate_error_wrong_password');
            }
            actions.setFieldError('currentPassword', msg);
            actions.setSubmitting(false);
            return;
          }

          try {
            await user.updatePassword(password);
            notify(t('alert_settings_changed_success'));
            actions.resetForm();
          } catch (err) {
            notify(t('alert_settings_changed_error'), 'error');
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ dirty, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
            <Form className={globalClasses.form} noValidate>
              <TextField
                disabled={isSubmitting}
                color='primary'
                error={!!errors.currentPassword && touched.currentPassword}
                fullWidth
                helperText={
                  !!errors.currentPassword && touched.currentPassword && errors.currentPassword
                    ? errors.currentPassword
                    : t('current_password_label_helper_text')
                }
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                    marginBottom: theme.spacing(2),
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setVisible(prevState => ({ ...prevState, currentPassword: !prevState.currentPassword }))
                        }
                        edge='end'
                      >
                        {visible.currentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id='currentPassword'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('current_password_label')}
                margin='none'
                name='currentPassword'
                required
                size='small'
                type={visible.currentPassword ? 'text' : 'password'}
                value={values.currentPassword}
                variant='outlined'
              />
              <TextField
                disabled={isSubmitting}
                color='primary'
                error={!!errors.password && touched.password}
                fullWidth
                helperText={!!errors.password && touched.password && errors.password ? errors.password : ' '}
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                    marginBottom: theme.spacing(2),
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setVisible(prevState => ({ ...prevState, password: !prevState.password }))}
                        edge='end'
                      >
                        {visible.password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id='password'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('new_password_label')}
                margin='none'
                name='password'
                required
                size='small'
                type={visible.password ? 'text' : 'password'}
                value={values.password}
                variant='outlined'
              />
              <TextField
                disabled={isSubmitting}
                color='primary'
                error={!!errors.confirmPassword && touched.confirmPassword}
                fullWidth
                helperText={
                  !!errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : ' '
                }
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                    marginBottom: theme.spacing(2),
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setVisible(prevState => ({ ...prevState, confirmPassword: !prevState.confirmPassword }))
                        }
                        edge='end'
                      >
                        {visible.confirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id='confirmPassword'
                onBlur={handleBlur}
                onChange={handleChange}
                label={t('confirm_password_label')}
                margin='none'
                name='confirmPassword'
                required
                size='small'
                type={visible.confirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                variant='outlined'
              />
            </Form>
            <div className={classes.container}>
              <LoadingButton
                color='primary'
                disabled={isSubmitting || dirty === false}
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                size='small'
                variant='contained'
              >
                {t('update_button')}
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </SettingsFormPaper>
  );
}
