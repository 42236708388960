import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
}));

export { default as ContentVideoItem } from './ContentVideoItem';
