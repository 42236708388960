import Model from 'models/Model';
import { ContentItem, LayoutItem, Map } from 'types';

class News extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'news';

  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom!: string;
  private _createdFromRef!: firebase.default.firestore.DocumentReference;
  private _key!: string;
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;
  private _locales!: string[];
  private _localizedFields!: {
    caption: Map<string>;
    content: Map<Array<ContentItem | LayoutItem>>;
    title: Map<string>;
  };
  private _photoUrl!: string;
  private _published!: boolean;

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference {
    return this._createdFromRef;
  }

  get key(): string {
    return this._key;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }

  get locales(): string[] {
    return this._locales;
  }

  get localizedFields(): { caption: Map<string>; content: Map<Array<ContentItem | LayoutItem>>; title: Map<string> } {
    return this._localizedFields;
  }

  get photoUrl(): string {
    return this._photoUrl;
  }
  get published(): boolean {
    return this._published;
  }

  public getCaption(currentLocale: string, fallbackLocale: string, maxCharacters?: number) {
    const currentLocaleCaption = this.localizedFields.caption[currentLocale] || undefined;
    const caption = currentLocaleCaption ? currentLocaleCaption : this.localizedFields.caption[fallbackLocale];

    if (!caption || !caption.length) {
      return null;
    }

    if (!maxCharacters) {
      return caption;
    }

    if (maxCharacters >= caption.length) {
      return caption;
    }

    return caption.slice(0, maxCharacters) + '...';
  }

  public getContents(currentLocale: string, fallbackLocale: string) {
    const content = this.localizedFields.content[currentLocale] || undefined;
    return content ? content : this.localizedFields.content[fallbackLocale];
  }

  public getTitle(currentLocale: string, fallbackLocale: string) {
    const title = this.localizedFields.title[currentLocale] || undefined;
    return title ? title : this.localizedFields.title[fallbackLocale];
  }

  public isRead(readNews: string[]) {
    return readNews.includes(this.id);
  }
}

export default News;
