import React from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { Button, Menu, MenuItem, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { NavigationTab } from '../Tab';
import { NavigationTabs } from '../Tabs';

const items = [{ path: '/' }, { path: '/learning' }, { path: '/news' }, { path: '/files' }];

const paths = items.map(item => item.path);

export default function AppNavigation() {
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const { xs } = useBreakpoints();

  const getFirstPath = (): string => {
    return '/' + history.location.pathname.split('/')[1];
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(
    paths.includes(history.location.pathname)
      ? history.location.pathname
      : paths.includes(getFirstPath())
      ? getFirstPath()
      : false
  );

  const handleChange = (newValue: any) => {
    history.push(newValue);
    setValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (xs) {
    return (
      <>
        <Button
          disableTouchRipple
          endIcon={<ArrowDropDown />}
          onClick={handleClick}
          style={{ padding: theme.spacing(0, 0.5), textTransform: 'none' }}
        >
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'block',
              textOverflow: 'ellipsis',
            }}
          >
            {t(value.toString())}
          </span>
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {items.map(({ path }) => (
            <MenuItem
              key={path}
              onClick={() => {
                handleClose();
                handleChange(path);
              }}
            >
              {t(path)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <div style={{ marginLeft: theme.spacing(3) }}>
      <NavigationTabs value={value} onChange={(_, value) => handleChange(value)}>
        {items.map(({ path }) => (
          <NavigationTab key={path} label={t(path)} value={path} />
        ))}
      </NavigationTabs>
    </div>
  );
}
