import Model from 'models/Model';
import { Brand } from 'models';
import { Map } from 'types';
import { firestore, storage } from 'configuration/firebase';
import { onFirestore, onStorage, onSuccess } from 'utils/logger';

class File extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'files';

  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom!: string;
  private _createdFromRef!: firebase.default.firestore.DocumentReference;
  private _downloadUrl!: string;
  private _groupRestrictionRefs!: firebase.default.firestore.DocumentReference[];
  private _groupRestrictions!: string[];
  private _hasFile!: boolean;
  private _hasGroupRestrictions!: boolean;
  private _hasRoleRestrictions!: boolean;
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;
  private _localizedFields!: {
    description: Map<string>;
  };
  private _metaData!: {
    contentType: string;
    size: number;
    timeCreated: string;
    updated: string;
  };
  private _name!: string;
  private _published!: boolean;
  private _roleRestrictionRefs!: firebase.default.firestore.DocumentReference[];
  private _roleRestrictions!: string[];

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference {
    return this._createdFromRef;
  }

  get downloadUrl(): string {
    return this._downloadUrl;
  }

  get groupRestrictionRefs(): firebase.default.firestore.DocumentReference[] {
    return this._groupRestrictionRefs;
  }

  get groupRestrictions(): string[] {
    return this._groupRestrictions;
  }

  get hasFile(): boolean {
    return this._hasFile;
  }

  get hasGroupRestrictions(): boolean {
    return this._hasGroupRestrictions;
  }

  get hasRoleRestrictions(): boolean {
    return this._hasRoleRestrictions;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }

  get localizedFields(): { description: Map<string> } {
    return this._localizedFields;
  }

  get metaData(): { timeCreated: string; contentType: string; size: number; updated: string } {
    return this._metaData;
  }

  get name(): string {
    return this._name;
  }

  get published(): boolean {
    return this._published;
  }

  get roleRestrictionRefs(): firebase.default.firestore.DocumentReference[] {
    return this._roleRestrictionRefs;
  }

  get roleRestrictions(): string[] {
    return this._roleRestrictions;
  }

  public delete(brand: string, url: string) {
    const docRef = firestore.collection(Brand.collection).doc(brand).collection(File.collection).doc(this.id);
    const storageRef = storage.refFromURL(url);

    onFirestore('delete', docRef.path);
    onStorage('delete', storageRef.fullPath);

    return Promise.all([
      docRef.delete().then(() => {
        onSuccess(docRef.path, 'delete-success');
      }),
      storageRef.delete().then(() => {
        onSuccess(storageRef.fullPath, 'delete-success');
      }),
    ]);
  }

  public isNotRestricted(userGroups: string[], userRole: string): boolean {
    if (this.hasGroupRestrictions === false && this.hasRoleRestrictions === false) return true;

    if (this.hasRoleRestrictions) {
      const result = this.roleRestrictions.some(roleId => userRole === roleId);
      if (result === false) return false;
    }

    if (this.hasGroupRestrictions) {
      const result = this.groupRestrictions.some(groupId => userGroups.includes(groupId));
      if (result === false) return false;
    }

    return true;
  }
}

export default File;
