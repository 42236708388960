import { Map } from 'types';

abstract class Model {
  private _id!: string;

  get id(): string {
    return this._id;
  }

  public getValue(key: string) {
    if (key.includes('.')) {
      const keyArr = key.split('.');

      let value: any = this;
      for (const keyValue of keyArr) {
        if (!value[keyValue]) {
          return null;
        }
        value = value[keyValue];
      }

      return value;
    }

    return (this as any)[key];
  }

  public getValues() {
    const data: Map<any> = {};
    Object.keys(this).forEach((key: string) => {
      if (key.includes('_')) {
        key = key.replace('_', '');
      }
      data[key] = (this as any)[key];
    }, this);
    return data;
  }

  public setValues(data?: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this.setValue(key, data[key]);
      }
    }
  }

  public setValue(key: string, value: any) {
    if (key === 'id') {
      this._id = value;
    } else {
      const objKey = '_' + key;
      (this as any)[objKey] = value;
    }
  }
}

export default Model;
