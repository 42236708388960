import Brand from 'models/Brand';
import Course from 'models/Course';
import LearningItem from '../LearningItem';
import { LearningStatus } from 'types';
import { UserProgress } from 'models';
import { arrayRemove, firestore } from 'configuration/firebase';

class Lesson extends LearningItem {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'lessons';
  private _course!: string;
  private _courseRef!: firebase.default.firestore.DocumentReference;
  private _durationInMin!: number;
  private _lastContentUpdateAt?: firebase.default.firestore.Timestamp;

  static get collection(): string {
    return this._collection;
  }

  get course(): string {
    return this._course;
  }

  get courseRef(): firebase.default.firestore.DocumentReference {
    return this._courseRef;
  }

  get durationInMin(): number {
    return this._durationInMin;
  }

  get lastContentUpdateAt(): firebase.default.firestore.Timestamp | undefined {
    return this._lastContentUpdateAt;
  }

  public isLocked(availableLessons: Lesson[], passedLessons: string[]) {
    const availableCourseLessons = availableLessons.filter(lesson => lesson.course === this.course);

    if (this.preConditions.length <= 0) return false;

    for (const preCondition of this.preConditions) {
      const preConditionLesson = availableCourseLessons.find(lesson => lesson.id === preCondition);

      if (preConditionLesson === undefined) {
        /* Pre condition lesson is not found (unpublished, restricted for user or deleted) */
        return true;
      }

      if (preConditionLesson.isPassed(passedLessons) === false) {
        /* Pre condition lesson is found but not passed */
        return true;
      }
    }

    return false;
  }

  public isPassed(passedLessons: string[]): boolean {
    return passedLessons.includes(this.id);
  }

  public getDuration() {
    return this.durationInMin;
  }

  public getStatus(passedLessons: string[], userProgress: UserProgress[]) {
    let status: LearningStatus = this.isPassed(passedLessons) ? 'passed' : undefined;

    if (status === 'passed') {
      const progressItemsForThisLesson = userProgress
        .filter(progress => progress.lesson === this.id)
        .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());

      if (progressItemsForThisLesson && progressItemsForThisLesson.length > 0) {
        if (
          !!this.lastContentUpdateAt &&
          progressItemsForThisLesson[0].timestamp.toMillis() < this.lastContentUpdateAt.toMillis()
        ) {
          status = 'update';
        }
      }
    }
    return status;
  }

  public async delete(brandId: string) {
    const brandRef = firestore.collection(Brand.collection).doc(brandId);
    const courseRef = brandRef.collection(Course.collection).doc(this.course);
    const lessonRef = brandRef.collection(Lesson.collection).doc(this.id);

    const promises: any[] = [];

    this.lessonContentRefs.forEach(lessonContentRef => {
      promises.push(lessonContentRef.delete());
    });

    const courseUpdateData: any = {};

    courseUpdateData['lessons'] = arrayRemove(this.id);
    courseUpdateData['lessonRefs'] = arrayRemove(lessonRef);

    if (this.lessonContentRefs.length > 0 && this.lessonContents.length > 0) {
      courseUpdateData['lessonContents'] = arrayRemove(...this.lessonContents);
      courseUpdateData['lessonContentRefs'] = arrayRemove(...this.lessonContentRefs);
    }

    promises.push(courseRef.update({ ...courseUpdateData }));
    promises.push(lessonRef.delete());

    return Promise.all([...promises]);
  }
}

export default Lesson;
