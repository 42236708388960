import React from 'react';
import ReactPlayer from 'react-player';

import { IContentVideoItem } from 'interfaces';
import { useStyles } from '.';

export default function ContentVideoItem({ item }: { item: IContentVideoItem }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ReactPlayer controls className={classes.player} height='auto' width='100%' url={item.content.url} />
    </div>
  );
}
