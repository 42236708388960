import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFoundView } from 'views/NotFound';

import { ForgotPasswordPage } from './ForgotPassword';
import { LoginPage } from './Login';

export default function SignInPage() {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/login' />
      </Route>
      <Route exact path='/login'>
        <LoginPage />
      </Route>
      <Route exact path='/forgot-password'>
        <ForgotPasswordPage />
      </Route>
      <Route path='*'>
        <NotFoundView disableHeader />
      </Route>
    </Switch>
  );
}
