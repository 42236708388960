import React, { useContext } from 'react';
import clsx from 'clsx';
import { AddAPhoto } from '@material-ui/icons';
import {
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Switch as MuiSwitch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, Course, User } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { ColorPicker } from 'components/Common/ColorPicker';
import { DataContext } from 'providers/Data';
import { ICourseGeneralFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { LocalizedFieldDialog } from 'components/Dialogs/LocalizedField';
import { MediaLibraryDialog } from 'components/Dialogs/MediaLibrary';
import { RobotoMedium } from 'components/Common/Roboto/Medium';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { UserContext } from 'providers/User';
import { courseValidationGeneral, useStyles } from '.';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onFirestore, onSuccess } from 'utils/logger';
import { setDeleteFlagforEmptyLocaleFields } from 'utils/data';
import { useGlobalStyles } from 'providers/Theme';

const backPath = '/admin/learning-content';

export default function AdminCourseDetailGeneralPage({ course }: { course: Course }) {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const { t } = useTranslation();

  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [showMediaDialog, setShowMediaDialog] = React.useState(false);

  const { courseColor, courseLogoUrl, key, localizedFields, published, sortIndex } = course;

  const initialValuesGeneral: ICourseGeneralFormData = {
    courseColor,
    courseLogoUrl,
    key,
    localizedFields: {
      description: { ...brand.getLocalesAsObjectFromField(localizedFields.description || {}) },
      title: { ...brand.getLocalesAsObjectFromField(localizedFields.title) },
    },
    published,
    sortIndex,
  };

  const handleSubmit = async (values: ICourseGeneralFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', 'course');

    return brandRef
      .collection(Course.collection)
      .doc(course.id)
      .set(
        {
          ...values,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
          localizedFields: { ...setDeleteFlagforEmptyLocaleFields(values.localizedFields) },
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(brandRef.collection(Course.collection).path, 'set-success', 'updated-course');
      });
  };

  return (
    <Formik
      initialValues={initialValuesGeneral}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_course_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() => courseValidationGeneral(brand.configuration.locales, brand.configuration.fallbackLocale)}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, dirty, setFieldValue }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <div>
                <div className={globalClasses.marginBottom}>
                  <Paper>
                    <div className={classes.top} style={{ backgroundColor: values.courseColor }}>
                      <RobotoMedium style={{ color: '#FFF', fontSize: 14, width: '70%' }}>
                        {values.localizedFields.title[brand.configuration.fallbackLocale]}
                      </RobotoMedium>
                      {!!values.courseLogoUrl && (
                        <div
                          style={{
                            backgroundImage: `url("${values.courseLogoUrl}")`,
                            backgroundPosition: 'right',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            height: '100%',
                            width: '30%',
                          }}
                        />
                      )}
                      {!values.courseLogoUrl && (
                        <Tooltip title='Kurs Icon'>
                          <IconButton disabled={isSubmitting} onClick={() => setShowMediaDialog(true)}>
                            <AddAPhoto fontSize='large' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    <div className={classes.bottom}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Button
                            disabled={isSubmitting}
                            onClick={() => setShowColorPicker(true)}
                            size='small'
                            style={{ textTransform: 'none' }}
                            variant={'text'}
                          >
                            {t('select_color_button')}
                          </Button>
                          <ColorPicker
                            color={values.courseColor}
                            onClose={() => setShowColorPicker(false)}
                            onChange={color => setFieldValue('courseColor', color)}
                            open={showColorPicker}
                          />
                        </Grid>
                        {!!values.courseLogoUrl && (
                          <Grid item xs={6}>
                            <Grid
                              container
                              direction='row'
                              alignContent='center'
                              alignItems='flex-end'
                              justify='flex-end'
                            >
                              <Grid item>
                                <Button
                                  disabled={isSubmitting}
                                  onClick={() => setFieldValue('courseLogoUrl', '')}
                                  size='small'
                                  variant='text'
                                  style={{ textTransform: 'none' }}
                                >
                                  {t('remove_course_logo_button')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Paper>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {' '}
                                  <Typography variant='h6'>{t('general_fields')}</Typography>
                                </div>
                                <div>
                                  {' '}
                                  <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <MuiSwitch
                                            checked={values.published}
                                            color='primary'
                                            disabled={isSubmitting}
                                            onChange={handleChange}
                                            name='published'
                                          />
                                        }
                                        label='Published'
                                        labelPlacement='end'
                                        style={{ marginRight: 0 }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormHelperText>{t('publish_this_course')}</FormHelperText>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.key && touched.key}
                                fullWidth
                                helperText={
                                  !!errors.key && touched.key && errors.key
                                    ? errors.key
                                    : t('course_key_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='key'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('course_key_label')}
                                name='key'
                                required
                                value={values.key}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <LocalizedFieldDialog
                                disabled={isSubmitting}
                                errors={errors.localizedFields?.title}
                                helperText={t('course_title_label_helper_text')}
                                fieldName='title'
                                fullWidth
                                label={t('course_title_label')}
                                name='localizedFields'
                                onBlur={handleBlur}
                                onSave={localeValues => {
                                  setFieldValue(`localizedFields.title`, localeValues);
                                }}
                                required
                                touched={touched.localizedFields?.title}
                                values={values.localizedFields.title}
                              />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.sortIndex && touched.sortIndex}
                                fullWidth
                                helperText={
                                  !!errors.sortIndex && touched.sortIndex && errors.sortIndex
                                    ? errors.sortIndex
                                    : t('course_index_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='sortIndex'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('course_index_label')}
                                name='sortIndex'
                                required
                                type='number'
                                value={values.sortIndex}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <LocalizedFieldDialog
                                disabled={isSubmitting}
                                errors={errors.localizedFields?.description}
                                fieldName='description'
                                fullWidth
                                label={t('course_description_label')}
                                maxCharacter={1000}
                                multiline
                                name='localizedFields'
                                onBlur={handleBlur}
                                onSave={localeValues => {
                                  setFieldValue(`localizedFields.description`, localeValues);
                                }}
                                touched={touched.localizedFields?.description}
                                values={values.localizedFields.description}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ButtonGroup
                  backPath={backPath}
                  createButtonText={t('create_course_button')}
                  isSubmitting={isSubmitting}
                  type={'update'}
                  updateButtonText={t('update_course_button')}
                />
              </div>
              <MediaLibraryDialog
                filter='images'
                handleClose={() => {
                  setShowMediaDialog(false);
                }}
                selectFile={file => {
                  if (!file || !file.contentType || !file.contentType.startsWith('image/')) {
                    notify(t('course_logo_picked_error'), 'error');
                    return;
                  }
                  if (file.contentType.startsWith('image/')) {
                    setFieldValue('courseLogoUrl', file.url);
                    notify(t('course_logo_picked_success'));
                    return;
                  }
                }}
                open={showMediaDialog}
              />
              <ScrollToError />
              <LoadingBackdrop open={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
