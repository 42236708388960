import React, { useContext, useState } from 'react';
import { Add, Info, Visibility } from '@material-ui/icons';
import { Button, Divider, IconButton, Tooltip, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { AddContentBuilderItemDialog } from 'components/Dialogs/AddContentBuilderItemDialog';
import { Brand } from 'models';
import { ContentBuilderPreviewDialog } from 'components/Dialogs/ContentBuilderPreviewDialog';
import { ContentType, LayoutItemType, Map } from 'types';
import { DataContext } from 'providers/Data';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  IThreeColumnAdminLayoutItem,
  ITwoColumnAdminLayoutItem,
} from 'interfaces';
import { LocaleTab } from 'components/Common/LocaleTabs/Tab';
import { LocaleTabs } from 'components/Common/LocaleTabs/Tabs';
import { useStyles } from '.';

export default function ContentBuilderActionBar({
  activeLocale,
  addContentOrLayoutItemDialogOpen,
  addContentOrLayoutItemDialogOnClose,
  addContentOrLayoutItemDialogOnOpen,
  content,
  contentType,
  handleAddColumnLayout,
  handleAddEditorItem,
  handleAddMediaItem,
  onActiveLocaleChange,
}: {
  activeLocale: string;
  addContentOrLayoutItemDialogOnClose: () => void;
  addContentOrLayoutItemDialogOnOpen: () => void;
  addContentOrLayoutItemDialogOpen: boolean;
  content: Map<
    (
      | IAdminContentEditorItem
      | IContentImageItem
      | IContentVideoItem
      | ITwoColumnAdminLayoutItem
      | IThreeColumnAdminLayoutItem
    )[]
  >;
  contentType: ContentType;
  handleAddColumnLayout: (type: LayoutItemType) => void;
  handleAddEditorItem: () => void;
  handleAddMediaItem: () => void;
  onActiveLocaleChange: (event: any, value: string) => void;
}) {
  const classes = useStyles();
  const brand = useContext(DataContext).brand as Brand;
  const theme = useTheme();

  const { t } = useTranslation();
  const { xs } = useBreakpoints();

  const [previewDialog, setPreviewDialog] = useState(false);

  const noContent = content[activeLocale].length === 0;

  return (
    <>
      <LocaleTabs onChange={onActiveLocaleChange} value={activeLocale}>
        {brand.configuration.locales.map(locale => (
          <LocaleTab
            key={locale}
            label={
              <div className={classes.tabLabelContainer}>
                <div>{t(`locale_${locale}`)}</div>
                {content[locale].length === 0 && (
                  <Tooltip className={classes.tooltip} title={t('no_content_for_locale').toString()}>
                    <Info fontSize='small' />
                  </Tooltip>
                )}
              </div>
            }
            value={locale}
          />
        ))}
      </LocaleTabs>
      <Divider />
      <div className={classes.bar}>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.right}>
          <div>
            {xs ? (
              <IconButton
                disabled={noContent}
                onClick={() => setPreviewDialog(true)}
                size='small'
                style={{ padding: 2, marginRight: theme.spacing(2) }}
              >
                <Visibility />
              </IconButton>
            ) : (
              <Button
                disabled={noContent}
                onClick={() => setPreviewDialog(true)}
                startIcon={<Visibility />}
                style={{ maxHeight: theme.spacing(4), marginRight: theme.spacing(2) }}
                size='small'
                variant='outlined'
              >
                {t('preview_button')}
              </Button>
            )}
            <Button
              onClick={addContentOrLayoutItemDialogOnOpen}
              startIcon={<Add />}
              style={{ maxHeight: 32 }}
              size='small'
              variant='outlined'
            >
              {t('add_new_item_button')}
            </Button>

            {addContentOrLayoutItemDialogOpen && (
              <AddContentBuilderItemDialog
                handleAddColumnLayout={handleAddColumnLayout}
                handleAddEditorItem={handleAddEditorItem}
                handleAddMediaItem={handleAddMediaItem}
                locale={activeLocale}
                onClose={addContentOrLayoutItemDialogOnClose}
                open={true}
              />
            )}
          </div>
        </div>
      </div>
      {noContent ? <Divider /> : null}
      <ContentBuilderPreviewDialog
        activeLocale={activeLocale}
        content={content}
        contentType={contentType}
        handleClose={() => setPreviewDialog(false)}
        open={previewDialog}
      />
    </>
  );
}
