import React, { useContext } from 'react';
import { Card, CardActionArea, useTheme } from '@material-ui/core';

import { ILessonContentChoiceAnswer, ILessonContentChoiceResult } from 'interfaces';
import { LessonContentChoice } from 'models';
import { LessonDetailContext } from 'pages/App/Learning/LessonDetail';
import { useStyles } from '.';

export default function ChoiceAnswerCard({
  answer,
  children,
  lessonContent,
}: {
  answer: ILessonContentChoiceAnswer;
  children: string;
  lessonContent: LessonContentChoice;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    dispatch,
    state: { questionResults },
  } = useContext(LessonDetailContext);

  /* If lesson content id of this lesson content (= this slide) is included in the result array, */
  /* the question has already been answered. */
  const questionResult = questionResults.find(result => result.lessonContentId === lessonContent.id);

  const hasAnswered = !!questionResult;
  const hasBeenClicked = questionResult && (questionResult as ILessonContentChoiceResult).answer.id === answer.id;

  let styles: React.CSSProperties | undefined = {};

  if (hasAnswered && hasBeenClicked === false) {
    styles = { opacity: 0.75 };
  }

  if (hasAnswered && hasBeenClicked && questionResult) {
    styles = questionResult.answeredCorrect
      ? { backgroundColor: theme.palette.success.light, color: theme.palette.success.dark }
      : { backgroundColor: theme.palette.error.light, color: theme.palette.error.dark };
  }

  return (
    <Card classes={{ root: classes.root }} style={{ ...styles }}>
      <CardActionArea
        className={classes.container}
        disabled={hasAnswered}
        onClick={() => {
          dispatch({
            payload: { answer, answeredCorrect: answer.value, lessonContentId: lessonContent.id },
            type: 'handleChoiceAnswered',
          });
        }}
      >
        {children}
      </CardActionArea>
    </Card>
  );
}
