import React from 'react';
import { Card, CardActionArea, Divider, useTheme } from '@material-ui/core';
import { Announcement } from '@material-ui/icons';
import { formatDistance } from 'date-fns';
import { useHistory } from 'react-router-dom';

import DateFNS from 'utils/date/date-fns';
import { News, User } from 'models';
import { RobotoMedium } from 'components/Common/Roboto/Medium';
import { UserContext } from 'providers/User';
import { useStyles } from '.';

export default function NewsCard({
  currentLocale,
  fallbackLocale,
  news,
}: {
  currentLocale: string;
  fallbackLocale: string;
  news: News;
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const user = React.useContext(UserContext).user as User;

  const baseDate = new Date();
  const date = news.createdAt ? news.createdAt.toDate() : new Date();

  return (
    <Card classes={{ root: classes.root }} style={{ background: theme.palette.background.paper }}>
      <CardActionArea className={classes.root} onClick={() => history.push(`/news/${news.id}`)}>
        <div className={classes.top}>
          <RobotoMedium style={{ color: theme.palette.text.secondary, fontSize: 14 }}>
            {news.getTitle(currentLocale, fallbackLocale)}
          </RobotoMedium>
          <Announcement color='action' />
        </div>
        <Divider />
        <div className={classes.bottom}>
          <div className={classes.status}>
            {news.isRead(user.newsProgress || []) ? null : <div className={classes.unread} />}
          </div>
          <RobotoMedium style={{ color: theme.palette.text.secondary, fontSize: 10 }}>
            {formatDistance(date, baseDate, { addSuffix: true, locale: DateFNS.getLocale(currentLocale) })}
          </RobotoMedium>
        </div>
      </CardActionArea>
    </Card>
  );
}
