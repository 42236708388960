import React, { ReactNode, useContext } from 'react';
import clsx from 'clsx';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { Brightness2, Flare } from '@material-ui/icons';
import { use100vh } from 'react-div-100vh';
import { useHistory, useLocation } from 'react-router-dom';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { AdminLearningItemDetailSubHeader } from './SubHeader/Admin/LearningItemDetail';
import { AdminNavigation } from './Navigation/Admin';
import { AppNavigation } from './Navigation/App';
import { AuthenticationContext } from 'providers/Authentication';
import { AvatarButton } from 'components/Buttons/Avatar';
import { CourseDetailSubHeader } from './SubHeader/Course/CourseDetail';
import { Logo } from 'components/Common/Logo';
import { NavigationType, SubHeaderType } from 'types';
import { NotificationsButton } from 'components/Buttons/Notifications';
import { ThemeContext, useGlobalStyles } from 'providers/Theme';
import { useStyles } from './';

const renderSubHeader = (subHeader?: SubHeaderType) => {
  switch (subHeader?.type) {
    case 'admin-learning-item-detail': {
      const { courseId, lessonId, lessonContentId } = subHeader;
      return (
        <AdminLearningItemDetailSubHeader courseId={courseId} lessonContentId={lessonContentId} lessonId={lessonId} />
      );
    }
    case 'course-detail':
      return <CourseDetailSubHeader course={subHeader?.course} />;
    default:
      return null;
  }
};

const renderNavigation = (navigation?: NavigationType) => {
  switch (navigation?.type) {
    case 'admin':
      return <AdminNavigation />;
    case 'app':
      return <AppNavigation />;
    default:
      return null;
  }
};

export default function Layout({
  children,
  defaultHorizontalMainPadding,
  disableHeader,
  onIconClick,
  subHeader,
  mainStyle,
  navigation,
  verticalCentered,
}: {
  children?: ReactNode;
  defaultHorizontalMainPadding?: boolean;
  disableHeader?: boolean;
  onIconClick?: () => void;
  mainStyle?: React.CSSProperties;
  subHeader?: SubHeaderType;
  navigation?: NavigationType;
  verticalCentered?: boolean;
}) {
  const { authUser } = useContext(AuthenticationContext);
  const { mode, toggleMode } = useContext(ThemeContext);
  const { xs } = useBreakpoints();

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const height = use100vh();
  const history = useHistory();

  const isAdminArea = useLocation().pathname.startsWith('/admin');
  const mainClasses = [globalClasses.layout, classes.main];

  if (defaultHorizontalMainPadding) mainClasses.push(globalClasses.horizontalPadding);
  if (verticalCentered) mainClasses.push(classes.verticalCentered);

  return (
    <>
      <div className={classes.root} style={{ height: height || '100vh' }}>
        {disableHeader ? null : (
          <>
            <AppBar elevation={1} position='fixed'>
              <Toolbar className={clsx([globalClasses.layout])}>
                <Logo
                  onClick={() => {
                    if (!!onIconClick) {
                      onIconClick();
                      return;
                    }
                    history.push(isAdminArea ? '/admin' : '/');
                  }}
                />
                <div style={{ marginRight: 16 }} />
                {renderNavigation(navigation)}
                <div style={{ flexGrow: 1 }} />
                <div style={{ marginLeft: 16 }} />
                <div className={classes.iconButton}>
                  <NotificationsButton />
                </div>
                {xs ? null : (
                  <div className={classes.iconButton}>
                    <IconButton onClick={() => toggleMode()} size='small'>
                      {mode === 'dark' ? <Flare /> : <Brightness2 />}
                    </IconButton>
                  </div>
                )}
                {authUser && <AvatarButton />}
              </Toolbar>
            </AppBar>
            <div className={classes.offset} />
          </>
        )}
        {subHeader && renderSubHeader(subHeader)}
        <main className={clsx([...mainClasses])} style={{ ...mainStyle }}>
          {children}
        </main>
      </div>
    </>
  );
}
