import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const unitValidation = () =>
  yup.object().shape({
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    internalId: yup
      .string()
      .required(i18n.t('form_value_required'))
      .max(64, i18n.t('form_value_max', { number: 64 })),
  });

export { default as UnitFields } from './UnitFields';
