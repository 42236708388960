import { makeStyles, withStyles } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';

export const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 2),
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 20,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  styleBar: {
    display: 'flex',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export { default as EditorItem } from './EditorItem';
