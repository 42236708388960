import React, { ReactNode, useContext } from 'react';

import useAdminLessonContents from 'hooks/data/useAdminLessonContents';
import useAdminUnits from 'hooks/data/useAdminUnits';
import useBrand from 'hooks/data/useBrand';
import useCourses from 'hooks/data/useCourses';
import useFiles from 'hooks/data/useFiles';
import useGroups from 'hooks/data/useGroups';
import useLessons from 'hooks/data/useLessons';
import useNews from 'hooks/data/useNews';
import useResources from 'hooks/data/useResources';
import useRoles from 'hooks/data/useRoles';
import useUserProgress from 'hooks/data/useUserProgress';
import useUsers from 'hooks/data/useUsers';
import { Brand, User } from 'models';
import { DataContext } from '.';
import { ErrorView } from 'views/Error';
import { Layout } from 'components/Layout';
import { UserContext } from 'providers/User';
import { firestore } from 'configuration/firebase';

export default function DataProvider({ children }: { children: ReactNode }) {
  const user = useContext(UserContext).user as User;

  const brandId = user.brand;
  const brandPath = firestore.collection(Brand.collection).doc(user.brand).path;

  const isFileAdmin = user.isFileAdmin();
  const isLearningContentAdmin = user.isLearningContentAdmin();
  const isNewsAdmin = user.isNewsAdmin();
  const isUserAdmin = user.isUserAdmin();
  const isUnitAdmin = user.isUnitAdmin();

  const userGroups = user.groups;
  const userId = user.id;
  const userRole = user.role;

  const { brandData, brandError, brandLoading } = useBrand(brandPath);
  const { coursesData, coursesError, coursesLoading } = useCourses(
    brandPath,
    isLearningContentAdmin,
    userGroups,
    userRole
  );
  const { filesData, filesError, filesLoading } = useFiles(brandPath, isFileAdmin, userGroups, userRole);
  const { groupsData, groupsError, groupsLoading } = useGroups(brandPath, isFileAdmin, isLearningContentAdmin, isUserAdmin, isUnitAdmin);
  const { lessonsData, lessonsError, lessonsLoading } = useLessons(
    brandPath,
    isLearningContentAdmin,
    userGroups,
    userRole
  );
  const { adminLessonContentsData, adminLessonContentsError, adminLessonContentsLoading } = useAdminLessonContents(
    brandPath,
    isLearningContentAdmin
  );
  const { newsData, newsError, newsLoading } = useNews(brandPath, isNewsAdmin);
  const { resourcesData, resourcesError, resourcesLoading } = useResources(isUserAdmin);
  const { rolesData, rolesError, rolesLoading } = useRoles(brandPath, isFileAdmin, isLearningContentAdmin, isUserAdmin);
  const { userProgressData, userProgressError, userProgressLoading } = useUserProgress(userId);
  const { unitsData, unitsError, unitsLoading } = useAdminUnits(brandPath, isUserAdmin);
  const { usersData, usersError, usersLoading } = useUsers(brandId, isUserAdmin);

  if (
    adminLessonContentsLoading ||
    brandLoading ||
    coursesLoading ||
    filesLoading ||
    groupsLoading ||
    lessonsLoading ||
    newsLoading ||
    resourcesLoading ||
    rolesLoading.brand ||
    rolesLoading.default ||
    userProgressLoading ||
    unitsLoading ||
    usersLoading
  ) {
    return <Layout verticalCentered>{null}</Layout>;
  }

  if (adminLessonContentsError.status) {
    return <ErrorView msg={adminLessonContentsError.message} />;
  }

  if (brandError.status) {
    return <ErrorView msg={brandError.message} />;
  }

  if (coursesError.status) {
    return <ErrorView msg={coursesError.message} />;
  }

  if (filesError.status) {
    return <ErrorView msg={filesError.message} />;
  }

  if (groupsError.status) {
    return <ErrorView msg={groupsError.message} />;
  }

  if (lessonsError.status) {
    return <ErrorView msg={lessonsError.message} />;
  }

  if (newsError.status) {
    return <ErrorView msg={newsError.message} />;
  }

  if (resourcesError.status) {
    return <ErrorView msg={resourcesError.message} />;
  }

  if (rolesError.status) {
    return <ErrorView msg={rolesError.message} />;
  }

  if (userProgressError.status) {
    return <ErrorView msg={userProgressError.message} />;
  }

  if (unitsError.status) {
    return <ErrorView msg={unitsError.message} />;
  }

  if (usersError.status) {
    return <ErrorView msg={usersError.message} />;
  }

  return (
    <DataContext.Provider
      value={{
        adminLessonContents: adminLessonContentsData,
        brand: brandData,
        courses: { ...coursesData },
        files: { ...filesData },
        groups: groupsData,
        lessons: {
          ...lessonsData,
          app: lessonsData.app.filter(lesson => coursesData.app.map(course => course.id).includes(lesson.course)), // If course is unpublished or restricted for user lesson will be filtered out
        },
        news: { ...newsData },
        resources: resourcesData,
        roles: rolesData,
        units: unitsData,
        users: usersData,
        userProgress: userProgressData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
