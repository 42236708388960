import React from 'react';
import { IAdminUnitDataState } from 'interfaces';
import { Unit } from 'models';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useAdminUnits(brandPath: string, isUserAdmin: boolean) {
  const [state, setState] = React.useState<IAdminUnitDataState>({
    unitsError: { status: false, message: '' },
    unitsData: [],
    unitsLoading: false,
  });
  const unitsPath = firestore.doc(brandPath).collection(Unit.collection).path;

  React.useEffect(() => {
    if (isUserAdmin) {
      onFirestore('onSnapshot', unitsPath);
      const unsubscribe = firestore.collection(unitsPath).onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(i, snapshot.docs.length, 'Unit', change.doc.id, change.oldIndex, change.newIndex, change.type);
          });
          const units = snapshot.docs.map(doc => new Unit(addIdToDocument(doc)));
          setState(prevState => ({ ...prevState, unitsData: units, unitsLoading: false }));
        },
        error => {
          const message = 'Units data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({ ...prevState, unitsError: { status: true, message }, unitsLoading: false }));
          onError('Units', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, unitsLoading: false }));
    }
  }, [isUserAdmin, unitsPath]);

  return { ...state };
}
