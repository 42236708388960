import React from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography, useTheme } from '@material-ui/core';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const theme = useTheme();
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        color='inherit'
        style={{
          color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        size={theme.spacing(10)}
        thickness={4}
        variant='determinate'
        {...props}
        value={100}
      />
      <CircularProgress thickness={4} size={theme.spacing(10)} variant='determinate' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography style={{ fontSize: 18, fontWeight: 600 }} variant='caption' component='div' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
