import React, { useContext } from 'react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { FormControlLabel, FormHelperText, Grid, Paper, Switch, TextField, Typography } from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { ContentBuilder } from 'components/Forms/ContentBuilder';
import { DataContext } from 'providers/Data';
import { INewsFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { LocalizedFieldDialog } from 'components/Dialogs/LocalizedField';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { useGlobalStyles } from 'providers/Theme';
import { newsValidation } from '.';

const backPath = '/admin/news';

export default function NewsFields({
  initialValues,
  onSubmit,
  type,
}: {
  initialValues: INewsFormData;
  onSubmit: (values: INewsFormData) => Promise<any>;
  type: 'create' | 'update';
}) {
  const brand = useContext(DataContext).brand as Brand;
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values)
          .then(() => {
            type === 'create' ? notify(t('alert_news_creation_success')) : notify(t('alert_news_update_success'));
          })
          .catch(err => {
            console.error(err);
            notify(t('default_error_message'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() => newsValidation(brand.configuration.locales, brand.configuration.fallbackLocale)}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, dirty, setFieldValue }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            {' '}
                            <Typography variant='h6'>{t('general_fields')}</Typography>
                          </div>
                          <div>
                            {' '}
                            <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={values.published}
                                      color='primary'
                                      disabled={isSubmitting}
                                      onChange={handleChange}
                                      name='published'
                                    />
                                  }
                                  label='Published'
                                  labelPlacement='end'
                                  style={{ marginRight: 0 }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormHelperText>{t('publish_this_news')}</FormHelperText>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          disabled={isSubmitting}
                          error={!!errors.key && touched.key}
                          fullWidth
                          helperText={
                            !!errors.key && touched.key && errors.key ? errors.key : t('news_key_label_helper_text')
                          }
                          FormHelperTextProps={{
                            style: {
                              minHeight: 19,
                            },
                          }}
                          id='key'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={t('news_key_label')}
                          name='key'
                          required
                          value={values.key}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizedFieldDialog
                          disabled={isSubmitting}
                          errors={errors.localizedFields?.title}
                          fieldName='title'
                          fullWidth
                          label={t('news_title_label')}
                          name='localizedFields'
                          onBlur={handleBlur}
                          onSave={localeValues => {
                            setFieldValue(`localizedFields.title`, localeValues);
                          }}
                          required
                          touched={touched.localizedFields?.title}
                          values={values.localizedFields.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizedFieldDialog
                          disabled={isSubmitting}
                          errors={errors.localizedFields?.caption}
                          helperText={t('news_caption_label_helper_text')}
                          fieldName='caption'
                          fullWidth
                          label={t('news_caption_label')}
                          maxCharacter={1000}
                          multiline
                          name='localizedFields'
                          onBlur={handleBlur}
                          onSave={localeValues => {
                            setFieldValue(`localizedFields.caption`, localeValues);
                          }}
                          touched={touched.localizedFields?.caption}
                          values={values.localizedFields.caption}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <ContentBuilder contentType={'news'} content={values.localizedFields.content} />
                </Grid>
              </Grid>
              <div className={clsx([globalClasses.dividerMarginBottom, globalClasses.dividerMarginTop])} />
              <ButtonGroup
                backPath={backPath}
                createButtonText={t('create_news_button')}
                isSubmitting={isSubmitting}
                type={type}
                updateButtonText={t('update_news_button')}
              />
              <div className={globalClasses.marginBottom} />
              <ScrollToError />
              <LoadingBackdrop open={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
