import { makeStyles } from '@material-ui/core';
import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const courseValidation = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string().required(i18n.t('form_value_required'));
    else {
      localeObject[key] = yup.string();
    }
  });

  const notRequiredLocaleObject: any = {};
  locales.forEach(key => {
    notRequiredLocaleObject[key] = yup.string().max(1000, i18n.t('form_value_max', { number: 1000 }));
  });

  const schema = yup.object().shape({
    courseColor: yup
      .string()
      .required(i18n.t('form_value_required'))
      .matches(/#[0-9a-f]{6}|#[0-9a-f]{3}/gi, i18n.t('default_error_message')),
    groupRestrictions: yup.array().of(yup.string()),
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .min(1, i18n.t('form_value_min', { number: 1 }))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    localizedFields: yup
      .object({
        description: yup.object({ ...notRequiredLocaleObject }).noUnknown(),
        title: yup.object({ ...localeObject }).noUnknown(),
      })
      .noUnknown(),
    preConditions: yup.array().of(yup.string()),
    published: yup.boolean().required(i18n.t('form_value_required')),
    roleRestrictions: yup.array().of(yup.string()),
    sortIndex: yup
      .number()
      .required(i18n.t('form_value_required'))
      .min(1, i18n.t('form_value_postive', { number: 1 })),
  });

  return schema;
};

export const useStyles = makeStyles(() => ({}));

export { default as AdminCoursesPage } from './AdminCoursesPage';
