import React, { useContext } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import BraineeFile from 'models/File';
import { Brand, Group, Role, User } from 'models';
import { DataContext } from 'providers/Data';
import { FileFields } from 'components/Forms/Fields/Admin/FileFields';
import { IFileFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { NotFoundView } from 'views/NotFound';
import { UserContext } from 'providers/User';
import { firestore, functions, serverTimestamp, storage } from 'configuration/firebase';
import { onFirestore, onFunctions, onInfo, onStorage, onSuccess } from 'utils/logger';
import { setDeleteFlagforEmptyLocaleFields } from 'utils/data';

const fileSource = 'AdminUpdateFilePage';

export default function AdminUpdateFilePage() {
  const brand = useContext(DataContext).brand as Brand;
  const user = useContext(UserContext).user as User;
  const { files } = useContext(DataContext);
  const { fileId }: { fileId: string } = useParams();

  const file = files.admin.find(item => item.id === fileId);

  if (file === undefined) return <NotFoundView />;

  const initialValues: IFileFormData = {
    downloadUrl: file.downloadUrl,
    groupRestrictions: file.groupRestrictions,
    hasFile: file.hasFile,
    localizedFields: { ...file.localizedFields },
    metaData: { ...file.metaData },
    name: file.name,
    published: file.published,
    roleRestrictions: file.roleRestrictions,
  };

  const handleSubmit = async (values: IFileFormData) =>
    new Promise<void>(async (resolve, reject) => {
      const { groupRestrictions, hasFile, localizedFields, roleRestrictions, published } = values;

      /* When download url and meta data does not change in the following equal check we are using the initial downoadUrl and metaData  */
      let downloadUrl = file.downloadUrl;
      let metaData = { ...file.metaData };

      const brandRef = firestore.collection(Brand.collection).doc(brand.id);
      const fileRef = brandRef.collection(BraineeFile.collection).doc(file.id);
      const userRef = firestore.collection(User.collection).doc(user.id);

      if (
        (file.published === true && values.published === false) ||
        !_.isEqual(file.groupRestrictions, values.groupRestrictions) ||
        !_.isEqual(file.roleRestrictions, values.roleRestrictions)
      ) {
        onInfo(fileSource, 'Permissions have changed.');
        const updateDownloadUrl = functions.httpsCallable('updateDownloadUrl');
        const path = `/files/${brand.id}/${file.name}`;
        const storageRef = storage.ref(path);

        try {
          onFunctions('updateDownloadUrl');
          await updateDownloadUrl({ fileName: file.name });
          onSuccess('updateDownolarUrl', 'callable-function-success');

          onStorage('getDownloadUrl', storageRef.fullPath);
          downloadUrl = await storageRef.getDownloadURL();
          onSuccess(storageRef.fullPath, 'getDownloadUrl-success');

          onStorage('getMetadata', storageRef.fullPath);
          const newMetadata = await storageRef.getMetadata();
          onSuccess(storageRef.fullPath, 'getMetadata-success');

          metaData['updated'] = newMetadata['updated'];
        } catch (err) {
          console.error(err);
          reject('default_error_message');
          return;
        }
      }

      try {
        onFirestore('set', userRef.path, 'file');

        await fileRef
          .set(
            {
              downloadUrl,
              metaData,
              groupRestrictions,
              hasFile,
              localizedFields: setDeleteFlagforEmptyLocaleFields(localizedFields),
              roleRestrictions,
              published,
              groupRestrictionRefs: values.groupRestrictions.map(groupId =>
                brandRef.collection(Group.collection).doc(groupId)
              ),
              hasGroupRestrictions: values.groupRestrictions.length > 0,
              hasRoleRestrictions: values.roleRestrictions.length > 0,
              lastUpdateAt: serverTimestamp,
              lastUpdateFrom: userRef.id,
              lastUpdateFromRef: userRef,
              roleRestrictionRefs: values.roleRestrictions.map(roleId =>
                brandRef.collection(Role.collection).doc(roleId)
              ),
            },
            { merge: true }
          )
          .then(() => {
            onSuccess(fileRef.path, 'set-success');
          });

        resolve();
        return;
      } catch (err) {
        console.error(err);
        reject('default_error_message');
        return;
      }
    });

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
      <FileFields onSubmit={handleSubmit} initialValues={initialValues} type='update' />
    </Layout>
  );
}
