export default function filterEmptyLocaleFields(fields: any) {
  const localizedFields: any = {};
  for (const [field, value] of Object.entries(fields)) {
    localizedFields[field] = {};
    for (const [locale, localeValue] of Object.entries(value as any)) {
      if (localeValue && localeValue !== '') {
        localizedFields[field][locale] = localeValue;
      }
    }
  }
  return localizedFields;
}
