import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AdminNewNewsPage } from './NewNews';
import { AdminNewsPage } from './News';
import { AdminUpdateNewsPage } from './UpdateNews';
import { NotFoundView } from 'views/NotFound';

export default function AdminFileMainPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminNewsPage />
      </Route>
      <Route exact path={path + '/new-news'}>
        <AdminNewNewsPage />
      </Route>
      <Route exact path={`${path}/update-news/:newsId`}>
        <AdminUpdateNewsPage />
      </Route>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
