import React, { useContext } from 'react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch as MuiSwitch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, LessonContent, LessonContentBase, LessonContentChoice, LessonContentSorting, User } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { DataContext } from 'providers/Data';
import { ILessonContentGeneralFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { UserContext } from 'providers/User';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { lessonValidationGeneral } from '.';
import { onError, onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

export default function AdminLessonContentDetailGeneralPage({
  lessonContent,
}: {
  lessonContent: LessonContent | LessonContentChoice | LessonContentSorting;
}) {
  const backPath = `/admin/learning-content/lesson/${lessonContent.lesson}/lesson-contents`;
  const brand = useContext(DataContext).brand as Brand;
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const { id, key, courseRef, lessonRef, published, sortIndex, type } = lessonContent;
  const { t } = useTranslation();

  const initialValues: ILessonContentGeneralFormData = {
    key,
    published,
    sortIndex,
    type,
  };

  const handleSubmit = async (values: ILessonContentGeneralFormData) => {
    const batch = firestore.batch();
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);
    const lessonContentRef = brandRef.collection(LessonContentBase.collection).doc(id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('batch-set', 'lesson-content');

    batch.set(
      lessonContentRef,
      {
        ...values,
        course: courseRef.id,
        courseRef: courseRef,
        createdAt: serverTimestamp,
        createdFrom: userRef.id,
        createdFromRef: userRef,
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lesson: lessonRef.id,
        lessonRef: lessonRef,
      },
      { merge: true }
    );

    if (published === false && values.published === true) {
      onFirestore('batch-set', 'lesson');
      batch.set(
        lessonRef,
        {
          lastContentUpdateAt: serverTimestamp, 
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        },
        { merge: true }
      );
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await batch.commit();
        onSuccess(lessonContentRef.path, 'batch-success');
        if (published === false && values.published === true) {
          onSuccess(lessonRef.path, 'batch-success');
        }
        return resolve();
      } catch (err) {
        onError('AdminLessonContentDetailGeneralPage', err);
        reject();
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_content_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() => lessonValidationGeneral()}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, dirty, setFieldValue }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {' '}
                                  <Typography variant='h6'>{t('general_fields')}</Typography>
                                </div>
                                <div>
                                  {' '}
                                  <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <MuiSwitch
                                            checked={values.published}
                                            color='primary'
                                            disabled={isSubmitting}
                                            onChange={handleChange}
                                            name='published'
                                          />
                                        }
                                        label='Published'
                                        labelPlacement='end'
                                        style={{ marginRight: 0 }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormHelperText>{t('publish_this_lesson_content')}</FormHelperText>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.key && touched.key}
                                fullWidth
                                helperText={
                                  !!errors.key && touched.key && errors.key
                                    ? errors.key
                                    : t('lesson_content_key_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='key'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('lesson_content_key_label')}
                                name='key'
                                required
                                value={values.key}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.sortIndex && touched.sortIndex}
                                fullWidth
                                helperText={
                                  !!errors.sortIndex && touched.sortIndex && errors.sortIndex
                                    ? errors.sortIndex
                                    : t('lesson_content_index_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='sortIndex'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('lesson_content_index_label')}
                                name='sortIndex'
                                required
                                type='number'
                                value={values.sortIndex}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                disabled
                                fullWidth
                                label='Content type'
                                required
                                variant='outlined'
                                value={values.type.charAt(0).toUpperCase() + values.type.slice(1)}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ButtonGroup
                  backPath={backPath}
                  createButtonText={t('create_lesson_content_button')}
                  isSubmitting={isSubmitting}
                  type={'update'}
                  updateButtonText={t('update_lesson_content_button')}
                />
              </div>
              <ScrollToError />
              <LoadingBackdrop open={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
