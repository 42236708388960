import React from 'react';
import { IconButton, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStyles } from '.';

export default function ButtonGroup({
  backPath,
  createButtonText,
  isSubmitting,
  type,
  updateButtonText,
}: {
  backPath: string;
  createButtonText: string;
  isSubmitting?: boolean;
  type: 'create' | 'update';
  updateButtonText: string;
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        {xs ? (
          <>
            <IconButton disabled={isSubmitting} onClick={() => history.push(backPath)} size='small'>
              <KeyboardBackspace />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              disabled={isSubmitting}
              onClick={() => history.push(backPath)}
              startIcon={<KeyboardBackspace />}
              variant='outlined'
            >
              {t('back_button')}
            </Button>
          </>
        )}
      </div>
      <div>
        <div className={classes.right}>
          <Button disabled={isSubmitting} color='primary' type='submit' variant='contained'>
            {type === 'create' ? createButtonText : updateButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
