import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'components/Buttons/Loading';

export default function ConfirmationDialog({
  contentText,
  handleClick,
  handleClose,
  loading,
  open,
  title,
  type,
}: {
  contentText: string;
  handleClick: () => void;
  handleClose: () => void;
  loading?: boolean;
  open: boolean;
  title: string;
  type?: 'delete';
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth='sm'
      onClose={() => {
        if (!loading) {
          return handleClose();
        }
      }}
      open={open}
    >
      <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='confirmationa-dialog-description' variant='body2'>
          {contentText} {type === 'delete' && t('delete_confirmation_dialog_content_sub_text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='default' variant='outlined'>
          {t('cancel_button')}
        </Button>
        <LoadingButton autoFocus onClick={handleClick} color='primary' loading={loading} variant='contained'>
          {t('delete_button')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
