import React, { useContext } from 'react';
import { Add, Delete, DragIndicator } from '@material-ui/icons';
import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Form, Formik } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import useNotification from 'hooks/notification/useNotification';
import { Brand, LessonContent, LessonContentBase, LessonContentChoice, LessonContentSorting, User } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { ContentBuilder } from 'components/Forms/ContentBuilder';
import { DataContext } from 'providers/Data';
import { ILessonContentChoiceFormData, ILessonContentContentFormData, ILessonContentSortingFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { LocaleTabs } from 'components/Common/LocaleTabs/Tabs';
import { LocaleTab } from 'components/Common/LocaleTabs/Tab';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { UserContext } from 'providers/User';
import { convertEditorItemToHtml, convertHtmlToEditor } from 'utils/data';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { lessonContentChoiceValidation, lessonContentSortingValidation, useStyles } from '.';
import { onError, onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

const hasErrors = (errors: any, touched: any, locale: string, rootField: string, field: string): boolean => {
  if (
    errors &&
    errors.localizedFields &&
    errors.localizedFields[rootField][field] &&
    errors.localizedFields[rootField][field][locale] &&
    touched &&
    touched.localizedFields &&
    touched.localizedFields[rootField][field] &&
    touched.localizedFields[rootField][field][locale]
  ) {
    return true;
  }
  return false;
};

const answerHasError = (errors: any, touched: any, locale: string, index: number, type: 'choice' | 'sorting') => {
  if (
    errors &&
    errors.localizedFields &&
    errors.localizedFields[type] &&
    errors.localizedFields[type]['answers'] &&
    errors.localizedFields[type]['answers'][index] &&
    errors.localizedFields[type]['answers'][index]['label'] &&
    errors.localizedFields[type]['answers'][index]['label'][locale] &&
    touched &&
    touched.localizedFields &&
    touched.localizedFields[type] &&
    touched.localizedFields[type]['answers'] &&
    touched.localizedFields[type]['answers'][index] &&
    touched.localizedFields[type]['answers'][index]['label'] &&
    touched.localizedFields[type]['answers'][index]['label'][locale]
  ) {
    return true;
  }
  return false;
};

export default function AdminLessonDetailLessonContentPage({
  lessonContent,
}: {
  lessonContent: LessonContent | LessonContentChoice | LessonContentSorting;
}) {
  const backPath = `/admin/learning-content/lesson/${lessonContent.lesson}/lesson-contents`;
  const brand = useContext(DataContext).brand as Brand;

  if (lessonContent.type === 'choice') {
    let localizedFieldCopy: any = {
      choice: {
        answers: [],
        question: {},
      },
    };

    let questionCopy: any = {};

    brand.configuration.locales.forEach(locale => {
      questionCopy = { ...questionCopy, [locale]: lessonContent.localizedFields.choice.question[locale] || '' };
    });

    const copiedAnswers = lessonContent.localizedFields.choice.answers.map((answer, i) => {
      let labelCopy: any = {};

      brand.configuration.locales.forEach(locale => {
        labelCopy = { ...labelCopy, [locale]: lessonContent.localizedFields.choice.answers[i].label[locale] || '' };
      });

      return { ...answer, label: { ...labelCopy } };
    });

    localizedFieldCopy['choice']['answers'] = [...copiedAnswers];
    localizedFieldCopy['choice']['question'] = { ...questionCopy };

    const initialValues: ILessonContentChoiceFormData = {
      localizedFields: { ...localizedFieldCopy },
      locales: brand.configuration.locales,
    };

    return (
      <ChoiceFields backPath={backPath} brand={brand} lessonContent={lessonContent} initialValues={initialValues} />
    );
  }

  if (lessonContent.type === 'sorting') {
    let localizedFieldCopy: any = {
      sorting: {
        answers: [],
        question: {},
      },
    };

    let questionCopy: any = {};

    brand.configuration.locales.forEach(locale => {
      questionCopy = { ...questionCopy, [locale]: lessonContent.localizedFields.sorting.question[locale] || '' };
    });

    const copiedAnswers = lessonContent.localizedFields.sorting.answers.map((answer, i) => {
      let labelCopy: any = {};

      brand.configuration.locales.forEach(locale => {
        labelCopy = { ...labelCopy, [locale]: lessonContent.localizedFields.sorting.answers[i].label[locale] || '' };
      });

      return { ...answer, label: { ...labelCopy } };
    });

    localizedFieldCopy['sorting']['answers'] = [...copiedAnswers];
    localizedFieldCopy['sorting']['question'] = { ...questionCopy };

    const initialValues: ILessonContentSortingFormData = {
      localizedFields: { ...localizedFieldCopy },
      locales: brand.configuration.locales,
    };

    return (
      <SortingFields backPath={backPath} brand={brand} lessonContent={lessonContent} initialValues={initialValues} />
    );
  }

  if (lessonContent.type === 'content') {
    const localizedFieldCopy: any = {
      content: {},
    };

    const contentBuilderLocales = lessonContent.locales;

    brand.configuration.locales.forEach(locale => {
      if (contentBuilderLocales.includes(locale)) {
        localizedFieldCopy['content'][locale] = lessonContent.localizedFields.content[locale].map(item => {
          if (item.type === 'editor') {
            return { ...convertHtmlToEditor(item) };
          }

          if (item.type === 'two-column') {
            return {
              ...item,
              columns: {
                left: item.columns.left.map(leftItem => {
                  if (leftItem.type === 'editor') {
                    return { ...convertHtmlToEditor(leftItem) };
                  }
                  return leftItem;
                }),
                right: item.columns.right.map(rightItem => {
                  if (rightItem.type === 'editor') {
                    return { ...convertHtmlToEditor(rightItem) };
                  }
                  return rightItem;
                }),
              },
            };
          }

          if (item.type === 'three-column') {
            return {
              ...item,
              columns: {
                left: item.columns.left.map(leftItem => {
                  if (leftItem.type === 'editor') {
                    return { ...convertHtmlToEditor(leftItem) };
                  }
                  return leftItem;
                }),
                middle: item.columns.middle.map(middleItem => {
                  if (middleItem.type === 'editor') {
                    return { ...convertHtmlToEditor(middleItem) };
                  }
                  return middleItem;
                }),
                right: item.columns.right.map(rightItem => {
                  if (rightItem.type === 'editor') {
                    return { ...convertHtmlToEditor(rightItem) };
                  }
                  return rightItem;
                }),
              },
            };
          }

          return item;
        });
      } else {
        localizedFieldCopy['content'][locale] = [];
      }
    });

    const initialValues: ILessonContentContentFormData = {
      localizedFields: { content: { ...localizedFieldCopy.content } },
      locales: brand.configuration.locales,
    };

    return <ContentFields backPath={backPath} lessonContent={lessonContent} initialValues={{ ...initialValues }} />;
  }

  return null;
}

function ChoiceFields({
  backPath,
  brand,
  initialValues,
  lessonContent,
}: {
  backPath: string;
  brand: Brand;
  initialValues: ILessonContentChoiceFormData;
  lessonContent: LessonContentChoice;
}) {
  const classes = useStyles();
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const [activeLocale, setActiveLocale] = React.useState(brand.configuration.fallbackLocale);

  const { t } = useTranslation();

  const handleSubmit = (values: ILessonContentChoiceFormData) => {
    const batch = firestore.batch();
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);

    const lessonContentRef = brandRef.collection(LessonContentBase.collection).doc(lessonContent.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('batch-set', 'lesson-content');

    batch.update(lessonContentRef, {
      lastUpdateAt: serverTimestamp,
      lastUpdateFrom: user.id,
      lastUpdateFromRef: userRef,
      locales: values.locales,
      localizedFields: { ...values.localizedFields },
    });

    if (lessonContent.published === true) {
      onFirestore('batch-set', 'lesson');
      batch.set(
        lessonContent.lessonRef,
        {
          lastContentUpdateAt: serverTimestamp,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        },
        { merge: true }
      );
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await batch.commit();
        onSuccess(lessonContentRef.path, 'batch-success');
        if (lessonContent.published === true) {
          onSuccess(lessonContent.lessonRef.path, 'batch-success');
        }
        return resolve();
      } catch (err) {
        onError('AdminLessonContentDetailContentPage', err);
        reject();
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_content_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() =>
        lessonContentChoiceValidation(brand.configuration.locales, brand.configuration.fallbackLocale)
      }
    >
      {({ dirty, errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values }) => {
        return (
          <Form className={globalClasses.form} noValidate>
            <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
            <Paper style={{ paddingBottom: theme.spacing(4) }}>
              <LocaleTabs onChange={(event, value) => setActiveLocale(value)} value={activeLocale}>
                {brand.configuration.locales.map(locale => (
                  <LocaleTab
                    key={locale}
                    label={
                      <div className={classes.tabContainer}>
                        <div>{t(`locale_${locale}`)}</div>
                      </div>
                    }
                    value={locale}
                  />
                ))}
              </LocaleTabs>
              <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                <Divider style={{ marginBottom: theme.spacing(2) }} />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={hasErrors(errors, touched, activeLocale, 'choice', 'question')}
                      fullWidth
                      helperText={
                        hasErrors(errors, touched, activeLocale, 'choice', 'question')
                          ? //@ts-ignore
                            errors.localizedFields.choice.question[activeLocale]
                          : t('lesson_content_question_label_helper_text')
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id={`localizedFields.choice.question.${activeLocale}`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('lesson_content_question_label')}
                      name={`localizedFields.choice.question.${activeLocale}`}
                      required={brand.configuration.fallbackLocale === activeLocale}
                      value={values.localizedFields.choice.question[activeLocale]}
                      variant='standard'
                    />
                  </Grid>
                  {values.localizedFields.choice.answers.map((answer, outerIndex) => {
                    return (
                      <Grid key={answer.id} item xs={12}>
                        <Grid container spacing={2} alignItems='center' alignContent='center' direction='row'>
                          <Grid item xs={1}>
                            <Checkbox
                              checked={answer.value}
                              onChange={() => {
                                values.localizedFields.choice.answers.forEach((answer, innerIndex) => {
                                  setFieldValue(
                                    `localizedFields.choice.answers[${innerIndex}].value`,
                                    outerIndex === innerIndex
                                  );
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              disabled={isSubmitting}
                              error={answerHasError(errors, touched, activeLocale, outerIndex, 'choice')}
                              fullWidth
                              id={`localizedFields.choice.answers[${outerIndex}].label.${activeLocale}`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('lesson_content_answer_label')}
                              name={`localizedFields.choice.answers[${outerIndex}].label.${activeLocale}`}
                              required={brand.configuration.fallbackLocale === activeLocale}
                              value={values.localizedFields.choice.answers[outerIndex].label[activeLocale]}
                              variant='standard'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Paper>
            <div>
              <ButtonGroup
                backPath={backPath}
                createButtonText={t('create_lesson_content_button')}
                isSubmitting={isSubmitting}
                type={'update'}
                updateButtonText={t('update_lesson_content_button')}
              />
            </div>
            <ScrollToError preAction={() => setActiveLocale(brand.configuration.fallbackLocale)} />
            <LoadingBackdrop open={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
}

function ContentFields({
  backPath,
  initialValues,
  lessonContent,
}: {
  backPath: string;
  initialValues: ILessonContentContentFormData;
  lessonContent: LessonContent;
}) {
  const brand = useContext(DataContext).brand as Brand;
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const handleSubmit = (values: ILessonContentContentFormData) => {
    const batch = firestore.batch();
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);

    const lessonContentRef = brandRef.collection(LessonContentBase.collection).doc(lessonContent.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    /* Filtes out all locales which does not have any content */
    const filteredLocales = values.locales.filter(locale => {
      const content = values.localizedFields.content[locale];
      return content && content.length > 0;
    });

    const localizedFieldsCopy: any = {
      content: {},
    };

    filteredLocales.forEach(locale => {
      localizedFieldsCopy['content'][locale] = values.localizedFields.content[locale]
        .map(item => convertEditorItemToHtml(item))
        .filter(item => item !== null);
    });

    onFirestore('batch-set', 'lesson-content');

    batch.update(lessonContentRef, {
      lastUpdateAt: serverTimestamp,
      lastUpdateFrom: user.id,
      lastUpdateFromRef: userRef,
      locales: filteredLocales,
      localizedFields: { ...localizedFieldsCopy },
    });

    if (lessonContent.published === true) {
      onFirestore('batch-set', 'lesson');
      batch.set(
        lessonContent.lessonRef,
        {
          lastContentUpdateAt: serverTimestamp,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        },
        { merge: true }
      );
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await batch.commit();
        onSuccess(lessonContentRef.path, 'batch-success');
        if (lessonContent.published === true) {
          onSuccess(lessonContent.lessonRef.path, 'batch-success');
        }
        return resolve();
      } catch (err) {
        onError('AdminLessonContentDetailContentPage', err);
        reject();
      }
    });
  };

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_content_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
    >
      {({ dirty, isSubmitting, values }) => {
        return (
          <Form className={globalClasses.form} noValidate>
            <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
            <ContentBuilder content={values.localizedFields.content} contentType='lesson-content' />
            <div>
              <ButtonGroup
                backPath={backPath}
                createButtonText={t('create_lesson_content_button')}
                isSubmitting={isSubmitting}
                type={'update'}
                updateButtonText={t('update_lesson_content_button')}
              />
            </div>
            <ScrollToError />
            <LoadingBackdrop open={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
}

function SortingFields({
  backPath,
  brand,
  initialValues,
  lessonContent,
}: {
  backPath: string;
  brand: Brand;
  initialValues: ILessonContentSortingFormData;
  lessonContent: LessonContentSorting;
}) {
  const classes = useStyles();
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const [activeLocale, setActiveLocale] = React.useState(brand.configuration.fallbackLocale);

  const { t } = useTranslation();

  const handleSubmit = (values: ILessonContentSortingFormData) => {
    const batch = firestore.batch();
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);

    const lessonContentRef = brandRef.collection(LessonContentBase.collection).doc(lessonContent.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('batch-set', 'lesson-content');

    batch.set(
      lessonContentRef,
      {
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: user.id,
        lastUpdateFromRef: userRef,
        locales: values.locales,
        localizedFields: { ...values.localizedFields },
      },
      { merge: true }
    );

    if (lessonContent.published === true) {
      onFirestore('batch-set', 'lesson');
      batch.set(
        lessonContent.lessonRef,
        {
          lastContentUpdateAt: serverTimestamp,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        },
        { merge: true }
      );
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await batch.commit();
        onSuccess(lessonContentRef.path, 'batch-success');
        if (lessonContent.published === true) {
          onSuccess(lessonContent.lessonRef.path, 'batch-success');
        }
        return resolve();
      } catch (err) {
        onError('AdminLessonContentDetailContentPage', err);
        reject();
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_content_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() =>
        lessonContentSortingValidation(brand.configuration.locales, brand.configuration.fallbackLocale)
      }
    >
      {({ dirty, errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values }) => {
        const twoAnswersOrLess = values.localizedFields.sorting.answers.length <= 2;

        const getItemStyle = (
          isDragging: boolean,
          theme: Theme,
          draggableStyle?: DraggingStyle | NotDraggingStyle
        ) => ({
          ...draggableStyle,
        });

        const handleAdd = () => {
          const copyArr = [...values.localizedFields.sorting.answers];
          const i = copyArr.length;
          copyArr.push({ id: uuid() + '-' + i, label: brand.getLocalesAsObject(''), sortIndex: i + 1 });
          setFieldValue('localizedFields.sorting.answers', copyArr);
        };

        const handleDragEnd = (result: DropResult) => {
          if (!result.destination) {
            return;
          }

          const items = reorder(
            values.localizedFields.sorting.answers,
            result.source.index,
            result.destination.index
          ).map((item: any, index: number) => {
            item.sortIndex = index + 1;
            return item;
          });

          setFieldValue('localizedFields.sorting.answers', items);
        };

        const handleDelete = (id: string) => {
          const copyArr = [...values.localizedFields.sorting.answers];
          const index = copyArr.findIndex(item => item.id === id);
          if (index !== -1) {
            copyArr.splice(index, 1);
            setFieldValue(
              'localizedFields.sorting.answers',
              copyArr.map((answer, i) => ({ ...answer, sortIndex: i + 1 }))
            );
          }
        };

        const reorder = (list: any[], startIndex: number, endIndex: number) => {
          const result = Array.from(list);
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          return result;
        };

        const renderItems = () => {
          return values.localizedFields.sorting.answers.map((answer, index) => (
            <Draggable key={answer.id} draggableId={answer.id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, theme, provided.draggableProps.style)}
                >
                  <TextField
                    disabled={isSubmitting}
                    error={answerHasError(errors, touched, activeLocale, index, 'sorting')}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <DragIndicator color='action' style={{ cursor: 'grab' }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            disabled={isSubmitting || twoAnswersOrLess}
                            edge='end'
                            onClick={() => handleDelete(values.localizedFields.sorting.answers[index].id)}
                            size='small'
                          >
                            {<Delete />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id={`localizedFields.sorting.answers[${index}].label.${activeLocale}`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={t('lesson_content_answer_label')}
                    margin='normal'
                    name={`localizedFields.sorting.answers[${index}].label.${activeLocale}`}
                    required={brand.configuration.fallbackLocale === activeLocale}
                    value={values.localizedFields.sorting.answers[index].label[activeLocale]}
                    variant='outlined'
                  />
                </div>
              )}
            </Draggable>
          ));
        };

        return (
          <Form className={globalClasses.form} noValidate>
            <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
            <Paper style={{ paddingBottom: theme.spacing(4) }}>
              <LocaleTabs onChange={(event, value) => setActiveLocale(value)} value={activeLocale}>
                {brand.configuration.locales.map(locale => (
                  <LocaleTab
                    key={locale}
                    label={
                      <div className={classes.tabContainer}>
                        <div>{t(`locale_${locale}`)}</div>
                      </div>
                    }
                    value={locale}
                  />
                ))}
              </LocaleTabs>
              <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                <Divider style={{ marginBottom: theme.spacing(2) }} />
                <Grid container direction='row' alignItems='center' spacing={4}>
                  <Grid item xs={11}>
                    <TextField
                      disabled={isSubmitting}
                      error={hasErrors(errors, touched, activeLocale, 'sorting', 'question')}
                      fullWidth
                      helperText={
                        hasErrors(errors, touched, activeLocale, 'sorting', 'question')
                          ? //@ts-ignore
                            errors.localizedFields.sorting.question[activeLocale]
                          : t('lesson_content_question_label_helper_text')
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id={`localizedFields.sorting.question.${activeLocale}`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('lesson_content_instruction_label')}
                      name={`localizedFields.sorting.question.${activeLocale}`}
                      required={brand.configuration.fallbackLocale === activeLocale}
                      value={values.localizedFields.sorting.question[activeLocale]}
                      variant='standard'
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => handleAdd()}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
                <div style={{ marginTop: theme.spacing(4), width: '100%' }}>
                  <DragDropContext onDragEnd={result => handleDragEnd(result)}>
                    <Droppable droppableId='droppable-sorting'>
                      {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {renderItems()}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </Paper>
            <div>
              <ButtonGroup
                backPath={backPath}
                createButtonText={t('create_lesson_content_button')}
                isSubmitting={isSubmitting}
                type={'update'}
                updateButtonText={t('update_lesson_content_button')}
              />
            </div>
            <ScrollToError preAction={() => setActiveLocale(brand.configuration.fallbackLocale)} />
            <LoadingBackdrop open={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
}
