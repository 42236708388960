import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    height: theme.spacing(6),
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1.5),
  },
  tabLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },

  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export { default as ContentBuilderActionBar } from './ContentBuilderActionBar';
