import { createContext } from 'react';
import { v1 } from 'uuid';

import { INotificationItem, INotificationProviderState } from 'interfaces';
import { NotificationProviderActions } from 'types';

export const initialState: INotificationProviderState = { notifications: [] };

export const NotificationContext = createContext<any>({});

export const reducer = (state: INotificationProviderState, action: NotificationProviderActions) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      const notification: INotificationItem = {
        ...action.payload,
        id: v1(),
      };
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    }
    case 'REMOVE_NOTIFICATION': {
      const copy = [...state.notifications];
      const itemIndex = copy.findIndex(item => item.id === action.payload);

      if (itemIndex !== -1) {
        copy.splice(itemIndex, 1);
      }

      return {
        ...state,
        notifications: [...copy],
      };
    }
    default:
      return state;
  }
};

export { default as NotificationProvider } from './NotificationsProvider';
