import * as yup from 'yup';
import i18n from 'configuration/i18n';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: { display: 'flex', justifyContent: 'center' },
}));

export const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .required(i18n.t('form_value_required'))
    .max(256, i18n.t('form_value_max', { number: 256 })),
  lastname: yup
    .string()
    .required(i18n.t('form_value_required'))
    .max(256, i18n.t('form_value_max', { number: 256 })),
});

export { default as RegistrationView } from './RegistrationView';
