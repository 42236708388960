import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const groupValidation = () =>
  yup.object().shape({
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    rInviteUnitSelectable: yup.boolean().required(i18n.t('form_value_required')),
  });

export { default as GroupFields } from './GroupFields';
