import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { NotAllowedView } from 'views/NotAllowed';
import { UserContext } from 'providers/User';

const ResourceRoute = ({
  component: Component,
  resource,
  withoutLayoutInFallbackView,
  ...props
}: { resource: string; withoutLayoutInFallbackView?: boolean } & RouteProps) => {
  const { user } = useContext(UserContext);

  return !!user && user.resources.includes(resource) ? (
    <Route {...props}>{props.children}</Route>
  ) : (
    <NotAllowedView disableLayout={withoutLayoutInFallbackView} />
  );
};

export default ResourceRoute;
