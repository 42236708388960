import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CourseDetailPage } from './CourseDetail';
import { CoursesPage } from './Courses';
import { LessonDetailPage } from './LessonDetail';
import { NotFoundView } from 'views/NotFound';

export default function LearningPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/courses`} />
      </Route>
      <Route exact path={`${path}/courses`}>
        <CoursesPage />
      </Route>
      <Route exact path={`${path}/course/:courseId`}>
        <CourseDetailPage />
      </Route>
      <Route exact path={`${path}/lesson/:lessonId`}>
        <LessonDetailPage />
      </Route>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
