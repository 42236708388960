import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { NewsCard } from 'components/Cards/NewsCard';

export default function FilesPage() {
  const { brand, news } = useContext(DataContext);
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'app' }}>
      <Grid container spacing={2}>
        {news.app.map(item => (
          <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
            <NewsCard
              currentLocale={language}
              fallbackLocale={brand?.configuration.fallbackLocale as string}
              news={item}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
