import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    color: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

export { default as LoadingBackdrop } from './LoadingBackdrop';
