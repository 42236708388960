import React, { useContext } from 'react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import {
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { DataContext } from 'providers/Data';
import { IFileFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { LocalizedFieldDialog } from 'components/Dialogs/LocalizedField';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { SelectWithChipsField } from '../../../SelectWithChips';
import { fileValidation, useStyles } from '.';
import { useGlobalStyles } from 'providers/Theme';

const backPath = '/admin/files';

export default function FileFields({
  onSubmit,
  initialValues,
  type,
  uploadState,
}: {
  onSubmit: (values: IFileFormData, file?: File | null) => Promise<void>;
  initialValues: IFileFormData;
  type: 'create' | 'update';
  uploadState?: { completed: number; loading: boolean };
}) {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();

  const { groups, roles } = useContext(DataContext);
  const { t } = useTranslation();

  const [file, setFile] = React.useState<File | null>(null);

  const availableRoles = roles.brand.filter(role => role.isSelectableInRestrictions);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        if (type === 'create') {
          try {
            await onSubmit(values, file);
            resetForm();
            setSubmitting(false);
            notify(t('file_creation_success'));
            history.push(backPath);
          } catch (err) {
            notify(t(`${err.message || err}`), 'error');
            setSubmitting(false);
          }
        }
        if (type === 'update') {
          try {
            await onSubmit(values);
            resetForm();
            setSubmitting(false);
            notify(t('file_update_success'));
            history.push(backPath);
          } catch (err) {
            notify(t(`${err.message || err}`), 'error');
            setSubmitting(false);
          }
        }
      }}
      validateOnBlur
      validationSchema={() => fileValidation(brand.configuration.locales, brand.configuration.fallbackLocale)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
        setFieldError,
        setFieldValue,
        validateField,
      }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <div className={globalClasses.marginBottom}>
                <Paper>
                  <div id='hasFile' />
                  <div id='metaData' />
                  <div id='name' />
                  <div className={clsx([classes.top])}>
                    {values.hasFile ? (
                      <TableContainer>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align='right'>{t('file_size')}</TableCell>
                              <TableCell align='right'>{t('file_content_type')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component='th' scope='row' classes={{ root: classes.cell }}>
                                <Typography className={classes.cell} noWrap>
                                  {values.name}
                                </Typography>
                              </TableCell>
                              <TableCell align='right' classes={{ root: classes.cell }}>
                                {Math.floor((values.metaData.size / 1000 / 1000) * 100) / 100 === 0
                                  ? '0,01 MB'
                                  : Math.floor((values.metaData.size / 1000 / 1000) * 100) / 100 + ' MB'}
                              </TableCell>
                              <TableCell align='right' classes={{ root: classes.cell }}>
                                {values.metaData.contentType}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography
                        color={
                          (errors.hasFile && touched.hasFile) || (errors.name && touched.name)
                            ? 'error'
                            : 'textSecondary'
                        }
                        variant='caption'
                      >
                        {t('file_upload_helper_text')}
                      </Typography>
                    )}
                  </div>
                  <Divider />
                  <div className={classes.bottom}>
                    <Grid container>
                      <Grid item xs={6}>
                        <input
                          className={classes.input}
                          disabled={isSubmitting || type === 'update'}
                          id='upload-file-button'
                          onChange={async event => {
                            const files = event.target.files;

                            if (!files || !files[0] || !files[0].name || !files[0].size) {
                              notify(t('alert_file_upload_error'), 'error');
                              event.target.value = '';
                              return;
                            }

                            if (!files[0].type) {
                              console.info('File type is unknown...');
                            }

                            if (files[0].size > 25 * 1000 * 1000) {
                              notify(t('alert_file_upload_error_file_size'), 'error');
                              event.target.value = '';
                              return;
                            }

                            await setFieldValue('hasFile', true);
                            await setFieldValue('name', files[0].name);
                            await setFieldValue('metaData', { contentType: files[0].type || '/', size: files[0].size });
                            setFile(files[0]);

                            validateField('hasFile');
                            validateField('name');
                            validateField('metaData');

                            event.target.value = '';
                          }}
                          type='file'
                        />
                        <label htmlFor='upload-file-button'>
                          <Button
                            component='span'
                            disabled={isSubmitting || values.hasFile || type === 'update'}
                            size='small'
                            style={{ textTransform: 'none' }}
                            variant={'text'}
                          >
                            {t('select_file_button')}
                          </Button>
                        </label>
                      </Grid>
                      {values.hasFile && (
                        <Grid item xs={6}>
                          <Grid
                            container
                            direction='row'
                            alignContent='center'
                            alignItems='flex-end'
                            justify='flex-end'
                          >
                            <Grid item>
                              <Button
                                disabled={isSubmitting || type === 'update'}
                                onClick={async () => {
                                  if (type !== 'update') {
                                    await setFieldValue('downloadUrl', initialValues.downloadUrl, false);
                                    await setFieldValue('hasFile', initialValues.hasFile, false);
                                    await setFieldValue('metaData', { ...initialValues.metaData }, false);
                                    await setFieldValue('name', initialValues.name, false);

                                    setFile(null);

                                    await setFieldError('downloadUrl', '');
                                    await setFieldError('hasFile', '');
                                    await setFieldError('metaData', '');
                                    await setFieldError('name', '');
                                  }
                                }}
                                size='small'
                                variant='text'
                                style={{ textTransform: 'none' }}
                              >
                                {t('remove_file_button')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Paper>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            {' '}
                            <Typography variant='h6'>{t('general_fields')}</Typography>
                          </div>
                          <div>
                            {' '}
                            <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={values.published}
                                      color='primary'
                                      disabled={isSubmitting}
                                      onChange={handleChange}
                                      name='published'
                                    />
                                  }
                                  label='Published'
                                  labelPlacement='end'
                                  style={{ marginRight: 0 }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormHelperText>{t('publish_this_file')}</FormHelperText>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <LocalizedFieldDialog
                          disabled={isSubmitting}
                          errors={errors.localizedFields?.description}
                          helperText={t('file_description_label_helper_text')}
                          fieldName='description'
                          fullWidth
                          label={t('file_description_label')}
                          name='localizedFields'
                          onBlur={handleBlur}
                          onSave={localeValues => {
                            setFieldValue(`localizedFields.description`, localeValues);
                          }}
                          touched={touched.localizedFields?.description}
                          values={values.localizedFields.description}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                    <Typography style={{ marginBottom: theme.spacing(2) }} variant='h6'>
                      {t('restrictions')}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SelectWithChipsField
                          caption={availableRoles.length === 0 ? undefined : t('file_role_restriction_caption')}
                          disabled={availableRoles.length === 0 || isSubmitting}
                          handleChange={handleChange}
                          label={t('file_role_restriction_label')}
                          name='roleRestrictions'
                          selectedValues={values.roleRestrictions}
                          values={availableRoles.map(role => ({ label: role.key || role.id, value: role.id }))}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWithChipsField
                          caption={groups.length === 0 ? undefined : t('file_group_restriction_caption')}
                          disabled={groups.length === 0 || isSubmitting}
                          handleChange={handleChange}
                          label={t('file_group_restriction_label')}
                          name='groupRestrictions'
                          selectedValues={values.groupRestrictions}
                          values={groups.map(group => ({ label: group.key || group.id, value: group.id }))}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <ButtonGroup
                backPath='/admin/files'
                createButtonText={t('create_file_button')}
                isSubmitting={isSubmitting}
                type={type}
                updateButtonText={t('update_file_button')}
              />
              <div className={globalClasses.marginBottom} />
              <ScrollToError />
              <LoadingBackdrop
                text={
                  uploadState && uploadState.loading
                    ? t('uploading_file_completed', { completed: uploadState.completed.toFixed(2) })
                    : ''
                }
                showText={uploadState?.loading}
                open={isSubmitting}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
