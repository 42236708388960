import i18n from 'configuration/i18n';
import { makeStyles } from '@material-ui/core';
import * as yup from 'yup';

export const roleValidation = () =>
  yup.object().shape({
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    isSelectableInRestrictions: yup.boolean().required(i18n.t('form_value_required')),
    resources: yup.array().of(yup.string()),
  });

export const useStyles = makeStyles(theme => ({}));

export { default as RoleFields } from './RoleFields';
