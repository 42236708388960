import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';
import i18n from 'configuration/i18n';

export const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const validationSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], i18n.t('form_value_confirm_new_password'))
    .required(i18n.t('form_value_required')),
  currentPassword: yup
    .string()
    .required(i18n.t('form_value_required'))
    .min(6, i18n.t('form_value_min', { number: 6 })),
  password: yup
    .string()
    .required(i18n.t('form_value_required'))
    .min(6, i18n.t('form_value_min', { number: 6 }))
    .notOneOf([yup.ref('currentPassword'), undefined], i18n.t('form_value_not_of_old_password')),
});

export { default as PasswordFields } from './PasswordFields';
