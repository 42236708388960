import React, { useContext } from 'react';
import { Check } from '@material-ui/icons';
import { Column } from 'material-table';
import { Link, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { Brand, Group, User } from 'models';
import { GroupFields } from 'components/Forms/Fields/Admin/GroupFields';
import { IGroupFormData } from 'interfaces';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { firestore, functions, serverTimestamp } from 'configuration/firebase';
import { onError, onFirestore, onFunctions, onSuccess } from 'utils/logger';

export default function AdminBrandGroupPage() {
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { brand, groups } = useContext(DataContext);
  const { t } = useTranslation();

  const [createState, setCreateState] = React.useState<{
    dialogOpen: boolean;
  }>({
    dialogOpen: false,
  });

  const [deleteState, setDeleteState] = React.useState<{
    dialogOpen: boolean;
    group?: Group;
    loading: boolean;
    name: string | null;
  }>({
    dialogOpen: false,
    group: undefined,
    loading: false,
    name: null,
  });

  const [updateState, setUpdateState] = React.useState<{
    dialogOpen: boolean;
    group?: Group;
  }>({
    dialogOpen: false,
    group: undefined,
  });

  const handleCreate = async ({ key, rInviteUnitSelectable }: IGroupFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', brandRef.collection(Group.collection).path);

    return new Promise<void>(async (resolve, reject) => {
      try {
        await brandRef.collection(Group.collection).doc().set({
          createdAt: serverTimestamp,
          createdFrom: userRef.id,
          createdFromRef: userRef,
          key,
          rInviteUnitSelectable,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        });
        onSuccess(brandRef.collection(Group.collection).doc().path, 'set-success');
        setCreateState({ dialogOpen: false });
        return resolve();
      } catch (err) {
        onError('AdminBrandGroupPage', err);
        setCreateState({ dialogOpen: false });
        return reject(err);
      }
    });
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const { group } = { ...deleteState };

    if (group) {
      try {
        onFunctions('deleteGroup');
        const deleteGroup = functions.httpsCallable('deleteGroup');
        await deleteGroup({ groupId: group.id });
        notify(t('alert_group_deletion_success'));
        onSuccess('deleteGroup', 'callable-function-success');
      } catch (err) {
        onError('AdminBrandGroupPage', err);
        notify(t('alert_group_deletion_error'), 'error');
      }
    } else {
      console.error('Group not found');
      notify(t('alert_group_deletion_error'), 'error');
    }

    setDeleteState({ group: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteDialog = (id: string) => {
    const group = groups.find(group => group.id === id);

    if (!group) {
      notify(t('alert_group_deletion_error'), 'error');
    } else {
      setDeleteState({ group, dialogOpen: true, loading: false, name: group.key });
    }
  };

  const handleUpdate = async ({ key, rInviteUnitSelectable }: IGroupFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    const { group } = { ...updateState };

    return new Promise<void>(async (resolve, reject) => {
      if (group === undefined) {
        return reject('Group not found');
      }

      onFirestore('set', brandRef.collection(Group.collection).doc(group?.id).path);

      try {
        await brandRef.collection(Group.collection).doc(group?.id).set({
          createdAt: serverTimestamp,
          createdFrom: userRef.id,
          createdFromRef: userRef,
          key,
          rInviteUnitSelectable,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
        });
        onSuccess(brandRef.collection(Group.collection).doc(group.id).path, 'set-success');
        setUpdateState({ dialogOpen: false, group: undefined });
        return resolve();
      } catch (err) {
        onError('AdminBrandGroupPage', err);
        setUpdateState({ dialogOpen: false, group: undefined });
        return reject(err);
      }
    });
  };

  const columns: Column<object>[] = [
    { defaultSort: 'asc', field: 'key', title: 'Name' },
    {
      field: 'rInviteUnitSelectable',
      render: (rowData: any) =>
        rowData.rInviteUnitSelectable ? (
          <div style={{ fontSize: theme.typography.fontSize - 1 }}>
            <Check fontSize='inherit' />
          </div>
        ) : null,
      title: t('selectable_in_unit_invitation'),
      type: 'boolean',
    },
    {
      editable: 'never',
      field: 'lastUpdateAt',
      title: t('last_modified'),
      type: 'date',
    },
  ];

  if (user?.isFileAdmin() && user?.isLearningContentAdmin() && user?.isUserAdmin()) {
    columns.push({
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ id }: any) => (
        <Link
          color='error'
          component='button'
          onClick={() => {
            handleOpenDeleteDialog(id);
          }}
          underline='none'
        >
          {t('delete_group_button')}
        </Link>
      ),
    });
  }

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_group_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ group: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_group_confirmation_dialog_title')}
        type='delete'
      />
      {createState.dialogOpen && (
        <GroupFields
          handleClick={values => handleCreate(values)}
          handleClose={() => {
            setCreateState({ dialogOpen: false });
          }}
          initialValues={{ key: '', rInviteUnitSelectable: false }}
          open={createState.dialogOpen}
          title={t('create_group_dialog_title')}
          type='create'
        />
      )}
      {updateState.dialogOpen && (
        <GroupFields
          handleClick={values => handleUpdate(values)}
          handleClose={() => {
            setUpdateState(prevState => ({ ...prevState, dialogOpen: false, group: undefined }));
          }}
          initialValues={{
            key: updateState.group?.key || '',
            rInviteUnitSelectable: updateState.group?.rInviteUnitSelectable || false,
          }}
          open={updateState.dialogOpen}
          title={t('update_group_dialog_title')}
          type='update'
        />
      )}
      <Table
        columns={[...columns]}
        data={groups.map(group => ({
          id: group.id,
          key: group.key,
          lastUpdateAt: group.lastUpdateAt ? group.lastUpdateAt.toDate() : t('loading_progress'),
          rInviteUnitSelectable: group.rInviteUnitSelectable,
        }))}
        title={t('/admin/brand/groups')}
        onAddClick={() => {
          setCreateState({ dialogOpen: true });
        }}
        onRowClick={(event, { id }: any) => {
          setUpdateState(prevState => ({
            ...prevState,
            dialogOpen: true,
            group: groups.find(group => group.id === id),
          }));
        }}
      />
    </>
  );
}
