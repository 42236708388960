import React, { useContext } from 'react';
import { Brand, News, User } from 'models';
import { DataContext } from 'providers/Data';
import { INewsFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { NewsFields } from 'components/Forms/Fields/Admin/NewsFields';
import { UserContext } from 'providers/User';
import { convertEditorItemToHtml, filterEmptyLocaleFields } from 'utils/data';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onError } from 'utils/logger';

export default function AdminNewNewsPage() {
  const brand = useContext(DataContext).brand as Brand;
  const user = useContext(UserContext).user as User;

  const initialValues: INewsFormData = {
    locales: [...brand.configuration.locales],
    key: '',
    localizedFields: {
      content: { ...brand.getLocalesAsObject([]) },
      caption: { ...brand.getLocalesAsObject() },
      title: { ...brand.getLocalesAsObject() },
    },
    photoUrl: '',
    published: false,
  };

  const handleSubmit = async (values: INewsFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    const { key, localizedFields, photoUrl, published } = values;

    /* Filtes out all locales which does not have any content */
    const filteredLocales = values.locales.filter(locale => {
      const content = localizedFields.content[locale];
      return content && content.length > 0;
    });

    const localizedFieldsCopy = {
      content: {},
      // set delete flag for unused fields is not necessary here because document is created here
      ...filterEmptyLocaleFields({ caption: { ...localizedFields.caption }, title: { ...localizedFields.title } }),
    };

    filteredLocales.forEach(locale => {
      localizedFieldsCopy['content'][locale] = localizedFields.content[locale]
        .map(item => convertEditorItemToHtml(item))
        .filter(item => item !== null);
    });

    return brandRef
      .collection(News.collection)
      .add({
        createdAt: serverTimestamp,
        createdFrom: user.id,
        createdFromRef: userRef,
        key,
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: user.id,
        lastUpdateFromRef: userRef,
        locales: filteredLocales, // array for localized content editor when content builder for a locale is not empty locale is stored in locales array
        localizedFields: { ...localizedFieldsCopy },
        photoUrl,
        published,
      })
      .catch(err => {
        onError('AdminNewNewsPage', err);
      });
  };

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
      <NewsFields initialValues={initialValues} onSubmit={handleSubmit} type='create' />
    </Layout>
  );
}
