import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { AdminCourseDetailPage } from './CourseDetail';
import { AdminCoursesPage } from './Courses';
import { AdminLessonContentDetailPage } from './LessonContentDetail';
import { AdminLessonDetailPage } from './LessonDetail';
import { NotFoundView } from 'views/NotFound';

export default function AdminLearningPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/courses`} />
      </Route>
      <Route exact path={`${path}/courses`}>
        <AdminCoursesPage />
      </Route>
      <Route path={`${path}/course/:courseId`}>
        <AdminCourseDetailPage path={path} />
      </Route>
      <Route path={`${path}/lesson/:lessonId`}>
        <AdminLessonDetailPage path={path} />
      </Route>
      <Route path={`${path}/lesson-content/:lessonContentId`}>
        <AdminLessonContentDetailPage path={path} />
      </Route>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
