import React, { useContext, useReducer } from 'react';
import clsx from 'clsx';
import { AppBar, CircularProgress, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close, Brightness2, Flare } from '@material-ui/icons';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

import useLessonContents from 'hooks/data/useLesonContents';
import { Brand, User } from 'models';
import { DataContext } from 'providers/Data';
import { ErrorView } from 'views/Error';
import { Layout } from 'components/Layout';
import { LessonFinishedView } from 'views/Learning/LessonFinished';
import { NotFoundView } from 'views/NotFound';
import { Slide } from 'components/Slide';
import { Stepper } from 'components/Common/Stepper';
import { ThemeContext, useGlobalStyles } from 'providers/Theme';
import { UserContext } from 'providers/User';
import { firestore } from 'configuration/firebase';
import { initialState, LessonDetailContext, lessonDetailPageReducer, useStyles } from '.';
import { onError, onSuccess } from 'utils/logger';

export default function LessonDetailPage() {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const height = use100vh();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const { courses, lessons, userProgress } = useContext(DataContext);
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { lessonId }: { lessonId: string } = useParams();
  const { mode, toggleMode } = useContext(ThemeContext);

  const brandPath = firestore.collection(Brand.collection).doc(user.brand).path;
  const passedLessons = useContext(DataContext).userProgress.map(progress => progress.lesson);
  const lesson = lessons.app.find(lesson => lesson.id === lessonId);
  const course = courses.app.find(course => lesson && course.id === lesson.course);

  const { lessonContentData, lessonContentError, lessonContentLoading } = useLessonContents(
    brandPath,
    user.isLearningContentAdmin(),
    lesson
  );

  const [addingState, setAddingState] = React.useState({ error: false, loading: false });
  const [state, dispatch] = useReducer(lessonDetailPageReducer, {
    ...initialState,
  });

  const steps = lessonContentData.length;
  const lessonIsFinished = state.activeStep === steps;
  const questionLength = lessonContentData.filter(content => content.type === 'choice' || content.type === 'sorting')
    .length;

  const lessonIsFinishedSuccessful = lessonIsFinished
    ? questionLength <= 0
      ? true
      : questionLength === state.correctAnswers
    : false;

  const isFirstStep = state.activeStep === 0;
  const lessonHasNoSteps = steps <= 0;

  React.useEffect(() => {
    if (lessonIsFinishedSuccessful && lessonHasNoSteps === false) {
      setAddingState({ error: false, loading: true });
      user
        .addLearningProgress(userProgress, course, lesson)
        .then(() => {
          onSuccess('addLearningProgress', 'batch-success');
          setAddingState({ error: false, loading: false });
        })
        .catch(error => {
          const message = 'User progress could not be added.';
          console.error('Error: ', message);
          setAddingState({ error: true, loading: false });
          onError('LessonDetailPage', error);
        });
    }
    // eslint-disable-next-line
  }, [lessonHasNoSteps, lessonIsFinishedSuccessful]);

  if (lessonContentError.status) {
    return (
      <Layout defaultHorizontalMainPadding>
        <ErrorView msg={lessonContentError.message} />
      </Layout>
    );
  }

  if (
    course === undefined ||
    lesson === undefined ||
    course.isLocked(courses.app, lessons.app, passedLessons) ||
    lesson.isLocked(lessons.app, passedLessons)
  ) {
    return (
      <Layout defaultHorizontalMainPadding>
        <NotFoundView />
      </Layout>
    );
  }

  const isQuestionStep =
    lessonContentData[state.activeStep] &&
    (lessonContentData[state.activeStep].type === 'choice' || lessonContentData[state.activeStep].type === 'sorting');
  const isNotAnswered =
    lessonContentData[state.activeStep] &&
    !state.questionResults.map(result => result.lessonContentId).includes(lessonContentData[state.activeStep].id);

  const renderPaperContent = () => {
    if (lessonContentLoading)
      return (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      );

    if (lessonHasNoSteps) return <div className={classes.center}>{t('no_steps_available')}</div>;

    if (lessonIsFinished)
      return (
        <div className={classes.center}>
          <LessonFinishedView
            addingState={addingState}
            course={course}
            lesson={lesson}
            finishedSuccessful={lessonIsFinishedSuccessful}
          />
        </div>
      );

    if (lessonContentData[state.activeStep]) {
      return <Slide lessonContent={lessonContentData[state.activeStep]} />;
    }

    return (
      <div className={classes.center}>
        <ErrorView disableLayout msg='No lesson content data' />
      </div>
    );
  };

  return (
    <div className={classes.root} style={{ height: height || '100vh' }}>
      <Prompt
        message={t('alert_leave_lesson_page').toString()}
        when={state.lessonStarted && lessonIsFinished === false}
      />
      <AppBar elevation={1} position='fixed'>
        <Toolbar className={clsx([globalClasses.layout, classes.toolbar])}>
          <IconButton
            color='inherit'
            disabled={lessonContentLoading || addingState.loading}
            onClick={() => history.push(`/learning/course/${lesson.course}`)}
            style={{ padding: 0 }}
          >
            <Close color='action' />
          </IconButton>
          <Typography color='textPrimary' variant='h6' className={classes.title}>
            {lesson?.getTitle(language, brand.configuration.fallbackLocale)}
          </Typography>
          <IconButton
            color='inherit'
            disabled={lessonContentLoading}
            onClick={() => toggleMode()}
            size='small'
            style={{ padding: 0 }}
          >
            {mode === 'dark' ? <Flare color='action' /> : <Brightness2 color='action' />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
      <main className={clsx([globalClasses.layout, classes.main])} style={{marginBottom: 0}}>
        <LessonDetailContext.Provider value={{ dispatch, state }}>{renderPaperContent()}</LessonDetailContext.Provider>
        <div>
          <Stepper
            activeStep={state.activeStep}
            backDisabled={
              isFirstStep ||
              lessonHasNoSteps ||
              lessonIsFinished ||
              lessonContentLoading ||
              (isQuestionStep && isNotAnswered)
            }
            nextDisabled={
              lessonIsFinished || lessonHasNoSteps || lessonContentLoading || (isQuestionStep && isNotAnswered)
            }
            handleBack={() => dispatch({ type: 'handleBack' })}
            handleNext={() => dispatch({ type: 'handleNext' })}
            steps={lessonContentLoading ? 0 /* shows animation on start */ : steps}
          />
        </div>
      </main>
    </div>
  );
}
