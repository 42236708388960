import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    width: '100%',
  },
  duration: {
    alignItems: 'flex-end',
    alignSelf: 'center',
  },
  status: {
    alignSelf: 'center',
    flexGrow: 1,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(11),
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(0),
    padding: theme.spacing(2),
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#000',
    color: '#FFF',
    borderRadius: theme.shape.borderRadius,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    opacity: theme.palette.type === 'dark' ? 0.75 : 0.5,
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export { default as CourseCard } from './CourseCard';
