import Brand from 'models/Brand';
import Model from 'models/Model';
import { arrayRemove, firestore } from 'configuration/firebase';

class LessonContentBase extends Model {
  private static _collection: string = 'lessoncontents';
  private _course!: string;
  private _courseRef!: firebase.default.firestore.DocumentReference;
  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom!: string;
  private _createdFromRef!: firebase.default.firestore.DocumentReference;
  private _key!: string;
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;
  private _lesson!: string;
  private _lessonRef!: firebase.default.firestore.DocumentReference;
  private _locales!: string[];
  private _published!: boolean;
  private _sortIndex!: number;

  static get collection(): string {
    return this._collection;
  }

  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  get course(): string {
    return this._course;
  }

  get courseRef(): firebase.default.firestore.DocumentReference {
    return this._courseRef;
  }

  get lesson(): string {
    return this._lesson;
  }

  get lessonRef(): firebase.default.firestore.DocumentReference {
    return this._lessonRef;
  }

  get locales(): string[] {
    return this._locales;
  }

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference {
    return this._createdFromRef;
  }

  get key(): string {
    return this._key;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }

  get published(): boolean {
    return this._published;
  }

  get sortIndex(): number {
    return this._sortIndex;
  }

  public async delete(brandId: string) {
    const brandRef = firestore.collection(Brand.collection).doc(brandId);
    const lessonContentRef = brandRef.collection(LessonContentBase.collection).doc(this.id);

    const batch = firestore.batch();
    batch.delete(lessonContentRef);

    batch.update(this.lessonRef, {
      lessonContents: arrayRemove(this.id),
      lessonContentRefs: arrayRemove(lessonContentRef),
    });

    batch.update(this.courseRef, {
      lessonContents: arrayRemove(this.id),
      lessonContentRefs: arrayRemove(lessonContentRef),
    });

    return batch.commit();
  }
}

export default LessonContentBase;
