import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  bottom: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(0),
    padding: theme.spacing(2),
  },
  date: {
    alignSelf: 'center',
    flexGrow: 1,
  },
  root: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    width: '100%',
  },
  status: {
    alignSelf: 'center',
    flexGrow: 1,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(11) - 1, // -1 because of divider height which is used between top and bottom
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  unread: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
}));

export { default as NewsCard } from './NewsCard';
