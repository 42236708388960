import React from 'react';
import { Brand, Unit } from 'models';
import { IUnitDataState } from 'interfaces';
import { firestore } from 'configuration/firebase';
import { onError, onFirestore, onSuccess } from 'utils/logger';

export default function useUnits(brandId: string, isUnitAdmin: boolean, isUserAdmin: boolean, unitsArr: string[]) {
  const unitsPath = firestore.collection(Brand.collection).doc(brandId).collection(Unit.collection).path;

  const [state, setState] = React.useState<IUnitDataState>({
    unitsData: [],
    unitsError: { message: '', status: false },
    unitsLoading: true,
  });

  React.useEffect(() => {
    if (isUnitAdmin && !isUserAdmin) {
      onFirestore('get', unitsPath);

      const promises: Array<
        Promise<firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>>
      > = [];

      unitsArr.forEach(unitId => {
        const unitRef = firestore.collection(unitsPath).doc(unitId);
        promises.push(unitRef.get());
      });

      Promise.all(promises)
        .then(snapshots => {
          onSuccess(unitsPath, 'get-success', promises.length + ' units');
          const units = snapshots.map(snapshot => new Unit({ ...snapshot.data(), id: snapshot.id }));
          setState(prevState => ({ ...prevState, unitsData: units, unitsLoading: false }));
        })
        .catch(err => {
          const message = 'Units data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({
            ...prevState,
            unitsError: { status: true, message },
            unitsLoading: false,
          }));
          onError('Units', err);
        });
    } else {
      setState(prevState => ({ ...prevState, unitsLoading: false }));
    }
  }, [isUnitAdmin, isUserAdmin, unitsPath, unitsArr]);

  return { ...state, unitsData: state.unitsData.sort((a, b) => a.key.localeCompare(b.key)) };
}
