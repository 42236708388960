import Model from 'models/Model';
import { Map } from 'types';

class LearningItem extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues({ ...data, locked: false });
  }

  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom!: string;
  private _createdFromRef!: firebase.default.firestore.DocumentReference;
  private _groupRestrictionRefs!: firebase.default.firestore.DocumentReference[];
  private _groupRestrictions!: string[];
  private _hasGroupRestrictions!: boolean;
  private _hasRoleRestrictions!: boolean;
  private _hasPreConditions!: boolean;
  private _key!: string;
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;
  private _lessonContents!: string[];
  private _lessonContentRefs!: firebase.default.firestore.DocumentReference[];
  private _localizedFields!: {
    description?: Map<string>;
    title: Map<string>;
  };
  private _preConditionRefs!: firebase.default.firestore.DocumentReference[];
  private _preConditions!: string[];
  private _published!: boolean;
  private _roleRestrictionRefs!: firebase.default.firestore.DocumentReference[];
  private _roleRestrictions!: string[];
  private _sortIndex!: number;

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference {
    return this._createdFromRef;
  }

  get groupRestrictionRefs(): firebase.default.firestore.DocumentReference[] {
    return this._groupRestrictionRefs;
  }

  get groupRestrictions(): string[] {
    return this._groupRestrictions;
  }

  get hasGroupRestrictions(): boolean {
    return this._hasGroupRestrictions;
  }

  get hasRoleRestrictions(): boolean {
    return this._hasRoleRestrictions;
  }

  get hasPreConditions(): boolean {
    return this._hasPreConditions;
  }

  get key(): string {
    return this._key;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }

  get lessonContents(): string[] {
    return this._lessonContents;
  }

  get lessonContentRefs(): firebase.default.firestore.DocumentReference[] {
    return this._lessonContentRefs;
  }

  get localizedFields(): { description?: Map<string>; title: Map<string> } {
    return this._localizedFields;
  }

  get preConditionRefs(): firebase.default.firestore.DocumentReference[] {
    return this._preConditionRefs;
  }

  get preConditions(): string[] {
    return this._preConditions;
  }

  get published(): boolean {
    return this._published;
  }

  get roleRestrictionRefs(): firebase.default.firestore.DocumentReference[] {
    return this._roleRestrictionRefs;
  }

  get roleRestrictions(): string[] {
    return this._roleRestrictions;
  }

  get sortIndex(): number {
    return this._sortIndex;
  }

  public getTitle(currentLocale: string, fallbackLocale: string) {
    const title = this.localizedFields.title[currentLocale] || undefined;
    return title ? title : this.localizedFields.title[fallbackLocale];
  }

  public isNotRestricted(userGroups: string[], userRole: string): boolean {
    if (this.hasGroupRestrictions === false && this.hasRoleRestrictions === false) return true;

    if (this.hasRoleRestrictions) {
      const result = this.roleRestrictions.some(roleId => userRole === roleId);
      if (result === false) return false;
    }

    if (this.hasGroupRestrictions) {
      const result = this.groupRestrictions.some(groupId => userGroups.includes(groupId));
      if (result === false) return false;
    }

    return true;
  }
}

export default LearningItem;
