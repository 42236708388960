import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout';
import { useStyles } from '.';

export default function NotFoundView({
  disableHeader,
  disableLayout,
}: {
  disableHeader?: boolean;
  disableLayout?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const pathname = useLocation().pathname;
  const isAdminArea = pathname.startsWith('/admin');

  if (disableLayout) {
    return (
      <div className={classes.center}>
        <div>
          <Typography color='textPrimary' variant='body2'>
            404 | {t('page_not_found', { pathname: pathname + ' ' })}
          </Typography>
        </div>
        <div>
          <Button
            className={classes.button}
            onClick={() => {
              isAdminArea ? history.push('/admin') : history.push('/');
            }}
          >
            {t('back_to_home')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Layout disableHeader={disableHeader} verticalCentered>
      <div className={classes.center}>
        <div>
          <Typography color='textPrimary' variant='body2'>
            404 | {t('page_not_found', { pathname: pathname + ' ' })}
          </Typography>
        </div>
        <div>
          <Button
            className={classes.button}
            onClick={() => {
              isAdminArea ? history.push('/admin') : history.push('/');
            }}
          >
            {t('back_to_home')}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
