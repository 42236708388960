import { Tab as MuiTab, withStyles, Theme, createStyles } from '@material-ui/core';

export const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      padding: 0,
      // marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(5),
      minWidth: 0,
      textTransform: 'none',

      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.light,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
    selected: {},
  })
)(MuiTab);

export { default as NavigationTab } from './NavigationTab';
