import 'firebase/app';
import Model from 'models/Model';
import { Map } from 'types';

class Brand extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'brands';
  private _configuration!: { fallbackLocale: string; locales: string[] };
  private _name!: string;

  static get collection(): string {
    return this._collection;
  }

  get configuration(): { fallbackLocale: string; locales: string[] } {
    return this._configuration;
  }

  get name(): string | undefined {
    return this._name;
  }

  public getLocalesAsObjectFromField(fieldValues: Map<string>) {
    const object: any = {};
    this.configuration.locales.forEach(key => {
      object[key] = fieldValues[key] || '';
    });
    return object;
  }

  public getLocalesAsObject(initialValue: any = '') {
    const object: any = {};
    this.configuration.locales.forEach(key => {
      object[key] = initialValue;
    });
    return object;
  }
}

export default Brand;
