import React, { useContext } from 'react';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';
import { Checkbox, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, Group, Lesson, Role, User } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { DataContext } from 'providers/Data';
import { ILessonRestrictionsFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { SelectWithChipsField } from 'components/Forms/SelectWithChips';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { lessonValidationRestrictions } from '.';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

export default function AdminLessonDetailRestrictionsPage({ lesson }: { lesson: Lesson }) {
  const backPath = `/admin/learning-content/course/${lesson.course}/lessons`;
  const brand = useContext(DataContext).brand as Brand;
  const history = useHistory();
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { groups, lessons, roles } = useContext(DataContext);
  const { t } = useTranslation();
  const { groupRestrictions, preConditions, roleRestrictions } = lesson;

  const initialValuesRestrictions: ILessonRestrictionsFormData = {
    groupRestrictions,
    preConditions,
    roleRestrictions,
  };

  const availableRoles = roles.brand.filter(role => role.isSelectableInRestrictions);

  const handleSubmit = async (values: ILessonRestrictionsFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', 'lesson');

    return brandRef
      .collection(Lesson.collection)
      .doc(lesson.id)
      .set(
        {
          ...values,
          groupRestrictionRefs: values.groupRestrictions.map(groupId =>
            brandRef.collection(Group.collection).doc(groupId)
          ),
          hasGroupRestrictions: values.groupRestrictions.length > 0,
          hasPreConditions: values.preConditions.length > 0,
          hasRoleRestrictions: values.roleRestrictions.length > 0,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
          preConditionRefs: values.preConditions.map(preConditionId =>
            brandRef.collection(Lesson.collection).doc(preConditionId)
          ),
          roleRestrictionRefs: values.roleRestrictions.map(roleId => brandRef.collection(Role.collection).doc(roleId)),
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(brandRef.collection(Lesson.collection).path, 'set-success');
      });
  };

  return (
    <Formik
      initialValues={initialValuesRestrictions}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() => lessonValidationRestrictions()}
    >
      {({ values, handleChange, isSubmitting, dirty }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table
                    caption={t('lesson_pre_condition_caption')}
                    columns={[
                      {
                        searchable: false,
                        sorting: false,
                        resizable: false,
                        render: ({ id }: any) => (
                          <Field
                            as={Checkbox}
                            disabled={isSubmitting}
                            name='preConditions'
                            size='small'
                            style={{ padding: theme.spacing(0) }}
                            type='checkbox'
                            value={id}
                          />
                        ),
                      },
                      { field: 'key', title: t('lesson_key'), resizable: false },
                      {
                        defaultSort: 'asc',
                        field: 'sortIndex',
                        title: t('lesson_sortIndex'),
                        resizable: false,
                      },
                      {
                        field: 'hasRestrictions',
                        title: t('restrictions'),
                        render: (rowData: any) =>
                          rowData.hasRestrictions ? (
                            <div style={{ fontSize: theme.typography.fontSize - 1 }}>
                              <Check fontSize='inherit' />
                            </div>
                          ) : null,
                      },
                      {
                        field: 'hasPreConditions',
                        title: t('preconditions'),
                        render: (rowData: any) =>
                          rowData.hasPreConditions ? (
                            <div style={{ fontSize: theme.typography.fontSize - 1 }}>
                              <Check fontSize='inherit' />
                            </div>
                          ) : null,
                      },
                      {
                        field: 'published',
                        title: 'Status',
                        type: 'string',
                        render: ({ published }: any) =>
                          published ? (
                            'Published'
                          ) : (
                            <span style={{ color: theme.palette.error.light }}>Unpublished</span>
                          ),
                      },
                    ]}
                    data={lessons.admin
                      .filter(item => item.id !== lesson.id && item.course === lesson.course)
                      .map(
                        ({
                          hasGroupRestrictions,
                          hasPreConditions,
                          hasRoleRestrictions,
                          id,
                          key,
                          published,
                          sortIndex,
                        }) => ({
                          hasPreConditions,
                          hasRestrictions: hasGroupRestrictions || hasRoleRestrictions,
                          id,
                          key,
                          published,
                          sortIndex,
                        })
                      )}
                    title={t('preconditions').toString()}
                  />
                </Grid>{' '}
                <Grid item xs={12}>
                  <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                    <Typography style={{ marginBottom: theme.spacing(2) }} variant='h6'>
                      {t('restrictions')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SelectWithChipsField
                          caption={availableRoles.length === 0 ? undefined : t('lesson_role_restriction_caption')}
                          disabled={availableRoles.length === 0 || isSubmitting}
                          handleChange={handleChange}
                          label={t('lesson_role_restriction_label')}
                          name='roleRestrictions'
                          selectedValues={values.roleRestrictions}
                          values={availableRoles.map(role => ({
                            label: role.key || role.id,
                            value: role.id,
                          }))}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWithChipsField
                          caption={groups.length === 0 ? undefined : t('lesson_group_restriction_caption')}
                          disabled={groups.length === 0 || isSubmitting}
                          handleChange={handleChange}
                          label={t('lesson_group_restriction_label')}
                          name='groupRestrictions'
                          selectedValues={values.groupRestrictions}
                          values={groups.map(group => ({ label: group.key || group.id, value: group.id }))}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <div>
                <ButtonGroup
                  backPath={backPath}
                  createButtonText={t('create_lesson_button')}
                  isSubmitting={isSubmitting}
                  type={'update'}
                  updateButtonText={t('update_lesson_button')}
                />
              </div>
              <LoadingBackdrop open={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
