import Brand from 'models/Brand';
import LearningItem from 'models/LearningItem';
import Lesson from 'models/Lesson';
import UserProgress from 'models/UserProgress';
import { LearningStatus } from 'types';
import { firestore } from 'configuration/firebase';

class Course extends LearningItem {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'courses';
  private _courseColor!: string;
  private _courseLogoUrl!: string;
  private _lessons!: string[];
  private _lessonRefs!: firebase.default.firestore.DocumentReference[];

  static get collection(): string {
    return this._collection;
  }

  get courseColor(): string {
    return this._courseColor;
  }

  get courseLogoUrl(): string {
    return this._courseLogoUrl;
  }

  get lessons(): string[] {
    return this._lessons;
  }

  get lessonRefs(): firebase.default.firestore.DocumentReference[] {
    return this._lessonRefs;
  }

  public isLocked(availableCourses: Course[], availableLessons: Lesson[], passedLessons: string[]) {
    if (this.preConditions.length <= 0) return false;

    for (const preCondition of this.preConditions) {
      const preConditionCourse = availableCourses.find(course => course.id === preCondition);

      if (preConditionCourse === undefined) {
        /* Pre condition course is not found (unpublished, restricted for user or deleted) */
        return true;
      }

      if (preConditionCourse.isPassed(availableLessons, passedLessons) === false) {
        /* Pre condition course is found but not passed */
        return true;
      }
    }

    return false;
  }

  private isPassed(availableLessons: Lesson[], passedLessons: string[]): boolean {
    const availableCourseLessons = availableLessons.filter(lesson => lesson.course === this.id);

    if (availableCourseLessons.length === 0) return false;

    return availableCourseLessons.every(lesson => passedLessons.includes(lesson.id));
  }

  public getDescription(currentLocale: string, fallbackLocale: string): string | undefined {
    if (!!this.localizedFields.description) {
      const description = this.localizedFields.description[currentLocale] || undefined;
      return description ? description : this.localizedFields.description[fallbackLocale];
    }

    return undefined;
  }

  public getDuration(availableLessons: Lesson[]) {
    return availableLessons
      .filter(lesson => lesson.course === this.id)
      .reduce((min, lesson) => (min = min + lesson.durationInMin), 0);
  }

  public getStatus(availableLessons: Lesson[], passedLessons: string[], userProgress: UserProgress[]) {
    const availableCourseLessons = availableLessons.filter(lesson => lesson.course === this.id);

    let status: LearningStatus = this.isPassed(availableCourseLessons, passedLessons) ? 'passed' : undefined;

    if (status === 'passed') {
      const oneLessonHasAnUpdate = availableCourseLessons.some(lesson => {
        const progressItemsForThisLesson = userProgress
          .filter(progress => progress.lesson === lesson.id)
          .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());

        if (progressItemsForThisLesson && progressItemsForThisLesson.length > 0) {
          if (
            !!lesson.lastContentUpdateAt &&
            progressItemsForThisLesson[0].timestamp.toMillis() < lesson.lastContentUpdateAt.toMillis()
          ) {
            return true;
          }
        }
        return false;
      });
      if (oneLessonHasAnUpdate) {
        status = 'update';
      }
    }

    // if (status === undefined) {
    //   try {
    //     const result = availableCourseLessons.some(lesson => {
    //       const progressItem = userProgress
    //         .filter(progress => progress.lesson === lesson.id)
    //         .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());
    //       if (progressItem) {
    //         status = progressItem[0].timestamp.toMillis() < lesson.lastUpdateAt.toMillis() ? 'update' : 'passed';
    //         if (status === 'update') {
    //           return true;
    //         }
    //       }
    //       return false;
    //     });

    //     if (result) {
    //       status = 'update';
    //     }
    //   } catch (err) {
    //     status = undefined;
    //   }
    // }

    if (status === undefined) {
      /** If status is not passed and not updated it shows how many lessons are available and how many of the available lessons are done */
      status =
        userProgress.filter(
          progress =>
            progress.course === this.id && availableCourseLessons.map(lesson => lesson.id).includes(progress.lesson)
        ).length +
        '/' +
        availableCourseLessons.filter(lesson => lesson.course === this.id).length;
    }

    return status;
  }

  public async delete(brandId: string) {
    const brandRef = firestore.collection(Brand.collection).doc(brandId);
    const courseRef = brandRef.collection(Course.collection).doc(this.id);

    const promises: any[] = [];

    this.lessonContentRefs.forEach(lessonContentRef => {
      promises.push(lessonContentRef.delete());
    });

    this.lessonRefs.forEach(lessonRef => {
      promises.push(lessonRef.delete());
    });

    promises.push(courseRef.delete());

    return Promise.all([...promises]);
  }
}

export default Course;
