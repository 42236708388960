import React from 'react';
import { FallbackImage } from 'components/Common/FallbackImage';
import { IContentImageItem } from 'interfaces';
import { useStyles } from '.';

export default function ContentImageItem({ item }: { item: IContentImageItem }) {
  const classes = useStyles();

  return (
    <FallbackImage
      alt={item.content.name}
      className={classes.image}
      src={item.content.url}
      height='auto'
      width='100%'
    />
  );
}
