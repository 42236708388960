import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles, withStyles } from '@material-ui/core';

export const Accordion = withStyles(() => ({}))(MuiAccordion);

export const AccordionSummary = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    minHeight: theme.spacing(30),
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles(theme => ({
  center: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 353,
    padding: theme.spacing(0, 1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 361,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  draggableContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '90%',
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    display: 'flex',
    padding: theme.spacing(0, 1.5),
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  paper: {
    borderRadius: 0,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    display: 'flex',
    padding: theme.spacing(0, 0.5),
  },
  rootPaper: {},
  root: {
    padding: theme.spacing(2, 0),
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 20,
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
}));

export { default as ContentBuilder } from './ContentBuilder';
