import React, { MouseEvent, useContext, useState } from 'react';
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Brightness2, ExitToApp, Flare, Settings, SyncAlt } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { AuthenticationContext } from 'providers/Authentication';
import { ThemeContext } from 'providers/Theme';
import { UserContext } from 'providers/User';
import { signOut } from '.';

export default function AvatarButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();
  const isAdminArea = useLocation().pathname.startsWith('/admin');
  const open = Boolean(anchorEl);

  const { authUser } = useContext(AuthenticationContext);
  const { mode, toggleMode } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { xs } = useBreakpoints();

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color='default' onClick={handleOpen} size='small' style={{ padding: 0 }}>
        <Avatar src={user && user.settings.photoUrl ? user.settings.photoUrl : undefined}>
          {user && user.settings.firstname && user.settings.lastname
            ? user.settings.firstname[0] + user.settings.lastname[0]
            : undefined}
        </Avatar>
      </IconButton>
      <Menu id='menu-appbar' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem disabled>
          <ListItemText primary={authUser?.email} />
        </MenuItem>
        {user && user.hasAdminAccess() && (
          <MenuItem
            onClick={() => {
              isAdminArea ? history.push('/') : history.push('/admin');
            }}
          >
            <ListItemIcon>
              <SyncAlt fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={isAdminArea ? 'App' : t('admin')} />
          </MenuItem>
        )}
        {xs ? (
          <MenuItem
            onClick={() => {
              toggleMode();
              handleClose();
            }}
          >
            <ListItemIcon>{mode === 'dark' ? <Flare /> : <Brightness2 />}</ListItemIcon>
            <ListItemText primary={mode === 'dark' ? 'Light mode' : 'Dark mode'} />
          </MenuItem>
        ) : null}
        <MenuItem onClick={() => history.push('/settings')}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          <ListItemText primary={t('settings')} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            signOut().then(() => history.push('/'));
          }}
        >
          <ListItemIcon>
            <ExitToApp fontSize='small' />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </MenuItem>
      </Menu>
    </>
  );
}
