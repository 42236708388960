import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { I18nextProvider } from 'react-i18next';

import './index.css';

import Brainee from 'Brainee';
import i18n from 'configuration/i18n';
// import reportWebVitals from 'reportWebVitals';
import { AuthenticationProvider } from 'providers/Authentication';
import { NotificationProvider } from 'providers/Notifications';
import { ScrollToTop } from 'components/Common/ScrollToTop';
import { ThemeProvider } from 'providers/Theme';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// import reportWebVitals from './reportWebVitals';
// reportWebVitals(process.env.NODE_ENV === 'development' ? console.log : undefined);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        <NotificationProvider>
          <Router>
            <AuthenticationProvider>
              <CssBaseline />
              <ScrollToTop />
              <Brainee />
            </AuthenticationProvider>
          </Router>
        </NotificationProvider>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
