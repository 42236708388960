import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DataContext } from 'providers/Data';
import { File, User } from 'models';
import { Layout } from 'components/Layout';
import { Map } from 'types';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';

export default function FilesPage() {
  const user = useContext(UserContext).user as User;
  const { brand, files } = useContext(DataContext);
  const { t } = useTranslation();

  function getDescription(description: Map<string>) {
    if (!!description[user.settings.currentLocale]) {
      return description[user.settings.currentLocale];
    }

    if (brand && description[brand.configuration.fallbackLocale]) {
      return description[brand.configuration.fallbackLocale];
    }

    return null;
  }

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'app' }}>
      <Table
        columns={[
          {
            defaultSort: 'asc',
            field: 'name',
            title: 'Name',
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 280 },
          },
          {
            field: 'size',
            title: t('file_size'),
          },
          {
            field: 'lastUpdateAt',
            title: t('last_modified'),
            type: 'date',
          },
        ]}
        data={files.app.map((file: File) => {
          const {
            localizedFields: { description },
            metaData: { size },
            downloadUrl,
            id,
            lastUpdateAt,
            name,
          } = file;

          return {
            downloadUrl,
            id,
            lastUpdateAt: lastUpdateAt ? lastUpdateAt.toDate() : t('loading_progress'),
            name: getDescription(description) || name,
            size:
              Math.floor((size / 1000 / 1000) * 100) / 100 === 0
                ? '< 0,01'
                : Math.floor((size / 1000 / 1000) * 100) / 100 + ' MB',
          };
        })}
        onRowClick={(event, rowData: any) => {
          window.open(rowData.downloadUrl, '_blank');
        }}
      />
    </Layout>
  );
}
