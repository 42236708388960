import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  left: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  right: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export { default as ButtonGroup } from './ButtonGroup';
