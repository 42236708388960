import * as yup from 'yup';

export const lessonValidationRestrictions = () =>
  yup.object().shape({
    groupRestrictions: yup.array().of(yup.string()),
    preConditions: yup.array().of(yup.string()),
    roleRestrictions: yup.array().of(yup.string()),
  });

export { default as AdminLessonDetailRestrictionsPage } from './AdminLessonDetailRestrictionsPage';
