import React from 'react';
import { Grid } from '@material-ui/core';

import { ChoiceAnswerCard } from 'components/Cards/AnswerCards/Choice';
import { LessonContentChoice } from 'models';
import { RobotoBlack } from 'components/Common/Roboto/Black';
import { useGlobalStyles } from 'providers/Theme';

export default function ChoiceSlide({
  currentLocale,
  fallbackLocale,
  lessonContent,
}: {
  currentLocale: string;
  fallbackLocale: string;
  lessonContent: LessonContentChoice;
}) {
  const globalClasses = useGlobalStyles();
  const { answers } = lessonContent.localizedFields.choice;

  return (
    <>
      <div className={globalClasses.marginBottom}>
        <RobotoBlack style={{ fontSize: 16, marginTop: 16 }}>{lessonContent.getQuestion(currentLocale, fallbackLocale)}</RobotoBlack>
      </div>
      <Grid container spacing={2}>
        {answers.map(answer => {
          return (
            <Grid key={answer.id} item xs={12}>
              <ChoiceAnswerCard answer={answer} lessonContent={lessonContent}>
                {lessonContent.getAnswer(answer.id, currentLocale, fallbackLocale)}
              </ChoiceAnswerCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
