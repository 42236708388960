export default function onFirestore(
  type: 'add' | 'batch-set' | 'onSnapshot' | 'get' | 'set' | 'delete',
  path: string,
  field?: string | string[]
) {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `${new Date().toLocaleTimeString()} - %c[${type}${
        field ? `-${field instanceof Array ? field.join(',') : field}` : ''
      }] - ${path}`,
      `color:red`
    );
    return;
  }
}
