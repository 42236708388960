import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  },
}));

export { default as Stepper } from './Stepper';
