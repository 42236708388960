import React from 'react';
import { INewsDataState } from 'interfaces';
import { News } from 'models';
import { addIdToDocument, conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useNews(brandPath: string, isNewsAdmin: boolean): INewsDataState {
  const [state, setState] = React.useState<INewsDataState>({
    newsError: { status: false, message: '' },
    newsData: { admin: [], app: [] },
    newsLoading: true,
  });

  const newsPath = firestore.doc(brandPath).collection(News.collection).path;

  React.useEffect(() => {
    onFirestore('onSnapshot', newsPath);

    const collectionRef = conditionalCollection(newsPath);
    let query = collectionRef;

    if (!isNewsAdmin) {
      query = query.where('published', '==', true);
      query = query.orderBy('createdAt', 'desc');
      // query = query.limit(15);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'News', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const news = snapshot.docs.map(doc => new News(addIdToDocument(doc)));
        setState(prevState => ({
          ...prevState,
          newsData: {
            ...prevState.newsData,
            app: news,
            admin: isNewsAdmin ? news : [],
          },
          newsLoading: false,
        }));
      },
      error => {
        const message = 'News data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({ ...prevState, newsError: { status: true, message }, newsLoading: false }));
        onError('News', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [newsPath, isNewsAdmin]);

  return {
    ...state,
    newsData: {
      ...state.newsData,
      app: state.newsData.app
        .filter(item => item.published && item.createdAt)
        .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
      // .slice(0, 15),
    },
  };
}
