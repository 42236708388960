import React, { useContext } from 'react';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Box, Paper, Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DateFNS from 'utils/date/date-fns';
import { Brand, User } from 'models';
import { ContentItemComponent } from 'components/Slide/Content';
import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { NotFoundView } from 'views/NotFound';
import { UserContext } from 'providers/User';
import { arrayUnion, firestore } from 'configuration/firebase';
import { onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles } from '.';

export default function NewsDetailPage() {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const {
    i18n: { language },
  } = useTranslation();
  const { newsId }: { newsId: string } = useParams();
  const { news } = useContext(DataContext);

  const newsProgress = user.newsProgress || [];
  const userPath = firestore.collection(User.collection).doc(user.id).path;

  useDeepCompareEffect(() => {
    const userRef = firestore.doc(userPath);

    if (!!newsId && !newsProgress.includes(newsId)) {
      onFirestore('set', userRef.path, 'newsProgress');
      userRef
        .set({ newsProgress: arrayUnion(newsId) }, { merge: true })
        .then(() => {
          onSuccess(userRef.path, 'set-success', 'newsProgress');
        })
        .catch(() => {
          console.error('News read info could not be stored.');
        });
    }
  }, [newsId, newsProgress, userPath]);

  const newsItem = news.app.find(x => x.id === newsId);

  if (newsItem === undefined) {
    return <NotFoundView />;
  }

  const caption = newsItem.getCaption(language, brand.configuration.fallbackLocale);
  const content = newsItem.getContents(language, brand.configuration.fallbackLocale);
  const title = newsItem.getTitle(language, brand.configuration.fallbackLocale);

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'app' }}>
      <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
        <div style={{ padding: theme.spacing(0, 0, 1) }}>
          {!!title && (
            <div style={{ padding: theme.spacing(0, 1, 0) }}>
              <Typography variant='h6' noWrap>
                {title}
              </Typography>
            </div>
          )}
          <Typography component='p' variant='subtitle1' style={{ padding: theme.spacing(0, 1, 0) }}>
            {format(newsItem.lastUpdateAt.toDate(), 'PPPP', { locale: DateFNS.getLocale(language) })}
          </Typography>
          {caption && (
            <div
              style={{
                padding: theme.spacing(0, 1),
              }}
            >
              <Typography color='textSecondary' style={{ lineHeight: 0.75 }} variant='caption'>
                {caption}
              </Typography>
            </div>
          )}
        </div>
        {content && (
          <div className={classes.contentContainer}>
            <Box color='textSecondary'>
              {content.map(item => (
                <ContentItemComponent key={item.id} item={item} />
              ))}
            </Box>
          </div>
        )}
      </Paper>
    </Layout>
  );
}
