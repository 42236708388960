import { de, enUS } from 'date-fns/locale';

class DateFNS {
  public static getLocale(locale: string) {
    if (locale === 'en') return enUS;
    return de;
  }
}

export default DateFNS;
