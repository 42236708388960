import i18n from 'configuration/i18n';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const validationSchema = yup.object().shape({
  email: yup.string().required(i18n.t('form_value_required')).email(i18n.t('form_value_email')),
  confirmEmail: yup
    .string()
    .required(i18n.t('form_value_required'))
    .email(i18n.t('form_value_email'))
    .oneOf([yup.ref('email')], i18n.t('form_value_confirm_new_email')),
  currentPassword: yup
    .string()
    .required(i18n.t('form_value_required'))
    .min(6, i18n.t('form_value_min_multi', { number: 6 })),
});

export { default as EmailFields } from './EmailFields';
