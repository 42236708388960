import { Theme, makeStyles } from '@material-ui/core';

export function getStyles(id: string, values: string[], theme: Theme) {
  return {
    fontWeight: values.indexOf(id) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export { default as SelectWithChipsField } from './SelectWIthChipsField';
