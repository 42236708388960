import React from 'react';
import { Lesson } from 'models';
import { ILessonsDataState } from 'interfaces';
import { addIdToDocument, conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useLessons(
  brandPath: string,
  isLearningContentAdmin: boolean,
  userGroups: string[],
  userRole: string
): ILessonsDataState {
  const [state, setState] = React.useState<ILessonsDataState>({
    lessonsError: { status: false, message: '' },
    lessonsData: { admin: [], app: [] },
    lessonsLoading: true,
  });
  const lessonsPath = firestore.doc(brandPath).collection(Lesson.collection).path;

  React.useEffect(() => {
    onFirestore('onSnapshot', lessonsPath);

    const collectionRef = conditionalCollection(lessonsPath);
    let query = collectionRef;

    if (!isLearningContentAdmin) {
      query = query.where('published', '==', true);
      // TODO: Integrate field for lesson and query for -> query = query.where('courseIsPublished', '==', true);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'Lesson', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const lessons = snapshot.docs.map(doc => new Lesson(addIdToDocument(doc)));
        setState(prevState => ({
          ...prevState,
          lessonsData: {
            ...prevState.lessonsData,
            app: lessons,
            admin: isLearningContentAdmin ? lessons : [],
          },
          lessonsLoading: false,
        }));
      },
      error => {
        const message = 'Lessons data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({ ...prevState, lessonsError: { status: true, message }, lessonsLoading: false }));
        onError('Lesson', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [isLearningContentAdmin, lessonsPath]);

  // Filtering of app lessons is done here in return statement to prevent user group array to be in use effect deps array.
  // see: https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
  // and see: https://stackoverflow.com/questions/36084515/how-does-shallow-compare-work-in-react
  return {
    ...state,
    lessonsData: {
      ...state.lessonsData,
      app: state.lessonsData.app.filter(lesson => lesson.isNotRestricted(userGroups, userRole) && lesson.published),
    },
  };
}
