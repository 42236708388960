import React, { Dispatch, useContext, useState } from 'react';
import { Button, Container, Grid, IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Logo } from 'components/Common/Logo';
import { Layout } from 'components/Layout';
import { LoadingButton } from 'components/Buttons/Loading';
import { NotificationContext } from 'providers/Notifications';
import { NotificationProviderActions } from 'types';
import { useGlobalStyles } from 'providers/Theme';
import { validationSchema, initialValues, onSubmit, useStyles } from '.';

export default function LoginPage() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const notificationsDispatch: Dispatch<NotificationProviderActions> = useContext(NotificationContext).dispatch;
  const theme = useTheme();

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Layout disableHeader verticalCentered>
      <Container maxWidth='sm'>
        <div className={globalClasses.paper}>
          <Logo size='large' />
          <Formik
            validateOnBlur
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => onSubmit(values, actions, notificationsDispatch, t('alert_login'))}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form className={globalClasses.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  autoComplete='email'
                  disabled={isSubmitting}
                  error={!!errors.email && touched.email}
                  fullWidth
                  helperText={!!errors.email && touched.email && errors.email ? errors.email : ' '}
                  FormHelperTextProps={{
                    style: {
                      minHeight: 19,
                    },
                  }}
                  id='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label='Email'
                  margin='none'
                  name='email'
                  required
                  type='email'
                  value={values.email}
                  variant='outlined'
                />
                <TextField
                  autoComplete='current-password'
                  disabled={isSubmitting}
                  color='primary'
                  error={!!errors.password && touched.password}
                  fullWidth
                  helperText={!!errors.password && touched.password && errors.password ? errors.password : ' '}
                  FormHelperTextProps={{
                    style: {
                      minHeight: 19,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          disabled={isSubmitting}
                          onClick={() => setShowPassword(prevState => !prevState)}
                          edge='end'
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label='Password'
                  margin='none'
                  name='password'
                  required
                  style={{ marginTop: theme.spacing(1) }}
                  type={!showPassword || isSubmitting ? 'password' : 'text'}
                  value={values.password}
                  variant='outlined'
                />
                <div className={classes.container} style={{ margin: theme.spacing(1, 0) }}>
                  <LoadingButton
                    color='primary'
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    {t('sign_in_button')}
                  </LoadingButton>
                </div>
                <Grid container>
                  <Grid item xs>
                    <Grid container justify='flex-end'>
                      <Button disabled={isSubmitting} onClick={() => history.push('/forgot-password')} variant='text'>
                        {t('forgot_password')}?
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
}
