import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { IGroupFormData } from 'interfaces';
import { groupValidation } from '.';
import { useGlobalStyles } from 'providers/Theme';

export default function GroupFields({
  handleClick,
  handleClose,
  open,
  initialValues,
  title,
  type,
}: {
  handleClick: (values: IGroupFormData) => Promise<void>;
  handleClose: () => void;
  open: boolean;
  initialValues: IGroupFormData;
  title: string;
  type: 'create' | 'update';
}) {
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleClick(values)
          .then(() => {
            if (type === 'create') {
              notify(t('alert_group_creation_success'));
            }
            if (type === 'update') {
              notify(t('alert_group_update_success'));
            }
          })
          .catch(() => {
            if (type === 'create') {
              notify(t('alert_group_creation_error'), 'error');
            }
            if (type === 'update') {
              notify(t('alert_group_update_error'), 'error');
            }
          });
      }}
      validateOnBlur
      validationSchema={() => groupValidation()}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
        return (
          <>
            <FormDialog
              createButtonText={t('create_button')}
              handleClick={handleSubmit}
              handleClose={handleClose}
              loading={isSubmitting}
              open={open}
              title={title}
              type={type}
              updateButtonText={t('update_button')}
            >
              <Form className={globalClasses.form} noValidate>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.key && touched.key}
                      fullWidth
                      helperText={!!errors.key && touched.key && errors.key ? errors.key : ' '}
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='key'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('group_key_label')}
                      name='key'
                      required
                      size='small'
                      value={values.key}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                      <Grid item xs={12}>
                        <FormControlLabel
                          classes={{
                            label: globalClasses.checkboxLabels,
                          }}
                          control={
                            <Checkbox
                              checked={values.rInviteUnitSelectable}
                              color='primary'
                              name='rInviteUnitSelectable'
                              onChange={handleChange}
                              size='small'
                              style={{ marginRight: 4 }}
                            />
                          }
                          label={t('invite_unit_select_switcher_description')}
                          labelPlacement='start'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </FormDialog>
          </>
        );
      }}
    </Formik>
  );
}
