import { Map } from 'types';
import Model from 'models/Model';

class UserProgress extends Model {
  constructor(data?: any) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'userprogress';

  private _brand!: string;
  private _brandRef!: firebase.default.firestore.DocumentReference;
  private _course!: string;
  private _courseRef!: firebase.default.firestore.DocumentReference;
  private _currentData!: {
    course: { key: string; localizedFields: { title: Map<string> } };
    lesson: { key: string; localizedFields: { title: Map<string> } };
  };
  private _lesson!: string;
  private _lessonRef!: firebase.default.firestore.DocumentReference;
  private _timestamp!: firebase.default.firestore.Timestamp;
  private _user!: string;
  private _userRef!: firebase.default.firestore.DocumentReference;

  static get collection(): string {
    return this._collection;
  }

  get brand(): string {
    return this._brand;
  }

  get brandRef(): firebase.default.firestore.DocumentReference {
    return this._brandRef;
  }

  get course(): string {
    return this._course;
  }

  get courseRef(): firebase.default.firestore.DocumentReference {
    return this._courseRef;
  }

  get currentData(): {
    course: { key: string; localizedFields: { title: Map<string> } };
    lesson: { key: string; localizedFields: { title: Map<string> } };
  } {
    return this._currentData;
  }

  get lesson(): string {
    return this._lesson;
  }

  get lessonRef(): firebase.default.firestore.DocumentReference {
    return this._lessonRef;
  }

  get timestamp(): firebase.default.firestore.Timestamp {
    return this._timestamp;
  }

  get user(): string {
    return this._user;
  }

  get userRef(): firebase.default.firestore.DocumentReference {
    return this._userRef;
  }
}

export default UserProgress;
