import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const lessonContentValidation = (locales: string[]) => {
  const schema = yup.object().shape({
    locales: yup.array().of(yup.string()),
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .min(1, i18n.t('form_value_min', { number: 1 }))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    published: yup.boolean().required(i18n.t('form_value_required')),
    sortIndex: yup.number().required(i18n.t('form_value_required')).min(1, i18n.t('form_value_positive')),
    type: yup.string().required(i18n.t('form_value_required')).oneOf(['content', 'choice', 'sorting']),
  });

  return schema;
};

export { default as AdminLessonDetailLessonContentsPage } from './AdminLessonDetailLessonContentsPage';
