import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(0, 0, 1.25),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0.5, 0, 1.25),
      width: '80%',
    },
  },
}));

export { default as AdminBrandPage } from './AdminBrandPage';
