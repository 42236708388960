import React from 'react';
import { Group } from 'models';
import { IGroupsDataState } from 'interfaces';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useGroups(
  brandPath: string,
  isFileAdmin: boolean,
  isLearningContentAdmin: boolean,
  isUserAdmin: boolean,
  isUnitAdmin: boolean
) {
  const [state, setState] = React.useState<IGroupsDataState>({
    groupsError: { status: false, message: '' },
    groupsData: [],
    groupsLoading: true,
  });
  const groupsPath = firestore.doc(brandPath).collection(Group.collection).path;

  React.useEffect(() => {
    if (isLearningContentAdmin || isUserAdmin || isUnitAdmin || isFileAdmin) {
      onFirestore('onSnapshot', groupsPath);
      const unsubscribe = firestore.collection(groupsPath).onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(i, snapshot.docs.length, 'Groups', change.doc.id, change.oldIndex, change.newIndex, change.type);
          });
          const groups = snapshot.docs.map(doc => new Group(addIdToDocument(doc)));
          setState(prevState => ({ ...prevState, groupsData: groups, groupsLoading: false }));
        },
        error => {
          const message = 'Groups data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({ ...prevState, groupsError: { status: true, message }, groupsLoading: false }));
          onError('Groups', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, groupsLoading: false }));
    }
  }, [groupsPath, isLearningContentAdmin, isFileAdmin, isUserAdmin, isUnitAdmin]);

  return { ...state };
}
