import React, { useContext } from 'react';
import { Card, CardActionArea, useTheme } from '@material-ui/core';

import { ILessonContentSortingAnswer } from 'interfaces';
import { LessonContentSorting } from 'models';
import { LessonDetailContext } from 'pages/App/Learning/LessonDetail';
import { useStyles } from '.';

export default function SortingAnswerCard({
  onClick,
  children,
  lessonContent,
}: {
  answer: ILessonContentSortingAnswer;
  onClick: () => void;
  children: string;
  lessonContent: LessonContentSorting;
}) {
  const {
    state: { questionResults },
  } = useContext(LessonDetailContext);

  /* If lesson content id of this lesson content (= this slide) is included in the result array, */
  /* the question has already been answered. */
  const questionResult = questionResults.find(result => result.lessonContentId === lessonContent.id);
  const hasAnswered = !!questionResult;

  const classes = useStyles();
  const theme = useTheme();

  let styles: React.CSSProperties | undefined = {};

  if (hasAnswered === true) {
    styles = { opacity: 0.75 };
  }

  if (hasAnswered && questionResult) {
    styles = questionResult.answeredCorrect
      ? { backgroundColor: theme.palette.success.light, color: theme.palette.success.dark }
      : { backgroundColor: theme.palette.error.light, color: theme.palette.error.dark };
  }

  return (
    <Card classes={{ root: classes.root }} style={{ ...styles }}>
      <CardActionArea className={classes.container} disabled={hasAnswered} onClick={onClick}>
        {children}
      </CardActionArea>
    </Card>
  );
}
