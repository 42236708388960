import React, { useContext } from 'react';

import BraineeFile from 'models/File';
import { Brand, Group, Role, User } from 'models';
import { DataContext } from 'providers/Data';
import { FileFields } from 'components/Forms/Fields/Admin/FileFields';
import { IFileFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { UserContext } from 'providers/User';
import { firestore, serverTimestamp, storage } from 'configuration/firebase';
import { filterEmptyLocaleFields } from 'utils/data';
import { onFirestore, onStorage, onSuccess } from 'utils/logger';

export default function AdminNewFilePage() {
  const brand = useContext(DataContext).brand as Brand;
  const user = useContext(UserContext).user as User;
  const { files } = useContext(DataContext);

  const [uploadState, setUploadState] = React.useState({ completed: 0, loading: false });

  const initialValues: IFileFormData = {
    downloadUrl: '',
    groupRestrictions: [],
    hasFile: false,
    localizedFields: {
      description: { ...brand.getLocalesAsObject() },
    },
    metaData: {
      contentType: '',
      size: 0,
    },
    name: '',
    published: false,
    roleRestrictions: [],
  };

  const handleSubmit = async (values: IFileFormData, file?: File | null) =>
    new Promise<void>((resolve, reject) => {
      if (file === null || file === undefined) {
        console.error('File not found...');
        reject('default_error_message');
        return;
      }

      if (files.admin.map(item => item.name).includes(file.name)) {
        console.error('File already exists...');
        reject('alert_file_already_exists');
        return;
      }

      const brandRef = firestore.collection(Brand.collection).doc(brand.id);
      const userRef = firestore.collection(User.collection).doc(user.id);
      const path = `/files/${brandRef.id}/${file.name}`;
      const storageRef = storage.ref(path);
      const documentRef = brandRef.collection(BraineeFile.collection).doc();

      const uploadTask = storageRef.put(file, {
        customMetadata: {
          brand: brandRef.id,
          docId: documentRef.id,
          docPath: documentRef.path,
          uid: user.id,
        },
      });

      onStorage('on', storageRef.fullPath);

      uploadTask.on(
        'state_changed',
        snapshot => {
          setUploadState(prevState => ({
            ...prevState,
            completed: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            loading: true,
          }));
        },
        err => {
          console.error('Upload error: ', err);
          setUploadState(prevState => ({ ...prevState, loading: false }));
          reject('default_error_message');
          return;
        },
        async () => {
          onSuccess(storageRef.fullPath, 'put-success');
          const { groupRestrictions, hasFile, localizedFields, name, published, roleRestrictions } = values;
          try {
            onStorage('getDownloadUrl', uploadTask.snapshot.ref.fullPath);
            const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
            onSuccess(uploadTask.snapshot.ref.fullPath, 'getDownloadUrl-success');
            onStorage('getMetadata', uploadTask.snapshot.ref.fullPath);
            const { contentType, size, timeCreated, updated } = await uploadTask.snapshot.ref.getMetadata();
            onSuccess(uploadTask.snapshot.ref.fullPath, 'getMetadata-success');

            onFirestore('set', documentRef.path, 'file');
            await documentRef
              .set({
                createdAt: serverTimestamp,
                createdFrom: userRef.id,
                createdFromRef: userRef,
                downloadUrl,
                groupRestrictionRefs: values.groupRestrictions.map(groupId =>
                  brandRef.collection(Group.collection).doc(groupId)
                ),
                groupRestrictions,
                hasFile,
                hasGroupRestrictions: values.groupRestrictions.length > 0,
                hasRoleRestrictions: values.roleRestrictions.length > 0,
                lastUpdateAt: serverTimestamp,
                lastUpdateFrom: userRef.id,
                lastUpdateFromRef: userRef,
                localizedFields: filterEmptyLocaleFields(localizedFields), // set delete flag for unused fields is not necessary here because document is created
                metaData: {
                  contentType,
                  size,
                  timeCreated,
                  updated,
                },
                name,
                published,
                roleRestrictions,
                roleRestrictionRefs: values.roleRestrictions.map(roleId =>
                  brandRef.collection(Role.collection).doc(roleId)
                ),
              })
              .then(() => {
                onSuccess(documentRef.path, 'set-success');
              });
            setUploadState(prevState => ({ ...prevState, loading: false }));
            resolve();
            return;
          } catch (err) {
            console.error(err);
            reject('default_error_message');
            return;
          }
        }
      );
    });

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
      <FileFields onSubmit={handleSubmit} initialValues={initialValues} type='create' uploadState={uploadState} />
    </Layout>
  );
}
