export default function onSuccess(
  path: string,
  type:
    | 'add-success'
    | 'batch-success'
    | 'callable-function-success'
    | 'delete-success'
    | 'getDownloadUrl-success'
    | 'getMetadata-success'
    | 'get-success'
    | 'put-success'
    | 'set-success',
  context?: string
) {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `${new Date().toLocaleTimeString()} - %c[${type}${context ? `-${context}` : ''}] - ${path}`,
      `color:green`
    );
    return;
  }
}
