import i18n from 'configuration/i18n';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .required(i18n.t('form_value_required'))
    .max(256, i18n.t('form_value_max', { number: 256 })),
  lastname: yup
    .string()
    .required(i18n.t('form_value_required'))
    .max(256, i18n.t('form_value_max', { number: 256 })),
});

export { default as GeneralSettingsFields } from './GeneralSettingsFields';
