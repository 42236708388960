import React, { useContext } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { Brand, Lesson } from 'models';
import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { LessonCard } from 'components/Cards/LessonCard';
import { NotFoundView } from 'views/NotFound';
import { RobotoBlack } from 'components/Common/Roboto/Black';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles } from '.';

export default function CourseDetailPage() {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { courseId }: { courseId: string } = useParams();
  const { courses } = useContext(DataContext);
  const { xs, sm } = useBreakpoints();

  const availableCourses = useContext(DataContext).courses.app;
  const availableLessons = useContext(DataContext).lessons.app;
  const history = useHistory();
  const passedLessons = useContext(DataContext).userProgress.map(progress => progress.lesson);

  const course = courses.app.find(course => course.id === courseId);

  if (course === undefined || course.isLocked(availableCourses, availableLessons, passedLessons)) {
    return <NotFoundView />;
  }

  const courseLessons = availableLessons.filter(lesson => lesson.course === courseId);
  const sortLessons = (a: Lesson, b: Lesson) => a.sortIndex - b.sortIndex;

  const courseHasDescription = !!course.getDescription(language, brand.configuration.fallbackLocale);

  return (
    <Layout subHeader={{ course, type: 'course-detail' }} navigation={{ type: 'app' }} defaultHorizontalMainPadding>
      <Grid container direction={xs || sm ? 'column-reverse' : 'row'} spacing={2}>
        <Grid item xs={12} md={courseHasDescription ? 8 : 12}>
          <Grid container spacing={2}>
            {courseLessons.sort(sortLessons).map(lesson => (
              <Grid key={lesson.id} item xs={12}>
                <LessonCard
                  currentLocale={language}
                  fallbackLocale={brand.configuration.fallbackLocale}
                  lesson={lesson}
                  onClick={() => history.push(`/learning/lesson/${lesson.id}`)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {courseHasDescription && (
          <Grid item xs={12} md={4}>
            <Paper className={clsx([globalClasses.verticalPadding, globalClasses.horizontalPadding])}>
              <RobotoBlack style={{ fontSize: 16 }}>{t('course_description')}</RobotoBlack>
              <div className={classes.description}>
                <Typography
                  color='textSecondary'
                  style={{
                    lineHeight: 1.15,
                  }}
                  variant='body2'
                >
                  {course.getDescription(language, brand.configuration.fallbackLocale)}
                </Typography>
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
