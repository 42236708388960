import React from 'react';
import { IconButton, MobileStepper, useTheme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useStyles } from '.';

export default function Stepper({
  activeStep,
  backDisabled,
  nextDisabled,
  handleBack,
  handleNext,
  steps,
}: {
  activeStep: number;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  handleNext: () => void;
  handleBack: () => void;
  steps: number;
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MobileStepper
      activeStep={activeStep}
      backButton={
        <IconButton onClick={handleBack} disabled={backDisabled} size='small'>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      }
      className={classes.stepper}
      nextButton={
        <IconButton size='small' onClick={handleNext} disabled={nextDisabled}>
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      }
      color=''
      position='static'
      steps={steps + 1}
      variant='progress'
      LinearProgressProps={{
        style: {
          width: '75%',
        },
      }}
    />
  );
}
