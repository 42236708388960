import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

export { default as RobotoMedium } from './RobotoMedium';
