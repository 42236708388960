import { Dispatch } from 'react';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';

import i18n from 'configuration/i18n';
import { ISignInFormData } from 'interfaces';
import { NotificationProviderActions } from 'types';
import { authentication } from 'configuration/firebase';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: { display: 'flex', justifyContent: 'center' },
}));

export const initialValues: ISignInFormData = { email: '', password: '' };

export function onSubmit(
  values: ISignInFormData,
  formikHelpers: FormikHelpers<ISignInFormData>,
  dispatch: Dispatch<NotificationProviderActions>,
  message: string
) {
  const { email, password } = values;
  formikHelpers.setSubmitting(true);
  setTimeout(async () => {
    try {
      await authentication.signInWithEmailAndPassword(email, password);
    } catch (err) {
      formikHelpers.setSubmitting(false);
      dispatch({
        type: 'ADD_NOTIFICATION',
        payload: {
          message,
          type: 'error',
        },
      });
    }
  }, 2000);
}

export const validationSchema = yup.object().shape({
  email: yup.string().required(i18n.t('form_value_required')).email(i18n.t('form_value_email')),
  password: yup
    .string()
    .required(i18n.t('form_value_required'))
    .min(6, i18n.t('form_value_min', { number: 6 })),
});

export { default as LoginPage } from './LoginPage';
