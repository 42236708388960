import { makeStyles, Tabs as MuiTabs, withStyles } from '@material-ui/core/';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      marginBottom: 0,
      marginTop: 0,
    },
  },
}));

export const TransparentTabs = withStyles({
  indicator: {
    backgroundColor: 'transparent',
  },
})(MuiTabs);

export { default as VerticalTabs } from './VerticalTabs';
