import React, { useContext } from 'react';
import { Card, CardActionArea, useTheme } from '@material-ui/core';
import { ExitToApp, Lock } from '@material-ui/icons';
import { useStyles } from '.';

import { DataContext } from 'providers/Data';
import { Duration } from 'components/Common/Duration';
import { Lesson } from 'models';
import { RobotoBold } from 'components/Common/Roboto/Bold';
import { Status } from 'components/Common/Status';

export default function LessonCard({
  currentLocale,
  disabled,
  fallbackLocale,
  lesson,
  onClick,
  showEnterButton,
}: {
  currentLocale: string;
  disabled?: boolean;
  fallbackLocale: string;
  lesson: Lesson;
  onClick: () => void;
  showEnterButton?: boolean;
}) {
  const { userProgress } = useContext(DataContext);
  const availableLessons = useContext(DataContext).lessons.app;
  const passedLessons = userProgress.map(progress => progress.lesson);
  const locked = lesson.isLocked(availableLessons, passedLessons);
  const theme = useTheme();

  const classes = useStyles();
  return (
    <Card classes={{ root: classes.root }}>
      <CardActionArea className={classes.container} disabled={disabled || locked} onClick={onClick}>
        <div className={classes.left}>
          <RobotoBold
            style={{
              color: theme.palette.text.secondary,
              fontSize: 14,
            }}
          >
            {lesson.getTitle(currentLocale, fallbackLocale)}
          </RobotoBold>
          <Duration minutes={lesson.getDuration()} />
        </div>
        <div className={classes.right}>
          {showEnterButton || (!lesson.isLocked(availableLessons, passedLessons) && !lesson.isPassed(passedLessons)) ? (
            <ExitToApp color='action' fontSize='small' />
          ) : (
            <Status status={lesson.getStatus(passedLessons, userProgress)} />
          )}
        </div>
      </CardActionArea>
      {locked && (
        <div className={classes.overlay}>
          <Lock />
        </div>
      )}
    </Card>
  );
}
