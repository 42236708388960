import React, { useContext } from 'react';
import { Divider, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ILessonContentSortingAnswer, ILessonContentSortingResult } from 'interfaces';
import { LessonContentSorting } from 'models';
import { LessonDetailContext } from 'pages/App/Learning/LessonDetail';
import { RobotoBlack } from 'components/Common/Roboto/Black';
import { SortingAnswerCard } from 'components/Cards/AnswerCards/Sorting';
import { shuffle, useStyles } from '.';
import { useGlobalStyles } from 'providers/Theme';

export default function SortingSlide({
  currentLocale,
  fallbackLocale,
  lessonContent,
}: {
  currentLocale: string;
  fallbackLocale: string;
  lessonContent: LessonContentSorting;
}) {
  const {
    dispatch,
    state: { questionResults },
  } = useContext(LessonDetailContext);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();

  const [localState, setLocalState] = React.useState<{
    answers: ILessonContentSortingAnswer[];
    selectedAnswers: ILessonContentSortingAnswer[];
  }>({
    answers: [],
    selectedAnswers: [],
  });

  React.useEffect(() => {
    setLocalState({
      answers: hasAnswered ? [] : shuffle([...answers]),
      selectedAnswers: !!questionResult && hasAnswered ? (questionResult as ILessonContentSortingResult).answer : [],
    });
    // eslint-disable-next-line
  }, [lessonContent.id]);

  const questionResult = questionResults.find(result => result.lessonContentId === lessonContent.id);
  const hasAnswered = !!questionResult;

  const { answers } = lessonContent.localizedFields.sorting;
  const { t } = useTranslation();

  const handleAdd = (item: ILessonContentSortingAnswer) => {
    const copyAnswerArr = [...localState.answers];
    const copySelectedAnswerArr = [...localState.selectedAnswers];
    copySelectedAnswerArr.push(item);

    const index = copyAnswerArr.findIndex(answer => answer.id === item.id);
    if (index === -1) return;

    copyAnswerArr.splice(index, 1);
    setLocalState(prevState => ({ ...prevState, answers: copyAnswerArr, selectedAnswers: copySelectedAnswerArr }));

    if (copyAnswerArr.length === 0) {
      const result = copySelectedAnswerArr.every((item, index) => item.sortIndex === index + 1);
      dispatch({
        payload: { answer: copySelectedAnswerArr, answeredCorrect: result, lessonContentId: lessonContent.id },
        type: 'handleSortingAnswered',
      });
    }
  };

  const handleRemove = (item: ILessonContentSortingAnswer) => {
    const copySelectedAnswerArr = [...localState.selectedAnswers];
    const copyAnswerArr = [...localState.answers];
    copyAnswerArr.push(item);

    const index = copySelectedAnswerArr.findIndex(answer => answer.id === item.id);
    if (index === -1) return;

    copySelectedAnswerArr.splice(index, 1);
    setLocalState(prevState => ({ ...prevState, answers: copyAnswerArr, selectedAnswers: copySelectedAnswerArr }));
  };

  return (
    <div className={classes.root}>
      <div className={globalClasses.marginBottom}>
        <RobotoBlack style={{ fontSize: 16,  marginTop: 16  }}>{lessonContent.getQuestion(currentLocale, fallbackLocale)}</RobotoBlack>
        <Typography color='textSecondary' variant='caption'>
          {t('sorting_question_instruction')}
        </Typography>
      </div>

      <div className={classes.topContainer}>
        {localState.selectedAnswers.map(answer => (
          <div className={classes.topCard} key={answer.id}>
            <SortingAnswerCard answer={answer} onClick={() => handleRemove(answer)} lessonContent={lessonContent}>
              {lessonContent.getAnswer(answer.id, currentLocale, fallbackLocale)}
            </SortingAnswerCard>
          </div>
        ))}
      </div>

      {hasAnswered ? null : <Divider style={{ marginBottom: theme.spacing(1) }} />}

      <div className={classes.bottomContainer}>
        {localState.answers.map(answer => (
          <div className={classes.bottomCard} key={answer.id + '-answers'}>
            <SortingAnswerCard answer={answer} onClick={() => handleAdd(answer)} lessonContent={lessonContent}>
              {lessonContent.getAnswer(answer.id, currentLocale, fallbackLocale)}
            </SortingAnswerCard>
          </div>
        ))}
      </div>
    </div>
  );
}
