import React from 'react';
import { TwitterPicker } from 'react-color';
import { useStyles } from '.';

export default function ColorPicker({
  color,
  onClose,
  open,
  onChange,
}: {
  color: string;
  onClose: () => void;
  open?: boolean;
  onChange: (color: string) => void;
}) {
  const classes = useStyles();
  return open ? (
    <div className={classes.popover}>
      <div className={classes.cover} onClick={onClose} />
      <TwitterPicker
        color={color}
        colors={[
          '#FFAE70',
          '#E0D5CD',
          '#5A4230',
          '#484442',
          '#688777',
          '#666C7F',
          '#A07E9D',
          '#CF4F4F',
          '#C3C08D',
          '#2F8344',
          '#DEDBE1',
          '#37A6AE',
          '#37A62E',
          '#AE5EAE',
        ]}
        triangle='hide'
        onChange={({ hex }) => {
          onChange(hex);
        }}
      />
    </div>
  ) : null;
}
