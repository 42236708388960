import React, { useContext } from 'react';
import { Check } from '@material-ui/icons';
import { Column } from 'material-table';
import { Grid, Link, TextField, useTheme } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from 'configuration/i18n';
import useNotification from 'hooks/notification/useNotification';
import { Brand, Course, Group, Role, User } from 'models';
import { ColorPreview } from 'components/Common/ColorPreview';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { ICourseGeneralFormData, ICourseRestrictionsFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { courseValidation } from '.';
import { filterEmptyLocaleFields } from 'utils/data';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

export default function AdminCoursesPage() {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteState, setDeleteState] = React.useState<{
    course?: Course;
    dialogOpen: boolean;
    loading: boolean;
    name: string | null;
  }>({
    course: undefined,
    dialogOpen: false,
    loading: false,
    name: null,
  });

  const { brand, courses } = React.useContext(DataContext);
  const { t } = useTranslation();

  const fallbackLocale = (brand as Brand).configuration.fallbackLocale;
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const initialValues: ICourseGeneralFormData & ICourseRestrictionsFormData = {
    courseColor: theme.palette.primary.main,
    courseLogoUrl: '',
    groupRestrictions: [],
    key: '',
    localizedFields: {
      description: { ...(brand as Brand).getLocalesAsObject() },
      title: { ...(brand as Brand).getLocalesAsObject() },
    },
    preConditions: [],
    published: false,
    roleRestrictions: [],
    sortIndex: courses.admin.length + 1,
  };

  const fields: Column<object>[] = [
    {
      align: 'left',
      field: 'courseColor',
      title: i18n.t('course_colour'),
      render: ({ courseColor }: any) => <ColorPreview color={courseColor} />,
      searchable: false,
      sorting: false,
      resizable: false,
    },
    { field: 'key', title: i18n.t('course_key'), resizable: false },
    { defaultSort: 'asc', field: 'sortIndex', title: i18n.t('course_sortIndex'), resizable: false },
    {
      field: 'hasRestrictions',
      title: t('restrictions'),
      render: (rowData: any) =>
        rowData.hasRestrictions ? (
          <div style={{ fontSize: theme.typography.fontSize - 1 }}>
            <Check fontSize='inherit' />
          </div>
        ) : null,
    },
    {
      field: 'hasPreConditions',
      title: t('preconditions'),
      render: (rowData: any) =>
        rowData.hasPreConditions ? (
          <div style={{ fontSize: theme.typography.fontSize - 1 }}>
            <Check fontSize='inherit' />
          </div>
        ) : null,
    },
    {
      field: 'published',
      title: 'Status',
      type: 'string',
      render: ({ published }: any) =>
        published ? 'Published' : <span style={{ color: theme.palette.error.light }}>Unpublished</span>,
    },
    {
      field: 'lastUpdateAt',
      title: t('last_modified'),
      type: 'date',
    },
    {
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ id }: any) => (
        <Link
          color='primary'
          component='button'
          onClick={() => history.push(`/admin/learning-content/course/${id}/lessons`)}
          underline='none'
        >
          {t('lessons_button')}
        </Link>
      ),
    },
    {
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ id }: any) => (
        <Link color='error' component='button' onClick={() => handleOpenDeleteCourse(id)} underline='none'>
          {t('delete_course_button')}
        </Link>
      ),
    },
  ];

  const handleCreateCourse = async (values: ICourseGeneralFormData & ICourseRestrictionsFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('add', 'course');

    return brandRef.collection(Course.collection).add({
      ...values,
      createdAt: serverTimestamp,
      createdFrom: userRef.id,
      createdFromRef: userRef,
      groupRestrictionRefs: values.groupRestrictions.map(groupId => brandRef.collection(Group.collection).doc(groupId)),
      hasGroupRestrictions: values.groupRestrictions.length > 0,
      hasPreConditions: values.preConditions.length > 0,
      hasRoleRestrictions: values.roleRestrictions.length > 0,
      lastUpdateAt: serverTimestamp,
      lastUpdateFrom: userRef.id,
      lastUpdateFromRef: userRef,
      lessonContents: [],
      lessonContentRefs: [],
      lessonRefs: [],
      lessons: [],
      localizedFields: filterEmptyLocaleFields(values.localizedFields), // set delete flag for unused fields is not necessary here because document is created here
      preConditionRefs: values.preConditions.map(preConditionId =>
        brandRef.collection(Course.collection).doc(preConditionId)
      ),
      roleRestrictionRefs: values.roleRestrictions.map(roleId => brandRef.collection(Role.collection).doc(roleId)),
    });
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const copy = { ...deleteState };

    if (copy.course) {
      try {
        await copy.course.delete(brand?.id as string);
        notify(t('alert_course_deletion_success'));
      } catch (err) {
        console.error(err);
        notify(t('alert_course_deletion_error'), 'error');
      }
    } else {
      notify(t('alert_course_deletion_error'), 'error');
    }

    setDeleteState({ course: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteCourse = (id: string) => {
    const course = courses.admin.find(courseItem => courseItem.id === id);

    if (!course) {
      notify(t('alert_course_deletion_error'), 'error');
    } else {
      setDeleteState({ course, dialogOpen: true, loading: false, name: course.key });
    }
  };

  const renderErrors = (errors: any, touched: any, locale: string, field: string): boolean => {
    if (
      errors &&
      errors.localizedFields &&
      errors.localizedFields[field] &&
      errors.localizedFields[field][locale] &&
      touched &&
      touched.localizedFields &&
      touched.localizedFields[field] &&
      touched.localizedFields[field][locale]
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_course_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ course: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_course_confirmation_dialog_title')}
        type='delete'
      />
      <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleCreateCourse(values)
              .then(ref => {
                onSuccess(ref.path, 'set-success');
                history.push(`/admin/learning-content/course/${ref.id}`);
              })
              .catch(() => {
                notify(t('alert_course_creation_error'), 'error');
              });
          }}
          validateOnBlur
          validationSchema={() =>
            courseValidation((brand as Brand).configuration.locales, (brand as Brand).configuration.fallbackLocale)
          }
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, isSubmitting }) => {
            return (
              <FormDialog
                createButtonText={t('create_button')}
                handleClick={handleSubmit}
                handleClose={() => {
                  resetForm();
                  setCreateOpen(false);
                }}
                loading={isSubmitting}
                open={createOpen}
                title={t('create_course_dialog_title')}
                type={'create'}
                updateButtonText={t('update_button')}
              >
                <Form className={globalClasses.form} noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        disabled={isSubmitting}
                        error={!!errors.key && touched.key}
                        fullWidth
                        helperText={
                          !!errors.key && touched.key && errors.key ? errors.key : t('course_key_label_helper_text')
                        }
                        FormHelperTextProps={{
                          style: {
                            minHeight: 19,
                          },
                        }}
                        id='key'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={t('course_key_label')}
                        margin='dense'
                        name='key'
                        required
                        value={values.key}
                        variant='outlined'
                      />
                    </Grid>
                    {(brand as Brand).configuration.locales.map(locale => (
                      <Grid key={locale} item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          error={renderErrors(errors, touched, locale, 'title')}
                          fullWidth
                          helperText={
                            renderErrors(errors, touched, locale, 'title')
                              ? (errors.localizedFields?.title as any)[locale]
                              : t('course_title_in_locale', { locale: t(`locale_${locale}`) })
                          }
                          FormHelperTextProps={{
                            style: {
                              minHeight: 19,
                            },
                          }}
                          id={`localizedFields.title.${locale}`}
                          label={t('course_title_label')}
                          onBlur={handleBlur}
                          margin='dense'
                          name={`localizedFields.title.${locale}`}
                          onChange={handleChange}
                          required={locale === fallbackLocale}
                          value={values.localizedFields.title[locale]}
                          variant='outlined'
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Form>
              </FormDialog>
            );
          }}
        </Formik>
        <Table
          columns={fields}
          data={courses.admin.map(
            ({
              courseColor,
              id,
              hasGroupRestrictions,
              hasRoleRestrictions,
              hasPreConditions,
              key,
              lastUpdateAt,
              published,
              sortIndex,
            }) => ({
              courseColor,
              key,
              id,
              hasRestrictions: hasGroupRestrictions || hasRoleRestrictions,
              hasPreConditions,
              lastUpdateAt: lastUpdateAt ? lastUpdateAt.toDate() : t('loading_progress'),
              published,
              sortIndex,
            })
          )}
          onAddClick={() => setCreateOpen(true)}
          onRowClick={(event, data: any) => history.push(`/admin/learning-content/course/${data.id}`)}
          title={t('courses')}
        />
      </Layout>
    </>
  );
}
