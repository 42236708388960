import { Dispatch, useContext } from 'react';
import { NotificationContext } from 'providers/Notifications';
import { NotificationProviderActions } from 'types';

const useNotification = () => {
  const dispatch: Dispatch<NotificationProviderActions> = useContext(NotificationContext).dispatch;
  return (message: string, type?: 'error' | 'success') =>
    dispatch({ type: 'ADD_NOTIFICATION', payload: { message, type } });
};

export default useNotification;
