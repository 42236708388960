import 'firebase/app';
import Model from 'models/Model';

class Group extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'groups';
  private _createdAt?: firebase.default.firestore.Timestamp;
  private _createdFrom?: string;
  private _createdFromRef?: firebase.default.firestore.DocumentReference;
  private _key!: string;
  private _lastUpdateAt?: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom?: string;
  private _lastUpdateFromRef?: firebase.default.firestore.DocumentReference;
  private _rInviteUnitSelectable!: boolean;

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp | undefined {
    return this._createdAt;
  }

  get createdFrom(): string | undefined {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference | undefined {
    return this._createdFromRef;
  }

  get key(): string {
    return this._key;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp | undefined {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string | undefined {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference | undefined {
    return this._lastUpdateFromRef;
  }

  get rInviteUnitSelectable(): boolean {
    return this._rInviteUnitSelectable;
  }
}

export default Group;
