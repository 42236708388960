import { LessonContentBase } from '..';
import { ContentItem, LayoutItem, Map } from 'types';

class LessonContent extends LessonContentBase {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private _localizedFields!: {
    content: Map<Array<ContentItem | LayoutItem>>;
  };

  private _type!: 'content';

  get localizedFields() {
    return this._localizedFields;
  }

  get type() {
    return this._type;
  }
}

export default LessonContent;
