import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Slider,
  Switch as MuiSwitch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import useNotification from 'hooks/notification/useNotification';
import { Brand, Lesson, User } from 'models';
import { ButtonGroup } from 'components/Forms/ButtonGroup';
import { DataContext } from 'providers/Data';
import { ILessonGeneralFormData } from 'interfaces';
import { LoadingBackdrop } from 'components/Loading/LoadingBackdrop';
import { LocalizedFieldDialog } from 'components/Dialogs/LocalizedField';
import { ScrollToError } from 'components/Forms/ScrollToError';
import { UserContext } from 'providers/User';
import { lessonValidationGeneral } from '.';
import { firestore, serverTimestamp } from 'configuration/firebase';
import { onFirestore, onSuccess } from 'utils/logger';
import { setDeleteFlagforEmptyLocaleFields } from 'utils/data';
import { useGlobalStyles } from 'providers/Theme';

export default function AdminLessonDetailGeneralPage({ lesson }: { lesson: Lesson }) {
  const backPath = `/admin/learning-content/course/${lesson.course}/lessons`;
  const brand = useContext(DataContext).brand as Brand;
  const notify = useNotification();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const { t } = useTranslation();
  const { xs, sm } = useBreakpoints();

  const { durationInMin, key, localizedFields, published, sortIndex } = lesson;

  const initialValuesGeneral: ILessonGeneralFormData = {
    durationInMin,
    key,
    localizedFields: { title: { ...brand.getLocalesAsObjectFromField(localizedFields.title) } },
    published,
    sortIndex,
  };

  const handleSubmit = async (values: ILessonGeneralFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', 'lesson');

    return brandRef
      .collection(Lesson.collection)
      .doc(lesson.id)
      .set(
        {
          ...values,
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: userRef.id,
          lastUpdateFromRef: userRef,
          localizedFields: setDeleteFlagforEmptyLocaleFields(values.localizedFields),
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(brandRef.collection(Lesson.collection).path, 'set-success', 'updated-lesson');
      });
  };

  return (
    <Formik
      initialValues={initialValuesGeneral}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmit(values)
          .then(() => {
            notify(t('alert_default_saving_success'));
          })
          .catch(() => {
            notify(t('alert_lesson_update_error'), 'error');
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            history.push(backPath);
          });
      }}
      validateOnBlur
      validationSchema={() => lessonValidationGeneral(brand.configuration.locales, brand.configuration.fallbackLocale)}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, dirty, setFieldValue }) => {
        return (
          <>
            <Form className={globalClasses.form} noValidate>
              <Prompt when={dirty} message={() => t('alert_leave_page') as string} />
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {' '}
                                  <Typography variant='h6'>{t('general_fields')}</Typography>
                                </div>
                                <div>
                                  {' '}
                                  <Grid container direction='column' alignItems='flex-end' justify='flex-end'>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <MuiSwitch
                                            checked={values.published}
                                            color='primary'
                                            disabled={isSubmitting}
                                            onChange={handleChange}
                                            name='published'
                                          />
                                        }
                                        label='Published'
                                        labelPlacement='end'
                                        style={{ marginRight: 0 }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormHelperText>{t('publish_this_lesson')}</FormHelperText>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.key && touched.key}
                                fullWidth
                                helperText={
                                  !!errors.key && touched.key && errors.key
                                    ? errors.key
                                    : t('lesson_key_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='key'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('lesson_key_label')}
                                name='key'
                                required
                                value={values.key}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <LocalizedFieldDialog
                                disabled={isSubmitting}
                                errors={errors.localizedFields?.title}
                                helperText={t('lesson_title_label_helper_text')}
                                fieldName='title'
                                fullWidth
                                label={t('lesson_title_label')}
                                name='localizedFields'
                                onBlur={handleBlur}
                                onSave={localeValues => {
                                  setFieldValue(`localizedFields.title`, localeValues);
                                }}
                                required
                                touched={touched.localizedFields?.title}
                                values={values.localizedFields.title}
                              />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <TextField
                                disabled={isSubmitting}
                                error={!!errors.sortIndex && touched.sortIndex}
                                fullWidth
                                helperText={
                                  !!errors.sortIndex && touched.sortIndex && errors.sortIndex
                                    ? errors.sortIndex
                                    : t('lesson_index_label_helper_text')
                                }
                                FormHelperTextProps={{
                                  style: {
                                    minHeight: 19,
                                  },
                                }}
                                id='sortIndex'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('lesson_index_label')}
                                name='sortIndex'
                                required
                                type='number'
                                value={values.sortIndex}
                                variant='outlined'
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Slider
                                id='durationInMin'
                                onChange={(event, value) => setFieldValue('durationInMin', value)}
                                marks={
                                  xs || sm
                                    ? [
                                        { value: 10, label: '10 min' },
                                        { value: 30, label: '30 min' },
                                        { value: 50, label: '50 min' },
                                      ]
                                    : [
                                        { value: 10, label: '10 min' },
                                        { value: 20, label: '20 min' },
                                        { value: 30, label: '30 min' },
                                        { value: 40, label: '40 min' },
                                        { value: 50, label: '50 min' },
                                      ]
                                }
                                max={60}
                                min={0}
                                name='durationInMin'
                                step={1}
                                value={values.durationInMin}
                                valueLabelDisplay='auto'
                              />
                              <FormHelperText>{t('lesson_duration_helper_text')}</FormHelperText>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ButtonGroup
                  backPath={backPath}
                  createButtonText={t('create_lesson_button')}
                  isSubmitting={isSubmitting}
                  type={'update'}
                  updateButtonText={t('update_lesson_button')}
                />
              </div>
              <ScrollToError />
              <LoadingBackdrop open={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
