import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { LoadingButtonProps, useStyles } from './';

export default function LoadingButton(props: LoadingButtonProps) {
  const classes = useStyles();
  const buttonProps = { ...props, loading: undefined };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button {...buttonProps} disabled={props.loading || props.disabled} variant='contained' />
        {props.loading && <CircularProgress size={24} className={classes.progress} />}
      </div>
    </div>
  );
}
