import React from 'react';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { TextFields, PermMedia, Add, Filter2, Filter3 } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { ContentItemType, LayoutItemType } from 'types';
import { useStyles } from '.';

const AddContentBuilderItemDialog = ({
  locale,
  handleAddColumnLayout,
  handleAddEditorItem,
  handleAddMediaItem,
  noLayoutItems,
  open,
  onClose,
}: {
  locale: string;
  handleAddEditorItem: (locale: string) => void;
  handleAddMediaItem: () => void;
  handleAddColumnLayout?: (type: LayoutItemType) => void;
  open: boolean;
  onClose: () => void;
  noLayoutItems?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const Icon = ({ type }: { type: ContentItemType | LayoutItemType }) => {
    if (type === 'editor') return <TextFields />;
    if (type === 'image') return <PermMedia />;
    if (type === 'two-column') return <Filter2 />;
    if (type === 'three-column') return <Filter3 />;
    return <Add />;
  };

  const renderBuildingBlockTitle = (type: ContentItemType | LayoutItemType) => {
    switch (type) {
      case 'editor': {
        return t('text_building_block');
      }
      case 'image': {
        return t('media_building_block');
      }
      case 'two-column': {
        return t('two_column_layout_block');
      }
      case 'three-column': {
        return t('three_column_layout_block');
      }
      default:
        return t('unknown_building_block');
    }
  };

  return (
    <Dialog maxWidth='sm' fullWidth onClose={handleClose} open={open}>
      <DialogTitle>{t('select_building_item', { locale })}</DialogTitle>
      {noLayoutItems ? null : (
        <List subheader={<ListSubheader component='div'>{t('layout_blocks')}</ListSubheader>}>
          <ListItem
            button
            onClick={() => {
              handleAddColumnLayout && handleAddColumnLayout('two-column');
              handleClose();
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Icon type={'two-column'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={renderBuildingBlockTitle('two-column')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleAddColumnLayout && handleAddColumnLayout('three-column');
              handleClose();
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Icon type={'three-column'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={renderBuildingBlockTitle('three-column')} />
          </ListItem>
        </List>
      )}
      <List subheader={<ListSubheader component='div'>{t('content_blocks')}</ListSubheader>}>
        <ListItem
          button
          onClick={() => {
            handleAddMediaItem();
            handleClose();
          }}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <Icon type={'image'} /* and video */ />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={renderBuildingBlockTitle('image')} /* and video */ />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            handleAddEditorItem(locale);
            handleClose();
          }}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <Icon type={'editor'} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={renderBuildingBlockTitle('editor')} />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default AddContentBuilderItemDialog;
