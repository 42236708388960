import React from 'react';
import { createMuiTheme, ThemeProvider as MuiThemeProvider, useMediaQuery } from '@material-ui/core';
import { useLocalStorage } from 'react-use';

import { ThemeContext } from '.';

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useLocalStorage<'dark' | 'light'>('brainee.theme.mode', prefersDarkMode ? 'dark' : 'light');

  const toggleMode = () => {
    if (mode === 'dark') {
      setMode('light');
    }
    if (mode === 'light') {
      setMode('dark');
    }
  };

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          background: {
            default: mode === 'light' ? '#EDEFF0' : '#303030',
          },
          primary: {
            main: '#FFAE70',
            contrastText: 'rgba(255,255,255,0.9)',
          },
          secondary: {
            main: '#43425D',
          },
          info: {
            main: '#7BACF1',
          },
          warning: {
            main: '#FFD600',
          },
          success: {
            main: '#6CD881',
          },
          error: {
            main: '#FF5F6D',
          },
          text: {
            primary: mode === 'light' ? '#303030' : '#EDEFF0',
          },
          type: mode,
        },
        overrides: {
          MuiAppBar: {
            colorDefault: {
              backgroundColor: 'transparent',
            },
          },
        },
        props: {
          MuiAppBar: {
            color: 'inherit',
            elevation: 0,
          },
          MuiCheckbox: {
            color: 'default',
          },
          MuiSwitch: {
            color: 'default',
          },
        },
        shape: {
          borderRadius: 8,
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={{ toggleMode, mode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
