import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
  },
  closeIcon: {
    marginRight: theme.spacing(1),
  },
  circularProgress: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 3),
    },
  },
  card: {
    backgroundColor: theme.palette.background.default,
    height: 0,
    paddingBottom: '85%',
    position: 'relative',
  },
  container: {
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(2),
    position: 'absolute',
    right: 0,
    top: 0,
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0, 0),
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  videoContainer: {
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export { default as MediaLibraryDialog } from './MediaLibraryDialog';
