import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Grid, Link, MenuItem, TextField, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import useNotification from 'hooks/notification/useNotification';
import {
  Brand,
  Course,
  Lesson,
  LessonContent,
  LessonContentBase,
  LessonContentChoice,
  LessonContentSorting,
  User,
} from 'models';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { ILessonContentChoiceAnswer, ILessonContentSortingAnswer } from 'interfaces';
import { LessonContentFormData } from 'types';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { arrayUnion, firestore, serverTimestamp } from 'configuration/firebase';
import { lessonContentValidation } from '.';
import { onError, onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

function createChoiceAnswers(brand: Brand, length: number) {
  const arr: ILessonContentChoiceAnswer[] = [];

  let i = 1;
  while (i < length + 1) {
    arr.push({
      id: uuid() + '-' + i,
      label: { [brand.configuration.fallbackLocale]: '' },
      value: i === 1 ? true : false,
    });
    i++;
  }

  return arr;
}

const createSortingAnswers = (brand: Brand, length: number) => {
  const arr: ILessonContentSortingAnswer[] = [];

  let i = 1;
  while (i < length + 1) {
    arr.push({ id: uuid() + '-' + i, label: { [brand.configuration.fallbackLocale]: '' }, sortIndex: i });
    i++;
  }

  return arr;
};

export default function AdminLessonDetailLessonContentsPage({ lesson }: { lesson: Lesson }) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { brand, adminLessonContents } = useContext(DataContext);
  const { t } = useTranslation();

  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteState, setDeleteState] = React.useState<{
    lessonContent?: LessonContent | LessonContentChoice | LessonContentSorting;
    dialogOpen: boolean;
    loading: boolean;
    name: string | null;
  }>({
    lessonContent: undefined,
    dialogOpen: false,
    loading: false,
    name: null,
  });

  const lessonLessonContents = adminLessonContents.filter(lessonContent => lessonContent.lesson === lesson.id);

  const initialValues: LessonContentFormData = {
    locales: [(brand as Brand).configuration.fallbackLocale],
    localizedFields: {
      content: { [(brand as Brand).configuration.fallbackLocale]: [] },
      choice: {
        answers: [...createChoiceAnswers(brand as Brand, 4)],
        question: { [(brand as Brand).configuration.fallbackLocale]: '' },
      },
      sorting: {
        answers: [...createSortingAnswers(brand as Brand, 4)],
        question: { [(brand as Brand).configuration.fallbackLocale]: '' },
      },
    },
    key: '',
    published: false,
    sortIndex: adminLessonContents.filter(lessonContent => lessonContent.lesson === lesson.id).length + 1,
    type: 'content',
  };

  const handleCreateLessonContentAndUptadeLessonAndCourse = async (values: LessonContentFormData) => {
    const batch = firestore.batch();
    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);
    const courseRef = brandRef.collection(Course.collection).doc(lesson.course);
    const lessonRef = brandRef.collection(Lesson.collection).doc(lesson.id);
    const newLessonContentRef = brandRef.collection(LessonContentBase.collection).doc();
    const userRef = firestore.collection(User.collection).doc(user.id);

    let lessonContent: any = { ...values, localizedFields: {} };

    switch (values.type) {
      case 'choice': {
        lessonContent = { ...lessonContent, localizedFields: { choice: { ...values.localizedFields.choice } } };
        break;
      }
      case 'content': {
        lessonContent = { ...lessonContent, localizedFields: { content: { ...values.localizedFields.content } } };
        break;
      }
      case 'sorting': {
        lessonContent = { ...lessonContent, localizedFields: { sorting: { ...values.localizedFields.sorting } } };
        break;
      }
    }

    onFirestore('batch-set', 'lesson-content');
    onFirestore('batch-set', 'lesson');
    onFirestore('batch-set', 'course');

    batch.set(
      newLessonContentRef,
      {
        ...lessonContent,
        course: courseRef.id,
        courseRef: courseRef,
        createdAt: serverTimestamp,
        createdFrom: userRef.id,
        createdFromRef: userRef,
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lesson: lessonRef.id,
        lessonRef: lessonRef,
      },
      { merge: true }
    );

    batch.set(
      lessonRef,
      {
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lessonContents: arrayUnion(newLessonContentRef.id),
        lessonContentRefs: arrayUnion(newLessonContentRef),
      },
      { merge: true }
    );

    batch.set(
      courseRef,
      {
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lessonContentRefs: arrayUnion(newLessonContentRef),
        lessonContents: arrayUnion(newLessonContentRef.id),
      },
      { merge: true }
    );

    return new Promise<firebase.default.firestore.DocumentReference<firebase.default.firestore.DocumentData>>(
      async (resolve, reject) => {
        try {
          await batch.commit();
          onSuccess(newLessonContentRef.path, 'batch-success');
          onSuccess(lessonRef.path, 'batch-success');
          onSuccess(courseRef.path, 'batch-success');
          return resolve(newLessonContentRef);
        } catch (err) {
          onError('AdminLessonDetailLessonContentsPage', err);
          reject();
        }
      }
    );
  };
  
  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const copy = { ...deleteState };

    if (copy.lessonContent) {
      try {
        await copy.lessonContent.delete(brand?.id as string);
        notify(t('alert_lesson_content_deletion_success'));
      } catch (err) {
        console.error(err);
        notify(t('alert_lesson_content_deletion_error'), 'error');
      }
    } else {
      notify(t('alert_lesson_content_deletion_error'), 'error');
    }

    setDeleteState({ lessonContent: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteLessonContent = (id: string) => {
    const lessonContent = lessonLessonContents.find(lessonContent => lessonContent.id === id);

    if (!lessonContent) {
      notify(t('alert_lesson_content_deletion_error'), 'error');
    } else {
      setDeleteState({ lessonContent, dialogOpen: true, loading: false, name: lessonContent.key });
    }
  };

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_lesson_content_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ lessonContent: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_lesson_content_confirmation_dialog_title')}
        type='delete'
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleCreateLessonContentAndUptadeLessonAndCourse(values)
            .then(ref => {
              history.push(`/admin/learning-content/lesson-content/${ref.id}`);
            })
            .catch(() => {
              notify(t('alert_lesson_content_creation_error'), 'error');
            });
        }}
        validateOnBlur
        validationSchema={() => lessonContentValidation(brand?.configuration.locales as string[])}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, isSubmitting }) => {
          return (
            <FormDialog
              createButtonText={t('create_button')}
              handleClick={handleSubmit}
              handleClose={() => {
                resetForm();
                setCreateOpen(false);
              }}
              loading={isSubmitting}
              open={createOpen}
              title={t('create_lesson_content_dialog_title')}
              type={'create'}
              updateButtonText={t('update_button')}
            >
              <Form className={globalClasses.form} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.key && touched.key}
                      fullWidth
                      helperText={
                        !!errors.key && touched.key && errors.key
                          ? errors.key
                          : t('lesson_content_key_label_helper_text')
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='key'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('lesson_content_key_label')}
                      margin='dense'
                      name='key'
                      required
                      value={values.key}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    error={!!errors.type && touched.type}
                    helperText={!!errors.type && touched.type && errors.type ? errors.type : ' '}
                    FormHelperTextProps={{
                      style: {
                        minHeight: 19,
                      },
                    }}
                    id='type'
                    label='Content type'
                    margin='dense'
                    name='type'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    value={values.type}
                    variant='outlined'
                  >
                    <MenuItem value={'content'}>Content</MenuItem>
                    <MenuItem value={'choice'}>Choice</MenuItem>
                    <MenuItem value={'sorting'}>Sorting</MenuItem>
                  </TextField>
                </Grid>
              </Form>
            </FormDialog>
          );
        }}
      </Formik>
      <Table
        onAddClick={() => setCreateOpen(true)}
        onRowClick={(event, data: any) => history.push(`/admin/learning-content/lesson-content/${data.id}`)}
        columns={[
          { field: 'key', title: t('lesson_content_key'), resizable: false },
          { field: 'type', title: t('lesson_content_type'), resizable: false },
          { defaultSort: 'asc', field: 'sortIndex', title: t('lesson_sortIndex'), resizable: false },
          { field: 'lastUpdateAt', title: t('last_modified'), type: 'datetime', resizable: false },
          {
            field: 'published',
            title: 'Status',
            type: 'string',
            render: ({ published }: any) =>
              published ? 'Published' : <span style={{ color: theme.palette.error.light }}>Unpublished</span>,
          },
          {
            field: 'actions',
            sorting: false,
            searchable: false,
            disableClick: true,
            align: 'right',
            render: ({ id }: any) => (
              <Link color='error' component='button' onClick={() => handleOpenDeleteLessonContent(id)} underline='none'>
                {t('delete_lesson_content_button')}
              </Link>
            ),
          },
        ]}
        data={lessonLessonContents.map(({ id, key, lastUpdateAt, published, sortIndex, type }) => ({
          id,
          key,
          lastUpdateAt: lastUpdateAt ? lastUpdateAt.toDate() : t('loading_progress'),
          published,
          sortIndex,
          type: type ? type.charAt(0).toUpperCase() + type.slice(1) : '',
        }))}
        title={t('lesson_contents').toString()}
      />
    </>
  );
}
