import React, { useContext } from 'react';
import { Check } from '@material-ui/icons';
import { Column } from 'material-table';
import { Link, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { Brand, Resource, Role, User } from 'models';
import { IRoleFormData } from 'interfaces';
import { RoleFields } from 'components/Forms/Fields/Admin/RoleFields';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { firestore, functions, serverTimestamp } from 'configuration/firebase';
import { onError, onFirestore, onFunctions, onSuccess } from 'utils/logger';

export default function AdminBrandRolesPage() {
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { brand, roles } = useContext(DataContext);
  const { t } = useTranslation();

  const [createState, setCreateState] = React.useState<{
    dialogOpen: boolean;
  }>({
    dialogOpen: false,
  });

  const [deleteState, setDeleteState] = React.useState<{
    dialogOpen: boolean;
    role?: Role;
    loading: boolean;
    name: string | null;
  }>({
    dialogOpen: false,
    role: undefined,
    loading: false,
    name: null,
  });

  const [updateState, setUpdateState] = React.useState<{
    dialogOpen: boolean;
    loading: boolean;
    role?: Role;
  }>({
    dialogOpen: false,
    loading: false,
    role: undefined,
  });

  const handleCreate = async ({ isSelectableInRestrictions, key, resources }: IRoleFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('set', brandRef.collection(Role.collection).path);

    return new Promise<void>(async (resolve, reject) => {
      try {
        await brandRef
          .collection(Role.collection)
          .doc()
          .set({
            createdAt: serverTimestamp,
            createdFrom: userRef.id,
            createdFromRef: userRef,
            isDefaultRole: false,
            isSelectableInRestrictions,
            key,
            resources,
            resourceRefs: resources.map(resource => firestore.collection(Resource.collection).doc(resource)),
            lastUpdateAt: serverTimestamp,
            lastUpdateFrom: userRef.id,
            lastUpdateFromRef: userRef,
          });
        onSuccess(brandRef.collection(Role.collection).doc().path, 'set-success');
        setCreateState({ dialogOpen: false });
        return resolve();
      } catch (err) {
        onError('AdminBrandRolePage', err);
        setCreateState({ dialogOpen: false });
        return reject(err);
      }
    });
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const { role } = { ...deleteState };

    if (role) {
      try {
        onFunctions('deleteRole');
        const deleteRole = functions.httpsCallable('deleteRole');
        await deleteRole({ roleId: role.id });
        notify(t('alert_role_deletion_success'));
        onSuccess('deleteRole', 'callable-function-success');
      } catch (err) {
        onError('AdminBrandRolePage', err);
        notify(t('alert_role_deletion_error'), 'error');
      }
    } else {
      console.error('Role not found');
      notify(t('alert_role_deletion_error'), 'error');
    }

    setDeleteState({ role: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteDialog = (id: string) => {
    const role = roles.brand.find(role => role.id === id);

    if (!role) {
      notify(t('alert_role_deletion_error'), 'error');
    } else {
      setDeleteState({ role, dialogOpen: true, loading: false, name: role.key ? role.key : null });
    }
  };

  const handleUpdate = async ({ isSelectableInRestrictions, key, resources }: IRoleFormData) => {
    const brandRef = firestore.collection(Brand.collection).doc(brand?.id);
    const updateRole = functions.httpsCallable('updateRole');

    const { role } = { ...updateState };

    return new Promise<void>(async (resolve, reject) => {
      if (role === undefined) {
        return reject('Role not found');
      }

      setUpdateState(prevState => ({ ...prevState, loading: true }));

      onFunctions('updateRole');

      try {
        await updateRole({ roleId: role.id, isSelectableInRestrictions, key, resources });
        onSuccess(brandRef.collection(Role.collection).doc(role.id).path, 'callable-function-success');
        setUpdateState({ dialogOpen: false, loading: false, role: undefined });
        return resolve();
      } catch (err) {
        onError('AdminBrandRolePage', err);
        setUpdateState({ dialogOpen: false, loading: false, role: undefined });
        return reject(err);
      }
    });
  };

  const columns: Column<object>[] = [
    { defaultSort: 'asc', field: 'key', title: 'Name' },
    {
      field: 'rInviteUnitSelectable',
      render: (rowData: any) =>
        rowData.isSelectableInRestrictions ? (
          <div style={{ fontSize: theme.typography.fontSize - 1 }}>
            <Check fontSize='inherit' />
          </div>
        ) : null,
      title: t('selectable_in_restrictions'),
      type: 'boolean',
    },
    {
      field: 'ressources',
      render: (rowData: any) =>
        rowData.resources.length > 0 ? (
          <div style={{ fontSize: theme.typography.fontSize - 1 }}>
            <Check fontSize='inherit' />
          </div>
        ) : null,
      title: t('resources'),
      type: 'boolean',
    },
    {
      editable: 'never',
      field: 'lastUpdateAt',
      title: t('last_modified'),
      type: 'date',
    },
  ];

  if (user?.isFileAdmin() && user?.isLearningContentAdmin() && user?.isUserAdmin()) {
    columns.push({
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ id }: any) => (
        <Link
          color='error'
          component='button'
          onClick={() => {
            handleOpenDeleteDialog(id);
          }}
          underline='none'
        >
          {t('delete_role_button')}
        </Link>
      ),
    });
  }

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_role_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ role: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_role_confirmation_dialog_title')}
        type='delete'
      />
      {createState.dialogOpen && (
        <RoleFields
          handleClick={values => handleCreate(values)}
          handleClose={() => {
            setCreateState({ dialogOpen: false });
          }}
          initialValues={{ isSelectableInRestrictions: false, key: '', resources: [] }}
          open={createState.dialogOpen}
          title={t('create_role_dialog_title')}
          type='create'
        />
      )}
      {updateState.dialogOpen && (
        <RoleFields
          handleClick={values => handleUpdate(values)}
          handleClose={() => {
            setUpdateState(prevState => ({ ...prevState, dialogOpen: false, role: undefined }));
          }}
          initialValues={{
            key: updateState.role?.key || '',
            isSelectableInRestrictions: updateState.role?.isSelectableInRestrictions || false,
            resources: updateState.role
              ? updateState.role.resources.filter(resource => resource !== 'rLoginBackend')
              : [],
          }}
          open={updateState.dialogOpen}
          title={t('update_role_dialog_title')}
          type='update'
        />
      )}
      <Table
        columns={[...columns]}
        data={roles.brand.map(role => ({
          id: role.id,
          key: role.key,
          lastUpdateAt: role.lastUpdateAt ? role.lastUpdateAt.toDate() : t('loading_progress'),
          isSelectableInRestrictions: role.isSelectableInRestrictions,
          resources: role.resources,
        }))}
        title={t('/admin/brand/roles')}
        onAddClick={() => {
          setCreateState({ dialogOpen: true });
        }}
        onRowClick={(event, { id }: any) => {
          setUpdateState(prevState => ({
            ...prevState,
            dialogOpen: true,
            role: roles.brand.find(role => role.id === id),
          }));
        }}
      />
    </>
  );
}
