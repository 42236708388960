import React from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { AdminBrandGroupPage } from './Groups';
import { AdminBrandRolesPage } from './Roles';
import { AdminBrandUnitsPage } from './Units';
import { AdminBrandUsersPage } from './User';
import { Layout } from 'components/Layout';
import { ResourceRoute } from 'components/Common/Routing/ResourceRoute';
import { VerticalTab } from 'components/Layout/Navigation/VerticalTab';
import { VerticalTabs } from 'components/Layout/Navigation/VerticalTabs';
import { useStyles } from '.';

export default function AdminBrandPage() {
  const classes = useStyles();
  const history = useHistory();

  const { path } = useRouteMatch();
  const { xs } = useBreakpoints();
  const { t } = useTranslation();

  const items = [`${path}/groups`, `${path}/roles`, `${path}/units`, `${path}/users`];

  const [value, setValue] = React.useState(
    items.includes(history.location.pathname) ? history.location.pathname : false
  );

  const handleChange = (newValue: any) => {
    history.push(newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <Layout
      defaultHorizontalMainPadding
      mainStyle={{ display: 'flex', flexDirection: xs ? 'column' : 'row' }}
      navigation={{ type: 'admin' }}
    >
      <VerticalTabs onChange={(_, value) => handleChange(value)} value={value}>
        {items.map(item => (
          <VerticalTab key={item} label={t(item)} value={item} />
        ))}
      </VerticalTabs>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/groups`} />
        </Route>
        <ResourceRoute exact path={`${path}/groups`} resource='rManageUsers' withoutLayoutInFallbackView>
          <div className={classes.container}>
            <AdminBrandGroupPage />
          </div>
        </ResourceRoute>
        <ResourceRoute exact path={`${path}/roles`} resource='rManageUsers' withoutLayoutInFallbackView>
          <div className={classes.container}>
            <AdminBrandRolesPage />
          </div>
        </ResourceRoute>
        <ResourceRoute exact path={`${path}/units`} resource='rManageUsers' withoutLayoutInFallbackView>
          <div className={classes.container}>
            <AdminBrandUnitsPage />
          </div>
        </ResourceRoute>
        <ResourceRoute exact path={`${path}/users`} resource='rManageUsers' withoutLayoutInFallbackView>
          <div className={classes.container}>
            <AdminBrandUsersPage />
          </div>
        </ResourceRoute>
        <Route path='*'>
          <div>404</div>
        </Route>
      </Switch>
    </Layout>
  );
}
