import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const lessonValidationGeneral = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string().required(i18n.t('form_value_required'));
    else {
      localeObject[key] = yup.string();
    }
  });

  const schema = yup.object().shape({
    durationInMin: yup.number().required(i18n.t('form_value_required')),
    key: yup
      .string()
      .required(i18n.t('form_value_required'))
      .min(1, i18n.t('form_value_min', { number: 1 }))
      .max(64, i18n.t('form_value_max', { number: 64 })),
    localizedFields: yup
      .object({
        title: yup.object({ ...localeObject }).noUnknown(),
      })
      .noUnknown(),
    published: yup.boolean().required(i18n.t('form_value_required')),
    sortIndex: yup.number().required(i18n.t('form_value_required')).min(1, i18n.t('form_value_postive')),
  });

  return schema;
};

export { default as AdminLessonDetailGeneralPage } from './AdminLessonDetailGeneralPage';
