import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

if (process.env.NODE_ENV === 'development') {
  console.log(`%cDevelopment Mode - Firebase project: ${process.env.REACT_APP_FB_PROJECT_ID}`, 'color: orange;');
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
export const app = firebase;
export const analytics = firebase.analytics();
export const authentication = firebase.auth();
export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const deleteField = firebase.firestore.FieldValue.delete;
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
