import Model from '../Model';

class Unit extends Model {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'units';
  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom!: string;
  private _createdFromRef!: firebase.default.firestore.DocumentReference;
  private _key!: string;
  private _internalId!: string;
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;

  static get collection(): string {
    return this._collection;
  }

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference {
    return this._createdFromRef;
  }

  get key(): string {
    return this._key;
  }

  get internalId(): string {
    return this._internalId;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get lastUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }
}

export default Unit;
