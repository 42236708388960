import { Localization } from 'material-table';
import { useTranslation } from 'react-i18next';

export const useMaterialTableTranslation = (): Localization => {
  const { t } = useTranslation();

  return {
    pagination: {
      labelDisplayedRows: `{from}-{to} ${t('mt_of')} {count}`,
      labelRowsSelect: t('mt_rows'),
      labelRowsPerPage: t('mt_rows_per_page'),
      firstAriaLabel: t('mt_first_page'),
      firstTooltip: t('mt_first_page'),
      previousAriaLabel: t('mt_previous_page'),
      previousTooltip: t('mt_previous_page'),
      nextAriaLabel: t('mt_next_page'),
      nextTooltip: t('mt_next_page'),
      lastAriaLabel: t('mt_last_page'),
      lastTooltip: t('mt_last_page'),
    },
    body: {
      emptyDataSourceMessage: t('mt_no_data'),
    },
    toolbar: {
      searchPlaceholder: t('mt_search'),
      searchTooltip: t('mt_search'),
      addRemoveColumns: t('mt_add_remove_columns'),
    },
  };
};
