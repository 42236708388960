import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  center: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}));

export { default as NotFoundView } from './NotFoundView';
