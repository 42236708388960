import React, { useContext } from 'react';
import clsx from 'clsx';
import useWindowDimensions from 'hooks/layout/useWindowsDimensions';
import { Close, Flare, Brightness2 } from '@material-ui/icons';
import {
  useTheme,
  makeStyles,
  Theme,
  AppBar,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  DialogContent,
  Box,
  Paper,
} from '@material-ui/core';
import { useGlobalStyles, ThemeContext, layoutWidth } from 'providers/Theme';
import { useTranslation } from 'react-i18next';
import { ContentItemComponent } from 'components/Slide/Content';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  ITwoColumnAdminLayoutItem,
  IThreeColumnAdminLayoutItem,
} from 'interfaces';
import { ContentType, Map } from 'types';
import { convertEditorItemToHtml } from 'utils/data';

export default function ContentBuilderPreviewDialog({
  activeLocale,
  content,
  contentType,
  handleClose,
  open,
}: {
  activeLocale: string;
  content: Map<
    (
      | IAdminContentEditorItem
      | IContentImageItem
      | IContentVideoItem
      | ITwoColumnAdminLayoutItem
      | IThreeColumnAdminLayoutItem
    )[]
  >;
  contentType: ContentType;
  handleClose: () => void;
  open: boolean;
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const { mode, toggleMode } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const [deviceSize, setDeviceSize] = React.useState(0);

  React.useEffect(() => {
    if (deviceSize === 0 && width !== null) {
      let startWidth = 320;
      if (width > 320 && width <= 375) startWidth = 320;
      else if (width > 375 && width <= 414) startWidth = 375;
      else if (width > 414 && width <= 768) startWidth = 414;
      else if (width > 768 && width <= 834) startWidth = 768;
      else if (width > 834 && width <= 1024) startWidth = 834;
      else if (width > 1024 && width <= 1280) startWidth = 1024;
      else {
        startWidth = 1280;
      }
      setDeviceSize(startWidth);
    }
  }, [deviceSize, width]);

  let horizontalPadding = classes.newsHorizontalPaddingXS;
  let verticalPadding = classes.newsVerticalPaddingXS;

  if (deviceSize >= 600) {
    horizontalPadding = classes.newsHorizontalPadding;
    verticalPadding = classes.newsVerticalPadding;
  }

  const paddings = [horizontalPadding, verticalPadding];

  let paperClasses = [...paddings];

  if (contentType === 'lesson-content') {
    paperClasses = [classes.lessonContentPaper];

    if (deviceSize >= 600) {
      horizontalPadding = classes.lessonContentHorizontalPadding;
    }

    if (deviceSize >= layoutWidth) {
      horizontalPadding = classes.lessonContentHorizontalPaddingDesktop;
    }
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <>
        <AppBar elevation={1} position='fixed'>
          <Toolbar className={clsx([globalClasses.layout, classes.toolbar])}>
            <IconButton color='inherit' onClick={handleClose} style={{ padding: 0 }}>
              <Close color='action' />
            </IconButton>
            <Typography color='textPrimary' variant='h6' className={classes.title}>
              {t('preview')}
            </Typography>
            {deviceSize === 0 ? null : (
              <div
                style={{
                  marginRight: theme.spacing(2),
                }}
              >
                <TextField
                  disabled={!width}
                  onChange={(event: any) => {
                    setDeviceSize(event.target.value);
                  }}
                  select
                  size='small'
                  type='number'
                  value={deviceSize}
                  InputProps={{
                    style: {
                      fontSize: 14,
                      minWidth: theme.spacing(15),
                    },
                  }}
                >
                  <MenuItem value={320}>IPhone SE (1G.)</MenuItem>
                  <MenuItem value={375}>IPhone 8</MenuItem>
                  <MenuItem value={414}>IPhone 8 Plus</MenuItem>
                  <MenuItem value={768}>IPad Mini</MenuItem>
                  <MenuItem value={834}>IPad Pro (10.5)</MenuItem>
                  <MenuItem value={1024}>IPad Pro (12.9)</MenuItem>
                  <MenuItem value={1280}>Desktop</MenuItem>
                </TextField>
              </div>
            )}
            <IconButton color='inherit' onClick={() => toggleMode()} size='small' style={{ padding: 0 }}>
              {mode === 'dark' ? <Flare color='action' /> : <Brightness2 color='action' />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />{' '}
        <DialogContent className={classes.dialog}>
          <div className={horizontalPadding} style={{ margin: 'auto', width: deviceSize }}>
            <Paper elevation={contentType === 'lesson-content' ? 0 : 1} className={clsx([...paperClasses])}>
              <Box color='textSecondary'>
                {content[activeLocale]
                  .map(item => convertEditorItemToHtml(item))
                  .filter(item => item !== null)
                  .map(item => (
                    <ContentItemComponent key={item.id} item={item} isPreviewMode previewSize={deviceSize} />
                  ))}
              </Box>
            </Paper>
          </div>
        </DialogContent>
      </>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3, 0),
  },
  lessonContentPaper: {
    background: theme.palette.background.default,
  },
  offset: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  newsHorizontalPaddingXS: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  newsVerticalPaddingXS: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  newsHorizontalPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  lessonContentHorizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  lessonContentHorizontalPaddingDesktop: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  newsVerticalPadding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));
