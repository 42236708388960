import React, { useContext } from 'react';
import clsx from 'clsx';
import useWindowDimensions from 'hooks/layout/useWindowsDimensions';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Brightness2, Close, Flare } from '@material-ui/icons';
import { ContentImageItem } from 'components/Slide/Content/ImageItem';
import { ContentVideoItem } from 'components/Slide/Content/VideoItem';
import { EditorItem } from 'components/Forms/ContentBuilder/Items/Editor';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  ITwoColumnAdminLayoutItem,
  IThreeColumnAdminLayoutItem,
} from 'interfaces';
import { ThemeContext, useGlobalStyles } from 'providers/Theme';
import { useTranslation } from 'react-i18next';

export default function ContentBuilderItemDialog({
  activeLocale,
  calledFromColumn,
  handleClose,
  item,
  open,
  title,
}: {
  activeLocale: string;
  calledFromColumn?: 'left' | 'middle' | 'right';
  item:
    | IAdminContentEditorItem
    | IContentImageItem
    | IContentVideoItem
    | ITwoColumnAdminLayoutItem
    | IThreeColumnAdminLayoutItem;
  handleClose: () => void;
  open: boolean;
  title: string;
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { mode, toggleMode } = useContext(ThemeContext);

  const { width } = useWindowDimensions();
  const [localeActiveSize, setLocaleActiveSize] = React.useState(0);

  React.useEffect(() => {
    if (localeActiveSize === 0 && width !== null) {
      let startWidth = 287;
      if (width > 320 && width <= 375) startWidth = 287;
      else if (width > 375 && width <= 414) startWidth = 343;
      else if (width > 414 && width <= 768) startWidth = 382;
      else if (width > 768 && width <= 834) startWidth = 735;
      else if (width > 834 && width <= 1024) startWidth = 802;
      else if (width > 1024 && width <= 1200) startWidth = 992;
      else {
        startWidth = 1184;
      }
      setLocaleActiveSize(startWidth);
    }
  }, [localeActiveSize, width]);

  const isEditorItem = item.type === 'editor';

  return (
    <Dialog
      fullScreen={!isEditorItem}
      maxWidth='lg'
      fullWidth
      open={open}
      onClose={handleClose}
      className={isEditorItem ? undefined : classes.dialog}
      style={{ padding: 0 }}
    >
      {!isEditorItem && (
        <>
          <AppBar elevation={1} position='fixed'>
            <Toolbar className={clsx([globalClasses.layout, classes.toolbar])}>
              <IconButton color='inherit' onClick={handleClose} style={{ padding: 0 }}>
                <Close color='action' />
              </IconButton>
              <Typography color='textPrimary' variant='h6' className={classes.title}>
                {title}
              </Typography>
              {localeActiveSize === 0 ? null : (
                <div
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                >
                  <TextField
                    disabled={!width}
                    onChange={(event: any) => setLocaleActiveSize(event.target.value)}
                    select
                    size='small'
                    type='number'
                    value={localeActiveSize}
                    InputProps={{
                      style: {
                        fontSize: 14,
                        minWidth: 136,
                      },
                    }}
                  >
                    <MenuItem value={287}>IPhone SE</MenuItem> {/* <= 320 */}
                    <MenuItem value={343}>IPhone 8</MenuItem> {/* > 320 && <= 375 */}
                    <MenuItem value={382}>IPhone 8 Plus</MenuItem> {/* > 320 && <= 414 */}
                    <MenuItem value={735}>IPad Mini</MenuItem> {/* > 320 && <= 768 */}
                    <MenuItem value={802}>IPad Pro (10,5 Inch)</MenuItem> {/* > 320 && <= 834 */}
                    <MenuItem value={992}>IPad Pro (12,9 Inch)</MenuItem> {/* > 320 && <= 1024 */}
                    <MenuItem value={1184}>Desktop</MenuItem> {/* > 1024 */}
                  </TextField>
                </div>
              )}
              <IconButton color='inherit' onClick={() => toggleMode()} size='small' style={{ padding: 0 }}>
                {mode === 'dark' ? <Flare color='action' /> : <Brightness2 color='action' />}
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.offset} />{' '}
        </>
      )}
      {isEditorItem && item.type === 'editor' ? (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <EditorItem activeLocale={activeLocale} calledFromColumn={calledFromColumn} item={item} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('close_button')}</Button>
          </DialogActions>
        </>
      ) : (
        <Paper className={classes.dialog} square>
          <DialogContent className={clsx([classes.main, globalClasses.layout])}>
            <div style={{ margin: 'auto', width: localeActiveSize }}>
              {item.type === 'image' && <ContentImageItem item={item} />}
              {item.type === 'video' && <ContentVideoItem item={item} />}
            </div>
          </DialogContent>
        </Paper>
      )}
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1, // flexGrow: 1 for remaining height for main
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 6),
    },
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(0, 1.75),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 6),
    },
  },
}));
