import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NewsDetailPage } from './NewsDetail';
import { NewsPage } from './News';
import { NotFoundView } from 'views/NotFound';

export default function Page() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <NewsPage />
      </Route>
      <Route exact path={`${path}/:newsId`}>
        <NewsDetailPage />
      </Route>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
