import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';

import { Brand } from 'models';
import { DataContext } from 'providers/Data';
import { Map } from 'types';

export default function LocalizedFieldDialog({
  disabled,
  errors,
  fieldName,
  fullWidth,
  helperText,
  label,
  maxCharacter,
  multiline,
  name,
  onBlur,
  required,
  touched,
  onSave,
  values,
}: {
  disabled?: boolean;
  errors?: FormikErrors<Map<string>> | undefined;
  fieldName: string;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  maxCharacter?: number;
  multiline?: boolean;
  name: string;
  onBlur: any;
  onSave: (values: Map<string>) => void;
  required?: boolean;
  touched?: FormikTouched<Map<string>> | undefined;
  values: Map<string>;
}) {
  const {
    configuration: { fallbackLocale, locales },
  } = useContext(DataContext).brand as Brand;
  const { t } = useTranslation();

  const [localeValues, setLocaleValues] = useState(values);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLocaleValues(values);
  }, [values]);

  const handleValueChange = (locale: string) => (event: any) => {
    setLocaleValues({ ...localeValues, [locale]: event.target.value });
  };

  const handleOpen = () => {
    if (!disabled) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLocaleValues(values);
  };

  const handleSave = () => {
    onSave(localeValues);
    setOpen(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSave(localeValues);
      setOpen(false);
    }
  };

  const hasVisibleErrors = () => {
    for (const locale of locales) {
      if (errors !== undefined && !!errors[locale] === true && touched !== undefined && touched[locale] === true) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <TextField
        onClick={handleOpen}
        disabled={disabled}
        error={hasVisibleErrors()}
        fullWidth={fullWidth}
        helperText={hasVisibleErrors() ? t('check_your_inputs') : helperText}
        FormHelperTextProps={{
          style: {
            minHeight: 19,
          },
        }}
        label={label}
        multiline={multiline}
        rows={multiline ? 5 : undefined}
        rowsMax={multiline ? 5 : undefined}
        name={name}
        required={required}
        value={values[fallbackLocale]}
        variant='outlined'
      />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          {locales.map((locale: string, index: number) => (
            <React.Fragment key={index}>
              <TextField
                InputProps={
                  maxCharacter
                    ? {
                        endAdornment: (
                          <InputAdornment position='start'>
                            {`${localeValues[locale].length}/${maxCharacter}`}
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
                error={!!errors && errors[locale] && !!touched && touched[locale] ? true : false}
                fullWidth
                helperText={errors && errors[locale] && !!touched && touched[locale] ? errors[locale] : ''}
                FormHelperTextProps={{
                  style: {
                    minHeight: 19,
                  },
                }}
                name={`localizedFields.${fieldName}.${locale}`}
                id={`localizedFields.${fieldName}.${locale}`}
                inputProps={maxCharacter ? { maxLength: maxCharacter } : {}}
                label={t('locale_' + locale)}
                margin='dense'
                multiline={multiline}
                rows={multiline ? 5 : undefined}
                rowsMax={multiline ? 5 : undefined}
                onBlur={onBlur}
                onChange={handleValueChange(locale)}
                onKeyPress={handleKeyPress}
                required={required && locale === fallbackLocale}
                value={localeValues ? localeValues[locale] : ''}
              />
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='default' variant='outlined'>
            {t('cancel_button')}
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained'>
            {t('apply_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
