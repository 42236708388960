import { createContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { ILessonDetailPageState } from 'interfaces';
import { LessonDetailPageActions } from 'types';

export const initialState: ILessonDetailPageState = {
  activeStep: 0,
  correctAnswers: 0,
  lessonStarted: false,
  questionResults: [],
};

export const LessonDetailContext = createContext<{
  dispatch: React.Dispatch<LessonDetailPageActions>;
  state: ILessonDetailPageState;
}>({
  dispatch: () => null,
  state: initialState,
});

export const lessonDetailPageReducer = (
  state: ILessonDetailPageState,
  action: LessonDetailPageActions
): ILessonDetailPageState => {
  let newState = { ...state };
  switch (action.type) {
    case 'handleBack': {
      newState = { ...state, activeStep: state.activeStep - 1 };
      break;
    }
    case 'handleChoiceAnswered': {
      const correctAnswers = state.correctAnswers + (action.payload.answeredCorrect ? 1 : 0);
      const questionResults = [...state.questionResults, { ...action.payload }];
      newState = { ...state, correctAnswers, questionResults };
      break;
    }
    case 'handleReset': {
      newState = { ...initialState };
      break;
    }
    case 'handleSortingAnswered': {
      const correctAnswers = state.correctAnswers + (action.payload.answeredCorrect ? 1 : 0);
      const questionResults = [...state.questionResults, { ...action.payload }];
      newState = { ...state, correctAnswers, questionResults };
      break;
    }
    case 'handleNext': {
      newState = { ...state, activeStep: state.activeStep + 1, lessonStarted: true };
      break;
    }
  }
  return newState;
};

export const useStyles = makeStyles((theme: Theme) => ({
  center: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1, // flexGrow: 1 for remaining height for main
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 6),
    },
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(0, 1.75),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 6),
    },
  },
}));

export { default as LessonDetailPage } from './LessonDetailPage';
