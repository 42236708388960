import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import { AvatarCard } from 'components/Cards/AvatarCard';
import { EmailFields } from 'components/Forms/Fields/SettingFields/EmailFields';
import { GeneralSettingsFields } from 'components/Forms/Fields/SettingFields/GeneralFields';
import { Layout } from 'components/Layout';
import { PasswordFields } from 'components/Forms/Fields/SettingFields/PasswordFields';
import { UserContext } from 'providers/User';

export default function SettingsPage() {
  const { user } = useContext(UserContext);

  return (
    <Layout defaultHorizontalMainPadding>
      <AvatarCard user={user} />
      <Grid container spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Grid item xs={12}>
          <GeneralSettingsFields user={user} />
        </Grid>
        <Grid item xs={12}>
          <PasswordFields user={user} />
        </Grid>
        <Grid item xs={12}>
          <EmailFields user={user} />
        </Grid>
      </Grid>
    </Layout>
  );
}
