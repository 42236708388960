import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const newsValidation = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string().required(i18n.t('form_value_required'));
    else {
      localeObject[key] = yup.string();
    }
  });

  const notRequiredLocaleObject: any = {};
  locales.forEach(key => {
    notRequiredLocaleObject[key] = yup.string().max(1000, i18n.t('form_value_max', { number: 1000 }));
  });

  const schema = yup.object().shape({
    key: yup.string().required(i18n.t('form_value_required')).min(1, i18n.t('form_value_min', { number: 1 })).max(64, i18n.t('form_value_max', { number: 64 })),
    locales: yup.array().of(yup.string()).required(i18n.t('form_value_required')),
    localizedFields: yup
      .object({
        caption: yup.object({ ...notRequiredLocaleObject }).noUnknown(),
        content: yup.object({}).required(i18n.t('form_value_required')),
        title: yup.object({ ...localeObject }).noUnknown(),
      })
      .noUnknown(),
    photoUrl: yup.string().url(),
    published: yup.boolean().required(i18n.t('form_value_required')),
  });

  return schema;
};

export { default as NewsFields } from './NewsFields';
