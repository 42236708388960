import React from 'react';
import { CircularProgress as MuiCircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '.';

export default function CircularProgress({ caption }: { caption?: string }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MuiCircularProgress className={caption ? classes.progress : undefined} />
      {caption && <Typography variant='caption'>{caption}</Typography>}
    </div>
  );
}
