import { ITranslationResources } from 'interfaces';

const default_error_message = 'Etwas ist schiefgelaufen. Probiere es bitte erneut!';
const default_saving_message = 'Änderungen wurden erfolgreich gespeichert.';

const de: ITranslationResources = {
  '/': 'Start',
  '/content': 'Inhalte',
  '/learning': 'Kurse',
  '/news': 'News',
  '/files': 'Dateien',
  '/general': 'Allgemein',
  '/restrictions': 'Restriktionen',
  '/lessons': 'Lessons',
  '/lesson-contents': 'Lesson Contents',
  '/admin/learning-content': 'Kurse',
  '/admin/files': 'Dateien',
  '/admin/brand': 'Brand',
  '/admin/news': 'News',
  '/admin/brand/groups': 'Gruppen',
  '/admin/brand/roles': 'Rollen',
  '/admin/brand/units': 'Units',
  '/admin/brand/users': 'Benutzer',
  '/admin/units': 'Units',
  add_button: 'Hinzufügen',
  add_course_button: 'Kurs hinzufügen',
  add_lesson_button: 'Lesson hinzufügen',
  add_new_editor_item_button: 'Baustein hinzufügen',
  add_new_item_button: 'Item hinzufügen',
  admin: 'Admin',
  alert_course_creation_error: default_error_message,
  alert_course_creation_success: 'Kurs wurde erfolgreich erstellt.',
  alert_course_deletion_error: default_error_message,
  alert_course_deletion_success: 'Kurs erfolgreich gelöscht',
  alert_course_update_error: default_error_message,
  alert_course_update_success: 'Kurs wurde erfolgreich aktualisiert.',
  alert_delete_avatar_success: 'Profilbild wurde entfernt.',
  alert_default_saving_success: default_saving_message,
  alert_file_already_exists: 'Datei existiert bereits!',
  alert_file_deletion_error: default_error_message,
  alert_file_deletion_success: 'Datei wurde erfolgreich gelöscht.',
  alert_file_upload_error_file_size: 'Es sind maximal 25 MB erlaubt.',
  alert_file_upload_error_file_type: 'Dateiformat wurde nicht erkannt.',
  alert_file_upload_error: default_error_message,
  alert_group_deletion_error: default_error_message,
  alert_group_deletion_success: 'Gruppe wurde erfolgreich gelöscht.',
  alert_group_creation_error: default_error_message,
  alert_group_creation_success: 'Gruppe wurde erfolgreich erstellt.',
  alert_group_update_error: default_error_message,
  alert_group_update_success: 'Gruppe wurde erfolgreich aktualisiert',
  alert_leave_page: 'Möchtest Du diese Seite wirklich verlassen?\n\nDeine Eingaben gehen dadurch verloren.',
  alert_lesson_content_creation_error: default_error_message,
  alert_lesson_content_deletion_error: default_error_message,
  alert_lesson_content_deletion_success: 'Lesson Content erfolgreich gelöscht',
  alert_lesson_content_update_error: default_error_message,
  alert_leave_lesson_page:
    'Möchtest Du diese Seite wirklich verlasen?\n\nDein bisheriger Lernfortschritt in dieser Lesson geht dadurch verloren.',
  alert_lesson_creation_error: default_error_message,
  alert_lesson_deletion_error: default_error_message,
  alert_lesson_deletion_success: 'Lesson erfolgreich gelöscht',
  alert_lesson_update_error: default_error_message,
  alert_login: 'Falsches Passwort oder dieser Benutzer existiert nicht.',
  alert_media_item_picked_error: default_error_message,
  alert_news_creation_success: 'News erfolgreich erstellt',
  alert_news_deletion_success: 'News erfolgreich gelöscht',
  alert_news_deletion_error: default_error_message,
  alert_news_update_success: 'News erfolgreich aktualisiert',
  alert_settings_changed_success: 'Änderungen wurden erfolgreich übernommen',
  alert_settings_changed_error: default_error_message,
  alert_reinvite_error: default_error_message,
  alert_reinvite_success: 'Einladung erfolgreich versendet.',
  alert_registration_error: default_error_message,
  alert_reset_password_error: 'Etwas ist schiefgelaufen. Möglicherweise existiert dieses Konto nicht.',
  alert_reset_password_success: 'Du erhälst in Kürze eine Mail.',
  alert_role_creation_success: 'Rolle wurde erfolgreich erstellt.',
  alert_role_update_success: 'Rolle wurde erfolgreich aktualisiert.',
  alert_role_creation_error: default_error_message,
  alert_role_update_error: default_error_message,
  alert_role_deletion_success: 'Rolle wurde erfolgreich gelöscht',
  alert_role_deletion_error: default_error_message,
  alert_unit_creation_error: default_error_message,
  alert_unit_creation_success: 'Unit wurde erfolgreich erstellt.',
  alert_unit_deletion_error: default_error_message,
  alert_unit_deletion_success: 'Unit wurde erfolgreich gelöscht.',
  alert_unit_update_error: default_error_message,
  alert_unit_update_success: 'Unit wurde erfolgreich aktualisiert.',
  alert_user_creation_success: 'Benutzer wurde erfolgreich eingeladen',
  alert_user_update_success: 'Benutzer wurde erfolgreich aktualisiert',
  alert_user_creation_error: default_error_message,
  alert_user_update_error: default_error_message,
  alert_user_deletion_error: default_error_message,
  alert_user_deletion_success: 'Benutzer wurde erfolgreich gelöscht.',
  alert_user_already_in_use_error: 'Die Email {{ email }} ist bereits in Benutzung.',
  alert_user_invite_error: default_error_message,

  alert_user_invite_success: 'Benutzer wurde erfolgreich eingeladen.',
  announcements_label: 'Ankündigungen',
  delete_unit_button: 'Unit löschen',
  delete_unit_confirmation_dialog_content_text:
    'Möchtest Du die Unit {{name}} wirklich löschen? Alle Benutzer, die dieser Unit zugeordnet sind werden aus dieser entfernt.',
  apply_button: 'Übernehmen',
  back_button: 'Zurück',
  back_to_home: 'Zurück zur Startseite',
  back_to_login: 'Zurück zur Anmeldung',
  content_blocks: 'Content',
  cancel_button: 'Abbrechen',
  create_button: 'Erstellen',
  check_your_inputs: 'Überprüfe deine Eingaben.',
  create_course: 'Kurs hinzufügen',
  create_lesson_button: 'Lesson hinzufügen',
  create_lesson_dialog_title: 'Lesson erstellen',
  create_course_button: 'Kurs hinzufügen',
  create_course_dialog_title: 'Kurs erstellen',
  create_file_button: 'Datei bereitstellen',
  create_file: 'Datei bereitstellen',
  create_group_dialog_title: 'Gruppe erstellen',
  create_group_button: 'Erstellen',
  create_lesson_content_dialog_title: 'Lesson Content erstellen',
  create_role_dialog_title: 'Rolle erstellen',
  create_news_button: 'News bereitstellen',
  create_news: 'News bereitstellen',
  create_unit_dialog_title: 'Unit erstellen',
  create_user_dialog_title: 'Benutzer erstellen',
  close_button: 'Schließen',
  complete_registration_button: 'Registrieren',
  content_builder_delete_confirmation: `Möchtest du dieses Item wirklich entfernen?\n\nDadurch werden auch alle zugeordneten Items entfernt.`,
  courses: 'Kurse',
  course_creation_success: 'Kurs erfolgreich erstellt',
  course_logo_picked_error: default_error_message,
  course_logo_picked_success: 'Logo erfolgreich hinzugefügt',
  course_colour: 'Kursfarbe',
  course_description: 'Kursbeschreibung',
  course_description_label: 'Kursbeschreibung',
  course_index_label: 'Index',
  course_index_label_helper_text: 'Bestimmt die Reihenfolge des Kurses.',
  course_key: 'Kurs',
  course_key_label: 'Bezeichner',
  course_key_label_helper_text: 'Der Bezeichner ist nur für Admins sichtbar.',
  course_group_restriction_caption:
    'Wähle hier die Gruppe(n) aus, die ein Benutzer besitzen muss, damit er diesen Kurs bearbeiten darf. Sofern ein Benutzer keine der augewählten Gruppen zugewiesen ist, ist dieser Kurs für ihn verborgen. Besitzt ein Benutzer mehrere Gruppen ist die Übereinstimmung mit einer gewählten Gruppe ausreichend. Wählst Du keine Gruppen aus, kann jeder Benutzer unabhängig von seinen zugewiesenen Gruppen diesen Kurs bearbeiten.',
  course_pre_condition_caption:
    'Wähle hier die Kurse aus, die ein Benutzer bestehen muss danmit er diesen Kurs bearbeiten darf. Solange die markierten Kursen nicht bestanden sind, ist dieser Kurs für den Benutzer sichtbar aber gesperrt.',
  course_role_restriction_caption:
    'Wähle hier die Rolle(n) aus, die ein Benutzer besitzen muss, damit er diesen Kurs bearbeiten darf. Sofern ein Benutzer keine der augewählten Rollen besitzt ist dieser Kurs für ihn verborgen. Wählst Du keine Rolle aus, kann jeder Benutzer unabhängig von seiner Rolle diesen Kurs bearbeiten.',
  course_sortIndex: 'Index',
  course_group_restriction_label: 'Gruppen Restriktionen',
  course_role_restriction_label: 'Rollen Restriktionen',
  course_title: 'Titel',
  course_title_label: 'Titel',
  course_title_label_helper_text: 'Der Titel wird auf der Kurskarte angezeigt.',
  course_title_in_locale: 'Titel in {{ locale }}',
  confirm_email_label: 'Email bestätigen',
  confirm_password_label: 'Passwort bestätigen',
  current_password_label: 'Aktuelles Passwort',
  current_password_label_helper_text: 'Aus Sicherheitsgründen benötigen wir für diese Aktion Dein aktuelles Passwort.',
  default_error_message: default_error_message,
  delete_button: 'Löschen',
  delete_course_button: 'Kurs löschen',
  delete_course_confirmation_dialog_title: 'Kurs löschen?',
  delete_course_confirmation_dialog_content_text: 'Möchtest Du den Kurs {{name}} wirklich löschen? Dadurch werden auch alle Lessons und Lesson Contents dieses Kurses gelöscht.',
  delete_confirmation_dialog_content_sub_text: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  delete_news_confirmation_dialog_content_text: 'Möchtest Du die News {{name}} wirklich löschen?',
  delete_file_button: 'Datei löschen',
  delete_file_confirmation_dialog_content_text: 'Möchtest Du die Datei {{name}} wirklich löschen?',
  delete_file_confirmation_dialog_title: 'Datei löschen?',
  delete_lesson_content_confirmation_dialog_content_text: 'Möchtest Du den Lesson Content {{name}} wirklich löschen?',
  delete_lesson_content_confirmation_dialog_title: 'Lesson Content löschen?',
  delete_lesson_confirmation_dialog_title: 'Lesson löschen?',
  delete_lesson_confirmation_dialog_content_text:
    'Möchtest Du die Lesson {{name}} wirklich löschen? Dadurch werden auch alle Lesson Contents dieser Lesson gelöscht.',
  delete_group_button: 'Gruppe löschen',
  delete_group_confirmation_dialog_content_text:
    'Möchtest Du die Gruppe {{name}} wirklich löschen? Sie wird auch aus allen Kursen, Lessons, Dateien und Benutzer entfernt.',
  delete_group_confirmation_dialog_title: 'Gruppe löschen?',
  delete_lesson_button: 'Lesson löschen',
  delete_lesson_content_button: 'Lesson Content löschen',
  delete_news_button: 'News löschen',
  delete_news_confirmation_dialog_title: 'News löschen?',
  delete_role_button: 'Rolle löschen',
  delete_role_confirmation_dialog_content_text:
    'Möchtest Du die Rolle {{name}} wirklich löschen? Die Benutzer, die dieser Rolle zugewiesen sind erhalten durch diese Aktion die Standardrolle User.',
  delete_role_confirmation_dialog_title: 'Rolle löschen?',
  delete_unit_confirmation_dialog_title: 'Unit löschen?',
  delete_user_confirmation_dialog_content_text:
    'Möchtest Du den Benutzer {{name}} wirklich löschen? Der Benutzer verliert dadurch alle seine Lernstände und gespeicherten Daten.',
  delete_user_confirmation_dialog_title: 'Benutzer löschen?',
  delete_user_button: 'Benutzer löschen',
  edit_button: 'Bearbeiten',
  edit_mode_button: 'Bearbeitungsmodus',
  editor_placeholder: 'Schreibe hier Deinen Inhalt ...',
  expires_in: 'Läuft ab',
  file_format_error: 'Ungültiges Dateiformat',
  file_content_type: 'Dateityp',
  file_creation_success: 'Datei wurde erfolgreich bereitgestellt',
  file_update_success: 'Datei wurde erfolgreich aktualisiert',
  file_description_label: 'Beschreibung',
  file_description_label_helper_text: 'Optionale Beschreibung der Datei (max. 1000 Zeichen)',
  file_group_restriction_caption:
    'Wähle hier die Gruppe(n) aus, die ein Benutzer besitzen muss, damit er diese Datei herunterladen darf. Sofern ein Benutzer keine der augewählten Gruppen zugewiesen ist, ist diese Datei für ihn verborgen. Besitzt ein Benutzer mehrere Gruppen ist die Übereinstimmung mit einer gewählten Gruppe ausreichend. Wählst Du keine Gruppen aus, kann jeder Benutzer unabhängig von seinen zugewiesenen Gruppen diese Datei herunterladen.',
  file_group_restriction_label: 'Gruppen Restriktionen',
  file_role_restriction_caption:
    'Wähle hier die Rolle(n) aus, die ein Benutzer besitzen muss, damit er diese Datei herunterladen darf. Sofern ein Benutzer keine der augewählten Rollen besitzt ist diese Datei für ihn verborgen. Wählst Du keine Rolle aus, kann jeder Benutzer unabhängig von seiner Rolle diese Datei herunterladen.',
  file_role_restriction_label: 'Rollen Restriktionen',
  file_size: 'Dateigröße',
  file_upload_helper_text: 'Bitte lade eine Datei mit maximal 25 MB hoch *',
  files: 'Dateien',
  firstname: 'Vorname',
  forgot_password: 'Passwort vergessen',
  forgot_password_1: 'Hast Du dein Passwort vergessen?',
  forgot_password_2: 'Wir senden Dir einen Link, um es zurückzusetzen.',

  form_value_confirm_new_email: 'Dieser Wert muss mit der Email übereinstimmen.',
  form_value_confirm_new_password: 'Dieser Wert muss mit Deinem neuen Passwort übereinstimmen.',
  form_value_not_of_old_password: 'Dieser Wert darf nicht mit Deinem alten Passwort übereinstimmen',
  form_value_email: 'Es ist eine gültige Email erforderlich.',
  form_value_min: 'Es ist mindestens {{ number }} Zeichen erforderlich.',
  form_value_min_multi: 'Es sind mindestens {{ number }} Zeichen erforderlich.',
  form_value_postive: 'Es ist eine Zahl größer als 0 erforderlich.',
  form_value_required: 'Ein Wert ist erforderlich.',
  form_value_max: 'Es sind maximal {{ number }} Zeichen erlaubt.',

  get_reset_link_button: 'Email senden',
  general_fields: 'Allgemein',
  general_settings: 'Allgemeine Einstellungen',
  group_restrictions: 'Gruppen Restriktionen',
  group_key_label: 'Name',
  invite_button: 'Einladen',
  invite_user: 'Benutzer einladen',
  invite_unit_select_switcher_description: 'Verantwortliche können Benutzer zu dieser Gruppe einladen?',
  invitation_completed: 'Abgeschlossen',
  invitation_expired: 'Abgelaufen {{ date }}',
  language_label: 'Sprache',
  lastname: 'Nachname',
  last_modified: 'Zuletzt geändert',
  last_update_at: 'Zuletzt bearbeitet am',
  last_login_at: 'Letzter Login',
  layout_blocks: 'Layout',
  learning_congrats: 'Herzlichen Glückwunsch!',
  learning_feedback: 'Danke für dein Feedback!',
  learning_not_passed: 'Schade! Du hast leider nicht bestanden.',
  learning_rating_question: 'Wie hat Dir die Lesson gefallen?',
  lessons: 'Lessons',
  lessons_button: 'Lessons',
  lesson_content_answer_label: 'Antwort',
  lesson_content_key: 'Bezeichner',
  lesson_content_key_label: 'Bezeichner',
  lesson_content_key_label_helper_text: 'Der Bezeichern ist nur für Admins sichtbar',
  lesson_content_question_label: 'Frage',
  lesson_content_question_label_helper_text: '',
  lesson_content_index_label: 'Index',
  lesson_content_index_label_helper_text: 'Bestimmt die Reihenfolge des Lesson Contents',
  lesson_content_instruction_label: 'Anweisung',
  lesson_content_type: 'Content Typ',
  lesson_contents: 'Lesson Contents',
  lesson_contents_button: 'Lesson Contents',
  lesson_duration_helper_text: 'Gebe hier die Dauer der Lesson in Minuten an',
  lesson_key: 'Lesson',
  lesson_key_label: 'Bezeichner',
  lesson_key_label_helper_text: 'Der Bezeichner ist nur für Admins sichtbar.',
  lesson_sortIndex: 'Index',
  lesson_index_label: 'Index',
  lesson_index_label_helper_text: 'Bestimmt die Reihenfolge der Lesson.',
  lesson_title_label: 'Titel',
  lesson_title_label_helper_text: ' ',
  lesson_title_in_locale: 'Titel in {{ locale }}',
  lesson_group_restriction_caption:
    'Wähle hier die Gruppe(n) aus, die ein Benutzer besitzen muss, damit er diese Lesson bearbeiten darf. Sofern ein Benutzer keine der augewählten Gruppen zugewiesen ist, ist diese Lesson für ihn verborgen. Besitzt ein Benutzer mehrere Gruppen ist die Übereinstimmung mit einer gewählten Gruppe ausreichend. Wählst Du keine Gruppen aus, kann jeder Benutzer unabhängig von seinen zugewiesenen Gruppen diese Lesson bearbeiten.',
  lesson_pre_condition_caption:
    'Wähle hier die Lesson aus, die ein Benutzer bestehen muss damit er diese Lesson bearbeiten darf. Solange die markierten Lessons nicht bestanden sind, ist diese Lesson für den Benutzer sichtbar aber gesperrt.',
  lesson_role_restriction_caption:
    'Wähle hier die Rolle(n) aus, die ein Benutzer besitzen muss, damit er diese Lesson bearbeiten darf. Sofern ein Benutzer keine der augewählten Rollen besitzt ist diese Lesson für ihn verborgen. Wählst Du keine Rolle aus, kann jeder Benutzer unabhängig von seiner Rolle diese Lesson bearbeiten.',
  lesson_group_restriction_label: 'Gruppen Restriktionen',
  lesson_role_restriction_label: 'Rollen Restriktionen',
  loading_progress: 'wird geladen ...',
  locale_de: 'Deutsch',
  locale_en: 'Englisch',
  media_library: 'Mediengalerie',
  media_building_block: 'Mediendatei',
  min_password: 'Das Passwort benötigt mindestens 6 Zeichen.',
  mt_add_remove_columns: 'Wähle die Spalten aus, die in der Tabelle angezeigt werden sollen.',
  mt_first_page: 'Erste Seite',
  mt_last_page: 'Letzte Seite',
  mt_next_page: 'Nächste Seite',
  mt_no_data: 'Keine Daten vorhanden',
  mt_of: 'von',
  mt_previous_page: 'Vorherige Seite',
  mt_rows: 'Zeilen',
  mt_rows_per_page: 'Ergebnisse pro Seite',
  mt_search: 'Suche',
  new_email_label: 'Neue Email',
  new_password_label: 'Neues Passwort',
  news_created_at: 'Erstellt am',
  news_last_update_at: 'Stand:',
  news_caption_label: 'Caption',
  news_caption_label_helper_text: 'News caption (max. 1000 Zeichen)',
  news_key_label: 'Bezeichner',
  news_key_label_helper_text: 'Der Bezeichner ist nur für Admins sichtbar.',
  news_title_label: 'Titel',
  no_content_available: 'Es ist kein Content für die Sprache {{locale}} vorhanden',
  no_steps_available: 'Kein Lesson Content vorhanden',
  no_content_for_locale: 'Kein Content vorhanden',
  not_allowed_page: 'Du bist nicht berechtigt {{ pathname }} zu öffnen.',
  notifications: 'Du hast {{number}} Benachrichtigungen',
  no_notifications: 'Es sind keine Benachrichtigungen vorhanden!',
  page_not_found: 'Seite {{ pathname }}konnte nicht gefunden werden',
  preconditions: 'Voraussetzungen',
  preview_button: 'Vorschau',
  preview: 'Vorschau',
  preview_file_button: 'Vorschau',
  preview_mode_button: 'Vorschaumodus',
  publish_this_course: 'Kurs veröffentlichen?',
  publish_this_file: 'Datei veröffentlichen?',
  publish_this_lesson: 'Lesson veröffentlichen?',
  publish_this_lesson_content: 'Lesson Content veröffentlichen?',
  publish_this_news: 'News veröffentlichen?',
  remove_course_logo_button: 'Logo entfernen',
  remove_file_button: 'Datei entfernen',
  restrictions: 'Restriktionen',
  resources: 'Ressourcen',
  resend_invitation: 'Erneut einladen',
  refresh_page: 'Erneut versuchen',
  reauthenticate_error_wrong_password: 'Falsches Passwort. Bitte gebe Dein aktuelles Passwort ein.',
  role_restrictions: 'Rollen Restriktionen',
  role_key_label: 'Name',
  role_resources_selection_label: 'Ressourcen',
  role_selectable_in_restrictions_switcher_description:
    'Verantwortliche können diese Rolle für Restriktionen verwenden?',
  select_color_button: 'Farbe wählen',
  select_file_button: 'Datei wählen',
  select_building_block: 'Wähle einen Baustein aus',
  status_passed: 'Bestanden',
  status_update: 'Aktualisierung',
  settings: 'Einstellungen',
  select_building_item: 'Item hinzufügen',
  selectable_in_unit_invitation: 'Auswählbar in Einladungen',
  selectable_in_restrictions: 'Auswählbar in Restriktionen',
  slide_not_supported: 'Dieser Slide wird in der aktuellen brainee Version nicht unterstützt.',
  sign_in_button: 'Anmelden',
  sorting_question_instruction: 'Tippe die Antworten in der richtigen Reihenfolge an',
  text_building_block: 'Text',
  two_column_layout_block: 'Zwei-Spalten-Layout',
  three_column_layout_block: 'Drei-Spalten-Layout',
  try_again_button: 'Erneut versuchen',
  unit_internalId_label: 'Identifier',
  unit_key_label: 'Name',
  update_course_button: 'Kurs aktualisieren',
  update_button: 'Aktualisieren',
  update_file_button: 'Datei aktualisieren',
  update_group_dialog_title: 'Gruppe aktualisieren',
  update_role_dialog_title: 'Rolle aktualisieren',
  update_news_button: 'News aktualisieren',
  update_lesson_content_button: 'Lesson Content aktualisieren',
  upload_media_file: 'Lade ein Bild oder ein Video hoch',
  update_email_settings: 'Email aktualisieren',
  update_password_settings: 'Passwort aktualisieren',
  update_unit_dialog_title: 'Unit aktualisieren',
  upload_file: 'Datei hochladen',
  uploading_file: 'Datei wird hochgeladen ...',
  uploading_file_completed: '{{ completed }} % abgeschlossen',
  update_user_dialog_title: 'Benutzer aktualisieren',
  update_lesson_button: 'Lesson aktualisieren',
  user_current_locale_label: 'Sprache',
  user_groups_label: 'Gruppen',
  user_units_label: 'Units',
  user_role_label: 'Rolle',
  user_invitation_label: 'Einladung',
  valid_email: 'Bitte trage eine gültige Email ein.',
  view_all_news_button: 'Zeige alle',
  welcome_back: 'Willkommen zurück',
};

export default de;
