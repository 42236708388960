import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  selected: {},
  addFab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  bottomToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 2, 1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 1),
    },
  },
  caption: {
    padding: theme.spacing(2, 2, 1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 1),
    },
  },
  cells: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  defaultBackground: {
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    padding: 0,
  },
  horizontalMargin: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3),
    },
  },
  pagination: {
    border: 0,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  paginationPadding: {},
  title: {
    flexGrow: 1,
  },
  titleBar: {
    display: 'flex',
    padding: theme.spacing(1, 2, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 3, 0),
    },
  },
  toolbar: {
    minHeight: 56,
  },
}));

export { default as Table } from './Table';
