import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Paper, useTheme } from '@material-ui/core';

import { RobotoBlack } from 'components/Common/Roboto/Black';
import { useGlobalStyles } from 'providers/Theme';

export default function SettingsFormPaper({ children, title }: { children: ReactNode; title: string }) {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();

  return (
    <Paper className={clsx([globalClasses.horizontalPadding, globalClasses.verticalPadding])}>
      <RobotoBlack style={{ marginBottom: theme.spacing(2) }}>{title}</RobotoBlack>
      {children}
    </Paper>
  );
}
