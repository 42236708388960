import React, { useContext } from 'react';
import { ILessonContentStateData } from 'interfaces';
import { Lesson, LessonContent, LessonContentBase, LessonContentChoice, LessonContentSorting } from 'models';
import { firestore } from 'configuration/firebase';
import { onFirestore, onError, onSuccess } from 'utils/logger';
import { DataContext } from 'providers/Data';

export default function useLessonContents(brandPath: string, isLearningContentAdmin: boolean, lesson?: Lesson) {
  const { adminLessonContents } = useContext(DataContext);
  const lessonContentsPath = firestore.doc(brandPath).collection(LessonContentBase.collection).path;
  const lessonId = lesson ? lesson.id : null;

  const [state, setState] = React.useState<ILessonContentStateData>({
    lessonContentError: { status: false, message: '' },
    lessonContentData: [],
    lessonContentLoading: true,
  });

  React.useEffect(() => {
    if (lessonId) {
      if (!isLearningContentAdmin) {
        onFirestore('get', lessonContentsPath);
        firestore
          .collection(lessonContentsPath)
          .where('published', '==', true)
          .where('lesson', '==', lessonId)
          .orderBy('sortIndex', 'asc')
          .get()
          .then(snapshot => {
            onSuccess(lessonContentsPath, 'get-success', `${snapshot.docs.length.toString()}`);
            const lessonContentData = snapshot.docs.map(doc => {
              const data = doc.data();
              switch (data.type) {
                case 'choice': {
                  return new LessonContentChoice({ ...data, id: doc.id });
                }
                case 'sorting': {
                  return new LessonContentSorting({ ...data, id: doc.id });
                }
                default:
                  return new LessonContent({ ...data, id: doc.id });
              }
            });
            setState(prevState => ({ ...prevState, lessonContentLoading: false, lessonContentData }));
          })
          .catch(error => {
            const message = 'Lesson content data could not be loaded.';
            console.error('Error: ', message);
            setState(prevState => ({
              ...prevState,
              lessonContentError: { status: true, message },
              lessonContentLoading: false,
            }));
            onError('Lesson Content', error);
          });
      } else {
        setState(prevState => ({
          ...prevState,
          lessonContentLoading: false,
        }));
      }
    }
  }, [isLearningContentAdmin, lessonContentsPath, lessonId]);

  return {
    ...state,
    lessonContentData: isLearningContentAdmin
      ? adminLessonContents
          .filter(lessonContent => lessonContent.published)
          .filter(lessonContent => !!lesson && lessonContent.lesson === lesson.id)
          .sort((a, b) => a.sortIndex - b.sortIndex)
      : state.lessonContentData
          .filter(lessonContent => !!lesson && lessonContent.lesson === lesson.id)
          .sort((a, b) => a.sortIndex - b.sortIndex),
  };
}
