import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import useBreakpoints from 'hooks/layout/useBreakpoints';

const ScrollToError = ({ preAction }: { preAction?: () => void }) => {
  const { isSubmitting, isValidating, errors } = useFormikContext();
  const { xs } = useBreakpoints();

  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[id="${keys[0]}"]`);

      if (preAction !== undefined) {
        preAction();
      }

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          window.scrollBy(0, xs ? -124 : -140); /* fixed header size */
        }, 100);
      }
    }
  }, [isSubmitting, isValidating, errors, xs, preAction]);

  return null;
};

export default ScrollToError;
