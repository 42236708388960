import React from 'react';
import { FormControl, InputLabel, Select, Chip, MenuItem, FormHelperText, useTheme } from '@material-ui/core';
import { getStyles, useStyles } from '.';

export default function SelectWIthChipsField({
  caption,
  dense,
  disabled,
  error,
  handleChange,
  label,
  name,
  required,
  selectedValues,
  values,
}: {
  caption?: string | string[];
  dense?: boolean;
  disabled?: boolean;
  error?: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  label?: string;
  required?: boolean;
  name: string;
  selectedValues: string[];
  values: Array<{ label: string; value: string }>;
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth
      hiddenLabel={false}
      id={`${name}`}
      required={required}
      size={dense ? 'small' : undefined}
      variant='outlined'
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        label={label}
        multiple
        variant='outlined'
        value={selectedValues}
        onChange={handleChange}
        name={name}
        renderValue={selected => (
          <div className={classes.chips}>
            {(selected as string[]).map(value => (
              <Chip
                key={value}
                label={values.find(item => item.value === value)?.label || value}
                className={classes.chip}
                size='small'
              />
            ))}
          </div>
        )}
        SelectDisplayProps={{
          style:
            selectedValues.length > 0 ? (dense ? { paddingTop: 10.5, paddingBottom: 2 } : { paddingBottom: 8 }) : {},
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4 + 8,
            },
          },
        }}
      >
        {values
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(item => (
            <MenuItem key={item.value} value={item.value} style={getStyles(item.value, selectedValues, theme)}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{caption}</FormHelperText>
    </FormControl>
  );
}
