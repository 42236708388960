import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { AdminCourseDetailGeneralPage } from './General';
import { AdminCourseDetailLessonsPage } from './Lessons';
import { AdminCourseDetailRestrictionsPage } from './Restrictions';
import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { NotFoundView } from 'views/NotFound';
import { VerticalTab } from 'components/Layout/Navigation/VerticalTab';
import { VerticalTabs } from 'components/Layout/Navigation/VerticalTabs';
import { useStyles } from '.';

export default function AdminCourseDetailPage({ path }: { path: string }) {
  const classes = useStyles();
  const history = useHistory();
  const { courseId }: any = useParams();
  const { xs } = useBreakpoints();
  const { t } = useTranslation();

  const pathWithId = path + '/course/' + courseId;

  const items = [
    { label: '/general', path: pathWithId + '/general' },
    { label: '/restrictions', path: pathWithId + '/restrictions' },
    { label: '/lessons', path: pathWithId + '/lessons' },
  ];

  const [value, setValue] = React.useState(
    items.map(item => item.path).includes(history.location.pathname) ? history.location.pathname : false
  );

  const handleChange = (newValue: any) => {
    history.push(newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(history.location.pathname);
  }, [history.location.pathname]);

  const { courses } = useContext(DataContext);

  const course = courses.admin.find(course => course.id === courseId);

  if (course === undefined) {
    return <NotFoundView />;
  }

  return (
    <Layout
      defaultHorizontalMainPadding
      mainStyle={{ display: 'flex', flexDirection: xs ? 'column' : 'row' }}
      navigation={{ type: 'admin' }}
      subHeader={{ courseId: course.id, type: 'admin-learning-item-detail' }}
    >
      <VerticalTabs onChange={(_, value) => handleChange(value)} value={value}>
        {items.map(({ path, label }) => (
          <VerticalTab key={path} label={t(label)} value={path} />
        ))}
      </VerticalTabs>
      <Switch>
        <Route exact path={pathWithId}>
          <Redirect to={`${pathWithId}/general`} />
        </Route>
        <Route exact path={`${pathWithId}/general`}>
          <div className={classes.container}>
            <AdminCourseDetailGeneralPage course={course} />
          </div>
        </Route>
        <Route exact path={`${pathWithId}/restrictions`}>
          <div className={classes.container}>
            <AdminCourseDetailRestrictionsPage course={course} />
          </div>
        </Route>
        <Route exact path={`${pathWithId}/lessons`}>
          <div className={classes.container}>
            <AdminCourseDetailLessonsPage course={course} />
          </div>
        </Route>
        <Route path='*'>
          <div>404</div>
        </Route>
      </Switch>
    </Layout>
  );
}
