import React, { ReactNode, useReducer } from 'react';
import { Notification } from 'components/Common/Notification';
import { NotificationContext, reducer, initialState } from '.';

export default function NotificationProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (state.notifications.length > 3) {
    const removedItems = state.notifications.splice(0, 1);
    dispatch({ type: 'REMOVE_NOTIFICATION', payload: removedItems[0].id });
  }

  function renderNotification() {
    return state.notifications.map((notification, index) => (
      <Notification dispatch={dispatch} key={index} notification={notification} />
    ));
  }

  return (
    <>
      {renderNotification()}
      <NotificationContext.Provider value={{ state, dispatch }}>{children}</NotificationContext.Provider>
    </>
  );
}
