import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Brand, LessonContent, LessonContentChoice, LessonContentSorting } from 'models';
import { ChoiceSlide } from './Questions/Choice';
import { DataContext } from 'providers/Data';
import { ErrorView } from 'views/Error';
import { SortingSlide } from './Questions/Sorting';
import { useStyles } from '.';
import { ContentItemComponent } from './Content';

export default function Slide({
  lessonContent,
}: {
  lessonContent: LessonContent | LessonContentChoice | LessonContentSorting;
}) {
  const brand = useContext(DataContext).brand as Brand;
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const classes = useStyles();

  const { type } = lessonContent;

  if (type === 'content') {
    const content =
      (lessonContent as LessonContent).localizedFields.content[language] ||
      (lessonContent as LessonContent).localizedFields.content[brand.configuration.fallbackLocale];
    return (
      <div className={classes.contentContainer}>
        <Box color='textSecondary'>
          {content.map(item => (
            <ContentItemComponent key={item.id} item={item} />
          ))}
        </Box>
      </div>
    );
  }

  if (type === 'choice' || type === 'sorting') {
    return (
      <div className={classes.contentContainer}>
        {type === 'choice' && (
          <ChoiceSlide
            fallbackLocale={brand.configuration.fallbackLocale}
            currentLocale={language}
            lessonContent={lessonContent as LessonContentChoice}
          />
        )}
        {type === 'sorting' && (
          <SortingSlide
            currentLocale={language}
            fallbackLocale={brand.configuration.fallbackLocale}
            lessonContent={lessonContent as LessonContentSorting}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes.center}>
      <ErrorView disableLayout msg={t('slide_not_supported')} />
    </div>
  );
}
