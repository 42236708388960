import { createContext } from 'react';
import { makeStyles } from '@material-ui/core';

export const layoutWidth = 1280; // material ui breakpoints lg

export const ThemeContext = createContext<{ toggleMode: () => void; mode: 'dark' | 'light' | undefined }>({
  toggleMode: () => {},
  mode: 'light',
});

export const useGlobalStyles = makeStyles(theme => ({
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  checkboxLabels: {
    fontSize: 14,
  },
  customLink: {
    textTransform: 'none',
    textDecoration: 'none',
    minWidth: 0,
    maxWidth: 'fit-content',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
    },
  },
  dividerMarginBottom: {
    marginBottom: theme.spacing(3),
  },
  dividerMarginTop: {
    marginTop: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  layout: {
    maxWidth: layoutWidth,
    [theme.breakpoints.up(layoutWidth)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: layoutWidth,
    },
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  verticalPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  seperator: {
    margin: theme.spacing(0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  noSelect: {
    WebkitTouchCallout: 'none' /* iOS Safari */,
    WebkitUserSelect: 'none' /* Safari */,
    KhtmlUserSelect: 'none' /* Konqueror HTML */,
    MozUserSelect: 'none' /* Old versions of Firefox */,
    msUserSelect: 'none' /* Internet Explorer/Edge */,
    userSelect: 'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
  },
}));

export { ThemeProvider } from './ThemeProvider';
