import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  pointer: {
    cursor: 'pointer',
  },
  large: {
    margin: theme.spacing(3, 1),
    maxWidth: theme.spacing(12),
  },
  medium: {
    margin: theme.spacing(2, 1),
    maxWidth: theme.spacing(6),
  },
  small: {
    margin: theme.spacing(0, 0),
    maxWidth: theme.spacing(4),
  },
  extraLarge: {
    margin: theme.spacing(0, 0, 4),
    maxWidth: theme.spacing(28),
  },
  big: {
    margin: theme.spacing(0, 0, 4),
    maxWidth: theme.spacing(40),
  },
}));

export { default as Logo } from './Logo';
