import { createContext } from 'react';

import { IUserState } from 'interfaces';
import { User } from 'models';

export const initialState: IUserState = {
  user: {
    data: undefined,
    loading: true,
  },
  error: {
    status: false,
    message: '',
  },
};

export const UserContext = createContext<{ user?: User }>({ user: undefined });

export { default as UserProvider } from './UserProvider';
