import React from 'react';
import { Container, TextField, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { AvatarCard } from 'components/Cards/AvatarCard';
import { Layout } from 'components/Layout';
import { LoadingButton } from 'components/Buttons/Loading';
import { User } from 'models';
import { useGlobalStyles } from 'providers/Theme';
import { useStyles, validationSchema } from '.';

export default function RegistrationView({ user }: { user: User }) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();

  let initialValues = {
    firstname: '',
    lastname: '',
  };

  if (user.settings && user.settings.firstname && user.settings.lastname) {
    initialValues['firstname'] = user.settings.firstname;
    initialValues['lastname'] = user.settings.lastname;
  }

  return (
    <Layout disableHeader verticalCentered>
      <Container maxWidth='sm'>
        <div className={globalClasses.paper}>
          <AvatarCard user={user} />
          <Formik
            validateOnBlur
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async ({ firstname, lastname }, { setSubmitting }) => {
              try {
                await user.completeRegistration(firstname, lastname);
              } catch (err) {
                notify(t(`${err.message || err}`), 'error');
                setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form className={globalClasses.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  autoComplete='firstname'
                  disabled={isSubmitting}
                  error={!!errors.firstname && touched.firstname}
                  fullWidth
                  helperText={!!errors.firstname && touched.firstname && errors.firstname ? errors.firstname : ' '}
                  FormHelperTextProps={{
                    style: {
                      minHeight: 19,
                    },
                  }}
                  id='firstname'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label={t('firstname')}
                  margin='none'
                  name='firstname'
                  required
                  type='text'
                  value={values.firstname}
                  variant='outlined'
                />
                <TextField
                  autoComplete='lastname'
                  disabled={isSubmitting}
                  color='primary'
                  error={!!errors.lastname && touched.lastname}
                  fullWidth
                  helperText={!!errors.lastname && touched.lastname && errors.lastname ? errors.lastname : ' '}
                  FormHelperTextProps={{
                    style: {
                      minHeight: 19,
                    },
                  }}
                  id='lastname'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label={t('lastname')}
                  margin='none'
                  name='lastname'
                  required
                  style={{ marginTop: theme.spacing(1) }}
                  type={'text'}
                  value={values.lastname}
                  variant='outlined'
                />
                <div className={classes.container}>
                  <LoadingButton
                    className={globalClasses.submit}
                    color='primary'
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    {t('complete_registration_button')}
                  </LoadingButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
}
