import React, { useContext, useState } from 'react';
import { Link, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { Brand, News } from 'models';
import { Layout } from 'components/Layout';
import { Table } from 'components/Common/Table';
import { firestore } from 'configuration/firebase';

export default function AdminNewsPage() {
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();

  const { brand, news } = useContext(DataContext);
  const { t } = useTranslation();

  const [deleteState, setDeleteState] = useState<{
    dialogOpen: boolean;
    loading: boolean;
    name: string | null;
    news?: News;
  }>({
    dialogOpen: false,
    loading: false,
    news: undefined,
    name: null,
  });

  const onAddClick = () => history.push('/admin/news/new-news');

  const handleOpenDeleteDialog = (id: string) => {
    const newsItem = news.admin.find(item => item.id === id);

    if (!newsItem) {
      notify(t('alert_news_deletion_error'), 'error');
    } else {
      setDeleteState({ news: newsItem, dialogOpen: true, loading: false, name: newsItem.key });
    }
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const copy = { ...deleteState };

    if (copy.news) {
      try {
        await firestore
          .collection(Brand.collection)
          .doc(brand?.id)
          .collection(News.collection)
          .doc(copy.news.id)
          .delete();
        notify(t('alert_news_deletion_success'));
      } catch (err) {
        console.error(err);
        notify(t('alert_news_deletion_error'), 'error');
      }
    } else {
      notify(t('alert_news_deletion_error'), 'error');
    }

    setDeleteState({ news: undefined, loading: false, name: null, dialogOpen: false });
  };

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_news_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ news: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_news_confirmation_dialog_title')}
        type='delete'
      />
      <Layout defaultHorizontalMainPadding navigation={{ type: 'admin' }}>
        <Table
          columns={[
            {
              field: 'name',
              title: 'Name',
              cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 280 },
            },
            {
              field: 'published',
              title: 'Status',
              type: 'string',
              render: ({ published }: any) =>
                published ? 'Published' : <span style={{ color: theme.palette.error.light }}>Unpublished</span>,
            },
            {
              defaultSort: 'desc',
              field: 'createdAt',
              title: t('news_created_at'),
              type: 'date',
            },
            {
              defaultSort: 'desc',
              field: 'lastUpdateAt',
              title: t('last_modified'),
              type: 'date',
            },
            {
              field: 'actions',
              sorting: false,
              searchable: false,
              disableClick: true,
              align: 'right',
              render: ({ id }: any) => (
                <Link color='error' component='button' onClick={() => handleOpenDeleteDialog(id)} underline='none'>
                  {t('delete_news_button')}
                </Link>
              ),
            },
          ]}
          data={news.admin.map(newsItem => ({
            createdAt: newsItem.createdAt ? newsItem.createdAt.toDate() : t('loading_progress'),
            id: newsItem.id,
            lastUpdateAt: newsItem.lastUpdateAt ? newsItem.lastUpdateAt.toDate() : t('loading_progress'),
            name: newsItem.key,
            published: newsItem.published,
          }))}
          onAddClick={onAddClick}
          onRowClick={(event, data: any) => history.push(`/admin/news/update-news/${data.id}`)}
          title={t('/news')}
        />
      </Layout>
    </>
  );
}
