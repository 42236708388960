import React from 'react';
import clsx from 'clsx';
import MaterialTable, { MaterialTableProps, MTableBody, MTableCell, MTableToolbar } from 'material-table';
import { Add } from '@material-ui/icons';
import { IconButton, Paper, TablePagination, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useLocalStorage } from 'react-use';

import { useMaterialTableTranslation } from 'hooks/translation/useMaterialTableTranslation';
import { useStyles } from '.';

export default function Table(
  props: MaterialTableProps<object> & {
    caption?: string;
    onAddClick?: () => void;
  }
) {
  const classes = useStyles();
  const localization = useMaterialTableTranslation();
  const theme = useTheme();
  const isSmallDeviceAndBigger = useMediaQuery(theme.breakpoints.up('sm'));
  const isXSmallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const [pageSize, setPageSize] = useLocalStorage('brainee.table.pageSize', isXSmallDevice ? 5 : 10);

  const { caption, onAddClick, title } = props;

  const containerClasses = [classes.paper];

  return (
    <MaterialTable
      components={{
        Body: props => <MTableBody {...props} />,
        Cell: props => <MTableCell className={classes.cells} style={{ whiteSpace: 'nowrap' }} {...props} />,
        Container: props => (
          <Paper className={clsx([...containerClasses])} elevation={2} square {...props}>
            {props.children}
          </Paper>
        ),
        Pagination: props => (
          <>
            <TablePagination
              {...props}
              className={classes.pagination}
              SelectProps={{
                style: {
                  fontSize: theme.typography.fontSize - 2,
                },
              }}
            />
            <td className={classes.paginationPadding} />
          </>
        ),
        Toolbar: props => (
          <>
            {(!!title || !!onAddClick) && (
              <div className={classes.titleBar}>
                <Typography className={classes.title} style={{ paddingTop: 8 }} variant='h6'>
                  {title}
                </Typography>
                {!!onAddClick && (
                  <IconButton onClick={onAddClick}>
                    <Add />
                  </IconButton>
                )}
              </div>
            )}
            <MTableToolbar {...props} classes={{ root: classes.toolbar }} />

            {caption !== undefined && (
              <Typography
                className={classes.caption}
                color='textSecondary'
                component='p'
                variant='caption'
                style={{ paddingTop: 8 }}
              >
                {caption}
              </Typography>
            )}
          </>
        ),
      }}
      localization={localization}
      options={{
        columnResizable: false,
        draggable: false,
        headerStyle: {
          padding: isSmallDeviceAndBigger ? theme.spacing(2, 3) : theme.spacing(2, 2),
          whiteSpace: 'nowrap',
        },
        emptyRowsWhenPaging: false,
        paginationType: 'normal',
        pageSize,
        pageSizeOptions: [5, 10, 25, 50, 100],
        paginationPosition: 'bottom',
        rowStyle: { fontSize: theme.typography.fontSize - 1 },
        searchFieldAlignment: 'left',
        showTitle: false,
        sorting: true,
        tableLayout: 'auto',
        ...props.options,
      }}
      onChangeRowsPerPage={size => setPageSize(size)}
      {...props}
    />
  );
}
