import React from 'react';
import { ContentItemComponent } from 'components/Slide/Content';
import { ITwoColumnLayoutItem } from 'interfaces';
import { makeStyles } from '@material-ui/core';

export default function LayoutTwoColumnItem({
  item,
  isPreviewMode,
  previewSize,
}: {
  item: ITwoColumnLayoutItem;
  isPreviewMode?: boolean;
  previewSize?: number;
}) {
  const classes = useStyles();

  let wrapperStyles: React.CSSProperties | undefined = undefined;

  if (isPreviewMode && previewSize) {
    wrapperStyles = {
      display: 'flex',
      flexDirection: previewSize >= 600 ? 'row' : 'column',
    };
  }

  return (
    <div className={isPreviewMode ? undefined : classes.wrapper} style={wrapperStyles}>
      <div className={classes.column}>
        {item.columns.left.map(leftItem => (
          <div key={leftItem.id}>
            <ContentItemComponent item={leftItem} />
          </div>
        ))}
      </div>
      <div className={classes.column}>
        {item.columns.right.map(rightItem => (
          <div key={rightItem.id}>
            <ContentItemComponent item={rightItem} />
          </div>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexBasis: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}));
