import React from 'react';
import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined } from '@material-ui/icons';
import { Divider, useTheme } from '@material-ui/core';
import { ContentState, Editor as DraftJSEditor, EditorState, Modifier, RichUtils } from 'draft-js';
import { ToggleButton } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { IAdminContentEditorItem } from 'interfaces';
import { StyledToggleButtonGroup, useStyles } from '.';

import 'draft-js/dist/Draft.css';

export default function EditorItem({
  activeLocale,
  calledFromColumn,
  item,
}: {
  activeLocale: string;
  calledFromColumn?: 'left' | 'middle' | 'right';
  item: IAdminContentEditorItem;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (editorState: EditorState) => {
    const copyArr = [...(values as any).localizedFields.content[activeLocale]];

    if (!!item.parent && calledFromColumn !== undefined) {
      const rootIndex = copyArr.findIndex(
        copiedItem => copiedItem.id && item.parent && copiedItem.id === item.parent.id
      );

      if (rootIndex !== -1) {
        const parentArr = copyArr[rootIndex];
        const parentArrColumn = [...parentArr.columns[calledFromColumn]];
        const index = parentArrColumn.findIndex(parentItem => parentItem.id === item.id);

        if (index !== -1) {
          const updatedChildItem = parentArrColumn[index] as IAdminContentEditorItem;
          const updatedChildArr = [...parentArrColumn];

          updatedChildArr[index] = { ...updatedChildItem, content: editorState };
          const updatedArr = [...copyArr];
          updatedArr[rootIndex]['columns'][calledFromColumn] = updatedChildArr;

          setFieldValue(`localizedFields.content.${activeLocale}`, updatedArr);
        }
      }
      return;
    }

    const index = copyArr.findIndex(copiedItem => copiedItem.id === item.id);

    if (index !== -1) {
      const updatedItem = copyArr[index] as IAdminContentEditorItem;
      const updatedArr = [...copyArr];

      updatedArr[index] = { ...updatedItem, content: editorState };
      setFieldValue(`localizedFields.content.${activeLocale}`, updatedArr);
    }
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const selection = item.content.getSelection();
  const currentStyle = item.content.getCurrentInlineStyle();
  const currentContent = item.content.getCurrentContent();
  const blockType = currentContent.getBlockForKey(selection.getStartKey()).getType();

  const handleBlockType = (event: any, value: any) => {
    event.preventDefault();
    if (value === null) {
      handleChange(RichUtils.toggleBlockType(item.content, 'unstyled'));
      return;
    }
    handleChange(RichUtils.toggleBlockType(item.content, value));
  };

  const handleInlineStyle = (event: any, value: any) => {
    event.preventDefault();
    handleChange(RichUtils.toggleInlineStyle(item.content, value));
  };

  let showPlaceholder = false;
  if (!currentContent.hasText()) {
    if (currentContent.getBlockMap().first().getType() === 'unstyled') {
      showPlaceholder = true;
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.styleBar}>
        <StyledToggleButtonGroup
          exclusive
          onChange={(e, value) => handleBlockType(e, value)}
          size='small'
          value={blockType}
        >
          <ToggleButton value='header-three'>
            <div className={classes.icon}>H</div>
          </ToggleButton>
          <ToggleButton value='unordered-list-item'>
            <FormatListBulleted />
          </ToggleButton>
          <ToggleButton value='ordered-list-item'>
            <FormatListNumbered />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation='vertical' className={classes.divider} />
        <StyledToggleButtonGroup size='small' value={currentStyle.toArray()}>
          <ToggleButton onClick={event => handleInlineStyle(event, 'BOLD')} value='BOLD'>
            <FormatBold />
          </ToggleButton>
          <ToggleButton onClick={event => handleInlineStyle(event, 'ITALIC')} value='ITALIC'>
            <FormatItalic />
          </ToggleButton>
          <ToggleButton onClick={event => handleInlineStyle(event, 'UNDERLINE')} value='UNDERLINE'>
            <FormatUnderlined />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </div>

      <div className={classes.content} style={{ margin: 'auto', width: '100%' }}>
        <div style={{ cursor: 'text', lineHeight: 1.75, minHeight: theme.spacing(30) }}>
          <DraftJSEditor
            editorState={item.content}
            handleKeyCommand={handleKeyCommand}
            handlePastedText={(text, html, editorState) => {
              const newContent = ContentState.createFromText(text);
              const blockMap = newContent.getBlockMap();
              const newContentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap
              );
              handleChange(EditorState.push(editorState, newContentState, 'insert-fragment'));
              return 'handled';
            }}
            onChange={handleChange}
            placeholder={showPlaceholder ? t('editor_placeholder') : undefined}
          />
        </div>
      </div>
    </div>
  );
}
