import { Tab as MuiTab, withStyles, Theme, createStyles } from '@material-ui/core';

export const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 0,
      padding: 0,
      maxWidth: 'fit-content',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(3),
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.light,
      },
      '&:focus': {
        color: theme.palette.primary.dark,
      },
    },
    selected: {},
  })
)(MuiTab);

export { default as LocaleTab } from './LocaleTab';
