import { makeStyles } from '@material-ui/core';
import i18n from 'configuration/i18n';
import * as yup from 'yup';

export const useStyles = makeStyles(theme => ({
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export const lessonContentChoiceValidation = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string().required(i18n.t('form_value_required'));
    else {
      localeObject[key] = yup.string();
    }
  });

  const answer = yup.object({
    id: yup.string().required(i18n.t('form_value_required')),
    label: yup.object({ ...localeObject }).noUnknown(),
    value: yup.boolean().required(i18n.t('form_value_required')),
  });

  return yup.object().shape({
    locales: yup.array().of(yup.string()).required(i18n.t('form_value_required')),
    localizedFields: yup.object({
      choice: yup.object({
        answers: yup.array().of(answer).required(i18n.t('form_value_required')),
        question: yup.object({ ...localeObject }).noUnknown(),
      }),
    }),
  });
};

export const lessonContentSortingValidation = (locales: string[], fallbackLocale: string) => {
  const localeObject: any = {};
  locales.forEach(key => {
    if (fallbackLocale === key) localeObject[key] = yup.string().required(i18n.t('form_value_required'));
    else {
      localeObject[key] = yup.string();
    }
  });

  const answer = yup.object({
    id: yup.string().required(i18n.t('form_value_required')),
    label: yup.object({ ...localeObject }).noUnknown(),
    sortIndex: yup.number().required(i18n.t('form_value_required')),
  });

  return yup.object().shape({
    locales: yup.array().of(yup.string()).required(i18n.t('form_value_required')),
    localizedFields: yup.object({
      sorting: yup.object({
        answers: yup.array().of(answer).required(i18n.t('form_value_required')),
        question: yup.object({ ...localeObject }).noUnknown(),
      }),
    }),
  });
};

export { default as AdminLessonContentDetailContentPage } from './AdminLessonContentDetailContentPage';
