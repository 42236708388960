import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(2),
    width: theme.spacing(8),
  },
}));

export { default as ColorPreview } from './ColorPreview';
