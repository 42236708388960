import React, { useContext } from 'react';
import clsx from 'clsx';
import useBreakpoints from 'hooks/layout/useBreakpoints';
import { Avatar, Button, Grid, Paper, Typography, Divider, useTheme } from '@material-ui/core';
import { CircularProgressWithLabel } from 'components/Loading/CircularProgressWithLabel';
import { DataContext } from 'providers/Data';
import { Layout } from 'components/Layout';
import { LessonCard } from 'components/Cards/LessonCard';
import { RobotoBlack } from 'components/Common/Roboto/Black';
import { UserContext } from 'providers/User';
import { useGlobalStyles } from 'providers/Theme';
import { useHistory } from 'react-router-dom';
import { useStyles } from '.';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { brand, courses, lessons, news, userProgress } = useContext(DataContext);
  const { user } = useContext(UserContext);
  const { xs, sm, md } = useBreakpoints();

  const brandHasNews = news.app.length > 0;
  const whatsNextLesson = user?.getWhatsNextLesson(courses.app, lessons.app, userProgress);

  return (
    <Layout defaultHorizontalMainPadding navigation={{ type: 'app' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={brandHasNews ? 8 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={clsx([globalClasses.verticalPadding, globalClasses.horizontalPadding, classes.paper])}>
                {!xs && (
                  <div>
                    <Avatar
                      className={classes.large}
                      src={user && user.settings.photoUrl ? user.settings.photoUrl : undefined}
                    >
                      {user && user.settings.firstname && user.settings.lastname
                        ? user.settings.firstname[0] + user.settings.lastname[0]
                        : undefined}
                    </Avatar>
                  </div>
                )}
                <div className={classes.middle}>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography color='textSecondary' variant='body2'>
                        {t('welcome_back')},
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color='textPrimary' className={classes.name} variant='body2'>
                        {user?.getName()}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.progress}>
                  <CircularProgressWithLabel
                    value={user ? user.getLearningProgressInPercentage(lessons.app, userProgress) : 0}
                  />
                </div>
              </Paper>
            </Grid>
            {lessons.app.length > 0 && whatsNextLesson && (
              <>
                <Grid item xs={12}>
                  <RobotoBlack style={{ fontSize: 16 }}>What`s next?</RobotoBlack>
                </Grid>
                <Grid item xs={12}>
                  <LessonCard
                    currentLocale={language}
                    fallbackLocale={brand?.configuration.fallbackLocale as string}
                    lesson={whatsNextLesson}
                    onClick={() => history.push(`/learning/lesson/${whatsNextLesson.id}`)}
                    showEnterButton
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {brandHasNews && (
          <Grid item xs={12} md={4}>
            <Paper className={clsx([globalClasses.verticalPadding, globalClasses.horizontalPadding])}>
              <RobotoBlack style={{ fontSize: 16 }}>{t('announcements_label')}</RobotoBlack>
              {news.app.slice(0, 3).map(newsItem => (
                <div
                  className={classes.newsItem}
                  key={newsItem.id}
                  onClick={() => history.push(`/news/${newsItem.id}`)}
                >
                  <RobotoBlack style={{ fontSize: 14, marginBottom: 4 }}>
                    {newsItem.getTitle(language, brand?.configuration.fallbackLocale as string)}
                  </RobotoBlack>
                  <Typography
                    color='textSecondary'
                    style={{ fontSize: theme.typography.fontSize - 2, lineHeight: 1.15 }}
                  >
                    {xs || sm || md
                      ? newsItem.getCaption(language, brand?.configuration.fallbackLocale as string)
                      : newsItem.getCaption(language, brand?.configuration.fallbackLocale as string, 175)}
                  </Typography>
                  <Divider className={classes.divider} />
                </div>
              ))}
              <Typography color='textSecondary' style={{ width: '100%' }}>
                <Button
                  color='inherit'
                  fullWidth
                  style={{ marginTop: theme.spacing(2), textTransform: 'none' }}
                  onClick={() => history.push('/news')}
                  size='small'
                  variant='outlined'
                >
                  {t('view_all_news_button')}
                </Button>
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
