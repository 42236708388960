import React from 'react';
import { IUnitUsersDataState } from 'interfaces';
import { User } from 'models';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onError, onListener } from 'utils/logger';

export default function useUnitUsers(brandId: string, isUnitAdmin: boolean, isUserAdmin: boolean, unitId?: string) {
  const usersPath = firestore.collection(User.collection).path;

  const [state, setState] = React.useState<IUnitUsersDataState>({
    usersData: [],
    usersError: { message: '', status: false },
    usersLoading: true,
  });

  React.useEffect(() => {
    if (isUnitAdmin && !isUserAdmin && unitId) {
      const collectionRef = firestore.collection(usersPath);

      const unsubscribe = collectionRef
        .where('brand', '==', brandId)
        .where('units', 'array-contains', unitId)
        .onSnapshot(
          snapshot => {
            snapshot.docChanges().forEach((change, i) => {
              onListener(i, snapshot.docs.length, 'User', change.doc.id, change.oldIndex, change.newIndex, change.type);
            });
            const users = snapshot.docs.map(doc => new User(addIdToDocument(doc)));
            setState(prevState => ({
              ...prevState,
              usersData: users,
              usersLoading: false,
            }));
          },
          error => {
            const message = 'Users data could not be loaded.';
            console.error('Error: ', message);
            setState(prevState => ({ ...prevState, usersError: { status: true, message }, usersLoading: false }));
            onError('Users', error);
          }
        );
      return () => {
        unsubscribe();
      };
    }
  }, [brandId, isUnitAdmin, isUserAdmin, unitId, usersPath]);

  return { ...state };
}
