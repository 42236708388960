import React from 'react';
import { IRolesDataState } from 'interfaces';
import { Role } from 'models';
import { addIdToDocument } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useRoles(
  brandPath: string,
  isFileAdmin: boolean,
  isLearningContentAdmin: boolean,
  isUserAdmin: boolean
) {
  const [state, setState] = React.useState<IRolesDataState>({
    rolesError: { status: false, message: '' },
    rolesData: {
      brand: [],
      default: [],
    },
    rolesLoading: {
      brand: true,
      default: true,
    },
  });
  const rolesPath = firestore.doc(brandPath).collection(Role.collection).path;
  const defaultRolesPath = firestore.collection(Role.collection).path;

  React.useEffect(() => {
    if (isFileAdmin || isLearningContentAdmin || isUserAdmin) {
      onFirestore('onSnapshot', rolesPath);
      const unsubscribe = firestore.collection(rolesPath).onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(i, snapshot.docs.length, 'Roles', change.doc.id, change.oldIndex, change.newIndex, change.type);
          });
          const roles = snapshot.docs.map(doc => new Role(addIdToDocument(doc)));
          setState(prevState => ({
            ...prevState,
            rolesData: { ...prevState.rolesData, brand: roles },
            rolesLoading: {
              ...prevState.rolesLoading,
              brand: false,
            },
          }));
        },
        error => {
          const message = 'Roles data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({
            ...prevState,
            rolesError: { status: true, message },
            rolesLoading: { ...prevState.rolesLoading, brand: false },
          }));
          onError('Roles', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, rolesLoading: { ...prevState.rolesLoading, brand: false } }));
    }
  }, [isFileAdmin, isLearningContentAdmin, isUserAdmin, rolesPath]);

  React.useEffect(() => {
    if (isUserAdmin) {
      onFirestore('onSnapshot', defaultRolesPath);
      const unsubscribe = firestore.collection(defaultRolesPath).onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(i, snapshot.docs.length, 'Roles', change.doc.id, change.oldIndex, change.newIndex, change.type);
          });
          const roles = snapshot.docs.map(doc => new Role(addIdToDocument(doc)));
          setState(prevState => ({
            ...prevState,
            rolesData: { ...prevState.rolesData, default: roles },
            rolesLoading: {
              ...prevState.rolesLoading,
              default: false,
            },
          }));
        },
        error => {
          const message = 'Roles data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({
            ...prevState,
            rolesError: { status: true, message },
            rolesLoading: {
              ...prevState.rolesLoading,
              default: false,
            },
          }));
          onError('Roles', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({ ...prevState, rolesLoading: { ...prevState.rolesLoading, default: false } }));
    }
  }, [isUserAdmin, defaultRolesPath]);

  return { ...state };
}
