import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { Brightness2, Close, CloudUpload, Flare, PlayArrow } from '@material-ui/icons';
import {
  AppBar,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, User } from 'models';
import { DataContext } from 'providers/Data';
import { onInfo, onStorage } from 'utils/logger';
import { storage } from 'configuration/firebase';
import { ThemeContext, useGlobalStyles } from 'providers/Theme';
import { useStyles } from '.';
import { UserContext } from 'providers/User';

export default function MediaFilesDialog({
  filter,
  handleClose,
  selectFile,
  open,
}: {
  filter?: 'images';
  handleClose: () => void;
  selectFile: (file: any) => void;
  open: boolean;
}) {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const notify = useNotification();
  const user = useContext(UserContext).user as User;
  const { mode, toggleMode } = useContext(ThemeContext);
  const { t } = useTranslation();

  const listAllError = t('error_list_storage_files').toString();

  const [state, setState] = React.useState<{ error: string; files: Array<any & { url: string }>; loading: boolean }>({
    error: '',
    files: [],
    loading: true,
  });
  const [upload, setUpload] = React.useState({ completed: 0, loading: false });

  const handleSelect = (file: any) => {
    selectFile(file);
    handleClose();
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      console.error('Files could not be found ...');
      notify(t('default_error_message'), 'error');
      return;
    }

    const file = files[0];

    if (!file) {
      console.error('Single file could not be found...');
      notify(t('default_error_message'), 'error');
      return;
    }

    const { name, type } = file;

    const fileNamesArr = state.files.map(file => file.name);

    const splittedNameArr = name.split('.');
    const splittedNameArrLength = splittedNameArr.length;

    const fileEnding =
      splittedNameArrLength === 0
        ? type.split('/')[1]
        : splittedNameArr[splittedNameArrLength - 1]; /** Documentation needed */

    let fallback = 2;
    let usedName = name;

    onInfo('MediaLibraryDialog', 'File Ending: ' + fileEnding);
    onInfo('MediaLibraryDialog', 'Mime Type: ' + file.type);

    while (fileNamesArr.includes(usedName)) {
      onInfo('MediaLibraryDialog', `The name ${usedName} already exists.`);
      usedName = name.split(`.${fileEnding}`)[0] + `(${fallback}).${fileEnding}`;
      onInfo('MediaLibraryDialog', `Trying to use ${usedName}`);
      fallback = fallback + 1;
    }

    onInfo('MediaLibraryDialog', 'New name was found. ' + usedName + ' will be used.');

    if (filter && filter === 'images') {
      if (type.startsWith('image/')) {
        setUpload(prevState => ({ ...prevState, loading: true }));

        const path = `/media/${brand.id}/${usedName}`;
        const storageRef = storage.ref(path);
        processUpload(user.id, brand.id, storageRef, file);
      } else {
        console.error('Wrong file format ...');
        notify(t('file_format_error'), 'error');
        return;
      }
    } else {
      if (type.startsWith('video/') || type.startsWith('image/')) {
        setUpload(prevState => ({ ...prevState, loading: true }));

        const path = `/media/${brand.id}/${usedName}`;
        const storageRef = storage.ref(path);
        processUpload(user.id, brand.id, storageRef, file);
      } else {
        console.error('Wrong file format ...');
        notify(t('file_format_error'), 'error');
        return;
      }
    }
  };

  const listItems = useCallback(async () => {
    const path = `/media/${brand.id}`;
    const storageRef = storage.ref();
    const listRef = storageRef.child(path);

    try {
      onStorage('listAll', path);
      const result = await listRef.listAll();

      const metaPromises = result.items.map((item: firebase.default.storage.Reference) => item.getMetadata());
      const urlPromises = result.items.map((item: firebase.default.storage.Reference) => item.getDownloadURL());

      const metaArr = await Promise.all(metaPromises);
      const urlArr = await Promise.all(urlPromises);

      const files: any = [];
      result.items.forEach((item, i) => {
        if (filter === 'images') {
          const contentType = metaArr && metaArr[i] && metaArr[i].contentType ? metaArr[i].contentType : null;
          if (!contentType.startsWith('image/')) {
            return;
          }
        }
        files[i] = { ...metaArr[i], url: urlArr[i] };
      });

      setState(prevState => ({ ...prevState, files }));
    } catch (err) {
      console.error(err);
      setState(prevState => ({ ...prevState, error: listAllError }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  }, [brand.id, filter, listAllError]);

  const processUpload = (uid: string, brandId: string, storageRef: firebase.default.storage.Reference, file: File) => {
    const uploadTask = storageRef.put(file, {
      customMetadata: {
        uid: uid,
        brand: brandId,
      },
    });

    uploadTask.on(
      'state_changed',
      snapshot => {
        setUpload(prevState => ({ ...prevState, completed: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }));
      },
      err => {
        console.error('Upload error', err);
        notify(t('default_error_message'), 'error');
        setUpload(prevState => ({ ...prevState, loading: false }));
      },
      async () => {
        try {
          const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
          const metaData = await uploadTask.snapshot.ref.getMetadata();
          const copyArr = [...state.files];
          copyArr.push({ ...metaData, url: downloadUrl });
          setState(prevState => ({ ...prevState, files: copyArr }));
          setUpload(prevState => ({ ...prevState, loading: false }));
          handleSelect({ ...metaData, url: downloadUrl });
        } catch (err) {
          console.error(err);
          notify(t('default_error_message'), 'error');
        }
      }
    );
  };

  useEffect(() => {
    listItems();
  }, [listItems]);

  const renderInputElement = () => {
    if (filter === 'images') {
      return (
        <input
          accept='image/*'
          className={classes.input}
          id='file-upload'
          type='file'
          onChange={event => {
            handleUpload(event);
            event.target.value = '';
          }}
        />
      );
    }
    return (
      <input
        accept='image/*, video/*'
        className={classes.input}
        id='file-upload'
        type='file'
        onChange={event => {
          handleUpload(event);
          event.target.value = '';
        }}
      />
    );
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.closeIcon}
            disabled={upload.loading}
            edge='start'
            color='inherit'
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <Typography className={classes.title} variant='h6'>
            {t('media_library')}
          </Typography>
          <IconButton className={classes.iconButton} onClick={() => toggleMode()} size='small'>
            {mode === 'dark' ? <Flare /> : <Brightness2 />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <List className={classes.list} dense>
        {renderInputElement()}
        <label htmlFor='file-upload'>
          <ListItem button className={classes.listItem} disabled={upload.loading}>
            <ListItemIcon style={{ minWidth: theme.spacing(5.5) }}>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText
              primary={upload.loading ? t('uploading_file') : t('upload_file')}
              secondary={
                upload.loading ? t('uploading_file_completed', { completed: upload.completed }) : t('upload_media_file')
              }
            />
            {upload.loading && (
              <ListItemSecondaryAction>
                <CircularProgress />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </label>
        <Divider />
      </List>
      <DialogContent>
        {state.loading ? (
          <div className={classes.circularProgress}>
            <CircularProgress />
          </div>
        ) : state.error ? (
          <Typography color='error' variant='body1'>
            {state.error}
          </Typography>
        ) : (
          <Grid container direction='row' spacing={2}>
            {state.files.map((file, i) => (
              <Fragment key={i}>
                {!!file && !!file.contentType && !!file.url && !!file.name && (
                  <Grid key={i} item xs={6} sm={3} lg={2} xl={1}>
                    <Card className={classes.card}>
                      <CardActionArea className={classes.container} onClick={() => handleSelect(file)}>
                        {file.contentType.startsWith('image/') && (
                          <div style={{ padding: theme.spacing(3) }}>
                            <img alt={file.name} height='auto' width={'100%'} src={file.url} />
                          </div>
                        )}
                        {file.contentType.startsWith('video/') && (
                          <div style={{ padding: theme.spacing(3), textAlign: 'center' }}>
                            <PlayArrow />
                            <Typography style={{ fontSize: 10 }} noWrap>
                              {file.name}
                            </Typography>
                          </div>
                        )}
                      </CardActionArea>
                    </Card>
                    <div className={classes.bottomContainer}>
                      {/* {file.contentType.startsWith('video/') ? (
                    <Button
                      onClick={() => window.open(file.url, '_blank')}
                      disabled={upload.loading}
                      size='small'
                      style={{ textTransform: 'none' }}
                      variant='text'
                    >
                      {t('preview_button')}
                    </Button>
                  ) : ( */}
                      <Tooltip title={file.name}>
                        <Typography color='textSecondary' className={globalClasses.noSelect} noWrap variant='caption'>
                          {file.name}
                        </Typography>
                      </Tooltip>
                      {/* )} */}

                      <Button
                        disabled={upload.loading || true}
                        size='small'
                        style={{ textTransform: 'none' }}
                        variant='text'
                      >
                        {t('delete_button')}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
