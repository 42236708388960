import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  noMessages: {
    padding: theme.spacing(3, 5, 2),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export { default as NotificationsButton } from './NotificationsButton';
