import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { File } from 'models';
import { IFilesState, IFilesDataState } from 'interfaces';
import { addIdToDocument, conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onError, onFirestore, onListener } from 'utils/logger';

export default function useFiles(
  brandPath: string,
  isFileAdmin: boolean,
  userGroups: string[],
  userRole: string
): IFilesDataState {
  const filesPath = firestore.doc(brandPath).collection(File.collection).path;

  const [files, setFiles] = React.useState<IFilesState>({
    filesData: { admin: [], app: [] },
    filesError: { status: false, message: '' },
    filesLoading: true,
  });

  React.useEffect(() => {
    onFirestore('onSnapshot', filesPath);

    const collectionRef = conditionalCollection(filesPath);
    let query = collectionRef.where('hasGroupRestrictions', '==', false).where('hasRoleRestrictions', '==', false);

    if (!isFileAdmin) {
      query = query.where('published', '==', true);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'File', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const data = snapshot.docs.map(doc => new File(addIdToDocument(doc)));
        setFiles(prevState => ({
          ...prevState,
          filesData: {
            ...prevState.filesData,
            admin: isFileAdmin ? data : [],
            app: data,
          },
          filesLoading: false,
        }));
      },
      error => {
        const message = 'Files data could not be loaded.';
        console.error('Error: ', message);
        setFiles(prevState => ({ ...prevState, filesError: { status: true, message }, filesLoading: false }));
        onError('File', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [filesPath, isFileAdmin]);

  const [groupFiles, setGroupFiles] = React.useState<IFilesState>({
    filesError: { status: false, message: '' },
    filesData: { admin: [], app: [] },
    filesLoading: true,
  });

  useDeepCompareEffect(() => {
    onFirestore('onSnapshot', filesPath);
    const collectionRef = conditionalCollection(filesPath);
    let query = collectionRef.where('hasGroupRestrictions', '==', true).where('hasRoleRestrictions', '==', false);

    if (!isFileAdmin) {
      query = query.where('published', '==', true);

      if (userGroups.length > 0 && userGroups.length <= 10 ) {
        /* If group has more than 10 items data for app is filtered out after query (see below) */
        query = query.where('groupRestrictions', 'array-contains-any', userGroups);
      }
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'File', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const data = snapshot.docs.map(doc => new File(addIdToDocument(doc)));
        setGroupFiles(prevState => ({
          ...prevState,
          filesData: {
            ...prevState.filesData,
            admin: isFileAdmin ? data : [],
            app: data,
          },
          filesLoading: false,
        }));
      },
      error => {
        const message = 'Group restricted files data could not be loaded.';
        console.error('Error: ', message);
        setGroupFiles(prevState => ({ ...prevState, filesError: { status: true, message }, filesLoading: false }));
        onError('File', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [filesPath, isFileAdmin, userGroups]);

  const [groupRoleFiles, setGroupRoleFiles] = React.useState<IFilesState>({
    filesError: { status: false, message: '' },
    filesData: { admin: [], app: [] },
    filesLoading: true,
  });

  React.useEffect(() => {
    onFirestore('onSnapshot', filesPath);

    const collectionRef = conditionalCollection(filesPath);
    let query = collectionRef.where('hasGroupRestrictions', '==', true).where('hasRoleRestrictions', '==', true);

    if (!isFileAdmin) {
      query = query.where('published', '==', true);
      query = query.where('roleRestrictions', 'array-contains', userRole);

      /* As soon as firestore enables to combine array-contains and array-contains-any in one query */
      /* array-contains-any for groups should be added here */

      /**
       *
       * if (userGroups.length <= 10) {
       *  query = query.where('groupRestrictions', 'array-contains-any', userGroups);
       * }
       *
       */
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'File', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const data = snapshot.docs.map(doc => new File(addIdToDocument(doc)));
        setGroupRoleFiles(prevState => ({
          ...prevState,
          filesData: {
            ...prevState.filesData,
            admin: isFileAdmin ? data : [],
            app: data,
          },
          filesLoading: false,
        }));
      },
      error => {
        const message = 'Group and role restricted files data could not be loaded.';
        console.error('Error: ', message);
        setGroupRoleFiles(prevState => ({ ...prevState, filesError: { status: true, message }, filesLoading: false }));
        onError('File', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [filesPath, isFileAdmin, userRole]);

  const [roleFiles, setRoleFiles] = React.useState<IFilesState>({
    filesError: { status: false, message: '' },
    filesData: { admin: [], app: [] },
    filesLoading: true,
  });

  React.useEffect(() => {
    onFirestore('onSnapshot', filesPath);

    const collectionRef = conditionalCollection(filesPath);
    let query = collectionRef.where('hasGroupRestrictions', '==', false).where('hasRoleRestrictions', '==', true);

    if (!isFileAdmin) {
      query = query.where('published', '==', true);
      query = query.where('roleRestrictions', 'array-contains', userRole);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        snapshot.docChanges().forEach((change, i) => {
          onListener(i, snapshot.docs.length, 'File', change.doc.id, change.oldIndex, change.newIndex, change.type);
        });
        const data = snapshot.docs.map(doc => new File(addIdToDocument(doc)));
        setRoleFiles(prevState => ({
          ...prevState,
          filesData: {
            ...prevState.filesData,
            admin: isFileAdmin ? data : [],
            app: data,
          },
          filesLoading: false,
        }));
      },
      error => {
        const message = 'Role restricted files data could not be loaded.';
        console.error('Error: ', message);
        setRoleFiles(prevState => ({ ...prevState, filesError: { status: true, message }, filesLoading: false }));
        onError('File', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [filesPath, isFileAdmin, userRole]);

  const filesLoading = [
    files.filesLoading,
    groupFiles.filesLoading,
    groupRoleFiles.filesLoading,
    roleFiles.filesLoading,
  ].some(state => state === true);

  const filesError = [files.filesError, groupFiles.filesError, groupRoleFiles.filesError, roleFiles.filesError].find(
    state => state.status === true
  );

  return {
    filesLoading,
    filesError: filesError === undefined ? { message: '', status: false } : filesError,
    filesData: {
      admin: [
        ...files.filesData.admin,
        ...groupFiles.filesData.admin,
        ...groupRoleFiles.filesData.admin,
        ...roleFiles.filesData.admin,
      ],
      app: [
        ...files.filesData.app.filter(file => file.published),
        ...groupFiles.filesData.app.filter(file => file.published && file.isNotRestricted(userGroups, userRole)),
        ...groupRoleFiles.filesData.app.filter(file => file.published && file.isNotRestricted(userGroups, userRole)),
        ...roleFiles.filesData.app.filter(file => file.published && file.isNotRestricted(userGroups, userRole)),
      ],
    },
  };
}
