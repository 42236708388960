import React, { useContext } from 'react';
import { Column } from 'material-table';
import { Link, Typography, useTheme } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DateFNS from 'utils/date/date-fns';
import useNotification from 'hooks/notification/useNotification';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { User } from 'models';
import { DataContext } from 'providers/Data';
import { IUserFormData } from 'interfaces';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { UserFields } from 'components/Forms/Fields/Admin/UserFields';
import { firestore, functions } from 'configuration/firebase';
import { onError, onFunctions, onSuccess } from 'utils/logger';

export default function AdminBrandUsersPage() {
  const notify = useNotification();
  const theme = useTheme();
  const myUser = useContext(UserContext).user as User;

  const { brand, roles, users } = useContext(DataContext);
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const [createState, setCreateState] = React.useState<{
    dialogOpen: boolean;
  }>({
    dialogOpen: false,
  });

  const [deleteState, setDeleteState] = React.useState<{
    dialogOpen: boolean;
    user?: User;
    loading: boolean;
    name: string | null;
  }>({
    dialogOpen: false,
    user: undefined,
    loading: false,
    name: null,
  });

  const [updateState, setUpdateState] = React.useState<{
    dialogOpen: boolean;
    loading: boolean;
    user?: User;
  }>({
    dialogOpen: false,
    loading: false,
    user: undefined,
  });

  const [inviteState, setInviteState] = React.useState({
    loading: false,
    uid: '',
  });

  const handleCreate = async (values: IUserFormData) => {
    onFunctions('inviteUserToBrand');
    const inviteUserToBrand = functions.httpsCallable('inviteUserToBrand');

    return new Promise<void>(async (resolve, reject) => {
      try {
        await inviteUserToBrand({ ...values, brandName: brand?.name });
        onSuccess('inviteUserToBrand', 'callable-function-success');
        setCreateState({ dialogOpen: false });
        return resolve();
      } catch (err) {
        onError('AdminBrandUnitsPage', err);
        setCreateState({ dialogOpen: false });
        return reject(err);
      }
    });
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const { user } = { ...deleteState };

    if (user) {
      try {
        const userRef = firestore.collection(User.collection).doc(user.id);
        const deleteBrandUser = functions.httpsCallable('deleteBrandUser');
        await deleteBrandUser({ uid: user.id });
        notify(t('alert_user_deletion_success'));
        onSuccess(userRef.path, 'delete-success');
      } catch (err) {
        onError('AdminBrandUsersPage', err);
        notify(t('alert_user_deletion_error'), 'error');
      }
    } else {
      console.error('User not found');
      notify(t('alert_user_deletion_error'), 'error');
    }

    setDeleteState({ user: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteDialog = (id: string) => {
    const user = users.admin.find(userItem => userItem.id === id);

    if (!user) {
      notify(t('alert_user_deletion_error'), 'error');
    } else {
      setDeleteState({ user, dialogOpen: true, loading: false, name: user.email });
    }
  };

  const handleUpdate = async (values: IUserFormData) => {
    const { user } = { ...updateState };
    return new Promise<void>(async (resolve, reject) => {
      if (user === undefined) {
        return reject('Unit not found');
      }
      setUpdateState(prevState => ({ ...prevState, loading: true }));
      try {
        const updateUser = functions.httpsCallable('updateUser');
        await updateUser({ groups: values.groups, role: values.role, units: values.units, uid: user.id });
        setUpdateState({ dialogOpen: false, loading: false, user: undefined });
        return resolve();
      } catch (err) {
        onError('AdminBrandUsersPage', err);
        setUpdateState({ dialogOpen: false, loading: false, user: undefined });
        return reject(err);
      }
    });
  };

  const handleReinvite = (email: string, uid: string) => {
    if (!uid) return;
    setInviteState(prevState => ({ ...prevState, uid, loading: true }));
    const reinviteUserToBrand = functions.httpsCallable('reinviteUserToBrand');
    onFunctions('reinviteUserToBrand');
    reinviteUserToBrand({ brandName: brand?.name, uid })
      .then(() => {
        notify(t('alert_reinvite_success'));

        onSuccess('reinviteUserToBrand', 'callable-function-success');
      })
      .catch(err => {
        notify(t('alert_reinvite_error'), 'error');
        onError('AdminBrandUsersPage', err);
      })
      .finally(() => {
        setInviteState(prevState => ({ ...prevState, uid: '', loading: false }));
      });
  };

  const columns: Column<object>[] = [
    { field: 'email', title: 'Email', defaultSort: 'asc' },
    { field: 'name', title: 'Name' },
    { field: 'role', title: t('user_role_label') },
    { field: 'lastLoginAt', title: t('last_login_at'), type: 'date' },
    {
      disableClick: true,
      field: 'invitation',
      title: t('user_invitation_label'),
      render: ({ id, invitation: { invitationIsComplete, validUntil } }: any) => {
        const now = new Date();

        if (inviteState.loading && inviteState.uid === id) {
          return t('loading_progress');
        }
        if (invitationIsComplete) {
          return t('invitation_completed');
        }
        if (validUntil.toDate() > now) {
          return (
            t('expires_in') +
            ': ' +
            formatDistance(validUntil.toDate(), now, {
              addSuffix: true,
              locale: DateFNS.getLocale(language),
            })
          );
        }

        return (
          <Typography color='error' style={{ fontSize: theme.typography.fontSize - 1 }} variant='body2'>
            {t('invitation_expired', {
              date: formatDistance(validUntil.toDate(), now, {
                addSuffix: true,
                locale: DateFNS.getLocale(language),
              }),
            })}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      sorting: false,
      searchable: false,
      disableClick: true,
      align: 'right',
      render: ({ invitation: { invitationIsComplete }, email, id }: any) => {
        return (
          <>
            {invitationIsComplete === false && (
              <Link
                color={inviteState.loading ? 'textSecondary' : 'primary'}
                component='button'
                onClick={() => {
                  if (inviteState.loading) {
                    return;
                  }
                  handleReinvite(email, id);
                }}
                underline={inviteState.loading ? 'none' : 'hover'}
                style={{ cursor: inviteState.loading ? 'default' : 'pointer', marginRight: 48 }}
              >
                {inviteState.loading && inviteState.uid === id ? t('loading_progress') : t('resend_invitation')}
              </Link>
            )}
            <Link
              color={inviteState.loading ? 'textSecondary' : 'error'}
              component='button'
              onClick={() => {
                if (inviteState.loading) {
                  return;
                }
                handleOpenDeleteDialog(id);
              }}
              underline={inviteState.loading ? 'none' : 'hover'}
              style={{ cursor: inviteState.loading ? 'default' : 'pointer' }}
            >
              {t('delete_user_button')}
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_user_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ user: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_user_confirmation_dialog_title')}
        type='delete'
      />
      {createState.dialogOpen && (
        <UserFields
          handleClick={values => handleCreate(values)}
          handleClose={() => {
            setCreateState({ dialogOpen: false });
          }}
          initialValues={{
            currentLocale: brand?.configuration.fallbackLocale as string,
            email: '',
            groups: [],
            role: 'user',
            units: [],
          }}
          open={createState.dialogOpen}
          title={t('create_user_dialog_title')}
          type='create'
        />
      )}

      {updateState.dialogOpen && (
        <UserFields
          handleClick={values => handleUpdate(values)}
          handleClose={() => {
            setUpdateState(prevState => ({ ...prevState, dialogOpen: false, user: undefined }));
          }}
          initialValues={{
            currentLocale: updateState.user?.settings.currentLocale || (brand?.configuration.fallbackLocale as string),
            email: updateState.user?.email || '',
            groups: updateState.user?.groups || [],
            role: updateState.user?.role || 'user',
            units: updateState.user?.units || [],
          }}
          open={updateState.dialogOpen}
          title={t('update_user_dialog_title')}
          type='update'
          user={updateState.user}
        />
      )}
      <Table
        columns={[...columns]}
        data={users.admin
          .map(
            ({
              email,
              id,
              invitation,
              lastLoginAt,
              role,
              settings: { currentLocale, firstname, lastname, photoUrl },
            }) => {
              const searchRole = roles.brand.find(brandRole => brandRole.id === role);
              const uppercasedRole =
                searchRole && searchRole.key
                  ? searchRole.key?.charAt(0).toUpperCase() + searchRole.key?.slice(1)
                  : role.charAt(0).toUpperCase() + role.slice(1);
              return {
                currentLocale,
                id,
                photoUrl,
                email,
                invitation,
                name: firstname + ' ' + lastname,
                lastLoginAt: lastLoginAt && lastLoginAt.general ? lastLoginAt.general.toDate() : ' ',
                role: uppercasedRole,
              };
            }
          )
          .filter(userItem => myUser.id !== userItem.id)}
        title={t('/admin/brand/users')}
        onAddClick={
          inviteState.loading
            ? undefined
            : () => {
                setCreateState({ dialogOpen: true });
              }
        }
        onRowClick={
          inviteState.loading
            ? undefined
            : (event, { id }: any) => {
                setUpdateState(prevState => ({
                  ...prevState,
                  dialogOpen: true,
                  user: users.admin.find(user => user.id === id),
                }));
              }
        }
      />
    </>
  );
}
