import { ITranslationResources } from 'interfaces';

const default_error_message = 'Something went wrong. Please try again!';
const default_saving_message = 'Changes have been saved successfully.';

const en: ITranslationResources = {
  '/': 'Start',
  '/content': 'Content',
  '/learning': 'Courses',
  '/news': 'News',
  '/files': 'Files',
  '/general': 'General',
  '/restrictions': 'Restrictions',
  '/lessons': 'Lessons',
  '/lesson-contents': 'Lesson Contents',
  '/admin/learning-content': 'Courses',
  '/admin/files': 'Files',
  '/admin/brand': 'Brand',
  '/admin/news': 'News',
  '/admin/brand/groups': 'Groups',
  '/admin/brand/roles': 'Roles',
  '/admin/brand/units': 'Units',
  '/admin/brand/users': 'User',
  '/admin/units': 'Units',
  add_button: 'Add',
  add_course_button: 'Add course',
  add_lesson_button: 'Add lesson',
  add_new_editor_item_button: 'Add building block',
  add_new_item_button: 'Add item',
  admin: 'Admin',
  alert_course_creation_error: default_error_message,
  alert_course_creation_success: 'Course has been created.',
  alert_course_deletion_error: default_error_message,
  alert_course_deletion_success: 'Course has been deleted',
  alert_course_update_error: default_error_message,
  alert_course_update_success: 'Course has been updated.',
  alert_delete_avatar_success: 'Profile picture removed.',
  alert_default_saving_success: default_saving_message,
  alert_file_already_exists: 'File already exists!',
  alert_file_deletion_error: default_error_message,
  alert_file_deletion_success: 'File has been deleted.',
  alert_file_upload_error_file_size: 'A maximum of 25 MB is allowed.',
  alert_file_upload_error_file_type: 'File format unknown.',
  alert_file_upload_error: default_error_message,
  alert_group_deletion_error: default_error_message,
  alert_group_deletion_success: 'Group has been deleted.',
  alert_group_creation_error: default_error_message,
  alert_group_creation_success: 'Group has been created.',
  alert_group_update_error: default_error_message,
  alert_group_update_success: 'Group has been updated.',
  alert_leave_page: 'Do you really want to leave this page?\n\nYour input will be lost.',
  alert_lesson_content_creation_error: default_error_message,
  alert_lesson_content_deletion_error: default_error_message,
  alert_lesson_content_update_error: default_error_message,
  alert_leave_lesson_page:
    'Do you really want to leave this page?\n\nYour prior learning progress in this lesson will be lost.',
  alert_lesson_creation_error: default_error_message,
  alert_lesson_deletion_error: default_error_message,
  alert_lesson_deletion_success: 'Lesson has been deleted.',
  alert_lesson_content_deletion_success: 'Lesson Content has been deleted.',
  alert_lesson_update_error: default_error_message,
  alert_login: 'Wrong password or this user does not exist.',
  alert_media_item_picked_error: default_error_message,
  alert_news_creation_success: 'News has been created.',
  alert_news_deletion_success: 'News has benn deleted.',
  alert_news_deletion_error: default_error_message,
  alert_news_update_success: 'News has been updated.',
  alert_settings_changed_success: 'Changes have been saved.',
  alert_settings_changed_error: default_error_message,
  alert_reinvite_error: default_error_message,
  alert_reinvite_success: 'The invitation has been sent.',
  alert_registration_error: default_error_message,
  alert_reset_password_error: 'Something has gone wrong. Perhaps this account does not exist.',
  alert_reset_password_success: 'You will receive a mail soon.',
  alert_role_creation_success: 'Role has been created',
  alert_role_update_success: 'Role has been updated',
  alert_role_creation_error: default_error_message,
  alert_role_update_error: default_error_message,
  alert_role_deletion_success: 'Role has been deleted',
  alert_role_deletion_error: default_error_message,
  alert_unit_creation_error: default_error_message,
  alert_unit_creation_success: 'Unit has been created.',
  alert_unit_deletion_error: default_error_message,
  alert_unit_deletion_success: 'Unit has been deleted.',
  alert_unit_update_error: default_error_message,
  alert_unit_update_success: 'Unit has been updated.',
  alert_user_creation_success: 'User has been invited.',
  alert_user_update_success: 'User has been updated.',
  alert_user_creation_error: default_error_message,
  alert_user_update_error: default_error_message,
  alert_user_deletion_error: default_error_message,
  alert_user_deletion_success: 'User has been deleted.',
  alert_user_already_in_use_error: 'This email {{ email }} is already in use.',
  alert_user_invite_error: default_error_message,
  alert_user_invite_success: 'User has been invited.',
  announcements_label: 'Announcements',
  delete_unit_button: 'Delete unit',
  delete_unit_confirmation_dialog_content_text:
    'Do you really want to delete the unit {{name}}? All users assigned to this unit will be removed from it.',
  apply_button: 'Apply',
  back_button: 'Back',
  back_to_home: 'Back to Home',
  back_to_login: 'Back to login',
  content_blocks: 'Content',
  cancel_button: 'Cancel',
  create_button: 'Create',
  check_your_inputs: 'Check your input',
  create_course: 'Add course',
  create_lesson_button: 'Add lesson',
  create_lesson_dialog_title: 'Create lesson',
  create_course_button: 'Add course',
  create_course_dialog_title: 'Create course',
  create_file_button: 'Provide file',
  create_file: 'Provide file',
  create_group_dialog_title: 'Create group',
  create_group_button: 'Create',
  create_lesson_content_dialog_title: 'Add Lesson Content',
  create_role_dialog_title: 'Create role',
  create_news_button: 'Create news',
  create_news: 'Create news',
  create_unit_dialog_title: 'Create unit',
  create_user_dialog_title: 'Create user',
  close_button: 'Close',
  complete_registration_button: 'Register',
  content_builder_delete_confirmation: `Do you really want to remove this item?\n\nThis will also remove all assigned items.`,
  courses: 'Courses',
  course_creation_success: 'Course has been cretead.',
  course_logo_picked_error: default_error_message,
  course_logo_picked_success: 'Logo has been added.',
  course_colour: 'Course colour',
  course_description: 'Course description',
  course_description_label: 'Course description',
  course_index_label: 'Index',
  course_index_label_helper_text: 'Determines the order of the course',
  course_key: 'Course',
  course_key_label: 'Identifier',
  course_key_label_helper_text: 'The identifier is only visible to admins.',
  course_group_restriction_caption:
    'Select the group(s) a user must have to be allowed to work on this course. If a user is not assigned to any of the selected groups, this course is hidden for him. If a user owns multiple groups, matching one selected group is sufficient. If you do not select any groups, any user can edit this course regardless of their assigned groups.',
  course_pre_condition_caption:
    'Select the courses that a user must pass to be allowed to work on this course. As long as the marked courses are not passed, this course is visible for the user but locked.',
  course_role_restriction_caption:
    'Select the role(s) a user must have to be allowed to work on this course. If a user does not have any of the selected roles, this course is hidden for him. If you do not select a role, any user can edit this course regardless of their role.',
  course_sortIndex: 'Index',
  course_group_restriction_label: 'Group restrictions',
  course_role_restriction_label: 'Role restrictions',
  course_title: 'Title',
  course_title_label: 'Title',
  course_title_label_helper_text: 'The title is displayed on the course card.',
  course_title_in_locale: 'Title in {{ locale }}',
  confirm_email_label: 'Confirm email',
  confirm_password_label: 'Confirm password',
  current_password_label: 'Current password',
  current_password_label_helper_text: 'For security reasons we need your current password for this action.',
  default_error_message: default_error_message,
  delete_button: 'Delete',
  delete_course_button: 'Delete course',
  delete_course_confirmation_dialog_title: 'Delete course?',
  delete_course_confirmation_dialog_content_text:
    'Do you really want to delete the course {{name}}? This will also delete all lessons and lesson contents of this course.',
  delete_confirmation_dialog_content_sub_text: 'This action cannot be undone.',
  delete_news_confirmation_dialog_content_text: 'Do you really want to delete the news {{name}}?',
  delete_file_button: 'Delete file',
  delete_file_confirmation_dialog_content_text: 'Do you really want to delete the file {{name}}?',
  delete_file_confirmation_dialog_title: 'Delete file?',
  delete_group_button: 'Delete group',
  delete_group_confirmation_dialog_content_text:
    'Do you really want to delete the group {{name}}? It will also be removed from all courses, lessons, files and users.',
  delete_group_confirmation_dialog_title: 'Delete group?',
  delete_lesson_button: 'Delete lesson',
  delete_lesson_confirmation_dialog_title: 'Delete lesson?',
  delete_lesson_confirmation_dialog_content_text:
    'Do you really want to delete the lesson {{name}}? This will also delete all lesson contents of this lesson.',
  delete_lesson_content_button: 'Delete lesson content',
  delete_lesson_content_confirmation_dialog_content_text: 'Do you really want to delete the lesson content {{name}}?',
  delete_lesson_content_confirmation_dialog_title: 'Delete lesson content?',
  delete_news_button: 'Delete news',
  delete_news_confirmation_dialog_title: 'Delete news?',
  delete_role_button: 'Delete role',
  delete_role_confirmation_dialog_content_text:
    'Do you really want to delete the role {{name}}? The users assigned to this role will get the default role User by this action.',
  delete_role_confirmation_dialog_title: 'Delete role?',
  delete_unit_confirmation_dialog_title: 'Delete unit?',
  delete_user_confirmation_dialog_content_text:
    'Do you really want to delete the user {{name}}? The user will lose all his learning states and saved data.',
  delete_user_confirmation_dialog_title: 'Delete user?',
  delete_user_button: 'Delete user',
  edit_button: 'Edit',
  edit_mode_button: 'Edit mode',
  editor_placeholder: 'Write your content here ...',
  expires_in: 'Expires',
  file_format_error: 'Unknown file format',
  file_content_type: 'File type',
  file_creation_success: 'File has been provided',
  file_update_success: 'File has been updated',
  file_description_label: 'Description',
  file_description_label_helper_text: 'Optional description of the file (max. 1000 characters)',
  file_group_restriction_caption:
    'Select the group(s) a user must have to be allowed to download this file. If a user is not assigned to any of the selected groups, this file is hidden for him. If a user owns more than one group, matching one selected group is sufficient. If you do not select any groups, any user can download this file regardless of his assigned groups.',
  file_group_restriction_label: 'Group restrictions',
  file_role_restriction_caption:
    'Select the role(s) a user must have to be allowed to download this file. If a user does not have any of the selected roles, this file is hidden for him. If you do not select a role, any user can download this file regardless of their role.',
  file_role_restriction_label: 'Role restrictions',
  file_size: 'File format',
  file_upload_helper_text: 'Please upload a file with a maximum of 25 MB *',
  files: 'Files',
  firstname: 'Firstname',
  forgot_password: 'Forgot password',
  forgot_password_1: 'Forgot your password?',
  forgot_password_2: 'We will send you a link to reset it.',
  form_value_confirm_new_email: 'This value must match the email.',
  form_value_confirm_new_password: 'This value must match your new password.',
  form_value_not_of_old_password: 'This value cannot be the same as your old password.',
  form_value_email: 'A valid email is required.',
  form_value_min: 'At least {{ number }} character is required.',
  form_value_min_multi: 'At least {{ number }} characters are required.',
  form_value_postive: 'A number greater than 0 is required.',
  form_value_required: 'A value is required.',
  form_value_max: 'A maximum of {{ number }} characters is allowed.',
  get_reset_link_button: 'Send email',
  general_fields: 'General',
  general_settings: 'General settings',
  group_restrictions: 'Group restrictions',
  group_key_label: 'Name',
  invite_button: 'Invite',
  invite_user: 'Invite user',
  invite_unit_select_switcher_description: 'Responsible parties can invite users to this group?',
  invitation_completed: 'Completed',
  invitation_expired: 'Expired {{ date }}',
  language_label: 'Language',
  lastname: 'Lastname',
  last_modified: 'Last modified',
  last_update_at: 'Last update at',
  last_login_at: 'Last login',
  layout_blocks: 'Layout',
  learning_congrats: 'Congratulations!',
  learning_feedback: 'Thanks for your feedback!',
  learning_not_passed: 'Too bad! Unfortunately, you did not pass.',
  learning_rating_question: 'How did you like the lesson?',
  lessons: 'Lessons',
  lessons_button: 'Lessons',
  lesson_content_answer_label: 'Answer',
  lesson_content_key: 'Identifier',
  lesson_content_key_label: 'Identifier',
  lesson_content_key_label_helper_text: 'The identifier is only visible for admins',
  lesson_content_question_label: 'Question',
  lesson_content_question_label_helper_text: '',
  lesson_content_index_label: 'Index',
  lesson_content_index_label_helper_text: 'Determines the order of the lesson content',
  lesson_content_instruction_label: 'Instruction',
  lesson_content_type: 'Content Typ',
  lesson_contents: 'Lesson Contents',
  lesson_contents_button: 'Lesson Contents',
  lesson_duration_helper_text: 'Duration of the lesson in minutes',
  lesson_key: 'Lesson',
  lesson_key_label: 'Identifier',
  lesson_key_label_helper_text: 'The identifier is only visible to admins.',
  lesson_sortIndex: 'Index',
  lesson_index_label: 'Index',
  lesson_index_label_helper_text: 'Determines the order of the lesson',
  lesson_title_label: 'Title',
  lesson_title_label_helper_text: ' ',
  lesson_title_in_locale: 'Title in {{ locale }}',
  lesson_group_restriction_caption:
    'Select the group(s) a user must have to be allowed to work on this lesson. If a user is not assigned to any of the selected groups, this lesson is hidden for him. If a user owns multiple groups, matching one selected group is sufficient. If you do not select any groups, any user can edit this lesson regardless of their assigned groups.',
  lesson_pre_condition_caption:
    'Select the lesson that a user must pass to be allowed to work on this lesson. As long as the selected lessons are not passed, this lesson is visible to the user but locked.',
  lesson_role_restriction_caption:
    'Select the role(s) a user must have to be allowed to work on this lesson. If a user does not have any of the selected roles, this lesson is hidden for him. If you do not select a role, any user can edit this lesson regardless of their role.',
  lesson_group_restriction_label: 'Group restrictions',
  lesson_role_restriction_label: 'Role restrictions',
  loading_progress: 'loading ...',
  locale_de: 'German',
  locale_en: 'English',
  media_library: 'Media library',
  media_building_block: 'Media file',
  min_password: 'The password needs at least 6 characters.',
  mt_add_remove_columns: 'Select the columns to be displayed in the table.',
  mt_first_page: 'First page',
  mt_last_page: 'Last page',
  mt_next_page: 'Next page',
  mt_no_data: 'No data available',
  mt_of: 'of',
  mt_previous_page: 'Previous page',
  mt_rows: 'Rows',
  mt_rows_per_page: 'Results per page',
  mt_search: 'Search',
  new_email_label: 'New Email',
  new_password_label: 'New password',
  news_created_at: 'Created at',
  news_last_update_at: 'Last update at',
  news_caption_label: 'Caption',
  news_caption_label_helper_text: 'News caption (max. 1000 characters)',
  news_key_label: 'Identifier',
  news_key_label_helper_text: 'The identifier is only visible to admins.',
  news_title_label: 'Title',
  no_content_available: 'There is no content for the language {{locale}} available',
  no_steps_available: 'No lesson content available',
  no_content_for_locale: 'No content available',
  not_allowed_page: 'You are not authorized to open {{ pathname }} page.',
  notifications: 'You have {{number}} notifications',
  no_notifications: 'There are no notifications!',
  page_not_found: 'Page {{ pathname }}could not be found.',
  preconditions: 'Preconditions',
  preview_button: 'Preview',
  preview: 'Preview',
  preview_file_button: 'Preview',
  preview_mode_button: 'Preview mode',
  publish_this_course: 'Publish course?',
  publish_this_file: 'Publish file?',
  publish_this_lesson: 'Publish lesson?',
  publish_this_lesson_content: 'Publish lesson content?',
  publish_this_news: 'Publish news?',
  remove_course_logo_button: 'Remove logo',
  remove_file_button: 'Remove file',
  restrictions: 'Restrictions',
  resources: 'Resources',
  resend_invitation: 'Invite again',
  reauthenticate_error_wrong_password: 'Wrong password. Please enter your current password.',
  refresh_page: 'Try again',
  role_restrictions: 'Role restrictions',
  role_key_label: 'Name',
  role_resources_selection_label: 'Resources',
  role_selectable_in_restrictions_switcher_description: 'Responsible parties can use this role for restrictions?',
  select_color_button: 'Choose colour',
  select_file_button: 'Select file',
  select_building_block: 'Select building block',
  status_passed: 'Passed',
  status_update: 'Update',
  settings: 'Settings',
  select_building_item: 'Add item',
  selectable_in_unit_invitation: 'Selectable in unit invitations',
  selectable_in_restrictions: 'Selectable in restrictions',
  slide_not_supported: 'This slide is not supported in the current brainee version.',
  sign_in_button: 'Sign in',
  sorting_question_instruction: 'Tap the answers in the correct order',
  text_building_block: 'Text',
  two_column_layout_block: 'Two-column layout',
  three_column_layout_block: 'Three-column layout',
  try_again_button: 'Try again',
  unit_internalId_label: 'Identifier',
  unit_key_label: 'Name',
  update_course_button: 'Update course',
  update_button: 'Update',
  update_file_button: 'Update file',
  update_group_dialog_title: 'Update group',
  update_role_dialog_title: 'Update role',
  update_news_button: 'Update news',
  update_lesson_content_button: 'Update lesson content',
  upload_media_file: 'Update an image or a video',
  update_email_settings: 'Update email',
  update_password_settings: 'Update password',
  update_unit_dialog_title: 'Update unit',
  upload_file: 'Upload file',
  uploading_file: 'File is uploaded ...',
  uploading_file_completed: '{{ completed }} % uploaded.',
  update_user_dialog_title: 'Update user',
  update_lesson_button: 'Update lesson',
  user_current_locale_label: 'Language',
  user_groups_label: 'Groups',
  user_units_label: 'Units',
  user_role_label: 'Roles',
  user_invitation_label: 'Invitation',
  valid_email: 'Please enter a valid email.',
  view_all_news_button: 'View all',
  welcome_back: 'Welcome back',
};

export default en;
