import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
}));

export { default as CircularProgress } from './CircularProgress';
