import React, { ReactNode } from 'react';
import { TransparentTabs } from '.';

export default function NavigationTabs({
  children,
  onChange,
  value,
}: {
  children: ReactNode;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: boolean | string;
}) {
  return (
    <TransparentTabs
      indicatorColor='primary'
      textColor='secondary'
      variant='scrollable'
      scrollButtons='off'
      onChange={onChange}
      value={value}
    >
      {children}
    </TransparentTabs>
  );
}
