import React, { Fragment, useContext } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { Button, Divider, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBreakpoints from 'hooks/layout/useBreakpoints';
import { NavigationTab } from '../Tab';
import { NavigationTabs } from '../Tabs';
import { UserContext } from 'providers/User';

export default function AdminNavigation() {
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const { xs } = useBreakpoints();
  const { user } = useContext(UserContext);

  const getNavigation = (): string[] => {
    if (!user || user.hasAdminAccess() === false) {
      return [];
    }

    let items: (string | null)[] = [];

    if (user.isUnitAdmin()) {
      items = ['/admin/units'];
    }

    if (user.isUserAdmin()) {
      items = ['/admin/brand'];
    }

    items = [
      ...items,
      user.isLearningContentAdmin() ? '/admin/learning-content' : null,
      user.isNewsAdmin() ? '/admin/news' : null,
      user.isFileAdmin() ? '/admin/files' : null,
    ];

    return items.filter(item => !!item) as string[];
  };

  const getFirstPath = (): string => {
    return '/admin/' + history.location.pathname.split('/')[2];
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(
    getNavigation().includes(history.location.pathname)
      ? history.location.pathname
      : getNavigation().includes(getFirstPath())
      ? getFirstPath()
      : false
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue: any) => {
    history.push(newValue);
    setValue(newValue);
  };

  if (!user || !user.hasAdminAccess()) {
    return null;
  }

  return (
    <Fragment>
      <Typography style={{ fontSize: 15, fontWeight: 700 }}>Admin</Typography>
      <Divider flexItem orientation='vertical' style={{ height: 24, margin: xs ? 'auto 8px auto 8px' : 'auto 16px' }} />

      {xs ? (
        <>
          <Button
            disableTouchRipple
            endIcon={<ArrowDropDown />}
            onClick={handleClick}
            style={{ padding: theme.spacing(0, 0.5), textTransform: 'none' }}
          >
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                textOverflow: 'ellipsis',
              }}
            >
              {t(value.toString())}
            </span>
          </Button>
          <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
            {getNavigation().map(path => (
              <MenuItem
                key={path}
                onClick={() => {
                  handleClose();
                  handleChange(path);
                }}
              >
                {t(path)}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <NavigationTabs value={value} onChange={(_, value) => handleChange(value)}>
          {getNavigation().map(item => (
            <NavigationTab key={item} label={t(item)} value={item} />
          ))}
        </NavigationTabs>
      )}
    </Fragment>
  );
}
