import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticationContext } from '../Authentication';
import { CircularProgress } from 'components/Loading/CircularProgress';
import { ErrorView } from 'views/Error';
import { Layout } from 'components/Layout';
import { RegistrationView } from 'views/Registration';
import { User } from 'models';
import { initialState, UserContext } from '.';
import { addIdToData } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onError, onFirestore, onListener } from 'utils/logger';

export default function UserProvider({ children }: { children: ReactNode }) {
  const authUser = useContext(AuthenticationContext).authUser as firebase.default.User;
  const { i18n } = useTranslation();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const userRef = firestore.collection(User.collection).doc(authUser.uid);
    onFirestore('onSnapshot', userRef.path);

    const unsubscribe = userRef.onSnapshot(
      snap => {
        onListener(0, 1, 'User', authUser.uid, 0, 0, 'changed');
        const data = snap.data();
        if (!snap.exists || !data) {
          throw new Error('User data does not exist.');
        }
        const user = new User(addIdToData(data, authUser.uid));
        i18n.changeLanguage(user.settings.currentLocale);
        setState(prevState => ({ ...prevState, user: { ...prevState.user, data: user, loading: false } }));
      },
      error => {
        const message = 'User data could not be loaded.';
        console.error('Error: ', message);
        setState(prevState => ({
          ...prevState,
          user: { ...prevState.user, loading: false },
          error: { ...prevState.error, status: true, message },
        }));
        onError('User', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [authUser.uid, i18n]);

  if (state.user.loading)
    return (
      <Layout disableHeader verticalCentered>
        <CircularProgress />
      </Layout>
    );

  if (state.error.status === true) {
    return <ErrorView msg={state.error.message} />;
  }

  if (state.user.data && state.user.data.events.registrationIsComplete !== true) {
    return <RegistrationView user={state.user.data} />;
  }

  return <UserContext.Provider value={{ user: state.user.data as User }}>{children}</UserContext.Provider>;
}
