import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  cover: {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    left: '0px',
    top: '0px',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
}));

export { default as ColorPicker } from './ColorPicker';
