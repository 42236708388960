import React, { Dispatch, Fragment, useState } from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { Snackbar, SnackbarContent, IconButton, useTheme } from '@material-ui/core';
import { INotificationItem } from 'interfaces';
import { NotificationProviderActions } from 'types';

export default function Notification({
  notification,
  dispatch,
}: {
  notification: INotificationItem;
  dispatch: Dispatch<NotificationProviderActions>;
}) {
  const [open, setOpen] = useState(true);
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: 'REMOVE_NOTIFICATION', payload: notification.id });
  };

  const errorStyle = { backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText };
  const successStyle = { backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText };
  let style = {};

  if (notification.type === 'error') style = { ...errorStyle };
  if (notification.type === 'success') style = { ...successStyle };

  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={notification.position ? { ...notification.position } : { vertical: 'bottom', horizontal: 'right' }}
      key={notification.id}
      open={open}
      onClose={handleClose}
      style={{ color: 'green' }}
    >
      <SnackbarContent
        action={
          <Fragment>
            <IconButton aria-label='close' color='inherit' onClick={handleClose} size='small'>
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
        variant='elevation'
        message={notification.message}
        style={{ ...style }}
      />
    </Snackbar>
  );
}
