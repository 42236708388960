import { convertFromHTML, ContentState, EditorState } from 'draft-js';
import { IContentEditorItem, IAdminContentEditorItem } from 'interfaces';

function convertHtmlToEditor(item: IContentEditorItem): IAdminContentEditorItem {
  const html = item.content;
  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return { ...item, content: EditorState.createWithContent(state) };
}

export default convertHtmlToEditor;
