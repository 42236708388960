import React from 'react';
import { IContentEditorItem } from 'interfaces';
import { useStyles } from '.';

export default function ContentEditorItem({
  children,
  item,
  isEdit,
}: {
  children?: any;
  item?: IContentEditorItem;
  isEdit?: boolean;
}) {
  const classes = useStyles();

  if (isEdit && children) {
    return <div className={classes.content}>{children}</div>;
  }

  if (item) return <div className={classes.content} dangerouslySetInnerHTML={{ __html: item.content }} />;

  return null;
}
