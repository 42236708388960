import { ILessonContentChoice } from 'interfaces';
import { LessonContentBase } from '..';

class LessonContentChoice extends LessonContentBase {
  constructor(data?: firebase.default.firestore.DocumentData) {
    super();
    this.setValues(data);
  }

  private _localizedFields!: {
    choice: ILessonContentChoice;
  };

  private _type!: 'choice';

  get localizedFields() {
    return this._localizedFields;
  }

  get type() {
    return this._type;
  }

  public getQuestion(currentLocale: string, fallbackLocale: string) {
    const { question } = this.localizedFields.choice;
    return question[currentLocale]
      ? question[currentLocale]
      : question[fallbackLocale]
      ? question[fallbackLocale]
      : 'undefined';
  }

  public getAnswer(answerId: string, currentLocale: string, fallbackLocale: string) {
    const { answers } = this.localizedFields.choice;

    const answer = answers.find(item => item.id === answerId);

    if (!answer) return 'undefined';

    const { label } = answer;

    return label[currentLocale] ? label[currentLocale] : label[fallbackLocale] ? label[fallbackLocale] : 'undefined';
  }
}

export default LessonContentChoice;
