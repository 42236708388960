import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout';
import { useStyles } from '.';

export default function ErrorView({ disableLayout, msg }: { disableLayout?: boolean; msg: string }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function refreshPage() {
    window.location.reload();
  }

  if (disableLayout) {
    return (
      <div className={classes.center}>
        <div>
          <Typography color='textPrimary' variant='body2'>
            500 | {msg}
          </Typography>
        </div>
        <div>
          <Button className={classes.button} onClick={() => refreshPage()}>
            {t('refresh_page')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Layout disableHeader verticalCentered>
      <div className={classes.center}>
        <div>
          <Typography color='textPrimary' variant='body2'>
            500 | {msg}
          </Typography>
        </div>
        <div>
          <Button className={classes.button} onClick={() => refreshPage()}>
            {t('refresh_page')}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
