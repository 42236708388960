import React, { useContext, useState } from 'react';
import DateFNS from 'utils/date/date-fns';
import useNotification from 'hooks/notification/useNotification';
import useUnits from 'hooks/data/useUnits';
import useUnitUsers from 'hooks/data/useUnitUsers';
import { CircularProgress } from 'components/Loading/CircularProgress';
import { DataContext } from 'providers/Data';
import { ErrorView } from 'views/Error';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { Form, Formik } from 'formik';
import { IUnitUserFormData } from 'interfaces';
import { Layout } from 'components/Layout';
import { Avatar, Grid, Link, TextField, Typography, useTheme } from '@material-ui/core';
import { NotFoundView } from 'views/NotFound';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SelectWithChipsField } from 'components/Forms/SelectWithChips';
import { Table } from 'components/Common/Table';
import { Unit, User } from 'models';
import { UserContext } from 'providers/User';
import { formatDistance } from 'date-fns';
import { functions } from 'configuration/firebase';
import { onError, onFunctions, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';
import { useTranslation } from 'react-i18next';
import { unitUserValidation } from '.';

export default function AdminUnitsPage() {
  const history = useHistory();
  const user = useContext(UserContext).user as User;

  const { unitsData, unitsError, unitsLoading } = useUnits(
    user.brand,
    user.isUnitAdmin(),
    user.isUserAdmin(),
    user.units
  );

  const { path } = useRouteMatch();

  if (unitsLoading) {
    return <Layout verticalCentered>{<CircularProgress />}</Layout>;
  }

  if (unitsError.status) {
    return <ErrorView msg={unitsError.message} />;
  }

  return (
    <Layout
      onIconClick={() => history.push('/admin/units')}
      defaultHorizontalMainPadding
      navigation={{ type: 'admin' }}
    >
      <Switch>
        <Route exact path={path}>
          <UnitsPage units={unitsData} />
        </Route>
        <Route exact path={`${path}/:unitId`}>
          <UnitDetailPage
            brandId={user.brand}
            isUnitAdmin={user.isUnitAdmin()}
            isUserAdmin={user.isUserAdmin()}
            units={unitsData}
          />
        </Route>
      </Switch>
    </Layout>
  );
}

const initialValues: IUnitUserFormData = {
  confirmEmail: '',
  email: '',
  groups: [],
  units: [],
};

function UnitsPage({ units }: { units: Unit[] }) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const myUser = useContext(UserContext).user as User;
  const myGroups = useContext(DataContext).groups.filter(
    group => myUser.groups.includes(group.id) && group.rInviteUnitSelectable
  );
  const notify = useNotification();
  const theme = useTheme();

  const { brand } = useContext(DataContext);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleInvite = async (values: IUnitUserFormData) => {
    const inviteUserToUnits = functions.httpsCallable('inviteUserToUnits');
    return inviteUserToUnits({
      brandName: brand?.name,
      currentLocale: myUser.settings.currentLocale,
      email: values.email,
      groups: values.groups,
      units: values.units,
    });
  };

  return (
    <>
      {open && (
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            handleInvite(values)
              .then(() => {
                notify(t('alert_user_invite_success'));
              })
              .catch(err => {
                if (err.code === 'already-exists') {
                  notify(t('alert_user_already_in_use_error', { email: values.email }), 'error');
                  return;
                }
                notify(t('alert_user_invite_error'), 'error');
              })
              .finally(() => {
                setOpen(false);
              });
          }}
          validateOnBlur
          validationSchema={() => unitUserValidation()}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <>
                <FormDialog
                  createButtonText={t('invite_button')}
                  handleClick={handleSubmit}
                  handleClose={() => {
                    setOpen(false);
                  }}
                  loading={isSubmitting}
                  open={true}
                  title={t('invite_user')}
                  type={'create'}
                  updateButtonText={t('update_button')}
                >
                  <Form className={globalClasses.form} noValidate>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <Avatar
                            style={{
                              height: theme.spacing(12),
                              width: theme.spacing(12),
                              marginBottom: theme.spacing(1),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          error={!!errors.email && touched.email}
                          fullWidth
                          helperText={!!errors.email && touched.email && errors.email ? errors.email : ' '}
                          FormHelperTextProps={{
                            style: {
                              minHeight: 19,
                            },
                          }}
                          id='email'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label='Email'
                          name='email'
                          required
                          size='small'
                          value={values.email}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isSubmitting}
                          error={!!errors.confirmEmail && touched.confirmEmail}
                          fullWidth
                          helperText={
                            !!errors.confirmEmail && touched.confirmEmail && errors.confirmEmail
                              ? errors.confirmEmail
                              : ' '
                          }
                          FormHelperTextProps={{
                            style: {
                              minHeight: 19,
                            },
                          }}
                          id='confirmEmail'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={t('confirm_email_label')}
                          name='confirmEmail'
                          required
                          size='small'
                          value={values.confirmEmail}
                          variant='outlined'
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                      <TextField
                        disabled
                        fullWidth
                        FormHelperTextProps={{
                          style: {
                            minHeight: 19,
                          },
                        }}
                        label={t('user_role_label')}
                        helperText=' '
                        select
                        size='small'
                        variant='outlined'
                        value='user'
                      >
                        <MenuItem value={'user'}>User</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled
                        fullWidth
                        FormHelperTextProps={{
                          style: {
                            minHeight: 19,
                          },
                        }}
                        helperText=' '
                        label={t('user_current_locale_label')}
                        required
                        select
                        size='small'
                        variant='outlined'
                        value={myUser.settings.currentLocale}
                      >
                        <MenuItem value={myUser.settings.currentLocale}>
                          {t(`locale_${myUser.settings.currentLocale}`)}
                        </MenuItem>
                      </TextField>
                    </Grid> */}
                      {myGroups.length > 0 ? (
                        <Grid item xs={12}>
                          <SelectWithChipsField
                            caption={errors.units && touched.units ? errors.units : undefined}
                            error={!!errors.groups && touched.groups}
                            dense
                            disabled={myGroups.length === 0 || isSubmitting}
                            handleChange={handleChange}
                            label={t('user_groups_label')}
                            name='groups'
                            selectedValues={values.groups}
                            values={myGroups.map(group => ({ label: group.key || group.id, value: group.id }))}
                          />
                          <div style={{ minHeight: 19 }} />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <SelectWithChipsField
                          caption={errors.units && touched.units ? errors.units : undefined}
                          dense
                          disabled={units.length === 0 || isSubmitting}
                          error={!!errors.units && touched.units}
                          handleChange={handleChange}
                          label={t('user_units_label')}
                          required
                          name='units'
                          selectedValues={values.units}
                          values={units.map(unit => ({ label: unit.key, value: unit.id }))}
                        />
                        <div style={{ minHeight: 19 }} />
                      </Grid>
                    </Grid>
                  </Form>
                </FormDialog>
              </>
            );
          }}
        </Formik>
      )}
      <Table
        columns={[
          { field: 'key', title: t('unit_key_label'), defaultSort: 'asc' },
          { field: 'internalId', title: t('unit_internalId_label') },
        ]}
        data={units.map(unit => ({
          id: unit.id,
          internalId: unit.internalId,
          key: unit.key,
        }))}
        onAddClick={() => setOpen(true)}
        onRowClick={(event, { id }: any) => history.push(`/admin/units/${id}`)}
        title='Units'
      />
    </>
  );
}

function UnitDetailPage({
  brandId,
  isUnitAdmin,
  isUserAdmin,
  units,
}: {
  brandId: string;
  isUnitAdmin: boolean;
  isUserAdmin: boolean;
  units: Unit[];
}) {
  const notify = useNotification();
  const theme = useTheme();

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { brand } = useContext(DataContext);
  const { unitId }: { unitId: string } = useParams();
  const { usersData, usersError, usersLoading } = useUnitUsers(brandId, isUnitAdmin, isUserAdmin, unitId);

  const unit = units.find(unitItem => unitItem.id === unitId);
  const myUser = useContext(UserContext).user as User;

  const [inviteState, setInviteState] = useState({
    loading: false,
    uid: '',
  });

  if (usersLoading) {
    return <CircularProgress />;
  }

  if (usersError.status) {
    return <ErrorView disableLayout msg={usersError.message} />;
  }

  if (usersData.length < 1 || !unit) {
    return <NotFoundView disableHeader />;
  }

  const handleReinvite = (uid: string) => {
    if (!uid) return;
    setInviteState(prevState => ({ ...prevState, uid, loading: true }));
    const reinviteUserToUnits = functions.httpsCallable('reinviteUserToUnits');
    onFunctions('reinviteUserToUnits');
    reinviteUserToUnits({ brandName: brand?.name, uid })
      .then(() => {
        notify(t('alert_reinvite_success'));
        onSuccess('reinviteUserToUnits', 'callable-function-success');
      })
      .catch(err => {
        notify(t('alert_reinvite_error'), 'error');
        onError('AdminUnitsPage', err);
      })
      .finally(() => {
        setInviteState(prevState => ({ ...prevState, uid: '', loading: false }));
      });
  };

  return (
    <Table
      columns={[
        { field: 'email', title: 'Email', defaultSort: 'asc' },
        { field: 'name', title: 'Name' },
        { field: 'lastLoginAt', title: t('last_login_at'), type: 'date' },
        {
          disableClick: true,
          field: 'invitation',
          title: t('user_invitation_label'),
          render: ({ id, invitation: { invitationIsComplete, validUntil } }: any) => {
            const now = new Date();

            if (inviteState.loading && inviteState.uid === id) {
              return t('loading_progress');
            }

            if (invitationIsComplete) {
              return t('invitation_completed');
            }
            if (validUntil.toDate() > now) {
              return (
                t('expires_in') +
                ': ' +
                formatDistance(validUntil.toDate(), now, {
                  addSuffix: true,
                  locale: DateFNS.getLocale(language),
                })
              );
            }

            return (
              <Typography color='error' style={{ fontSize: theme.typography.fontSize - 1 }} variant='body2'>
                {t('invitation_expired', {
                  date: formatDistance(validUntil.toDate(), now, {
                    addSuffix: true,
                    locale: DateFNS.getLocale(language),
                  }),
                })}
              </Typography>
            );
          },
        },
        {
          field: 'actions',
          sorting: false,
          searchable: false,
          disableClick: true,
          align: 'right',
          render: ({ invitation: { invitationIsComplete }, id }: any) => {
            return (
              <>
                {invitationIsComplete === false && (
                  <Link
                    color={inviteState.loading ? 'textSecondary' : 'primary'}
                    component='button'
                    onClick={() => {
                      if (inviteState.loading) {
                        return;
                      }
                      handleReinvite(id);
                    }}
                    underline={inviteState.loading ? 'none' : 'hover'}
                    style={{ cursor: inviteState.loading ? 'default' : 'pointer', marginRight: 48 }}
                  >
                    {inviteState.loading && inviteState.uid === id ? t('loading_progress') : t('resend_invitation')}
                  </Link>
                )}
              </>
            );
          },
        },
      ]}
      data={usersData
        .map(({ email, id, invitation, lastLoginAt, settings: { currentLocale, firstname, lastname, photoUrl } }) => {
          return {
            currentLocale,
            id,
            photoUrl,
            email,
            invitation,
            name: firstname + ' ' + lastname,
            lastLoginAt: lastLoginAt && lastLoginAt.general ? lastLoginAt.general.toDate() : ' ',
          };
        })
        .filter(userItem => myUser.id !== userItem.id)}
      title={unit.key}
    />
  );
}
