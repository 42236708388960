import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { AdminBrandPage, AdminFilesPage, AdminNewsPage, AdminLearningPage, AdminUnitsPage } from '.';
import { NotFoundView } from 'views/NotFound';
import { ResourceRoute } from 'components/Common/Routing/ResourceRoute';
import { UserContext } from 'providers/User';

export default function AdminPage() {
  const { path } = useRouteMatch();
  const { user } = useContext(UserContext);

  const getRedirectRoute = (): string => {
    if (user?.isUserAdmin()) {
      return path + '/brand';
    }

    if (user?.isLearningContentAdmin()) {
      return path + '/learning-content';
    }

    if (user?.isNewsAdmin()) {
      return path + '/news';
    }

    if (user?.isFileAdmin()) {
      return path + '/files';
    }

    if (user?.isUnitAdmin()) {
      return path + '/units';
    }

    return '/';
  };

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={getRedirectRoute()} />
      </Route>
      <ResourceRoute path={`${path}/learning-content`} resource='rEditContent'>
        <AdminLearningPage />
      </ResourceRoute>
      <ResourceRoute path={`${path}/brand`} resource='rManageUsers'>
        <AdminBrandPage />
      </ResourceRoute>
      <ResourceRoute path={`${path}/files`} resource='rEditFiles'>
        <AdminFilesPage />
      </ResourceRoute>
      <ResourceRoute path={`${path}/news`} resource='rEditNews'>
        <AdminNewsPage />
      </ResourceRoute>
      <ResourceRoute path={`${path}/units`} resource='rInviteUnit'>
        <AdminUnitsPage />
      </ResourceRoute>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  );
}
