import React, { Fragment, useContext } from 'react';
import { Divider, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { ExpandMore } from '@material-ui/icons';
import { EditorState } from 'draft-js';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

import useNotification from 'hooks/notification/useNotification';
import { Brand } from 'models';
import { ContentBuilderActionBar } from './ActionBar';
import { ContentImageItem } from 'components/Slide/Content/ImageItem';
import { ContentVideoItem } from 'components/Slide/Content/VideoItem';
import { DataContext } from 'providers/Data';
import { EditorItem } from './Items/Editor';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  IThreeColumnAdminLayoutItem,
  ITwoColumnAdminLayoutItem,
} from 'interfaces';
import { ContentType, LayoutItemType, Map } from 'types';
import { MediaLibraryDialog } from 'components/Dialogs/MediaLibrary';
import { Accordion, AccordionDetails, AccordionSummary, useStyles } from '.';
import { LayoutTwoColumnItem } from 'components/Forms/ContentBuilder/Items/Layout/TwoColumn';
import { LayoutThreeColumnItem } from 'components/Forms/ContentBuilder/Items/Layout/ThreeColumn';
import { ContentBuilderItemBar } from './Items/Bar';

const getItemStyle = (isDragging: boolean, theme: Theme, draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
  border: isDragging ? `solid 1px ${theme.palette.divider}` : 0,
  ...draggableStyle,
});

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ContentBuilder({
  content,
  contentType,
}: {
  content: Map<
    Array<
      | IAdminContentEditorItem
      | IContentImageItem
      | IContentVideoItem
      | ITwoColumnAdminLayoutItem
      | IThreeColumnAdminLayoutItem
    >
  >;
  contentType: ContentType;
}) {
  const brand = useContext(DataContext).brand as Brand;
  const classes = useStyles();
  const notify = useNotification();
  const theme = useTheme();

  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const [activeDialog, setActiveDialog] = React.useState({
    addContentOrLayoutItem: { open: false },
    addMediaItem: { open: false },
  });
  const [activeLocale, setActiveLocale] = React.useState(brand.configuration.fallbackLocale);

  const handleAddColumnLayout = (type: LayoutItemType) => {
    const copyArr = [...content[activeLocale]];

    const columns: any = {};

    if (type === 'two-column') {
      columns['left'] = [];
      columns['right'] = [];
    }

    if (type === 'three-column') {
      columns['left'] = [];
      columns['middle'] = [];
      columns['right'] = [];
    }

    copyArr.push({
      columns,
      id: uuid(),
      sortIndex: copyArr.length + 1,
      type,
    });
    setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
  };

  const handleAddEditorItem = () => {
    const copyArr = [...content[activeLocale]];
    copyArr.push({ content: EditorState.createEmpty(), id: uuid(), sortIndex: copyArr.length + 1, type: 'editor' });
    setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
  };

  const handleAddMediaItem = (name: string, url: string, type: 'image' | 'video') => {
    const copyArr = [...content[activeLocale]];
    const newItem: IContentImageItem | IContentVideoItem = {
      content: { name, url },
      id: uuid(),
      sortIndex: copyArr.length + 1,
      type,
    };
    copyArr.push(newItem);
    setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
  };

  const handleDeleteItem = (contentId: string) => {
    const copyArr = [...content[activeLocale]];
    const index = copyArr.findIndex(item => item.id === contentId);
    if (index !== -1) {
      copyArr.splice(index, 1);
      setFieldValue(`localizedFields.content.${activeLocale}`, copyArr.map((item, i) => ({ ...item, sortIndex: i + 1 })));
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(content[activeLocale], result.source.index, result.destination.index).map(
      (item: any, index: number) => {
        item.sortIndex = index + 1;
        return item;
      }
    );

    setFieldValue(`localizedFields.content.${activeLocale}`, items);
  };

  const renderContentBuilderItems = () => {
    if (content[activeLocale].length === 0)
      return (
        <div className={classes.center}>
          <Typography variant='body2'>{t('no_content_available', { locale: t('locale_' + activeLocale) })}</Typography>
        </div>
      );

    return content[activeLocale].map((item, index) => (
      <Fragment key={item.id}>
        <Divider />
        <Draggable draggableId={item.id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, theme, provided.draggableProps.style)}
            >
              <Accordion square TransitionProps={{}}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <ContentBuilderItemBar
                    activeLocale={activeLocale}
                    handleDeleteItem={() => handleDeleteItem(item.id)}
                    item={item}
                    isRootItem={true}
                  />
                </AccordionSummary>
                <AccordionDetails>{renderContentBuilderItem(item)}</AccordionDetails>
              </Accordion>
            </div>
          )}
        </Draggable>
      </Fragment>
    ));
  };

  const renderContentBuilderItem = (
    item:
      | IAdminContentEditorItem
      | IContentImageItem
      | IContentVideoItem
      | ITwoColumnAdminLayoutItem
      | IThreeColumnAdminLayoutItem
  ) => {
    if (item.type === 'editor') return <EditorItem activeLocale={activeLocale} item={item} />;

    if (item.type === 'image' || item.type === 'video') {
      return (
        <div className={classes.content}>
          {item.type === 'image' && <ContentImageItem item={item} />}
          {item.type === 'video' && <ContentVideoItem item={item} />}
        </div>
      );
    }

    if (item.type === 'two-column' || item.type === 'three-column') {
      return (
        <div className={classes.content} style={{ padding: 0 }}>
          {item.type === 'two-column' && <LayoutTwoColumnItem activeLocale={activeLocale} item={item} />}
          {item.type === 'three-column' && <LayoutThreeColumnItem activeLocale={activeLocale} item={item} />}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Paper className={classes.rootPaper}>
        <ContentBuilderActionBar
          activeLocale={activeLocale}
          contentType={contentType}
          addContentOrLayoutItemDialogOnClose={() =>
            setActiveDialog(prevState => ({
              ...prevState,
              addContentOrLayoutItem: { ...prevState.addContentOrLayoutItem, open: false },
            }))
          }
          addContentOrLayoutItemDialogOnOpen={() => {
            setActiveDialog(prevState => ({
              ...prevState,
              addContentOrLayoutItem: { ...prevState.addContentOrLayoutItem, open: true },
            }));
          }}
          addContentOrLayoutItemDialogOpen={activeDialog.addContentOrLayoutItem.open}
          content={content}
          handleAddColumnLayout={handleAddColumnLayout}
          handleAddEditorItem={handleAddEditorItem}
          handleAddMediaItem={() => setActiveDialog(prevState => ({ ...prevState, addMediaItem: { open: true } }))}
          onActiveLocaleChange={(event, value) => setActiveLocale(value)}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable'>
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {renderContentBuilderItems()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
      {activeDialog.addMediaItem.open && (
        <MediaLibraryDialog
          handleClose={() => {
            setActiveDialog(prevState => ({ ...prevState, addMediaItem: { open: false } }));
          }}
          selectFile={file => {
            if (!file || !file.contentType) {
              notify(t('alert_media_item_picked_error'), 'error');
              return;
            }
            if (file.contentType.startsWith('image/')) {
              handleAddMediaItem(file.name, file.url, 'image');
              return;
            }

            if (file.contentType.startsWith('video/')) {
              handleAddMediaItem(file.name, file.url, 'video');
              return;
            }

            notify(t('alert_media_item_picked_error'), 'error');
          }}
          open={true}
        />
      )}
    </>
  );
}
