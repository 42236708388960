import React, { useContext } from 'react';
import { Check } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { Grid, Link, TextField, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, Course, Group, Lesson, Role, User } from 'models';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog';
import { DataContext } from 'providers/Data';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { ILessonGeneralFormData, ILessonRestrictionsFormData } from 'interfaces';
import { Table } from 'components/Common/Table';
import { UserContext } from 'providers/User';
import { arrayUnion, firestore, serverTimestamp } from 'configuration/firebase';
import { lessonValidation } from '.';
import { onError, onFirestore, onSuccess } from 'utils/logger';
import { useGlobalStyles } from 'providers/Theme';

export default function AdminCourseDetailLessonsPage({ course }: { course: Course }) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const notify = useNotification();
  const theme = useTheme();
  const user = useContext(UserContext).user as User;

  const { brand, lessons } = useContext(DataContext);
  const { t } = useTranslation();

  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteState, setDeleteState] = React.useState<{
    lesson?: Lesson;
    dialogOpen: boolean;
    loading: boolean;
    name: string | null;
  }>({
    lesson: undefined,
    dialogOpen: false,
    loading: false,
    name: null,
  });

  const courseLessons = lessons.admin.filter(lesson => lesson.course === course.id);

  const initialValues: ILessonGeneralFormData & ILessonRestrictionsFormData = {
    durationInMin: 0,
    groupRestrictions: [],
    key: '',
    localizedFields: { title: { ...(brand as Brand).getLocalesAsObject() } },
    preConditions: [],
    published: false,
    roleRestrictions: [],
    sortIndex: courseLessons.length + 1,
  };

  const renderErrors = (errors: any, touched: any, locale: string): boolean => {
    if (
      errors &&
      errors.localizedFields &&
      errors.localizedFields.title &&
      errors.localizedFields.title[locale] &&
      touched &&
      touched.localizedFields &&
      touched.localizedFields.title &&
      touched.localizedFields.title[locale]
    ) {
      return true;
    }
    return false;
  };

  const handleCreateLessonAndUptadeCourse = async (values: ILessonGeneralFormData & ILessonRestrictionsFormData) => {
    const batch = firestore.batch();

    const brandRef = firestore.collection(Brand.collection).doc((brand as Brand).id);
    const courseRef = brandRef.collection(Course.collection).doc(course.id);
    const newLessonRef = brandRef.collection(Lesson.collection).doc();
    const userRef = firestore.collection(User.collection).doc(user.id);

    onFirestore('batch-set', 'lesson');
    onFirestore('batch-set', 'course');

    batch.set(
      newLessonRef,
      {
        ...values,
        course: courseRef.id,
        courseRef,
        createdAt: serverTimestamp,
        createdFrom: userRef.id,
        createdFromRef: userRef,
        groupRestrictionRefs: values.groupRestrictions.map(groupId =>
          brandRef.collection(Group.collection).doc(groupId)
        ),
        hasGroupRestrictions: values.groupRestrictions.length > 0,
        hasPreConditions: values.preConditions.length > 0,
        hasRoleRestrictions: values.roleRestrictions.length > 0,
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lessonContents: [],
        lessonContentRefs: [],
        preConditionRefs: values.preConditions.map(preConditionId =>
          brandRef.collection(Lesson.collection).doc(preConditionId)
        ),
        roleRestrictionRefs: values.roleRestrictions.map(roleId => brandRef.collection(Role.collection).doc(roleId)),
      },
      { merge: true }
    );

    batch.set(
      courseRef,
      {
        lastUpdateAt: serverTimestamp,
        lastUpdateFrom: userRef.id,
        lastUpdateFromRef: userRef,
        lessonRefs: arrayUnion(newLessonRef),
        lessons: arrayUnion(newLessonRef.id),
      },
      { merge: true }
    );

    return new Promise<firebase.default.firestore.DocumentReference<firebase.default.firestore.DocumentData>>(
      async (resolve, reject) => {
        try {
          await batch.commit();
          onSuccess(newLessonRef.path, 'batch-success');
          onSuccess(courseRef.path, 'batch-success');
          return resolve(newLessonRef);
        } catch (err) {
          onError('AdminCourseDetailLessonsPage', err);
          reject();
        }
      }
    );
  };

  const handleDelete = async () => {
    setDeleteState(prevState => ({ ...prevState, loading: true }));

    const copy = { ...deleteState };

    if (copy.lesson) {
      try {
        await copy.lesson.delete(brand?.id as string);
        notify(t('alert_lesson_deletion_success'));
      } catch (err) {
        console.error(err);
        notify(t('alert_lesson_deletion_error'), 'error');
      }
    } else {
      notify(t('alert_lesson_deletion_error'), 'error');
    }

    setDeleteState({ lesson: undefined, loading: false, name: null, dialogOpen: false });
  };

  const handleOpenDeleteLesson = (id: string) => {
    const lesson = lessons.admin.find(lessonItem => lessonItem.id === id);

    if (!lesson) {
      notify(t('alert_lesson_deletion_error'), 'error');
    } else {
      setDeleteState({ lesson, dialogOpen: true, loading: false, name: lesson.key });
    }
  };

  return (
    <>
      <ConfirmationDialog
        contentText={t('delete_lesson_confirmation_dialog_content_text', { name: deleteState.name })}
        handleClick={() => handleDelete()}
        handleClose={() => {
          setDeleteState({ lesson: undefined, name: null, loading: false, dialogOpen: false });
        }}
        loading={deleteState.loading}
        open={deleteState.dialogOpen}
        title={t('delete_lesson_confirmation_dialog_title')}
        type='delete'
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleCreateLessonAndUptadeCourse(values)
            .then(ref => {
              history.push(`/admin/learning-content/lesson/${ref.id}`);
            })
            .catch(() => {
              notify(t('alert_lesson_creation_error'), 'error');
            });
        }}
        validateOnBlur
        validationSchema={() =>
          lessonValidation((brand as Brand).configuration.locales, (brand as Brand).configuration.fallbackLocale)
        }
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, isSubmitting }) => {
          return (
            <FormDialog
              createButtonText={t('create_button')}
              handleClick={handleSubmit}
              handleClose={() => {
                resetForm();
                setCreateOpen(false);
              }}
              loading={isSubmitting}
              open={createOpen}
              title={t('create_lesson_dialog_title')}
              type={'create'}
              updateButtonText={t('update_button')}
            >
              <Form className={globalClasses.form} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.key && touched.key}
                      fullWidth
                      helperText={
                        !!errors.key && touched.key && errors.key ? errors.key : t('lesson_key_label_helper_text')
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='key'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('lesson_key_label')}
                      margin='dense'
                      name='key'
                      required
                      value={values.key}
                      variant='outlined'
                    />
                  </Grid>
                  {(brand as Brand).configuration.locales.map(locale => (
                    <Grid key={locale} item xs={12}>
                      <TextField
                        disabled={isSubmitting}
                        error={renderErrors(errors, touched, locale)}
                        fullWidth
                        helperText={
                          renderErrors(errors, touched, locale)
                            ? (errors.localizedFields?.title as any)[locale]
                            : t('lesson_title_in_locale', { locale: t(`locale_${locale}`) })
                        }
                        FormHelperTextProps={{
                          style: {
                            minHeight: 19,
                          },
                        }}
                        id={`localizedFields.title.${locale}`}
                        label={t('lesson_title_label')}
                        onBlur={handleBlur}
                        margin='dense'
                        name={`localizedFields.title.${locale}`}
                        onChange={handleChange}
                        required={locale === (brand?.configuration.fallbackLocale as string)}
                        value={values.localizedFields.title[locale]}
                        variant='outlined'
                      />
                    </Grid>
                  ))}
                </Grid>
              </Form>
            </FormDialog>
          );
        }}
      </Formik>
      <Table
        onAddClick={() => setCreateOpen(true)}
        onRowClick={(event, data: any) => history.push(`/admin/learning-content/lesson/${data.id}`)}
        columns={[
          { field: 'key', title: t('lesson_key'), resizable: false },
          { defaultSort: 'asc', field: 'sortIndex', title: t('lesson_sortIndex'), resizable: false },
          {
            field: 'hasRestrictions',
            title: t('restrictions'),
            render: (rowData: any) =>
              rowData.hasRestrictions ? (
                <div style={{ fontSize: theme.typography.fontSize - 1 }}>
                  <Check fontSize='inherit' />
                </div>
              ) : null,
          },
          {
            field: 'hasPreConditions',
            title: t('preconditions'),
            render: (rowData: any) =>
              rowData.hasPreConditions ? (
                <div style={{ fontSize: theme.typography.fontSize - 1 }}>
                  <Check fontSize='inherit' />
                </div>
              ) : null,
          },
          { field: 'lastUpdateAt', title: t('last_modified'), type: 'datetime', resizable: false },
          {
            field: 'published',
            title: 'Status',
            type: 'string',
            render: ({ published }: any) =>
              published ? 'Published' : <span style={{ color: theme.palette.error.light }}>Unpublished</span>,
          },
          {
            field: 'actions',
            sorting: false,
            searchable: false,
            disableClick: true,
            align: 'right',
            render: ({ id }: any) => (
              <Link
                color='primary'
                component='button'
                onClick={() => history.push(`/admin/learning-content/lesson/${id}/lesson-contents`)}
                underline='none'
              >
                {t('lesson_contents_button')}
              </Link>
            ),
          },
          {
            field: 'actions',
            sorting: false,
            searchable: false,
            disableClick: true,
            align: 'right',
            render: ({ id }: any) => (
              <Link color='error' component='button' onClick={() => handleOpenDeleteLesson(id)} underline='none'>
                {t('delete_lesson_button')}
              </Link>
            ),
          },
        ]}
        data={courseLessons.map(
          ({
            hasGroupRestrictions,
            hasRoleRestrictions,
            hasPreConditions,
            id,
            key,
            lastUpdateAt,
            published,
            sortIndex,
          }) => ({
            hasRestrictions: hasGroupRestrictions || hasRoleRestrictions,
            hasPreConditions,
            id,
            key,
            lastUpdateAt: lastUpdateAt ? lastUpdateAt.toDate() : t('loading_progress'),
            published,
            sortIndex,
          })
        )}
        title={t('lessons').toString()}
      />
    </>
  );
}
