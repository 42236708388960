import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bottomCard: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topCard: {
    marginBottom: theme.spacing(1),
  },
  topContainer: {
    flex: 1,
    height: '100%',
  },
}));

export const shuffle = (array: any[]) => {
  // Fisher–Yates shuffle
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    // swap elements array[i] and array[j] "destructuring assignment" syntax to achieve that
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export { default as SortingSlide } from './SortingSlide';
