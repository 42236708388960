import React from 'react';
import { IAdminLessonContentsDataState } from 'interfaces';
import { LessonContent, LessonContentBase, LessonContentChoice, LessonContentSorting } from 'models';
import { conditionalCollection } from 'utils/data';
import { firestore } from 'configuration/firebase';
import { onFirestore, onListener, onError } from 'utils/logger';

export default function useAdminLessonContents(
  brandPath: string,
  isLearningContentAdmin: boolean
): IAdminLessonContentsDataState {
  const [state, setState] = React.useState<IAdminLessonContentsDataState>({
    adminLessonContentsError: { status: false, message: '' },
    adminLessonContentsData: [],
    adminLessonContentsLoading: true,
  });

  const lessonContentsPath = firestore.doc(brandPath).collection(LessonContentBase.collection).path;

  React.useEffect(() => {
    if (isLearningContentAdmin) {
      onFirestore('onSnapshot', lessonContentsPath);

      const collectionRef = conditionalCollection(lessonContentsPath);
      let query = collectionRef;

      const unsubscribe = query.onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach((change, i) => {
            onListener(
              i,
              snapshot.docs.length,
              'LessonContent',
              change.doc.id,
              change.oldIndex,
              change.newIndex,
              change.type
            );
          });
          const adminLessonContentsData = snapshot.docs.map(doc => {
            const data = doc.data();
            switch (data.type) {
              case 'choice': {
                return new LessonContentChoice({ ...data, id: doc.id });
              }
              case 'sorting': {
                return new LessonContentSorting({ ...data, id: doc.id });
              }
              default:
                return new LessonContent({ ...data, id: doc.id });
            }
          });
          setState(prevState => ({
            ...prevState,
            adminLessonContentsData,
            adminLessonContentsLoading: false,
          }));
        },
        error => {
          const message = 'Lesson Contents data could not be loaded.';
          console.error('Error: ', message);
          setState(prevState => ({
            ...prevState,
            adminLessonContentsError: { status: true, message },
            adminLessonContentsLoading: false,
          }));
          onError('Lesson Contents', error);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      setState(prevState => ({
        ...prevState,
        adminLessonContentsLoading: false,
      }));
    }
  }, [isLearningContentAdmin, lessonContentsPath]);

  return { ...state };
}
