import { ChangeEvent } from 'react';
import Model from 'models/Model';
import { Course, Lesson, UserProgress } from 'models';
import { app, arrayUnion, authentication, firestore, serverTimestamp, storage } from 'configuration/firebase';
import { onError, onFirestore, onInfo, onStorage, onSuccess } from 'utils/logger';

class User extends Model {
  constructor(data?: any) {
    super();
    this.setValues(data);
  }

  private static _collection: string = 'users';
  private _brand!: string;
  private _brandRef!: firebase.default.firestore.DocumentReference;
  private _createdAt!: firebase.default.firestore.Timestamp;
  private _createdFrom?: string | null;
  private _createdFromRef?: firebase.default.firestore.DocumentReference | null;
  private _email!: string;
  private _events!: { registrationIsComplete?: boolean };
  private _groups!: string[];
  private _groupRefs!: firebase.default.firestore.DocumentReference[];
  private _invitation!: {
    code?: string;
    invitationIsComplete: boolean;
    lastMailSentAt?: firebase.default.firestore.Timestamp;
    validUntil?: firebase.default.firestore.Timestamp;
  };
  private _lastClaimUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastLoginAt?: {
    app?: firebase.default.firestore.Timestamp;
    backend?: firebase.default.firestore.Timestamp;
    general?: firebase.default.firestore.Timestamp;
    web?: firebase.default.firestore.Timestamp;
  };
  private _lastUpdateAt!: firebase.default.firestore.Timestamp;
  private _lastUpdateFrom!: string;
  private _lastUpdateFromRef!: firebase.default.firestore.DocumentReference;
  private _lessonsProgress!: string[];
  private _newsProgress?: string[];
  private _resources!: string[];
  private _resourceRefs!: firebase.default.firestore.DocumentReference[];
  private _roleRef!: firebase.default.firestore.DocumentReference;
  private _role!: string;
  private _settings!: { currentLocale: string; firstname: string; lastname: string; photoUrl: string };
  private _units!: string[];
  private _unitRefs!: firebase.default.firestore.DocumentReference[];

  static get collection(): string {
    return this._collection;
  }

  get brand(): string {
    return this._brand;
  }

  get brandRef(): firebase.default.firestore.DocumentReference {
    return this._brandRef;
  }

  get createdAt(): firebase.default.firestore.Timestamp {
    return this._createdAt;
  }

  get createdFrom(): string | null | undefined {
    return this._createdFrom;
  }

  get createdFromRef(): firebase.default.firestore.DocumentReference | null | undefined {
    return this._createdFromRef;
  }

  get email(): string {
    return this._email;
  }

  get events(): { registrationIsComplete?: boolean } {
    return this._events;
  }
  get groups(): string[] {
    return this._groups;
  }

  get groupRefs(): firebase.default.firestore.DocumentReference[] {
    return this._groupRefs;
  }

  get invitation(): {
    code?: string;
    invitationIsComplete: boolean;
    lastMailSentAt?: firebase.default.firestore.Timestamp;
    validUntil?: firebase.default.firestore.Timestamp;
  } {
    return this._invitation;
  }

  get lastClaimUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastClaimUpdateAt;
  }

  get lastLoginAt():
    | undefined
    | {
        app?: firebase.default.firestore.Timestamp;
        backend?: firebase.default.firestore.Timestamp;
        general?: firebase.default.firestore.Timestamp;
        web?: firebase.default.firestore.Timestamp;
      } {
    return this._lastLoginAt;
  }

  get lastUpdateAt(): firebase.default.firestore.Timestamp {
    return this._lastUpdateAt;
  }

  get lastUpdateFrom(): string {
    return this._lastUpdateFrom;
  }

  get latUpdateFromRef(): firebase.default.firestore.DocumentReference {
    return this._lastUpdateFromRef;
  }

  get lessonsProgress(): string[] {
    return this._lessonsProgress;
  }

  get newsProgress(): string[] | undefined {
    return this._newsProgress;
  }

  get resources(): string[] {
    return this._resources;
  }

  get resourceRefs(): firebase.default.firestore.DocumentReference[] {
    return this._resourceRefs;
  }

  get roleRef(): firebase.default.firestore.DocumentReference {
    return this._roleRef;
  }

  get role(): string {
    return this._role;
  }

  get settings(): { currentLocale: string; firstname: string; lastname: string; photoUrl: string } {
    return this._settings;
  }

  get units(): string[] {
    return this._units;
  }

  get unitRefs(): firebase.default.firestore.DocumentReference[] {
    return this._unitRefs;
  }

  public async completeRegistration(firstname: string, lastname: string) {
    const userRef = firestore.collection(User.collection).doc(this.id);

    onFirestore('set', userRef.path, [
      ' events: { registrationIsComplete }',
      'lastUpdateAt',
      'lastUpdateFrom',
      'lastUpdateFromRef',
      'settings: { firstname, lastname }',
    ]);

    return userRef
      .set(
        {
          events: { registrationIsComplete: true },
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: this.id,
          lastUpdateFromRef: userRef,
          settings: { firstname, lastname },
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(userRef.path, 'set-success');
      });
  }

  public hasAdminAccess() {
    if (this.resources.length === 0) return false;

    if (this.resources.includes('rEditContent')) return true;
    if (this.resources.includes('rEditFiles')) return true;
    if (this.resources.includes('rEditNews')) return true;
    if (this.resources.includes('rInviteUnit')) return true;
    if (this.resources.includes('rManageUsers')) return true;

    return false;
  }

  public isFileAdmin() {
    return this.resources.includes('rEditFiles');
  }

  public isLearningContentAdmin() {
    return this.resources.includes('rEditContent');
  }

  public isNewsAdmin() {
    return this.resources.includes('rEditNews');
  }

  public isUserAdmin() {
    return this.resources.includes('rManageUsers');
  }

  public isUnitAdmin() {
    return this.resources.includes('rInviteUnit');
  }

  public async addLearningProgress(progress: UserProgress[], course?: Course, lesson?: Lesson) {
    onFirestore('batch-set', 'addLearningProgress');
    if (!course || !lesson) throw new Error('Internal error...');

    const batch = firestore.batch();
    const brandLessonsProgressRef = this.brandRef.collection('brandprogress').doc();
    const userRef = firestore.collection(User.collection).doc(this.id);

    batch.set(userRef, { lessonsProgress: arrayUnion(lesson.id) }, { merge: true });

    const progressDoc = progress.find(progress => progress.lesson === lesson.id);

    if (progressDoc) {
      batch.set(
        userRef.collection(UserProgress.collection).doc(progressDoc.id),
        {
          currentData: {
            course: { key: course.key, localizedFields: { ...course.localizedFields } },
            lesson: { key: lesson.key, localizedFields: { ...lesson.localizedFields } },
          },
          timestamp: serverTimestamp,
        },
        { merge: true }
      );
    } else {
      batch.set(userRef.collection(UserProgress.collection).doc(), {
        brand: this.brand,
        brandRef: this.brandRef,
        course: lesson.course,
        courseRef: lesson.courseRef,
        currentData: {
          course: { key: course.key, localizedFields: { ...course.localizedFields } },
          lesson: { key: lesson.key, localizedFields: { ...lesson.localizedFields } },
        },
        lesson: lesson.id,
        lessonRef: this.brandRef.collection(Lesson.collection).doc(lesson.id),
        timestamp: serverTimestamp,
        user: this.id,
        userRef: userRef,
      });
    }

    batch.set(brandLessonsProgressRef, {
      brand: this.brand,
      brandRef: this.brandRef,
      course: lesson.course,
      courseRef: lesson.courseRef,
      currentData: {
        course: { key: course.key, localizedFields: { ...course.localizedFields } },
        lesson: { key: lesson.key, localizedFields: { ...lesson.localizedFields } },
      },
      lesson: lesson.id,
      lessonRef: this.brandRef.collection(Lesson.collection).doc(lesson.id),
      timestamp: serverTimestamp,
      user: this.id,
      userRef: userRef,
    });

    return batch.commit();
  }

  public async deleteAvatar() {
    const storageRef = storage.ref().child(`avatars/${this.id}/${this.id}`);
    const userRef = firestore.collection(User.collection).doc(this.id);

    onFirestore('delete', userRef.path, 'photoUrl');
    onStorage('delete', storageRef.fullPath);

    return Promise.all([
      userRef
        .set({ settings: { photoUrl: '' } }, { merge: true })
        .then(() => {
          onSuccess(userRef.path, 'delete-success', 'photoUrl');
        })
        .catch(err => {
          console.error('Updating document did not work.');
          onError('User', err);
        }),
      storageRef
        .delete()
        .then(() => {
          onSuccess(storageRef.fullPath, 'delete-success', 'avatar');
        })
        .catch(err => {
          console.error('Storage object could not be deleted.');
          onError('User', err);
        }),
    ]);
  }

  public getLearningProgressInPercentage(lessons: Lesson[], userProgress: UserProgress[]) {
    const availableLessonIds = lessons.map(lesson => lesson.id);
    const availableLessonsLength = availableLessonIds.length;
    const passedLessonIds = userProgress
      .map(progress => progress.lesson)
      .filter(progressId => availableLessonIds.includes(progressId));
    const passedLessonsLength = passedLessonIds.length;

    if(passedLessonsLength === 0) return 0;

    const percentageProgress = (passedLessonsLength / availableLessonsLength) * 100;
    return percentageProgress;
  }

  public getName() {
    if (this.settings.firstname && this.settings.lastname)
      return this.settings.firstname + ' ' + this.settings.lastname;

    return ' ';
  }

  public getWhatsNextLesson(courses: Course[], lessons: Lesson[], userProgress: UserProgress[]): Lesson | undefined {
    const sortedCourses = courses.sort((a, b) => a.sortIndex - b.sortIndex);

    for (const course of sortedCourses) {
      const sortedCourseLessons = lessons
        .filter(lesson => lesson.course === course.id)
        .sort((a, b) => a.sortIndex - b.sortIndex);
      for (const lesson of sortedCourseLessons) {
        if (lesson.getStatus(this.lessonsProgress, userProgress) === undefined) {
          return lesson;
        }
      }
    }

    for (const course of sortedCourses) {
      const sortedCourseLessons = lessons
        .filter(lesson => lesson.course === course.id)
        .sort((a, b) => a.sortIndex - b.sortIndex);
      for (const lesson of sortedCourseLessons) {
        if (lesson.getStatus(this.lessonsProgress, userProgress) === 'update') {
          return lesson;
        }
      }
    }

    const sortedProgress = userProgress
      .filter(progress => lessons.map(lesson => lesson.id).includes(progress.lesson))
      .sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis());

    if (sortedProgress.length > 0) {
      return lessons.find(lesson => lesson.id === sortedProgress[0].lesson);
    }

    return undefined;
  }

  public async reauthenticate(password: string) {
    const currentUser = authentication.currentUser;

    if (!currentUser) {
      throw new Error('Current authenticated user could not be found.');
    }

    const credential: firebase.default.auth.AuthCredential = app.auth.EmailAuthProvider.credential(
      this.email,
      password
    );

    return currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        onInfo('Model/User', 'Reauthenticating was succesful.');
      })
      .catch(err => {
        onError('Model/User', err);
      });
  }

  public async updateAvatar(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files) {
      console.error('Files could not be found ...');
      return;
    }

    const file = files[0];

    if (!file) {
      console.error('Single file could not be found...');
      return;
    }

    if (file.size > 5 * 1000 * 1000) {
      console.error('Maximum file size exceeded.');
    }

    const ref = storage.ref().child(`avatars/${this.id}/${this.id}`);

    try {
      onStorage('put', ref.fullPath);
      await ref.put(file, { customMetadata: { brandId: this.brand, uid: this.id } });
      onSuccess(ref.fullPath, 'put-success');
    } catch (err) {
      console.error(err);
      throw Error('Upload did not work.');
    }

    let downloadUrl = '';

    try {
      onStorage('getDownloadUrl', ref.fullPath);
      downloadUrl = await ref.getDownloadURL();
      onSuccess(ref.fullPath, 'getDownloadUrl-success');
    } catch (err) {
      console.error('Getting download url did not work.');
      return;
    }

    const userRef = firestore.collection(User.collection).doc(this.id);

    onFirestore('set', userRef.path, ['lastUpdateAt', 'lastUpdateFrom', 'lastUpdateFromRef', 'settings: { photoUrl }']);

    return userRef
      .set(
        {
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: this.id,
          lastUpdateFromRef: userRef,
          settings: { photoUrl: downloadUrl },
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(userRef.path, 'set-success');
      });
  }

  public async updateEmail(email: string) {
    authentication.languageCode = this.settings.currentLocale === 'de' ? 'de' : 'en';
    const currentUser = authentication.currentUser;

    if (!currentUser) {
      throw new Error('Current authenticated user could not be found.');
    }

    const userRef = firestore.collection(User.collection).doc(this.id);

    onFirestore('set', userRef.path, ['email', 'lastUpdateAt', 'lastUpdateFrom', 'lastUpdateFromRef', 'usedEmails']);
    return Promise.all([
      currentUser
        .updateEmail(email)
        .then(() => {
          onInfo('Model/User', 'Updating email (auth object) was succesful.');
        })
        .catch(err => {
          onError('Model/User', err);
        }),
      firestore
        .collection(User.collection)
        .doc(this.id)
        .set(
          {
            email: email,
            lastUpdateAt: serverTimestamp,
            lastUpdateFrom: this.id,
            lastUpdateFromRef: userRef,
            usedEmails: arrayUnion(email),
          },
          { merge: true }
        )
        .then(() => {
          onSuccess(userRef.path, 'set-success');
        })
        .catch(err => {
          onError('Model/User', err);
        }),
    ]);
  }

  public async updateGeneralSettings(firstname: string, lastname: string, newLocale: string) {
    const userRef = firestore.collection(User.collection).doc(this.id);

    onFirestore('set', userRef.path, [
      'lastUpdateAt',
      'lastUpdateFrom',
      'lastUpdateFromRef',
      'settings: { currentLocale, firstname, lastname }',
    ]);

    return userRef
      .set(
        {
          lastUpdateAt: serverTimestamp,
          lastUpdateFrom: this.id,
          lastUpdateFromRef: userRef,
          settings: { currentLocale: newLocale, firstname, lastname },
        },
        { merge: true }
      )
      .then(() => {
        onSuccess(userRef.path, 'set-success');
      })
      .catch(err => {
        console.error('Updating general settings did not work...');
        onError('User', err);
      });
  }

  public async updatePassword(password: string) {
    authentication.languageCode = this.settings.currentLocale === 'de' ? 'de' : 'en';
    const currentUser = authentication.currentUser;

    if (!currentUser) {
      throw new Error('Current authenticated user could not be found.');
    }

    return currentUser
      .updatePassword(password)
      .then(() => {
        onInfo('Model/User', 'Updating password was succesful.');
      })
      .catch(err => {
        onError('Model/User', err);
      });
  }
}

export default User;
