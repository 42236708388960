import React, { useContext } from 'react';
import { Avatar, Grid, MenuItem, TextField, useTheme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import useNotification from 'hooks/notification/useNotification';
import { Brand, User } from 'models';
import { DataContext } from 'providers/Data';
import { FormDialog } from 'components/Dialogs/FormDialog';
import { IUserFormData } from 'interfaces';
import { SelectWithChipsField } from 'components/Forms/SelectWithChips';
import { useGlobalStyles } from 'providers/Theme';
import { userValidation } from '.';

export default function UserFields({
  handleClick,
  handleClose,
  open,
  initialValues,
  title,
  type,
  user,
}: {
  handleClick: (values: IUserFormData) => Promise<void>;
  handleClose: () => void;
  open: boolean;
  initialValues: IUserFormData;
  title: string;
  type: 'create' | 'update';
  user?: User;
}) {
  const globalClasses = useGlobalStyles();
  const notify = useNotification();
  const theme = useTheme();
  const { brand, groups, roles, units } = useContext(DataContext);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleClick(values)
          .then(() => {
            if (type === 'create') {
              notify(t('alert_user_creation_success'));
            }
            if (type === 'update') {
              notify(t('alert_user_update_success'));
            }
          })
          .catch(() => {
            if (type === 'create') {
              notify(t('alert_user_creation_error'), 'error');
            }
            if (type === 'update') {
              notify(t('alert_user_update_error'), 'error');
            }
          });
      }}
      validateOnBlur
      validationSchema={() => userValidation()}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <>
            <FormDialog
              createButtonText={t('create_button')}
              handleClick={handleSubmit}
              handleClose={handleClose}
              loading={isSubmitting}
              open={open}
              title={title}
              type={type}
              updateButtonText={t('update_button')}
            >
              <Form className={globalClasses.form} noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <Avatar
                        style={{ height: theme.spacing(12), width: theme.spacing(12), marginBottom: theme.spacing(1) }}
                        src={user?.settings.photoUrl}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.email && touched.email}
                      fullWidth
                      helperText={!!errors.email && touched.email && errors.email ? errors.email : ' '}
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label='Email'
                      name='email'
                      required
                      size='small'
                      value={values.email}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.role && touched.role}
                      fullWidth
                      helperText={!!errors.role && touched.role && errors.role ? errors.role : ' '}
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='role'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('user_role_label')}
                      name='role'
                      required
                      select
                      size='small'
                      value={values.role}
                      variant='outlined'
                    >
                      {[...roles.brand, ...roles.default]
                        .sort((a: any, b: any) => {
                          let tempA = a;
                          let tempB = b;
                          if (a.isDefaultRole) {
                            tempA.setValue('key', a.id);
                          }
                          if (b.isDefaultRole) {
                            tempB.setValue('key', b.id);
                          }

                          return tempA.key?.localeCompare(b.key);
                        })
                        .map(role => (
                          <MenuItem key={role.id} value={role.id}>
                            {(role.key as string).charAt(0).toUpperCase() + (role.key as string).slice(1)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={isSubmitting}
                      error={!!errors.currentLocale && touched.currentLocale}
                      fullWidth
                      helperText={
                        !!errors.currentLocale && touched.currentLocale && errors.currentLocale
                          ? errors.currentLocale
                          : ' '
                      }
                      FormHelperTextProps={{
                        style: {
                          minHeight: 19,
                        },
                      }}
                      id='currentLocale'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('user_current_locale_label')}
                      name='currentLocale'
                      required
                      select
                      size='small'
                      value={values.currentLocale}
                      variant='outlined'
                    >
                      {(brand as Brand).configuration.locales.map(locale => (
                        <MenuItem key={locale} value={locale}>
                          {t(`locale_${locale}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectWithChipsField
                      dense
                      disabled={groups.length === 0 || isSubmitting}
                      handleChange={handleChange}
                      label={t('user_groups_label')}
                      name='groups'
                      selectedValues={values.groups}
                      values={groups.map(group => ({ label: group.key || group.id, value: group.id }))}
                    />
                    <div style={{ minHeight: 19 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectWithChipsField
                      dense
                      disabled={units.length === 0 || isSubmitting}
                      handleChange={handleChange}
                      label={t('user_units_label')}
                      name='units'
                      selectedValues={values.units}
                      values={units.map(unit => ({ label: unit.key, value: unit.id }))}
                    />
                    <div style={{ minHeight: 19 }} />
                  </Grid>
                </Grid>
              </Form>
            </FormDialog>
          </>
        );
      }}
    </Formik>
  );
}
