import React from 'react';
import { CheckCircle, NotificationsActive } from '@material-ui/icons';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LearningStatus } from 'types';
import { RobotoMedium } from 'components/Common/Roboto/Medium';
import { useStyles } from '.';

export default function Status({ status }: { status: LearningStatus }) {
  const classes = useStyles();
  const theme = useTheme();
  const xxs = useMediaQuery('(min-width:256px)');
  const { t } = useTranslation();

  if (status === undefined) return null;

  const color =
    status === 'passed'
      ? theme.palette.success.main
      : status === 'update'
      ? theme.palette.primary.main
      : theme.palette.text.secondary;

  const renderIcon = () =>
    status === 'passed' ? (
      <CheckCircle className={classes.icon} fontSize='small' style={{ color }} />
    ) : status === 'update' ? (
      <NotificationsActive className={classes.icon} fontSize='small' style={{ color }} />
    ) : null;

  return (
    <div className={classes.container}>
      {renderIcon()}
      {xxs && (
        <RobotoMedium style={{ color, fontSize: 12 }}>
          {status === 'passed' || status === 'update' ? t('status_' + status) : status}
        </RobotoMedium>
      )}
    </div>
  );
}
