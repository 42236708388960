import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'components/Buttons/Loading';
import useBreakpoints from 'hooks/layout/useBreakpoints';

export default function FormDialog({
  children,
  createButtonText,
  handleClick,
  handleClose,
  open,
  title,
  updateButtonText,
  loading,
  type,
}: {
  children: ReactNode;
  createButtonText: string;
  updateButtonText: string;
  handleClick: () => void;
  handleClose: () => void;
  loading?: boolean;
  open: boolean;
  title: string;
  type?: 'create' | 'update';
}) {
  const { t } = useTranslation();
  const { xs } = useBreakpoints();

  return (
    <Dialog
      fullScreen={xs}
      fullWidth
      maxWidth='sm'
      onClose={() => {
        if (!loading) {
          return handleClose();
        }
      }}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='default' variant='outlined'>
          {t('cancel_button')}
        </Button>
        <LoadingButton color='primary' loading={loading} onClick={handleClick} variant='contained'>
          {type === 'create' && createButtonText}
          {type === 'update' && updateButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
