import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminPage } from 'pages/Admin';
import { AuthenticationContext } from 'providers/Authentication';
import { AuthenticationPage } from 'pages/Authentication';
import { CircularProgress } from 'components/Loading/CircularProgress';
import { DataProvider } from 'providers/Data';
import { FilesPage } from 'pages/App/Files';
import { HomePage } from 'pages/App/Home';
import { Layout } from 'components/Layout';
import { LearningPage } from 'pages/App/Learning';
import { NewsDetailPage } from 'pages/App/News/NewsDetail';
import { NewsPage } from 'pages/App/News';
import { NotFoundView } from 'views/NotFound';
import { SettingsPage } from 'pages/Settings';
import { UserProvider } from 'providers/User';

export default function Brainee() {
  const { authUser, loading } = useContext(AuthenticationContext);

  if (loading) {
    return (
      <Layout disableHeader verticalCentered>
        <CircularProgress />
      </Layout>
    );
  }

  if (!authUser) return <AuthenticationPage />;

  return (
    <UserProvider>
      <DataProvider>
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route path='/admin'>
            <AdminPage />
          </Route>
          <Route path='/learning'>
            <LearningPage />
          </Route>
          <Route exact path='/news'>
            <NewsPage />
          </Route>
          <Route exact path='/news/:newsId'>
            <NewsDetailPage />
          </Route>
          <Route exact path='/files'>
            <FilesPage />
          </Route>
          <Route path='/settings'>
            <SettingsPage />
          </Route>

          {/* Accesible routes when user is signed out */}
          <Route exact path={['/forgot-password', '/login']}>
            <Redirect to='/' />
          </Route>

          <Route path='*'>
            <NotFoundView />
          </Route>
        </Switch>
      </DataProvider>
    </UserProvider>
  );
}
