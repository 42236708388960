import React, { useState } from 'react';
import useNotification from 'hooks/notification/useNotification';
import { Add } from '@material-ui/icons';
import { AddContentBuilderItemDialog } from 'components/Dialogs/AddContentBuilderItemDialog';
import { Button, Grid, Paper, makeStyles, useTheme } from '@material-ui/core';
import { ContentBuilderItemBar } from 'components/Forms/ContentBuilder/Items/Bar';
import { EditorState } from 'draft-js';
import {
  IAdminContentEditorItem,
  IContentImageItem,
  IContentVideoItem,
  ITwoColumnAdminLayoutItem,
  IThreeColumnAdminLayoutItem,
} from 'interfaces';
import { MediaLibraryDialog } from 'components/Dialogs/MediaLibrary';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';

const useLocalStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${theme.palette.divider}`,
    height: '100%',
  },
  root: {
    minHeight: theme.spacing(26),
  },
}));

export default function LayoutTwoColumnItem({
  activeLocale,
  item,
}: {
  activeLocale: string;
  item: ITwoColumnAdminLayoutItem;
}) {
  const classes = useLocalStyles();
  const [selectItemDialog, setSelectItemDialog] = useState<{ open: boolean; column: 'left' | 'right' | null }>({
    column: null,
    open: false,
  });
  const [selectMediaDialog, setSelectMediaDialog] = useState<{ open: boolean; column: 'left' | 'right' | null }>({
    column: null,
    open: false,
  });

  const notify = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const handleAddMediaItem = (column: 'left' | 'right', name: string, url: string, type: 'image' | 'video') => {
    const copyArr: (
      | ITwoColumnAdminLayoutItem
      | IContentImageItem
      | IContentVideoItem
      | IAdminContentEditorItem
      | IThreeColumnAdminLayoutItem
    )[] = [...(values as any).localizedFields.content[activeLocale]];

    const index = copyArr.findIndex(copy => copy.sortIndex === item.sortIndex && item.type === 'two-column');
    const itemCopy: ITwoColumnAdminLayoutItem = copyArr[index] as ITwoColumnAdminLayoutItem;

    const newItem: IContentImageItem | IContentVideoItem = {
      content: { name, url },
      id: uuid(),
      sortIndex: itemCopy.columns[column].length + 1,
      type,
      parent: {
        id: item.id,
      },
    };

    itemCopy.columns[column].push(newItem);

    copyArr[index] = { ...itemCopy };

    setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
  };

  const handleAddEditorItem = (column: 'left' | 'right') => {
    const copyArr: (
      | ITwoColumnAdminLayoutItem
      | IContentImageItem
      | IContentVideoItem
      | IAdminContentEditorItem
      | IThreeColumnAdminLayoutItem
    )[] = [...(values as any).localizedFields.content[activeLocale]];

    const index = copyArr.findIndex(copy => copy.sortIndex === item.sortIndex && item.type === 'two-column');
    const itemCopy: ITwoColumnAdminLayoutItem = copyArr[index] as ITwoColumnAdminLayoutItem;

    const newItem: IAdminContentEditorItem = {
      content: EditorState.createEmpty(),
      id: uuid(),
      sortIndex: itemCopy.columns[column].length + 1,
      type: 'editor',
      parent: {
        id: item.id,
      },
    };

    itemCopy.columns[column].push(newItem);

    copyArr[index] = { ...itemCopy };

    setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
  };

  const handleDeleteItem = (column: 'left' | 'right', contentId: string) => {
    const copyArr: (
      | ITwoColumnAdminLayoutItem
      | IContentImageItem
      | IContentVideoItem
      | IAdminContentEditorItem
      | IThreeColumnAdminLayoutItem
    )[] = [...(values as any).localizedFields.content[activeLocale]];
    const rootIndex = copyArr.findIndex(copy => copy.sortIndex === item.sortIndex && item.type === 'two-column');
    const itemCopy: ITwoColumnAdminLayoutItem = copyArr[rootIndex] as ITwoColumnAdminLayoutItem;

    const index = itemCopy.columns[column].findIndex(leftItem => leftItem.id === contentId);

    if (index !== -1) {
      itemCopy.columns[column].splice(index, 1);
      copyArr[rootIndex] = {
        ...itemCopy,
        columns: {
          ...itemCopy.columns,
          [column]: itemCopy.columns[column].map((item, index) => ({ ...item, sortIndex: index + 1 })),
        },
      };
      setFieldValue(`localizedFields.content.${activeLocale}`, copyArr);
    }
  };

  return (
    <>
      {selectItemDialog.open && (
        <AddContentBuilderItemDialog
          handleAddEditorItem={() => {
            const stateCopy = { ...selectItemDialog };
            if (stateCopy.column) {
              handleAddEditorItem(stateCopy.column);
            }
          }}
          handleAddMediaItem={() => {
            const stateCopy = { ...selectItemDialog };
            setSelectMediaDialog({ column: stateCopy.column, open: true });
          }}
          locale={activeLocale}
          noLayoutItems
          onClose={() => {
            setSelectItemDialog(prevState => ({ ...prevState, open: false, column: null }));
          }}
          open
        />
      )}
      {selectMediaDialog.open && (
        <MediaLibraryDialog
          handleClose={() => {
            setSelectMediaDialog(prevState => ({ ...prevState, open: false, column: null }));
          }}
          selectFile={file => {
            const column = selectMediaDialog.column;

            if (!file || !file.contentType) {
              notify(t('alert_media_item_picked_error'), 'error');
              return;
            }
            if (file.contentType.startsWith('image/') && column) {
              handleAddMediaItem(column, file.name, file.url, 'image');
              return;
            }

            if (file.contentType.startsWith('video/') && column) {
              handleAddMediaItem(column, file.name, file.url, 'video');
              return;
            }

            notify(t('alert_media_item_picked_error'), 'error');
          }}
          open={true}
        />
      )}
      <Grid container className={classes.root}>
        <Grid className={classes.item} item xs={12} sm={6}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid container>
                {item.columns.left.map(leftItem => (
                  <Grid key={leftItem.id} item xs={12} style={{ margin: theme.spacing(1, 2, 0) }}>
                    <Paper className={classes.paper}>
                      <ContentBuilderItemBar
                        activeLocale={activeLocale}
                        calledFromColumn='left'
                        handleDeleteItem={() => handleDeleteItem('left', leftItem.id)}
                        item={leftItem}
                        isRootItem={false}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
              <Paper square>
                <Button
                  onClick={() => setSelectItemDialog({ open: true, column: 'left' })}
                  fullWidth
                  style={{ borderRadius: 0 }}
                >
                  <Add />
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.item} item xs={12} sm={6}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid container>
                {item.columns.right.map(rightItem => (
                  <Grid key={rightItem.id} item xs={12} style={{ margin: theme.spacing(1, 2, 0) }}>
                    <Paper className={classes.paper}>
                      <ContentBuilderItemBar
                        activeLocale={activeLocale}
                        calledFromColumn='right'
                        handleDeleteItem={() => handleDeleteItem('right', rightItem.id)}
                        item={rightItem}
                        isRootItem={false}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
              <Paper square>
                <Button
                  onClick={() => setSelectItemDialog({ open: true, column: 'right' })}
                  fullWidth
                  style={{ borderRadius: 0 }}
                >
                  <Add />
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
